/**
 * Returns a formatted string, considering the locale and
 * precision digits passed on parameters.
 *
 * @param showMark - Boolean that defines if the currency mark will be displayed.
 * _Default is `false`_.
 * @param precision - Number that defines the amount of precision digits.
 * _Default is 2_.
 * @param numericValue - Number that will be formatted.
 */
export const formatNumber = ({
  numericValue,
  precision = 2,
  showMark = false,
}: {
  numericValue: number;
  showMark?: boolean;
  precision?: number;
}) => {
  let locale = localStorage.getItem("i18nextLng") as string;
  let currency = "BRL";
  let style = "decimal";

  if (locale !== "pt-BR") {
    currency = "USD";
  }

  if (showMark) {
    style = "currency";
  }

  return new Intl.NumberFormat(locale, {
    style,
    currency: currency,
    minimumFractionDigits: precision,
    maximumFractionDigits: precision,
  }).format(numericValue);
};

/** The equivalent to one Megabyte in Bytes. */
export const MB_IN_BYTES = 1048576;

import { all, takeLatest, call, put, select } from "redux-saga/effects";
import i18n from "../../../i18n";

import api from "../../../services/api";
import { addMessage } from "../toast/actions";
import { signInSuccess, signInFailure } from "./actions";
import { AuthTypes } from "./types";
import { ApplicationState } from "store";

export function* signIn({ payload }: any) {
  try {
    const { totp, remember } = payload;
    const { name, password } = yield select((state: ApplicationState) => state.auth.credentials);
    const { data } = yield call(
      api.post,
      "/sign-in-keycloak",
      {
        name,
        password,
        totp
      },
      {
        withCredentials: true,
      }
    );

    if (!data.token) {
      console.log("Falha ao logar!");
      return;
    }

    api.defaults.headers.common["Authorization"] = `Bearer ${data.token}`;

    yield put(
      signInSuccess(
        data.token,
        data.person,
        data.accessType,
        remember ? name : ""
      )
    );
  } catch (error) {
    yield put(
      addMessage({
        title: i18n.t("toasts:invalidUsernameOrPassword"),
        type: "error",
      })
    );
    yield put(signInFailure());
  }
}

export function* signInMfa({ payload }: any) {
  try {
    const { username, totpInitialCode, remember, encodedTotpSecret } = payload;
    const { password } = yield select((state: ApplicationState) => state.auth.credentials);

    const token = yield select((state: ApplicationState) => state.auth.responseData.token);
    api.defaults.headers.common["Authorization"] = `Bearer ${token}`;

    const { data } = yield call(
      api.post,
      "/keycloak/mfa/submit",
      {
        username,
        password,
        deviceName: "admin-api",
        totpInitialCode,
        encodedTotpSecret,
      }
    );

    api.defaults.headers.common["Authorization"] = `Bearer ${data.token}`;

    yield put(
      signInSuccess(
        data.token,
        data.person,
        data.accessType,
        remember ? username : ""
      )
    );

  } catch (error) {
    yield put(
      addMessage({
        title: i18n.t("toasts:invalidUsernameOrPassword"),
        type: "error",
      })
    );
    yield put(signInFailure());
  }
}


export function signOut() {
  delete api.defaults.headers.common["Authorization"];
}

export function setToken({ payload }: any) {
  api.defaults.headers.common["Authorization"] = `Bearer ${payload}`;
}

export default all([
  takeLatest(AuthTypes.SIGN_IN_REQUEST, signIn),
  takeLatest(AuthTypes.SIGN_IN_MFA_REQUEST, signInMfa),
  takeLatest(AuthTypes.SIGN_OUT, signOut),
  takeLatest(AuthTypes.SET_TOKEN, setToken),
]);

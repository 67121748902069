import React, { useRef, useEffect, useState } from "react";
import { useField } from "@unform/core";
import { FormGroup, InputGroup, InputGroupText } from "reactstrap";

interface Props {
  name: string;
  label?: string;
}
type InputProps = JSX.IntrinsicElements["input"] & Props;

const InputGroupPassword: React.FC<InputProps> = ({
  name,
  label,
  onFocus,
  onBlur,
  ...rest
}) => {
  const { fieldName, defaultValue, registerField, error } = useField(name);

  const inputRef = useRef<HTMLInputElement>(null);

  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [isFocused, setIsFocused] = useState(false);

  const getStyles = (pStyle) => {
    if (isFocused) {
      return {
        ...pStyle,
        borderColor: "#2684FF",
        boxShadow: "0 0 0 1px #2684FF",
      };
    }

    return { ...pStyle };
  };

  const handleFocus = (e) => {
    setIsFocused(true);

    if (onFocus) {
      onFocus(e);
    }
  };

  const handleBlur = (e) => {
    setIsFocused(false);

    if (onBlur) {
      onBlur(e);
    }
  };

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      getValue: (ref) => {
        return ref.value;
      },
      setValue: (ref, value) => {
        ref.value = value;
      },
      clearValue: (ref) => {
        ref.value = "";
      },
    });
  }, [fieldName, registerField]);

  return (
    <FormGroup>
      {label && <label htmlFor={fieldName}>{label}</label>}
      <InputGroup>
        <input
          id={fieldName}
          className={`form-control ${error && "is-invalid"}`}
          ref={inputRef}
          defaultValue={defaultValue}
          type={showPassword ? "text" : "password"}
          onFocus={handleFocus}
          onBlur={handleBlur}
          style={getStyles(rest.style)}
          {...rest}
        />
        <InputGroupText
          className="cursor-pointer"
          onClick={() => setShowPassword(!showPassword)}
        >
          <i
            className={`${showPassword ? "mdi mdi-eye" : "mdi mdi-eye-off"}`}
          />
        </InputGroupText>
      </InputGroup>
      {error && <span className="error">{error}</span>}
    </FormGroup>
  );
};

export default InputGroupPassword;

import React, {
  createContext,
  useState,
  useContext,
  Dispatch,
  SetStateAction,
  useMemo,
} from 'react';
import { Listing } from '../store/modules/listing/types';

interface EditListingContextData {
  listing: Listing;
  setListing: Dispatch<SetStateAction<Listing>>;
  hasListing: boolean;
}

const EditListingContext = createContext<EditListingContextData>(
  {} as EditListingContextData,
);

export const EditListingProvider: React.FC = ({ children }) => {
  const [listing, setListing] = useState<Listing>(null);
  const listingProviderValue = useMemo(
    () => ({ listing, setListing, hasListing: !!listing }),
    [listing, setListing],
  );

  return (
    <EditListingContext.Provider value={listingProviderValue}>
      {children}
    </EditListingContext.Provider>
  );
};

export function useEditListing(): EditListingContextData {
  const context = useContext(EditListingContext);

  if (!context) {
    throw new Error(
      'useEditListing must be used within an EditListingProvider',
    );
  }

  return context;
}

import React, { useState, useEffect, useRef, useMemo } from "react";
import { Card, CardBody, Row, Col } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { FormHandles } from "@unform/core";
import { Form } from "@unform/web";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

import { ApplicationState } from "../../store";
import { addMessage } from "../../store/modules/toast/actions";
import Input from "../../components/FormFields/Input";
import InputMask from "../../components/FormFields/InputMask";
import SEO from "../../components/SEO";
import { useBreadcrumb } from "../../hooks/useBreadcrumb";
import api from "../../services/api";
import { Order } from "store/modules/order/types";
import { AccessType, UserState } from "store/modules/user/types";
import { ACCESS_TYPE_BROKER_B0, ACCESS_TYPE_BROKER_B1, ACCESS_TYPE_BROKER_B2 } from "utils/constants";
import ActionBar from "components/ActionBar";
import { permission } from "utils/permissionEnums";
import Spinner from "components/Spinner";
import { formatDateToBrazilian } from "utils/date-time";
import { Spinner as ReactSpinner } from "reactstrap";
import { formatCpfCnpj } from "utils/documents";

const OrderDetails: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const { t } = useTranslation("orders");

  const { setBreadcrumbItems } = useBreadcrumb();
  // const { hasOrder, order, setOrder } = useEditOrder();

  const formRef = useRef<FormHandles>(null);
  const userState = useSelector<ApplicationState, UserState>((s) => s.user);
  const [orderDetail, setOrderDetail] = useState<Order>();
  const [hasPermissionToExecute, setHasPermissionToExecute] = useState<boolean>(false);
  const [loadingActionBar, setLoadingActionBar] = useState(false);

  const accessType = useSelector<ApplicationState, AccessType>(
    (state) => state.user.accessType
  );
  const isAccessTypeBroker = useMemo(
    () =>
      [ACCESS_TYPE_BROKER_B0, ACCESS_TYPE_BROKER_B1, ACCESS_TYPE_BROKER_B2].includes(
        accessType.name
      ),
    [accessType.name]
  );


  useEffect(() => {
    setBreadcrumbItems({
      title: "Detalhe da ordem",
      items: [
        { title: "home", link: "/dashboard" },
        { title: "orders", link: "/orders" },
        { title: "Detalhe da ordem", link: "" },
      ],
    });
  }, [setBreadcrumbItems]);

  useEffect(() => {

    const controller = new AbortController();

    async function getPageData() {
      try {

        const { data: orderDetail } = await api.get(`/orders/${id}`, {
          signal: controller.signal,
        });

        setOrderDetail(orderDetail);

        const orderCreatedByLogin = orderDetail.createdBy.split('|')[0].trim();
        const orderCreatedByCompanyType = orderDetail.createdBy.split('|')[1] ? orderDetail.createdBy.split('|')[1].trim() : '';

        if (
          orderDetail.status == 'CREATED' &&
          (userState.profile.login != orderCreatedByLogin && userState.profile.companyType == orderCreatedByCompanyType) &&
          isValidDate(orderDetail.orderDate)
        ) {
          setHasPermissionToExecute(true);
        }

      } catch (error) {
        console.log(error)
        dispatch(
          addMessage({
            title: t("error"),
            description: t("itWasNotPossibleToBringInitialData"),
            type: "error",
          })
        );
      }
    }

    getPageData();

    return () => {
      controller.abort();
    };
  }, [dispatch, t]);

  async function executeOrder() {

    try {

      const controller = new AbortController();

      setLoadingActionBar(true);

      const { data } = await api.patch(`/orders`, {
        orderId: orderDetail.orderId,
        assignorDocument: orderDetail.assignorDocument,
        transfereeDocument: orderDetail.transfereeDocument,
        distributorDocument: orderDetail.distributorDocument
      });

      setLoadingActionBar(false);

      navigate('/orders');

      dispatch(
        addMessage({
          title: t("orderHasBeenExecuted"),
          type: "info",
        })
      );

    } catch (error) {
      console.log(error)
      setLoadingActionBar(false);
    }

  }

  function isValidDate(dateFromOrder: string): boolean {
    const dateGMT = new Date(new Date(dateFromOrder).setUTCHours(0,0,0,0));
    const currentDateGMT =  new Date(new Date().setUTCHours(0,0,0,0));
    return dateGMT >= currentDateGMT;
  }

  useEffect(() => {
    const errors = formRef.current?.getErrors();

    if (errors && Object.keys(errors).length > 0) {
      formRef.current.submitForm();
    }
  }, [i18next.language]);

  async function handleAction(
    type: "EXECUTE",
    reason?: string
  ) {
    console.log('type -> ', type)
    if (type === "EXECUTE") executeOrder();
  }

  if (!orderDetail?.orderId) return <Spinner />;

  return (
    <>
      <SEO
        title={
          t("Detalhe da ordem", { ns: "breadcrumb" })
        }
        shouldIndexPage={false}
      />

      <Row>
        <Col xs="12" md="6">
          <p />
        </Col>
        <Col xs="12" md="6">
          <div className="text-end mb-4">
            <div className="button-items">
              <ActionBar
                actions={[
                  {
                    permission: hasPermissionToExecute,
                    action: permission.EXECUTE,
                  },
                ]}
                onClick={handleAction}
                disabled={loadingActionBar}
              />
              {loadingActionBar && (
                <ReactSpinner
                  className="ml-1"
                  size="md"
                />
              )
              }
            </div>
          </div>
        </Col>
      </Row>

      <Form
        ref={formRef}
        onSubmit={null}
        initialData={orderDetail}
      >
        <Card>
          <CardBody>
            <h4 className="card-title mb-4 text-uppercase">
              {t("orderData")}
            </h4>


            <Row>
              {!isAccessTypeBroker ?
                <Col xs="12" md="4">
                  <Input
                    label={t("distributor")}
                    name="distributor"
                    value={`${formatCpfCnpj(orderDetail.distributorDocument)} - ${orderDetail.distributor}`}
                    disabled
                  />
                </Col> : null}
              <Col xs="12" md="4">
                <Input
                  label={t("date")}
                  name="orderDate"
                  value={formatDateToBrazilian(new Date(orderDetail.orderDate))}
                  disabled
                />
              </Col>
              <Col xs="12" md="4">
                <Input
                  label={t("state")}
                  name="state"
                  value={t(orderDetail.status)}
                  disabled
                />
              </Col>
            </Row>
            <Row>
              <Col xs="12" md="4">
                <Input
                  label={t("ticker")}
                  name="ticker"
                  value={orderDetail.ticker}
                  disabled
                />
              </Col>
              <Col xs="12" md="4">
                <Input
                  label={t("assignor")}
                  name="assignor"
                  value={`${formatCpfCnpj(orderDetail.assignorDocument)} - ${orderDetail.assignorCorporateName}`}
                  disabled
                />
              </Col>
              <Col xs="12" md="4">
                <Input
                  label={t("transferee")}
                  name="transferee"
                  value={`${formatCpfCnpj(orderDetail.transfereeDocument)} - ${orderDetail.transfereeCorporateName}`}
                  disabled
                />
              </Col>
              <Col xs="12" md="4">

                <InputMask
                  isNumericString
                  fixedDecimalScale
                  label={t("quantity")}
                  name="quantity"
                  decimalScale={0}
                  value={orderDetail.qtdTokens}
                  disabled
                />
              </Col>
              <Col xs="12" md="4">
                <InputMask
                  isNumericString
                  fixedDecimalScale
                  label={t("PU")}
                  name="unitValue"
                  thousandSeparator="."
                  decimalSeparator=","
                  decimalScale={8}
                  prefix="R$ "
                  value={orderDetail.negotiatedUnitPrice}
                  disabled
                />
              </Col>
              <Col xs="12" md="4">
                <InputMask
                  isNumericString
                  fixedDecimalScale
                  label={t("orderValue")}
                  name="value"
                  thousandSeparator="."
                  decimalSeparator=","
                  decimalScale={2}
                  prefix="R$ "
                  value={orderDetail.value}
                  disabled
                />
              </Col>
            </Row>
          </CardBody>
        </Card>

      </Form>
    </>
  );
};

export default OrderDetails;

import React, { useEffect, useCallback } from 'react';
import { animated } from 'react-spring';
import { useDispatch } from 'react-redux';

import { removeMessage } from '../store/modules/toast/actions';

interface ToastMessage {
  id: string;
  type?: 'success' | 'error' | 'info';
  title: string;
  description?: string;
}

interface ToastProps {
  message: ToastMessage;
  style: Record<string, unknown>;
}

const icons = {
  info: <i className="mdi mdi-information-outline fs-5 me-2 lh-1" />,
  error: <i className="mdi mdi-alert-circle-outline fs-5 me-2 lh-1" />,
  success: <i className="mdi mdi-check-circle-outline fs-5 me-2 lh-1" />,
};

const colors = {
  info: {
    background: '#d4f1f9',
    color: '#156176',
  },
  success: {
    background: '#def8e6',
    color: '#2e7244',
  },
  error: {
    background: '#fbdde2',
    color: '#c53030',
  },
};

const Toast: React.FC<ToastProps> = ({ message, style }) => {
  const dispatch = useDispatch();

  const removeToast = useCallback(
    (id: string) => {
      dispatch(removeMessage(id));
    },
    [dispatch],
  );

  useEffect(() => {
    const timer = setTimeout(() => {
      removeToast(message.id);
    }, 3000);

    return () => {
      clearTimeout(timer);
    };
  }, [removeToast, message.id]);

  return (
    <animated.div
      className="position-relative d-flex mb-2 rounded-3 shadow"
      style={{
        ...style,
        ...{
          width: '360px',
          padding: message.description ? '12px 16px 10px' : '16px',
        },
        ...colors[message.type],
      }}
    >
      {icons[message.type || 'info']}
      <div>
        <strong>{message.title}</strong>
        {message.description && (
          <p
            className="mt-1 opacity-75 font-size-14"
            style={{ lineHeight: '20px' }}
          >
            {message.description}
          </p>
        )}
      </div>
      <button
        type="button"
        className="position-absolute border-0 bg-transparent"
        style={{
          right: '5px',
          alignItems: message.description ? 'center' : '',
        }}
        onClick={() => removeToast(message.id)}
      >
        <i
          className="mdi mdi-close-circle-outline fs-5 lh-1"
          style={{
            margin: message.description ? '0 12px 0 0' : '4px 12px 0 0',
            color: colors[message.type].color,
          }}
        />
      </button>
    </animated.div>
  );
};

export default Toast;

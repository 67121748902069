import React from "react";
import { Controller, Control, get } from "react-hook-form";
import ReactSelect, {
  OptionTypeBase,
  Props as SelectProps,
  OptionsType,
} from "react-select";
import { useTranslation } from "react-i18next";

interface Props extends Omit<SelectProps<OptionTypeBase>, "isMulti"> {
  label: string;
  name: string;
  control: Control<any>;
  isMulti?: boolean;
  options: OptionsType<OptionTypeBase>;
  required?: boolean;
  isDisabled?: boolean;
  placeholder?: string;
}

const SelectHookForm: React.FC<Props> = ({
  label,
  name,
  control,
  isMulti = false,
  options,
  required = false,
  isDisabled,
  placeholder,
  ...rest
}) => {
  const { t } = useTranslation("common");

  return (
    <Controller
      name={name}
      control={control}
      defaultValue=""
      render={({
        field: { value, onChange, ...field },
        formState: { errors },
      }) => {
        const error = get(errors, name);
        const selectRef = get(control._fields, name);

        const searchInOptions = () => {
          // if (isDisabled) {
          //   return [];
          // }

          if (Array.isArray(value)) {
            return options.filter((option) =>
              value.find((x) => x === option.value)
            );
          }

          return options.find((option) => option.value === value);
        };

        const handleClickLabel = () => {
          if (selectRef._f) {
            selectRef._f.ref.focus();
          }
        };

        return (
          <div className="mb-3">
            <label
              onClick={handleClickLabel}
              htmlFor={`${name}-select`}
            >{`${label}${required ? " *" : ""}`}</label>
            <ReactSelect
              aria-invalid={error !== undefined ? "true" : "false"}
              id={`${name}-select`}
              inputId={`${name}-input`}
              instanceId={name}
              isDisabled={isDisabled}
              name={name}
              options={options}
              isMulti={isMulti}
              value={searchInOptions()}
              classNamePrefix="react-select"
              onChange={(e) => {
                if (Array.isArray(e)) {
                  onChange(e.map((x) => x.value));
                } else {
                  onChange(e?.value);
                }
              }}
              placeholder={placeholder ?? `${t("select")}...`}
              styles={{
                control: (provided, state) => ({
                  ...provided,
                  borderColor: error ? "#BE0041" : "hsl(0, 0%, 80%)",
                  backgroundColor: state.isDisabled
                    ? "#e9ecef"
                    : provided.backgroundColor,
                }),
                placeholder: (provided, state) => ({
                  color: state.isDisabled ? "#5b626b" : provided.color,
                }),
              }}
              openMenuOnFocus
              {...field}
              {...rest}
            />
            {error && <span className="error">{error.message}</span>}
          </div>
        );
      }}
    />
  );
};

export default SelectHookForm;

export enum ToastTypes {
  ADD_MESSAGE = '@toast/ADD_MESSAGE',
  REMOVE_MESSAGE = '@toast/REMOVE_MESSAGE',
}

export interface ToastState {
  messages: ToastMessage[];
}

export interface ToastMessage {
  id: string;
  type?: 'success' | 'error' | 'info';
  title: string;
  description?: string;
}

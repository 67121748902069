import React, { useState } from "react";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";

interface TabsProp {
  isDefault?: boolean;
  justified?: boolean;
  tabs: Array<{
    id: number;
    title: string;
    icon: string;
    content: JSX.Element;
  }>;
}

const Tabs: React.FC<TabsProp> = ({
  tabs,
  justified = false,
  isDefault = false,
}) => {
  const [activeTab, setActiveTab] = useState(tabs[0].id);
  return (
    <>
      <Nav
        tabs
        justified={justified}
        className={`${isDefault ? "" : "nav-tabs-custom"}`}
        role="tablist"
      >
        {tabs.map(({ id, title, icon }) => {
          if (id) {
            return (
              <NavItem key={`navItem-${id}`}>
                <NavLink
                  active={activeTab === id}
                  className="cursor-pointer"
                  onClick={() => setActiveTab(id)}
                >
                  <span className="d-block d-sm-none">
                    <i className={icon} />
                  </span>
                  <span className="d-none d-sm-block">{title}</span>
                </NavLink>
              </NavItem>
            );
          }

          return null;
        })}
      </Nav>

      <TabContent activeTab={activeTab}>
        {tabs.map(({ id, content }) => {
          if (id) {
            return (
              <TabPane key={`tabContent-${id}`} tabId={id} className="py-3">
                <div className="mb-0">{content}</div>
              </TabPane>
            );
          }

          return null;
        })}
      </TabContent>
    </>
  );
};

export default Tabs;

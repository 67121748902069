import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';

import brazilFlag from '../../../assets/images/flags/br_flag.jpg';
import usFlag from '../../../assets/images/flags/us_flag.jpg';

interface LanguageMenuProps {
  class: string;
}

const LanguageMenu: React.FC<LanguageMenuProps> = ({ class: extraClass }) => {
  const { i18n } = useTranslation();
  const [menu, setMenu] = useState<boolean>(false);

  function toggle() {
    setMenu(!menu);
  }

  function changeLanguage(lng) {
    i18n.changeLanguage(lng);
  }

  function currentFlag() {
    if (i18n.language === 'pt-BR') {
      return (
        <>
          <img
            className="me-2"
            src={brazilFlag}
            alt="Header Language"
            height="16"
          />
          Português
          <span className="mdi mdi-chevron-down" />
        </>
      );
    }
    return (
      <>
        <img src={usFlag} alt="user" className="me-1" height="12" />
        English
        <span className="mdi mdi-chevron-down" />
      </>
    );
  }

  function restLanguages() {
    if (i18n.language === 'pt-BR') {
      return (
        <DropdownItem
          tag="button"
          className="notify-item"
          onClick={() => changeLanguage('en-US')}
        >
          <img src={usFlag} alt="user" className="me-1" height="12" />
          <span className="align-middle">English</span>
        </DropdownItem>
      );
    }
    return (
      <DropdownItem
        tag="button"
        className="notify-item"
        onClick={() => changeLanguage('pt-BR')}
      >
        <img
          className="me-2"
          src={brazilFlag}
          alt="Header Language"
          height="16"
        />
        Português
      </DropdownItem>
    );
  }

  return (
    <Dropdown
      isOpen={menu}
      toggle={toggle}
      id="languagemenu"
      className={`d-none ms-2 ${extraClass}`}
    >
      <DropdownToggle tag="button" className="btn header-item waves-effect">
        {currentFlag()}
      </DropdownToggle>
      <DropdownMenu end>{restLanguages()}</DropdownMenu>
    </Dropdown>
  );
};

export default LanguageMenu;

import { Reducer } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { encryptTransform } from "redux-persist-transform-encrypt";

const persistSpecificReducers = (reducers: Reducer) => {
  const persistedReducer = persistReducer(
    {
      key: "@OTC",
      storage,
      whitelist: ["auth", "user"],
      transforms: [
        encryptTransform({
          secretKey: process.env.REACT_APP_PERSISTOR_SALT,
          onError: function (error) {
            console.log(
              "> [persistSpecificReducers][encryptTransform]",
              JSON.stringify(error)
            );
          },
        }),
      ],
    },
    reducers
  );

  return persistedReducer;
};

export default persistSpecificReducers;

// Action Types
export enum ProfileTypes {
  CREATE_USER_REQUEST = '@user/CREATE_USER_REQUEST',
  CREATE_USER_SUCCESS = '@user/CREATE_USER_SUCCESS',

  UPDATE_PROFILE_REQUEST = '@user/UPDATE_PROFILE_REQUEST',
  UPDATE_PROFILE_SUCCESS = '@user/UPDATE_PROFILE_SUCCESS',
  CLEAR_CREATED_USER = '@user/CLEAR_CREATED_USER',

  CHANGE_PROFILE_PICTURE = '@user/CHANGE_PROFILE_PICTURE',
}

interface AccessTypePermissions {
  permissions: string[];
  dataContext: string;
}

export interface AccessType {
  name: string;
  description: string;
  permissions: AccessTypePermissions[];
}

// Data type
export interface Document {
  id: number;
  type: string;
  dispatcher: string;
  number: string;
  expirationDate: Date;
  documentImage: string;
}
export interface Address {
  id: number;
  country: string;
  zip: string;
  state: string;
  city: string;
  neighborhood: string;
  street: string;
  number: string;
  additionalInfo: string;
}
export interface Profile {
  id?: number;
  name: string;
  login: string;
  cpf: string;
  email: string;
  telephone: string;
  cellphone: string;
  role: string;
  accessType: string;
  company: string;
  companyName: string;
  companyTradingName: string;
  companyId: number;
  website: string;
  professionalSummary: string;
  profilePicture?: string;
  updatePassword: boolean;
  unitResponsible: boolean;
  blocked: boolean;

  address: Address;
  professionalDocument?: Document;
  companyType: string;
}

export interface CreatedUser {
  login: string;
  password: string;
}

// State Type
export interface UserState {
  readonly loading: boolean;
  readonly profile: Profile;
  readonly accessType: AccessType;
  readonly createdUser: CreatedUser;
}

import { action } from 'typesafe-actions';
import { NavigateFunction } from 'react-router-dom';
import { BrokerTypes, Broker } from './types';

export const updateBrokerRequest = (
  broker: Broker,
  navigate: NavigateFunction,
): TypeSafeAction<BrokerTypes> =>
  action(BrokerTypes.UPDATE_BROKER_REQUEST, { broker, navigate });

export const updateBrokerSuccess = (): TypeSafeAction<BrokerTypes> =>
  action(BrokerTypes.UPDATE_BROKER_SUCCESS);

export const createBrokerRequest = (
  broker: Broker,
  navigate: NavigateFunction,
): TypeSafeAction<BrokerTypes> =>
  action(BrokerTypes.CREATE_BROKER_REQUEST, { broker, navigate });

export const createBrokerSuccess = (): TypeSafeAction<BrokerTypes> =>
  action(BrokerTypes.CREATE_BROKER_SUCCESS);

import React, { useState, Dispatch, SetStateAction, useEffect } from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Col,
  Input,
  Label,
} from "reactstrap";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import i18next from "i18next";

import FileInput from "../HookFormFields/FileInput";
import Select from "../HookFormFields/Select";
import GuarantorModal from "./GuarantorModal";
import DataTable from "../DataTable";
import { Guarantor, Warranty } from "../../store/modules/offer/types";
import { addMessage } from "store/modules/toast/actions";
import { formatNumber, MB_IN_BYTES } from "utils/numbers";
import { WARRANTY_TYPES } from "utils/constants";
import { formatCnpj, formatCpf } from "utils/documents";

interface WarrantyFormProps {
  isAddWarranty: boolean;
  setIsAddWarranty: Dispatch<SetStateAction<boolean>>;
  onChange(e: Warranty): void;
}

const WarrantyModal: React.FC<WarrantyFormProps> = ({
  isAddWarranty,
  setIsAddWarranty,
  onChange,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation("offers");

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
    trigger,
    setValue,
  } = useForm({
    reValidateMode: "onSubmit",
    resolver: yupResolver(
      Yup.object().shape({
        warrantyInstrument: Yup.string().required(t("requiredField")),
        warrantyType: Yup.string().required(t("requiredField")),
        observation: Yup.string()
      })
    ),
  });

  const [isAddGuarantor, setIsAddGuarantor] = useState<boolean>(false);
  const [guarantorList, setGuarantorList] = useState<Guarantor[]>([]);
  const [filename, setFileName] = useState("");

  const submitWarranty: SubmitHandler<Omit<Warranty, "guarantors">> = (
    data
  ) => {
    const newObj: Warranty = {
      ...data,
      warrantyInstrumentName: filename,
      guarantors: guarantorList.map((guarantor) => ({ ...guarantor, status: "ACTIVE" })),
      status: "ACTIVE",
    };

    onChange(newObj);
    setIsAddWarranty(false);

    reset();
    setGuarantorList([]);
  };

  const remove = (rowIndex: number) => {
    setGuarantorList((prevGuarantors) => {
      const updatedGuarantors = [...prevGuarantors];
      updatedGuarantors.splice(rowIndex, 1);
      return updatedGuarantors;
    });
  };


  useEffect(() => {
    if (errors && Object.keys(errors).length > 0) {
      trigger();
    }
  }, [i18next.language]);

  return (
    <>
      {isAddGuarantor && (
        <GuarantorModal
          isAddGuarantor={isAddGuarantor}
          setIsAddGuarantor={setIsAddGuarantor}
          onChange={(obj) => setGuarantorList((oldState) => [...oldState, {...obj, status: 'ACTIVE'}])}
        />
      )}

      <Modal
        isOpen={isAddWarranty}
        toggle={() => setIsAddWarranty(false)}
        size="lg"
        centered
      >
        <ModalHeader className="justify-content-center">
          {t("addWarranty")}
        </ModalHeader>
        <ModalBody>
          <form onSubmit={handleSubmit(submitWarranty)}>
            <Row className="mb-4">
              <Col xs="12" md="6">
                <FileInput
                  label={t("warrantyInstrument")}
                  name="warrantyInstrument"
                  control={control}
                  onChange={(e) => {
                    const { files } = e.target;

                    if (files && files[0]) {
                      if (files[0].size > 2 * MB_IN_BYTES) {
                        e.currentTarget.value = "";

                        dispatch(
                          addMessage({
                            title: t("attention"),
                            description: `${t("maxFileSizeMessage")} 2MB`,
                            type: "info",
                          })
                        );

                        return;
                      }

                      const reader = new FileReader();

                      reader.onload = (eLoad) => {
                        setValue(
                          "warrantyInstrument",
                          eLoad.target?.result as string
                        );
                      };

                      reader.readAsDataURL(files[0]);
                      setFileName(files[0].name);
                    }
                  }}
                />
              </Col>
              <Col xs="12" md="6">
                <Select
                  label={t("warrantyType")}
                  name="warrantyType"
                  control={control}
                  options={WARRANTY_TYPES.map((item) => ({
                    label: t(item),
                    value: item,
                  }))}
                />
              </Col>
            </Row>
          </form>

          <Row>
            <Col xs="12" md="6" className="offset-md-6 text-end mb-2">
              <Button
                outline
                id="addGuarantorBtn"
                className="waves-effect waves-light"
                color="secondary"
                type="button"
                onClick={() => setIsAddGuarantor(true)}
              >
                <i className="mdi mdi-plus me-2" />
                {t("addGuarantor")}
              </Button>
            </Col>
            <Col className="mb-2">
              <Label for="observation">{t("observation")}</Label>
              <Controller
                control={control}
                name="observation"
                defaultValue=""
                render={({ field }) => (
                  <Input
                    type="textarea"
                    className="w-full"
                    placeholder={t("typeYourObservationsHere")}
                    {...field}
                  />
                )}
              />
              {errors.observation && <div className="text-danger">{t("requiredField")}</div>}
            </Col>
            <DataTable
              columns={[
                {
                  name: t("guarantorName"),
                  selector: (row) => row.guarantorName,
                  center: true,
                  sortable: true,
                },
                {
                  name: t("guarantorCompanyName"),
                  selector: (row) => row.guarantorCompanyName,
                  center: true,
                  sortable: true,
                },
                {
                  name: "CPF",
                  selector: (row) => row.guarantorCpf,
                  center: true,
                  sortable: true,
                  cell: ({ guarantorCpf }) => formatCpf(guarantorCpf),
                },
                {
                  name: "CNPJ",
                  selector: (row) => row.guarantorCnpj,
                  center: true,
                  sortable: true,
                  cell: ({ guarantorCnpj }) => formatCnpj(guarantorCnpj),
                },
                {
                  name: t("guarantorPercentage"),
                  selector: (row) => row.guarantorPercentage,
                  center: true,
                  sortable: true,
                  cell: ({ guarantorPercentage }) =>
                    `${formatNumber({
                      numericValue: Number(guarantorPercentage),
                    })} %`,
                },
                {
                  name: "",
                  cell: (row, rowIndex) => (
                    <Button
                      id={`removeGuarantor-${rowIndex}`}
                      className="waves-effect waves-light m-1"
                      color="danger"
                      size="sm"
                      onClick={() => remove(rowIndex)}
                    >
                      <i className="fas fa-trash-alt" />
                    </Button>
                  ),
                  button: true,
                },
              ]}
              data={guarantorList}
            />
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button
            id="closeWarrantyModal"
            color="none"
            onClick={() => setIsAddWarranty(false)}
          >
            {t("cancel")}
          </Button>
          <Button
            id="addWarrantyModal"
            color="primary"
            onClick={handleSubmit(submitWarranty)}
          >
            {t("addWarranty")}
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default WarrantyModal;

import { AxiosResponse } from "axios";

// TODO: maybe will be adapted to the new backend
type GenericApiErrorResponse = {
  timestamp: string;
  status: number;
  error: string;
  trace: string;
  message: string;
  path: string;
};

// TODO: maybe will be adapted to the new backend
type MappedApiErrorResponse = {
  error: string;
};

type ProcessedError = {
  description: string;
  status: number;
};

/**
 * Receives an error from Api call, treat them and return an object with two properties:
 * `status` and `description`.
 */
export const handleErrorResponse = (error: any): ProcessedError => {
  // Handle Internal Server Error
  if (error?.response?.data?.status === 500) {
    return {
      description: `Algo de inesperado aconteceu. [${error.response.data.status}]`,
      status: error.response.data.status,
    };
  }

  if (error?.response && "data" in error.response) {
    const mappedApiError =
      error.response as AxiosResponse<MappedApiErrorResponse>;

    if (mappedApiError.data.error) {
      return {
        description: mappedApiError.data.error,
        status: mappedApiError.status,
      };
    }

    const genericApiError =
      error.response as AxiosResponse<GenericApiErrorResponse>;

    // When nothing is returned on `data`
    if (String(genericApiError.data).length === 0) {
      return {
        description: `Algo de inesperado aconteceu. [${genericApiError.status}]`,
        status: genericApiError.status,
      };
    }

    return {
      description: genericApiError.data.message,
      status: genericApiError.status,
    };
  }

  if (error instanceof Error) {
    const syntaxError = error as Error;

    return {
      description: syntaxError.message,
      status: 422,
    };
  }

  return {
    description: "Erro desconhecido",
    status: 0,
  };
};

import React, { useEffect, useRef, useState } from "react";
import ReactDatePicker, {
  ReactDatePickerProps,
  registerLocale,
} from "react-datepicker";
import ptBR from "date-fns/locale/pt-BR";
import enUS from "date-fns/locale/en-US";
import { Controller, Control, get } from "react-hook-form";
import { useTranslation } from "react-i18next";
import "react-datepicker/dist/react-datepicker.css";

import {
  convertBrazilianToDate,
  convertISO8601ToDate,
  isValidBrazilianDate,
  isValidDate,
} from "utils/date-time";

interface Props extends Omit<ReactDatePickerProps, "onChange"> {
  name: string;
  label: string;
  control: Control<any>;
  placeholder?: string;
  required?: boolean;
}

const DatePicker: React.FC<Props> = ({
  name,
  label,
  control,
  placeholder,
  required = false,
  onFocus,
  ...rest
}) => {
  const { i18n } = useTranslation();

  useEffect(() => {
    registerLocale("pt-BR", ptBR);
    registerLocale("en-US", enUS);
  }, []);

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={null}
      render={({
        field: { value, onChange, onBlur, ...field },
        formState: { errors },
      }) => {
        const error = get(errors, name);

        const datepickerRef = useRef(null);

        const [isFocused, setIsFocused] = useState(false);

        const handleChange = (date: Date) => {
          if (!isValidDate(date)) {
            onChange(null);

            return;
          }

          onChange(date);
          setIsFocused(false);
        };

        const handleFocus = (e) => {
          setIsFocused(true);

          if (onFocus) {
            onFocus(e);
          }
        };

        const handleBlur = ({ target }) => {
          setIsFocused(false);

          if (!isValidBrazilianDate(target.value)) {
            onChange(null);

            return;
          }

          onChange(convertBrazilianToDate(target.value));
        };

        useEffect(() => {
          const startReservationDateInput = document.getElementById(name);

          if (isFocused) {
            startReservationDateInput.style.boxShadow = "0 0 0 1px #2684FF";
            startReservationDateInput.style.borderColor = "#2684FF";
          } else {
            startReservationDateInput.style.boxShadow = "";
            startReservationDateInput.style.borderColor = "";
          }
        }, [isFocused]);

        return (
          <div className="mb-3">
            <label htmlFor={name}>{`${label}${required ? " *" : ""}`}</label>
            <ReactDatePicker
              id={name}
              ref={datepickerRef}
              className={`form-control ${error ? "is-invalid" : ""}`}
              aria-invalid={error !== undefined ? "true" : "false"}
              selected={
                typeof value === "string" ? convertISO8601ToDate(value) : value
              }
              onChange={handleChange}
              onFocus={handleFocus}
              onBlur={handleBlur}
              placeholderText={placeholder}
              wrapperClassName="w-100"
              autoComplete="off"
              locale={i18n.language}
              dropdownMode="select"
              showMonthDropdown
              showYearDropdown
              {...rest}
              {...field}
            />
            {error && <span className="error">{error.message}</span>}
          </div>
        );
      }}
    />
  );
};

export default DatePicker;

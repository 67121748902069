import { all, takeLatest, call, put } from "redux-saga/effects";

import i18n from "../../../i18n";
import api from "../../../services/api";

import { addMessage } from "../toast/actions";
import { OfferTypes } from "./types";
import {
  createOfferSuccess,
  patchPublishedOfferSuccess,
  updateOfferSuccess,
} from "./actions";

export function* createOffer({ payload }: any) {
  try {
    const { offer, navigate } = payload;

    yield call(api.post, "/offers", offer);

    yield put(
      addMessage({
        title: i18n.t("offerHasBeenCreated", { ns: "toasts" }),
        type: "success",
      })
    );

    yield put(createOfferSuccess());

    navigate("/offers");
  } catch (error) {
    yield put(
      addMessage({
        title: i18n.t("errorOnCreateOffer", { ns: "toasts" }),
        type: "error",
      })
    );
  }
}

export function* updateOffer({ payload }: any) {
  try {
    const { offer, navigate } = payload;

    yield call(api.put, `/offers/${offer.id}`, offer);

    yield put(
      addMessage({
        title: i18n.t("offerHasBeenUpdated", { ns: "toasts" }),
        type: "success",
      })
    );

    yield put(updateOfferSuccess());

    navigate(-1);
  } catch (error) {
    yield put(
      addMessage({
        title: i18n.t("errorOnUpdateOffer", { ns: "toasts" }),
        type: "error",
      })
    );
  }
}

export function* patchPublishedOffer({ payload }: any) {
  try {
    const { offer, navigate } = payload;

    yield call(api.patch, `/offers/${offer.id}/date`, {
      changeDate: {
        reservationDeadline: offer.reservationDeadline,
      },
    });

    yield put(
      addMessage({
        title: i18n.t("offerHasBeenUpdated", { ns: "toasts" }),
        type: "success",
      })
    );

    yield put(patchPublishedOfferSuccess());

    navigate(-1);
  } catch (error) {
    yield put(
      addMessage({
        title: i18n.t("errorOnUpdateOffer", { ns: "toasts" }),
        type: "error",
      })
    );
  }
}

export default all([
  takeLatest(OfferTypes.CREATE_OFFER_REQUEST, createOffer),
  takeLatest(OfferTypes.UPDATE_OFFER_REQUEST, updateOffer),
  takeLatest(OfferTypes.PATCH_PUBLISHED_OFFER_REQUEST, patchPublishedOffer),
]);

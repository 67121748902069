import React, { useState } from "react";
import { Button } from "reactstrap";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";
import SweetAlert from "react-bootstrap-sweetalert";
import * as Yup from "yup";

import { permission as permissionEnum } from "../utils/permissionEnums";
import LiquidateDialog from "./LiquidateDialog/LiquidateDialog";
import InvalidateDialog from "./InvalidateDialog/InvalidateDialog";

interface Action {
  permission: boolean;
  action: string;
  fieldsProps?: object
}

interface ActionBarProps {
  actions: Action[];
  onClick(permission: string, reason?: string, ticker?: string): void;
  disabled?: boolean;
  fieldsProps?: object;
  requestType?: string;
}

const config = {
  [permissionEnum.REFUSE]: { color: "warning", icon: "mdi mdi-hand-right" },
  [permissionEnum.BLOCK]: { color: "danger", icon: "mdi mdi-lock" },
  [permissionEnum.UNBLOCK]: { color: "info", icon: "mdi mdi-lock-open" },
  [permissionEnum.PUBLISH]: { color: "info", icon: "mdi mdi-publish" },
  [permissionEnum.SUBMIT]: { color: "info", icon: "mdi mdi-publish" },
  [permissionEnum.UPDATE]: { color: "info", icon: "mdi mdi-pencil-outline" },
  [permissionEnum.APPROVE]: { color: "info", icon: "mdi mdi-check ms-1" },
  [permissionEnum.REJECT]: { color: "danger", icon: "mdi mdi-hand-right" },
  [permissionEnum.REJECT]: { color: "danger", icon: "mdi mdi-hand-right" },
  [permissionEnum.CANCEL]: { color: "secondary", icon: "mdi mdi-close ms-1" },
  [permissionEnum.EXECUTE]: { color: "info", icon: "mdi mdi-publish" },
  [permissionEnum.SETTLED]: { color: "info", icon: "mdi mdi-currency-usd-off" },
  [permissionEnum.INVALIDATE]: { color: "dark", icon: "mdi mdi-cancel" },
  [permissionEnum.DISABLE]: { color: "danger", icon: "mdi mdi-hand-right" },
  publish_ipo: { color: "info", icon: "mdi mdi-publish" },
};

const ActionBar: React.FC<ActionBarProps> = ({
  actions,
  onClick,
  disabled,
  fieldsProps,
  requestType
}) => {
  const { t } = useTranslation("common");

  const [reason, setReason] = useState("");
  const [error, setError] = useState("");
  const [storedAction, setStoredAction] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [showSubmitAlert, setShowSubmitAlert] = useState(false);
  const [showApproveAlert, setShowApproveAlert] = useState(false);
  const [showRefuseAlert, setShowRefuseAlert] = useState(false);
  const [showSettledAlert, setShowSettledAlert] = useState(false);
  const [showInvalidateAlert, setShowInvalidateAlert] = useState(false);
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  function handleClick(action: string) {
    setStoredAction(action);

    if (action === permissionEnum.REFUSE) {
      setShowRefuseAlert(true);
    } else if (action === permissionEnum.UPDATE) {
      onClick(action.toUpperCase());
    } else if (action === permissionEnum.SUBMIT) {
      setShowSubmitAlert(true);
    } else if (action === permissionEnum.APPROVE) {
      setShowApproveAlert(true);
    } else if (action === permissionEnum.EXECUTE) {
      setShowSubmitAlert(true);
    } else if (action === permissionEnum.SETTLED) {
      handleClickOpen();
      setShowSettledAlert(true);
    } else if(action === permissionEnum.INVALIDATE) {
      handleClickOpen();
      setShowInvalidateAlert(true);
    }
     else {
      setShowAlert(true);
    }
  }

  async function handleSubmit() {
    try {
      setError("");

      const schema = Yup.object().shape({
        text: Yup.string().required(t("reasonRequired")),
      });
      await schema.validate(
        { text: reason },
        {
          abortEarly: false,
        }
      );
      // Validation passed
      setShowRefuseAlert(false);
      onClick(storedAction.toUpperCase(), reason);
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((e) => {
          setError(e.message);
        });
      }
    }
  }

  return (
    <>
      {showAlert ? (
        <SweetAlert
          showCancel
          title={t("actionTitle", { action: t(storedAction) })}
          cancelBtnBsStyle="danger"
          confirmBtnBsStyle="success"
          onConfirm={() => {
            setShowAlert(false);
            onClick(storedAction.toUpperCase());
          }}
          onCancel={() => setShowAlert(false)}
        />
      ) : null}

      {showApproveAlert ? (
        <SweetAlert
          showCancel
          title={
            requestType === "OFFER_CONSOLIDATION"
              ? t("confirmIssue", { action: t(storedAction) })
              : requestType === "OFFER_PUBLICATION"
              ? t("confirmPublication", { action: t(storedAction) })
              : t("confirmIssue", { action: t(storedAction) })
          }
          cancelBtnBsStyle="danger"
          cancelBtnText={t("no", { action: t(storedAction) })}
          confirmBtnBsStyle="success"
          confirmBtnText={t("yes", { action: t(storedAction) })}
          onConfirm={() => {
            setShowApproveAlert(false);
            onClick(storedAction.toUpperCase());
          }}
          onCancel={() => setShowApproveAlert(false)}
        />
      ) : null}


      {showSubmitAlert ? (
        <SweetAlert
          showCancel
          title={t("actionTitle", { action: t(storedAction) })}
          cancelBtnBsStyle="danger"
          cancelBtnText={t("no", { action: t(storedAction) })}
          confirmBtnBsStyle="success"
          confirmBtnText={t("yes", { action: t(storedAction) })}
          onConfirm={() => {
            setShowSubmitAlert(false);
            onClick(storedAction.toUpperCase());
          }}
          onCancel={() => setShowSubmitAlert(false)}
        />
      ) : null}

      {showRefuseAlert ? (
        <SweetAlert
          showCancel
          title={t("actionTitle", { action: t(storedAction) })}
          cancelBtnBsStyle="danger"
          cancelBtnText={t("cancel")}
          confirmBtnBsStyle="success"
          confirmBtnText={t("confirm")}
          onConfirm={handleSubmit}
          onCancel={() => {
            setError("");
            setShowRefuseAlert(false);
          }}
        >
          <div className="mb-3" style={{ textAlign: "left" }}>
            <label htmlFor="reason">{t("informReason")}</label>
            <input
              id="reason"
              className={`form-control ${error && "is-invalid"}`}
              type="text"
              value={reason}
              onChange={(e) => setReason(e.target.value)}
            />
            {error && <span className="error">{error}</span>}
          </div>
        </SweetAlert>
      ) : null}


      {showSettledAlert ? (
        <LiquidateDialog open={open} onClose={handleClose}
          tickerParam={fieldsProps['ticker'] ?? ""}
          instrumentTypeParam={fieldsProps['instrumentType']}
          transactionExpirationDateParam={fieldsProps['transactionExpirationDate'] ?? ''}
        >
        </LiquidateDialog>
      ) : null}

      {showInvalidateAlert ? (
        <InvalidateDialog open={open} onClose={handleClose} tickerParam={fieldsProps['ticker'] ?? ""}></InvalidateDialog>
      ) : null}

      {actions.map(({ permission, action }) => {
        if (!permission) return null;

        return (
          <Button
            id={`${action}-button`}
            key={uuidv4()}
            type="button"
            color={config[action].color}
            size="sm"
            className="waves-effect waves-light rounded-pill px-3"
            onClick={() => handleClick(action)}
            disabled={disabled}
          >
            {t(action)}
            <i className={`${config[action].icon} ms-1`} />
          </Button>
        );
      })}
    </>
  );
};

export default ActionBar;

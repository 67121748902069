import React from 'react';
import { Row, Col } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { useBreadcrumb } from '../hooks/useBreadcrumb';

const Breadcrumb: React.FC = () => {
  const { t } = useTranslation('breadcrumb');
  const { breadcrumbItems } = useBreadcrumb();
  const itemLength = breadcrumbItems.items.length;

  return (
    <Row>
      <Col sm="12">
        <div className="page-title-box">
          <h4>{t(breadcrumbItems.title)}</h4>
          <ol className="breadcrumb m-0">
            {breadcrumbItems.items.map((item, key) =>
              key + 1 === itemLength ? (
                <li key={key} className="breadcrumb-item active">
                  {t(item.title)}
                </li>
              ) : (
                <li key={key} className="breadcrumb-item">
                  <Link to={item.link}>{t(item.title)}</Link>
                </li>
              ),
            )}
          </ol>
        </div>
      </Col>
    </Row>
  );
};

export default Breadcrumb;

import React, {
  createContext,
  useState,
  useContext,
  Dispatch,
  SetStateAction,
  useMemo,
} from 'react';
import { Offer } from '../store/modules/offer/types';

interface EditOfferContextData {
  offer: Offer;
  setOffer: Dispatch<SetStateAction<Offer>>;
  hasOffer: boolean;
}

const EditOfferContext = createContext<EditOfferContextData>(
  {} as EditOfferContextData,
);

export const EditOfferProvider: React.FC = ({ children }) => {
  const [offer, setOffer] = useState<Offer>(null);
  const offerProviderValue = useMemo(
    () => ({ offer, setOffer, hasOffer: !!offer }),
    [offer, setOffer],
  );

  return (
    <EditOfferContext.Provider value={offerProviderValue}>
      {children}
    </EditOfferContext.Provider>
  );
};

export function useEditOffer(): EditOfferContextData {
  const context = useContext(EditOfferContext);

  if (!context) {
    throw new Error('useEditOffer must be used within an EditOfferProvider');
  }

  return context;
}

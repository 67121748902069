import React, {
  createContext,
  useState,
  useContext,
  Dispatch,
  SetStateAction,
  useMemo,
} from 'react';

interface BreadcrumbItem {
  title: string;
  link: string;
}

interface BreadcrumbState {
  title: string;
  items: BreadcrumbItem[];
}

interface BreadcrumbContextData {
  breadcrumbItems: BreadcrumbState;
  setBreadcrumbItems: Dispatch<SetStateAction<BreadcrumbState>>;
}

const BreadcrumbContext = createContext<BreadcrumbContextData>(
  {} as BreadcrumbContextData,
);

export const BreadcrumbProvider: React.FC = ({ children }) => {
  const [breadcrumbItems, setBreadcrumbItems] = useState<BreadcrumbState>({
    title: 'home',
    items: [{ title: 'home', link: '/dashboard' }],
  });
  const breadcrumbProviderValue = useMemo(
    () => ({ breadcrumbItems, setBreadcrumbItems }),
    [breadcrumbItems, setBreadcrumbItems],
  );

  return (
    <BreadcrumbContext.Provider value={breadcrumbProviderValue}>
      {children}
    </BreadcrumbContext.Provider>
  );
};

export function useBreadcrumb(): BreadcrumbContextData {
  const context = useContext(BreadcrumbContext);

  if (!context) {
    throw new Error('useBreadcrumb must be used within an BreadcrumbProvider');
  }

  return context;
}

import React, { useState, useEffect, useCallback } from "react";
import { Row, Col, Card, CardBody, Button } from "reactstrap";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";

import SEO from "../../components/SEO";
import SearchBar from "../../components/SearchBar";
import DataTable from "../../components/DataTable";
import { useEditCommission } from "../../hooks/useEditCommission";
import { useBreadcrumb } from "../../hooks/useBreadcrumb";
import usePermissions from "../../hooks/usePermissions";
import { dataContext, permission } from "../../utils/permissionEnums";
import api from "../../services/api";

interface Commission {
  offer: number;
  ticker: string;
  contractTerm: string;
  tradingName: string;
  totalAmountOffered: number;
  saleValue: number;
  comissionValue: number;
}
interface Request {
  page: number;
  limit: number;
  sort: string;
  count: number;
  content: Commission[];
}

const Commissions: React.FC = () => {
  const { setBreadcrumbItems } = useBreadcrumb();
  const { setCommission } = useEditCommission();
  const { t } = useTranslation("commissions");
  const [commissionList, setCommissionList] = useState<Commission[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [perPage, setPerPage] = useState<number>(10);
  const [totalRows, setTotalRows] = useState<number>(0);
  const [persistedFilters, setPersistedFilters] = useState({
    ticker: "",
    companyName: "",
  });
  const hasPermissionToCreate = usePermissions(
    dataContext.COMMISSION,
    permission.CREATE
  );

  useEffect(() => {
    setBreadcrumbItems({
      title: "manageCommissions",
      items: [
        { title: "home", link: "/dashboard" },
        { title: "systemCommissions", link: "/commissions" },
      ],
    });
  }, [setBreadcrumbItems]);

  const getCommissions = useCallback(
    async (
      tickerParam = persistedFilters.ticker,
      companyNameParam = persistedFilters.companyName,
      page = 1,
      size = perPage
    ) => {
      setLoading(true);

      const { data } = await api.get<Request>(
        `/commissions?ticker=${tickerParam}&tradingName=${companyNameParam}&page=${page}&limit=${size}`
      );

      setCommissionList(data.content);
      setTotalRows(data.count);
      setPersistedFilters({
        ticker: tickerParam,
        companyName: companyNameParam,
      });

      setLoading(false);
    },
    [perPage, persistedFilters.companyName, persistedFilters.ticker]
  );

  useEffect(() => {
    getCommissions();
  }, [getCommissions]);

  const handleFetchData = ({ ticker, companyName }: any) => {
    getCommissions(ticker, companyName);
  };

  const handlePageChange = (page) => {
    getCommissions(undefined, undefined, page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setPerPage(newPerPage);
    getCommissions(undefined, undefined, page, newPerPage);
  };

  return (
    <>
      <SEO title={t("commissions")} shouldIndexPage={false} />

      <Row>
        <Col xs="12" className="mb-3">
          <div className="d-flex flex-sm-row justify-content-sm-between flex-column align-items-center">
            <div>
              {hasPermissionToCreate && (
                <Link to="/commissions/commission">
                  <Button
                    id="newCommission"
                    color="primary"
                    size="md"
                    className="waves-effect waves-light me-3"
                    onClick={() => setCommission(null)}
                  >
                    <i className="mdi mdi-plus me-2" />
                    {t("newCommission")}
                  </Button>
                </Link>
              )}
            </div>

            <SearchBar
              fields={[
                {
                  key: "ticker",
                  label: "Ticker",
                  variant: "text",
                },
                {
                  key: "companyName",
                  label: t("companyName"),
                  variant: "text",
                },
              ]}
              onSearch={handleFetchData}
            />
          </div>
        </Col>
        <Col xs="12">
          <Card>
            <CardBody>
              <DataTable
                columns={[
                  {
                    name: "Ticker",
                    cell: ({ offer, ticker }) => (
                      <Link to={`/commissions/${offer}`}>
                        <Button
                          id={`commission_${offer}`}
                          type="button"
                          color="info"
                          outline
                          size="sm"
                          className="waves-effect waves-light"
                        >
                          {ticker}
                          <i className="mdi mdi-square-edit-outline ms-1" />
                        </Button>
                      </Link>
                    ),
                    button: true,
                  },
                  {
                    name: t("tradingName"),
                    selector: (row) => row.tradingName,
                    center: true,
                    sortable: true,
                  },
                  {
                    name: t("offerTerm"),
                    center: true,
                    sortable: true,
                    cell: ({ contractTerm }) =>
                      format(new Date(contractTerm || null), "dd/MM/yyyy"),
                  },
                  {
                    name: t("volumeOffered"),
                    center: true,
                    sortable: true,
                    cell: ({ totalAmountOffered }) =>
                      totalAmountOffered &&
                      totalAmountOffered.toLocaleString("pt-BR", {
                        style: "currency",
                        currency: "BRL",
                      }),
                  },
                  {
                    name: t("saleValue"),
                    selector: (row) => row.saleValue,
                    center: true,
                    sortable: true,
                  },
                  {
                    name: t("comissionsValue"),
                    selector: (row) => row.comissionValue,
                    center: true,
                    sortable: true,
                  },
                ]}
                defaultSortFieldId="ticker"
                data={commissionList}
                progressPending={loading}
                paginationServer
                paginationTotalRows={totalRows}
                onChangePage={handlePageChange}
                onChangeRowsPerPage={handlePerRowsChange}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Commissions;

import React, { useEffect, useRef, useState } from "react";
import { Card, CardBody, Button } from "reactstrap";
import { Form } from "@unform/web";
import { SubmitHandler, FormHandles } from "@unform/core";
import * as Yup from "yup";                                                                         
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import api from "../../services/api";

import CompaniesTab from "../../components/listingCompany/create/Companies";
import SEO from "../../components/SEO";
import { useBreadcrumb } from "../../hooks/useBreadcrumb";
import { createCompaniesRequest, updateCompaniesRequest } from "../../store/modules/listing/actions";
import { addMessage } from "../../store/modules/toast/actions";
import { useEditCompany } from "hooks/useEditCompany";
import { isValidCNPJ } from "utils/validateDocuments";
import { LabelValue } from "store/modules/company/types";
import CompanyDistributors from "components/company/CompanyDistributors";
import { formatCnpj } from "utils/documents";

const CreateEditCompany: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation("companies");

  const { setBreadcrumbItems } = useBreadcrumb();
  const { hasCompany, company, setCompany } = useEditCompany();
  const [currentDistributors, setCurrentDistributors] = useState<LabelValue[]>([]);
  const [listDistributors, setListDistributors] = useState<LabelValue[]>([]);
  const [lockListDistributors, setLockListDistributors] = useState<LabelValue[]>([]);

  function extractDataArray(arr) {
    return arr.map((val) => {
      // eslint-disable-next-line no-underscore-dangle
      if (val.__isNew__) {
        return {
          name: val.value,
        };
      }
      return {
        name: val.label || val.name,
        id: val.value || val.id,
      };
    });
  }

  const formRef = useRef<FormHandles>();

  const handleSubmit: SubmitHandler = async (data) => {
    try {
      formRef.current.setErrors({});
      const newData = {
        ...data,
      };

      const schema = Yup.object().shape({
        address: Yup.object().shape({
          zip: Yup.string().test(
            "len",
            t("invalidValue"),
            ({ length }) => length === 0 || length === 8
          ),
        }),
        company: Yup.object().shape({
          cnpj: Yup.string()
            .nullable()
            .required(t("requiredField"))
            .test({
              message: t("invalidValue"),
              test: (value) => {
                if (value) {
                  return isValidCNPJ(value);
                }

                return false;
              },
            }),
          companyName: Yup.string().required(t("requiredField")),
        }),
        tradingName: Yup.string().required(t("requiredField")),
        telephone: Yup.string().test({
          message: t("invalidValue"),
          test: (value) => {
            if(value != ""){
              if(value.length < 12){
                return false;
              }
            }
            return true;
        }}),
        email: Yup.string().required(t("requiredField")),
        // distributors: Yup.array().required(t("requiredField")),
      });
      await schema.validate(newData, {
        abortEarly: false,
      });

      const newObj: any = {
        company: {
          cnpj: newData.company.cnpj,
          companyType: "INVESTOR_COMPANY",
          name: newData.name ?? newData.tradingName,
          companyName: newData.company.companyName,
        },
        tradingName: newData.tradingName,
        address: newData.address,
        email: newData.email,
        website: newData.website,
        telephone: newData.telephone,
        cellphone: newData.cellphone,
        distributors: extractDataArray(currentDistributors)
      };

      if (newObj.distributors.length === 0) {
        dispatch(
          addMessage({
            title: t("attention"),
            description: t("alertAssociateDistributor"),
            type: "info",
          })
        );
        return false;
      }

      if (hasCompany) {
        newObj.id = company.id;
        dispatch(updateCompaniesRequest(newObj, navigate));
      } else {
        dispatch(createCompaniesRequest(newObj, navigate));
      }

    } catch (err) {
      const validationErrors = {};

      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });

        formRef.current.setErrors(validationErrors);

        dispatch(
          addMessage({
            title: t("attention"),
            description: t("checkFields"),
            type: "info",
          })
        );

        formRef.current
          .getFieldRef(Object.getOwnPropertyNames(validationErrors)[0])
          .focus();
      }
    }
  };

  const handleCancel = () => {
    setCompany(null);

    navigate(-1);
  };

  useEffect(() => {
    const errors = formRef.current?.getErrors();

    if (errors && Object.keys(errors).length > 0) {
      formRef.current.submitForm();
    }
  }, [i18next.language]);

  useEffect(() => {
    setBreadcrumbItems({
      title: hasCompany ? "editCompany" : "registerNewCompany",
      items: [
        { title: "home", link: "/dashboard" },
        { title: "companies", link: "/companies" },
        { title: hasCompany ? "editCompany" : "registerNewCompany", link: "" },
      ],
    });

    async function getDistributors() {
      try {
        const { data } = await api.get<any>(`/brokers?state=PUBLISHED`);
        let formated = data.map(t => {
          return {
            value: {
              id: t.id,
              // label: t.company.name,
              label: `${formatCnpj(t.company.cnpj)} - ${t.company.name}`,
              removable: true
            },
            label: `${formatCnpj(t.company.cnpj)} - ${t.company.name}`,
          }
        })

        setLockListDistributors(JSON.parse(JSON.stringify(formated)));

        if (hasCompany) {

          const formatted: any = company.distributors.map(c => {
            return {
              id: c.value.value,
              label: c.value.label,
              removable: true
            }
          })

          setCurrentDistributors(formatted);

          const newListDistributors = formated.filter(f => !formatted.some((c: any) => c.id == f.value.id));
          setListDistributors(newListDistributors);
        } else {
          setListDistributors(formated);
        }
      } catch (error) {
        console.log("[CompanyDetails][getDistributors]", error);
      }
    }

    getDistributors();

  }, [setBreadcrumbItems, hasCompany]);

  return (
    <>
      <SEO
        title={
          hasCompany
            ? t("editCompany", { ns: "breadcrumb" })
            : t("registerNewCompany", { ns: "breadcrumb" })
        }
        shouldIndexPage={false}
      />

      <Card>
        <CardBody>
          <Form
            ref={formRef}
            onSubmit={handleSubmit}
            initialData={hasCompany ? company : {}}
          >
            <CompaniesTab formRef={formRef} distributors={listDistributors} />
          </Form>
          <CompanyDistributors
            currentDistributors={currentDistributors}
            setCurrentDistributors={setCurrentDistributors}
            listDistributors={listDistributors}
            setListDistributors={setListDistributors}
            lockListDistributors={lockListDistributors}
          />
        </CardBody>
      </Card>

      <div className="mb-5 text-end">
        <div>
          <Button
            className="waves-effect waves-light me-3"
            color="info"
            size="lg"
            onClick={() => formRef.current.submitForm()}
          >
            {t("save")}
          </Button>
          <Button
            className="waves-effect waves-light"
            size="lg"
            color="secondary"
            onClick={handleCancel}
            outline
          >
            {t("cancel")}
          </Button>
        </div>
      </div>
    </>
  );
};

export default CreateEditCompany;

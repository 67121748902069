import React, {
  createContext,
  useState,
  useContext,
  Dispatch,
  SetStateAction,
  useMemo,
} from 'react';

import { Broker } from '../store/modules/broker/types';

interface EditBrokerContextData {
  broker: Broker;
  setBroker: Dispatch<SetStateAction<Broker>>;
  hasBroker: boolean;
}

const EditBrokerContext = createContext<EditBrokerContextData>(
  {} as EditBrokerContextData,
);

export const EditBrokerProvider: React.FC = ({ children }) => {
  const [broker, setBroker] = useState<Broker>(null);
  const brokerProviderValue = useMemo(
    () => ({ broker, setBroker, hasBroker: !!broker }),
    [broker, setBroker],
  );

  return (
    <EditBrokerContext.Provider value={brokerProviderValue}>
      {children}
    </EditBrokerContext.Provider>
  );
};

export function useEditBroker(): EditBrokerContextData {
  const context = useContext(EditBrokerContext);

  if (!context) {
    throw new Error('useEditBroker must be used within an EditBrokerProvider');
  }

  return context;
}

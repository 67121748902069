import { FormHandles } from "@unform/core";
import AddressCard from "components/AddressCard";
import Input from "components/FormFields/Input";
import { useTranslation } from "react-i18next";
import { Card, CardBody, Col, Row } from "reactstrap";
import { LabelValue } from "store/modules/naturalPerson/types";
import InputMask from "../../FormFields/InputMask";
import DatePicker from "components/FormFields/DatePicker";
import Select from "components/FormFields/Select";
import { useState } from "react";

interface ProfileTabProps {
    formRef: React.MutableRefObject<FormHandles>;
    distributors: LabelValue[];
    isReadOnly?: boolean;
    hasNaturalPerson?: boolean;
}

const NaturalPersonTab: React.FC<ProfileTabProps> = ({
    formRef,
    isReadOnly = false,
    hasNaturalPerson = false,
  }) => {
    const { t } = useTranslation("persons");
    const [maritalStatusOptions] = useState<{ label: string; value: string }[]>(
      [
        {
          label: t("single"),
          value: "Solteiro"
        },
        {
          label: t("married"),
          value: "Casado"
        },
        {
          label: t("separated"),
          value: "Separado"
        },
        {
          label: t("divorded"),
          value: "Divorciado"
        },
        {
          label: t("widowed"),
          value: "Viúvo"
        }
      ]
    );

    return (
      <>
        <h4 className="card-title mb-4 text-uppercase">{t("generalData")}</h4>
        <Row className="mb-4">
          <Col xs="12" md="4">
            <InputMask
              label="CPF"
              name="cpf"
              format="###.###.###-##"
              disabled={hasNaturalPerson || isReadOnly}
              required
            />
          </Col>
          <Col xs="12" md="4">
            <Input
              label={t("name")}
              name="name"
              disabled={isReadOnly}
              required
            />
          </Col>
          <Col xs="12" md="4">
            <Select
              label={t("maritalStatus")}
              name="maritalStatus"
              options={maritalStatusOptions}
              disabled={isReadOnly}
              // required
            />
          </Col>
        </Row>
        <Row className="mb-4">
          <Col xs="12" md="4">
           <DatePicker
              label={t("birthDate")}
              name="birthDate"
              placeholder="dd/mm/aaaa"
              dateFormat="dd/MM/yyyy"
              disabled={isReadOnly}
              // required
            />
            </Col>
        </Row>

        <AddressCard formRef={formRef} showCard={false} disabled={isReadOnly} />

        <Card style={{ display: "contents" }}>
          <CardBody style={{ padding: "0px" }}>
            <h4 className="card-title my-4 text-uppercase">
              {t("contactInformation")}
            </h4>
            <Row>
              <Col xs="12" md="4">
                <Input
                  label="Email"
                  name="email"
                  type="email"
                  placeholder={t("enterEmail")}
                  disabled={isReadOnly}
                  required
                />
              </Col>
              <Col xs="12" md="4">
                <InputMask
                  label={t("telephone")}
                  name="telephone"
                  format="+## (##) ####-####"
                  placeholder={t("enterTelephone")}
                  disabled={isReadOnly}
                />
              </Col>

              <Col xs="12" md="4">
                <InputMask
                  label={t("cellphone")}
                  name="cellphone"
                  format="+## (##) #####-####"
                  placeholder={t("entercellphone")}
                  disabled={isReadOnly}
                />
              </Col>
            </Row>
          </CardBody>
        </Card>
      </>
    );
  };

  export default NaturalPersonTab;


import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { ApplicationState } from '../../../store';
import { changeSidebarType } from '../../../store/modules/layout/actions';
import { Profile } from '../../../store/modules/user/types';

// Import Menus
import LanguageMenu from '../Menus/LanguageMenu';
import NotificationMenu from '../Menus/NotificationMenu';
import ProfileMenu from '../Menus/ProfileMenu';

import logoDark from '../../../assets/images/logo-dark.png';
import logoLight from '../../../assets/images/logo-light.png';

const Topbar: React.FC = () => {
  const dispatch = useDispatch();
  const leftSideBarType = useSelector<ApplicationState, string>(
    state => state.layout.leftSideBarType,
  );
  const profile = useSelector<ApplicationState, Profile>(
    state => state.user.profile,
  );
  const { t } = useTranslation('common');
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  useEffect(() => {
    document.body.setAttribute('data-layout', 'vertical');
  }, []);

  function sidebarToggle() {
    if (leftSideBarType === 'default' || leftSideBarType === 'hide') {
      dispatch(changeSidebarType('condensed', isMobile));
    } else if (leftSideBarType === 'condensed') {
      dispatch(changeSidebarType('default', isMobile));
    }
  }

  return (
    <header id="page-topbar">
      <div className="navbar-header">
        <div className="d-flex">
          <div className="navbar-brand-box d-flex align-items-center justify-content-center">
            <Link to="/dashboard" className="logo logo-dark">
              <span className="logo-sm p-3">
                {/* <img className="h-auto" src={logoSm} alt="" height="22" /> */}
              </span>
              <span className="logo-lg">
                <img className="h-auto" src={logoDark} alt="" height="17" />
              </span>
            </Link>

            <Link to="/dashboard" className="logo logo-light">
              <span className="logo-sm p-3">
                {/* <img className="h-auto" src={logoSm} alt="" height="22" /> */}
              </span>
              <span className="logo-lg">
                <img className="h-auto" src={logoLight} alt="" height="18" />
              </span>
            </Link>
          </div>

          <button
            type="button"
            onClick={() => sidebarToggle()}
            className="btn btn-sm px-3 font-size-24 header-item waves-effect"
            id="vertical-menu-btn"
          >
            <i className="mdi mdi-menu" />
          </button>

          <div className="d-none d-sm-flex flex-column justify-content-center">
            <h4 className="m-0 font-size-18">
              {profile?.companyName /* Nome da empresa */}
            </h4>
            <div className="d-flex wrap align-items-center">
              {t(profile?.companyType) /* Tipo de empresa */}
              <i className="fas fa-angle-right p-1" />
              {t(profile?.accessType) /* Nível do usuário */}
            </div>
          </div>
        </div>

        <div className="d-flex">
          <LanguageMenu class="d-md-block" />

          <NotificationMenu />

          <ProfileMenu />
        </div>
      </div>
    </header>
  );
};

export default Topbar;

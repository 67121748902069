import { Reducer } from 'redux';
import produce, { Draft } from 'immer';
import { CommissionState, CommissionTypes } from './types';

const INITIAL_STATE: CommissionState = {
  loading: false,
};

const reducer: Reducer<CommissionState> = (state = INITIAL_STATE, action) => {
  return produce(state, (draft: Draft<CommissionState>) => {
    switch (action.type) {
      case CommissionTypes.CREATE_COMMISSION_REQUEST: {
        draft.loading = true;
        break;
      }
      case CommissionTypes.CREATE_COMMISSION_SUCCESS: {
        draft.loading = false;
        break;
      }
      case CommissionTypes.UPDATE_COMMISSION_REQUEST: {
        draft.loading = true;
        break;
      }
      case CommissionTypes.UPDATE_COMMISSION_SUCCESS: {
        draft.loading = false;
        break;
      }
      default:
    }
  });
};

export default reducer;

import React, {
  createContext,
  useState,
  useContext,
  Dispatch,
  SetStateAction,
  useMemo,
} from "react";
import { Company } from "store/modules/company/types";

interface EditCompanyContextData {
  company: Company;
  setCompany: Dispatch<SetStateAction<Company>>;
  hasCompany: boolean;
}

const EditCompanyContext = createContext<EditCompanyContextData>(
  {} as EditCompanyContextData
);

export const EditCompanyProvider: React.FC = ({ children }) => {
  const [company, setCompany] = useState<Company>(null);
  const companyProviderValue = useMemo(
    () => ({ company, setCompany, hasCompany: !!company }),
    [company, setCompany]
  );

  return (
    <EditCompanyContext.Provider value={companyProviderValue}>
      {children}
    </EditCompanyContext.Provider>
  );
};

export function useEditCompany(): EditCompanyContextData {
  const context = useContext(EditCompanyContext);

  if (!context) {
    throw new Error(
      "useEditCompany must be used within an EditCompanyProvider"
    );
  }

  return context;
}

import React, { useEffect, useRef, useState } from "react";
import NumberFormat, { NumberFormatProps } from "react-number-format";
import { useField } from "@unform/core";

interface Props
  extends NumberFormatProps<React.InputHTMLAttributes<HTMLInputElement>> {
  name: string;
  label: string;
}

const InputPercentage: React.FC<Props> = ({
  name,
  label,
  onFocus,
  onBlur,
  ...rest
}) => {
  const { fieldName, registerField, defaultValue, error } = useField(name);

  const inputRef = useRef(null);

  const [inputValue, setInputValue] = useState<number>(defaultValue || 0);
  const [isFocused, setIsFocused] = useState(false);

  const getStyles = (pStyle) => {
    if (isFocused) {
      return {
        ...pStyle,
        borderColor: "#2684FF",
        boxShadow: "0 0 0 1px #2684FF",
      };
    }

    return { ...pStyle };
  };

  const handleFocus = (e) => {
    setIsFocused(true);

    if (onFocus) {
      onFocus(e);
    }
  };

  const handleBlur = (e) => {
    setIsFocused(false);

    if (onBlur) {
      onBlur(e);
    }
  };

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      getValue: (ref) => {
        return inputValue;
      },
      setValue: (ref, value: number) => {
        setInputValue(value);
      },
      clearValue: (ref) => {
        setInputValue(0);
      },
    });
  }, [fieldName, registerField, inputValue]);

  return (
    <div className="mb-3">
      <label htmlFor={fieldName}>{label}</label>
      <NumberFormat
        id={fieldName}
        getInputRef={inputRef}
        defaultValue={defaultValue}
        value={inputValue}
        className={`form-control ${error && "is-invalid"}`}
        decimalScale={inputValue >= 100 ? 0 : 2}
        fixedDecimalScale
        thousandSeparator="."
        decimalSeparator=","
        allowedDecimalSeparators={[",", "."]}
        suffix="%"
        onFocus={handleFocus}
        onBlur={handleBlur}
        onValueChange={({ floatValue }) => {
          if (floatValue > 100) setInputValue(100);
          else setInputValue(floatValue);
        }}
        maxLength={inputValue >= 100 ? 3 : null}
        mask="_"
        style={getStyles(rest.style)}
        {...rest}
      />
      {error && <span className="error">{error}</span>}
    </div>
  );
};

export default InputPercentage;

import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Row, Col, Card, CardBody, Button } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { Form } from "@unform/web";
import { useTranslation } from "react-i18next";
import format from "date-fns/format";

import SEO from "../../components/SEO";
import ActionBar from "../../components/ActionBar";
import Spinner from "../../components/Spinner";
import DataTable from "../../components/DataTable";
import SearchBar from "../../components/SearchBar";
import Input from "../../components/FormFields/Input";
import InputMask from "../../components/FormFields/InputMask";
import DatePicker from "../../components/FormFields/DatePicker";
import { useBreadcrumb } from "../../hooks/useBreadcrumb";
import { useEditCommission } from "../../hooks/useEditCommission";
import { addMessage } from "../../store/modules/toast/actions";
import usePermissions from "../../hooks/usePermissions";
import { dataContext, permission } from "../../utils/permissionEnums";
import {
  Commission,
  CommissionBody,
} from "../../store/modules/commission/types";
import { ApplicationState } from "../../store";
import { Profile } from "../../store/modules/user/types";

import ExportCSV from "../../utils/ExportCSV";
import api from "../../services/api";

const CommissionDetails: React.FC = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { setBreadcrumbItems } = useBreadcrumb();
  const { setCommission: setCommissionHook } = useEditCommission();
  const { t } = useTranslation("commissions");
  const [commission, setCommission] = useState<Commission>({} as Commission);
  const [fakeData, setFakeData] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const profile = useSelector<ApplicationState, Profile>(
    (state) => state.user.profile
  );

  const hasPermissionToUpdate = usePermissions(
    dataContext.COMMISSION,
    permission.UPDATE,
    commission.offer?.state
  );

  useEffect(() => {
    setBreadcrumbItems({
      title: "commissionDetails",
      items: [
        { title: "home", link: "/dashboard" },
        { title: "commissions", link: "/commissions" },
        { title: "commissionDataSheet", link: "/" },
      ],
    });
  }, [setBreadcrumbItems]);

  useEffect(() => {
    async function getPageData() {
      try {
        const { data: commissionData } = await api.get<Commission>(
          `/offers/${id}/commissions?details=true`
        );
        setCommission(commissionData);
      } catch (error) {
        console.log(error);
      }
    }

    async function getRawCommission() {
      try {
        const { data: offerCommissions } = await api.get(
          `/offers/${id}/commissions`
        );
        const newObj: CommissionBody = {
          offer: parseInt(id, 10),
          values: offerCommissions,
        };
        setCommissionHook(newObj);
      } catch (error) {
        console.log(error);
      }
    }

    getPageData();
    getRawCommission();
  }, [id, setCommissionHook]);

  useEffect(() => {
    function getFakeData() {
      setLoading(true);
      setInterval(() => {
        const obj = [1, 2, 3, 4].map((e) => ({
          id: 123 + e,
          brokerName: "Nome do Broker",
          date: new Date(2021, 5, 28),
          purchasedValue: 50000,
          commission: "4%",
          commissionValue: 2000,
        }));

        setFakeData(obj);
        setLoading(false);
      }, 1000);
    }

    getFakeData();
  }, []);

  const subHeaderComponentMemo = React.useMemo(
    () => (
      <>
        <Button
          className="waves-effect waves-light text-info rounded-pill mb-1"
          color="light"
          outline
          size="lg"
          onClick={() => ExportCSV(fakeData)}
        >
          <i className="fas fa-print me-1" />
          csv
        </Button>

        <SearchBar
          fields={[
            {
              key: "brokerName",
              label: t("brokerName"),
              variant: "text",
            },
          ]}
          onSearch={() => ({})}
        />
      </>
    ),
    [t, fakeData]
  );

  function editCommission() {
    navigate("/commissions/commission");
  }

  async function takeAction(type, reason?) {
    const { data } = await api.patch(
      `/commissions/${commission.offer.id}/state`,
      {
        changeState: {
          action: type,
          ...(reason && { message: reason }),
        },
      }
    );
    setCommission(data);
    dispatch(
      addMessage({
        title: "commissionHasBeenUpdated",
        type: "info",
      })
    );
  }

  async function handleAction(
    type: "REFUSE" | "BLOCK" | "PUBLISH" | "SUBMIT" | "UPDATE",
    reason?: string
  ) {
    if (type === "UPDATE") editCommission();
    else if (type === "REFUSE") takeAction(type, reason);
    else takeAction(type);
  }

  if (!commission.offer?.id) return <Spinner />;

  return (
    <>
      <SEO title={t("manageCommission")} shouldIndexPage={false} />

      <Row>
        <Col xs="12" md="12">
          <div className="text-end mb-4">
            <div className="button-items">
              <ActionBar
                actions={[
                  {
                    permission: hasPermissionToUpdate,
                    action: permission.UPDATE,
                  },
                ]}
                onClick={handleAction}
              />
            </div>
          </div>
        </Col>
      </Row>

      <Form onSubmit={() => ({})} initialData={commission}>
        <Card>
          <CardBody>
            <h4 className="card-title text-info mb-4 text-uppercase">
              {t("commissionRegistrationData")}
            </h4>

            <h5 className="mb-4">{t("offer")}</h5>

            <Row>
              <Col xs="12" md="4">
                <Input
                  label={t("offerTicker")}
                  name="offer.ticker"
                  type="text"
                  disabled
                />
              </Col>
              <Col xs="12" md="4">
                <Input
                  label={t("tradingName")}
                  name="offer.listedCompany.tradingName"
                  type="text"
                  disabled
                />
              </Col>
              <Col xs="12" md="4">
                <InputMask
                  label="CNPJ"
                  name="offer.listedCompany.cnpj"
                  format="##.###.###/####-##"
                  disabled
                />
              </Col>
            </Row>
            <Row>
              <Col xs="12" md="4">
                <Input
                  label={t("companyName")}
                  name="offer.listedCompany.name"
                  disabled
                />
              </Col>
              <Col xs="12" md="4">
                <DatePicker
                  label={t("offerTerm")}
                  name="offer.term"
                  dateFormat="dd/MM/yyyy"
                  disabled
                />
              </Col>
              <Col xs="12" md="4">
                <InputMask
                  label={t("volumeOffered")}
                  name="offer.volumeOffered"
                  thousandSeparator="."
                  decimalSeparator=","
                  prefix="R$ "
                  disabled
                />
              </Col>
            </Row>
            <Row>
              <Col xs="12" md="4">
                <InputMask
                  label={t("saleValue")}
                  name="offer.saleValue"
                  thousandSeparator="."
                  decimalSeparator=","
                  prefix="R$ "
                  disabled
                />
              </Col>
              <Col xs="12" md="4">
                <Input
                  label={t("sumOfCommissions")}
                  name="sumOfCommissions"
                  disabled
                />
              </Col>
              <Col xs="12" md="4">
                <Input
                  label={t("amountOfReceivers")}
                  name="amountOfReceivers"
                  disabled
                />
              </Col>
            </Row>

            <h5 className="my-4">{t("commissions")}</h5>

            {commission.commissions.map((obj, objIndex) => (
              <Row
                key={objIndex}
                className="p-2 border rounded border-secondary mb-4"
              >
                <Col xs="12" md="4">
                  <Input
                    label={t("brokerTradingName")}
                    name={`commissions[${objIndex}].broker.tradingName`}
                    disabled
                  />
                </Col>
                <Col xs="12" md="4">
                  <Input
                    label={t("brokerCompanyName")}
                    name={`commissions[${objIndex}].broker.name`}
                    disabled
                  />
                </Col>
                <Col xs="12" md="4">
                  <InputMask
                    label={t("brokerCNPJ")}
                    name={`commissions[${objIndex}].broker.cnpj`}
                    format="##.###.###/####-##"
                    disabled
                  />
                </Col>
                <Col xs="12" md="4">
                  <InputMask
                    label={t("brokerCommission")}
                    name={`commissions[${objIndex}].percentage`}
                    decimalScale={2}
                    fixedDecimalScale
                    suffix="%"
                    disabled
                  />
                </Col>
                <Col xs="12" md="4">
                  <Input
                    label={t("sumOfCommissions")}
                    name={`commissions[${objIndex}].sumOfCommissions`}
                    disabled
                  />
                </Col>
                <Col xs="12" md="4">
                  <Input
                    label={t("amountOfReceivers")}
                    name={`commissions[${objIndex}].amountOfReceivers`}
                    disabled
                  />
                </Col>
              </Row>
            ))}
          </CardBody>
        </Card>
      </Form>

      <Card>
        <CardBody>
          <h4 className="card-title text-info mb-4 text-uppercase">
            {t("reservationsMade")}
          </h4>

          <DataTable
            columns={[
              {
                name: t("brokerName"),
                selector: (row) => row.brokerName,
                center: true,
                sortable: true,
              },
              {
                name: t("date"),
                center: true,
                sortable: true,
                cell: ({ date }) => format(date, "dd/MM/yyyy - HH:mm:ss"),
              },
              {
                name: t("purchasedValue"),
                center: true,
                sortable: true,
                cell: ({ purchasedValue }) =>
                  new Intl.NumberFormat("pt-BR", {
                    style: "currency",
                    currency: "BRL",
                  }).format(purchasedValue),
              },
              {
                name: t("commission"),
                selector: (row) => row.commission,
                center: true,
                sortable: true,
              },
              {
                name: t("commissionValue"),
                center: true,
                sortable: true,
                cell: ({ commissionValue }) =>
                  new Intl.NumberFormat("pt-BR", {
                    style: "currency",
                    currency: "BRL",
                  }).format(commissionValue),
              },
            ]}
            data={fakeData}
            progressPending={loading}
            selectableRows={profile.accessType === "A0"}
            onChange={(e) => console.log(e.selectedRows)}
            subHeader
            subHeaderComponent={subHeaderComponentMemo}
          />
          {profile.accessType === "A0" && (
            <Button className="waves-effect waves-light" color="primary">
              {t("approve")}
            </Button>
          )}
        </CardBody>
      </Card>
    </>
  );
};

export default CommissionDetails;

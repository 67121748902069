import { Reducer } from 'redux';
import produce, { Draft } from 'immer';
import { BrokerState, BrokerTypes } from './types';

const INITIAL_STATE: BrokerState = {
  loading: false,
};

const reducer: Reducer<BrokerState> = (state = INITIAL_STATE, action) => {
  return produce(state, (draft: Draft<BrokerState>) => {
    switch (action.type) {
      case BrokerTypes.CREATE_BROKER_REQUEST: {
        draft.loading = true;
        break;
      }
      case BrokerTypes.CREATE_BROKER_SUCCESS: {
        draft.loading = false;
        break;
      }
      case BrokerTypes.UPDATE_BROKER_REQUEST: {
        draft.loading = true;
        break;
      }
      case BrokerTypes.UPDATE_BROKER_SUCCESS: {
        draft.loading = false;
        break;
      }
      default:
    }
  });
};

export default reducer;

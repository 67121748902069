import React, { useState } from 'react';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { ApplicationState } from '../../../store';
import { Profile } from '../../../store/modules/user/types';

import imageUsrPlaceholder from '../../../assets/images/usr-placeholder.jpeg';

const ProfileMenu: React.FC = () => {
  const profile = useSelector<ApplicationState, Profile>(
    state => state.user.profile,
  );
  const [menu, setMenu] = useState<boolean>(false);

  function toggle() {
    setMenu(!menu);
  }

  return (
    <Dropdown isOpen={menu} toggle={toggle} className="d-inline-block">
      <DropdownToggle
        tag="button"
        className="btn header-item waves-effect ms-2"
        id="page-header-user-dropdown"
      >
        {profile?.name}
        <img
          className="rounded-circle header-profile-user ms-2"
          src={profile?.profilePicture || imageUsrPlaceholder}
          alt="User Avatar"
        />
      </DropdownToggle>
      <DropdownMenu end>
        <Link to="/profile">
          <DropdownItem>
            <i className="mdi mdi-account-circle font-size-17 text-muted align-middle me-1" />
            Perfil
          </DropdownItem>
        </Link>
        <Link to="/auth/logout">
          <DropdownItem>
            <i className="mdi mdi-power font-size-17 text-muted align-middle me-1" />
            Sair
          </DropdownItem>
        </Link>
      </DropdownMenu>
    </Dropdown>
  );
};

export default ProfileMenu;

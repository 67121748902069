import { combineReducers } from 'redux';

import auth from './auth';
import user from './user';
import layout from './layout';
import toast from './toast';
import advisor from './advisor';
import listing from './listing';
import offer from './offer';
import order from './order';
import broker from './broker';
import commission from './commission';

const combined = combineReducers({
  auth,
  user,
  layout,
  toast,
  advisor,
  listing,
  offer,
  order,
  broker,
  commission,
});

export default combined;

import { all, takeLatest, call, put } from 'redux-saga/effects';

import i18n from '../../../i18n';
import api from '../../../services/api';

import { addMessage } from '../toast/actions';
import { ListingTypes } from './types';
import {
  createListingSuccess,
  createCompaniesSuccess,
  updateCompaniesSuccess,
  updateListingSuccess,
} from './actions';

export function* createListing({ payload }: any) {
  try {
    const { listing, navigate } = payload;

    yield call(api.post, '/listedcompanies', listing);

    yield put(
      addMessage({
        title: i18n.t('toasts:listingHasBeenCreated'),
        type: 'success',
      }),
    );
    yield put(createListingSuccess());
    navigate('/listings');
  } catch (error) {
    console.log('Erro ao adicionar emissor listado!');
  }
}

export function* updateListing({ payload }: any) {
  try {
    const { listing, navigate } = payload;

    yield call(api.put, `/listedcompanies/${listing.id}`, listing);

    yield put(
      addMessage({
        title: i18n.t('toasts:listingHasBeenUpdated'),
        type: 'success',
      }),
    );
    yield put(updateListingSuccess());
    navigate(-1);
  } catch (error) {
    console.log('Erro ao atualizar emissor listado!');
  }
}

export function* createCompanies({ payload }: any) {
  try {
    const { listing, navigate } = payload;

    yield call(api.post, '/investor_company', listing);

    yield put(
      addMessage({
        title: i18n.t('toasts:companyCreated'),
        type: 'success',
      }),
    );
    yield put(createCompaniesSuccess());
    navigate('/companies');
  } catch (error) {
    console.log('Erro ao adicionar emissor listado!');
  }
}

export function* updateCompany({ payload }: any) {
  try {
    const { listing, navigate } = payload;

    yield call(api.put, `/investor_company/${listing.id}`, listing);

    yield put(
      addMessage({
        title: i18n.t('toasts:companyUpdated'),
        type: 'success',
      }),
    );
    yield put(updateCompaniesSuccess());
    navigate(-1);
  } catch (error) {
    console.log('Erro ao atualizar emissor listado!');
  }
}

export function* createNaturalPerson({ payload }: any) {
  try {
    const { listing, navigate } = payload;

    yield call(api.post, '/investor_people', listing);

    yield put(
      addMessage({
        title: i18n.t('toasts:naturalPersonCreated'),
        type: 'success',
      }),
    );
    yield put(createCompaniesSuccess());
    navigate('/persons');
  } catch (error) {
    console.log('Erro ao adicionar emissor listado!');
  }
}

export function* updateNaturalPerson({ payload }: any) {
  try {
    const { listing, navigate } = payload;

    yield call(api.put, `/investor_people/${listing.id}`, listing);

    yield put(
      addMessage({
        title: i18n.t('toasts:naturalPersonUpdated'),
        type: 'success',
      }),
    );
    yield put(updateCompaniesSuccess());
    navigate(-1);
  } catch (error) {
    console.log('Erro ao atualizar emissor listado!');
  }
}

export default all([
  takeLatest(ListingTypes.CREATE_LISTING_REQUEST, createListing),
  takeLatest(ListingTypes.UPDATE_LISTING_REQUEST, updateListing),
  takeLatest(ListingTypes.CREATE_COMPANIES_REQUEST, createCompanies),
  takeLatest(ListingTypes.UPDATE_COMPANIES_REQUEST, updateCompany),
  takeLatest(ListingTypes.CREATE_NATURAL_PERSON_REQUEST, createNaturalPerson),
  takeLatest(ListingTypes.UPDATE_NATURAL_PERSON_REQUEST, updateNaturalPerson),
]);

// Action Types
export enum ListingTypes {
  CREATE_LISTING_REQUEST = '@user/CREATE_LISTING_REQUEST',
  CREATE_LISTING_SUCCESS = '@user/CREATE_LISTING_SUCCESS',
  UPDATE_LISTING_REQUEST = '@user/UPDATE_LISTING_REQUEST',
  UPDATE_LISTING_SUCCESS = '@user/UPDATE_LISTING_SUCCESS',

  CREATE_COMPANIES_REQUEST = '@user/CREATE_COMPANIES_REQUEST',
  CREATE_COMPANIES_SUCCESS = '@user/CREATE_COMPANIES_SUCCESS',
  UPDATE_COMPANIES_REQUEST = '@user/UPDATE_COMPANIES_REQUEST',
  UPDATE_COMPANIES_SUCCESS = '@user/UPDATE_COMPANIES_SUCCESS',

  CREATE_NATURAL_PERSON_REQUEST = '@user/CREATE_NATURAL_PERSON_REQUEST',
  CREATE_NATURAL_PERSON_SUCCESS = '@user/CREATE_NATURAL_PERSON_SUCCESS',
  UPDATE_NATURAL_PERSON_REQUEST = '@user/UPDATE_NATURAL_PERSON_REQUEST',
  UPDATE_NATURAL_PERSON_SUCCESS = '@user/UPDATE_NATURAL_PERSON_SUCCESS',
}

interface PermissionOrData {
  name: string;
}

interface AccessTypePermissions {
  permission: PermissionOrData;
  data: PermissionOrData[];
}

export interface AccessType {
  name: string;
  description: string;
  permissions: AccessTypePermissions[];
}

interface Value {
  id?: number;
  titleId?: number;
  year: string;
  value: string;
}

export interface Attachment {
  attachment: string;
  attachmentName: string;
  attachmentUploadDate?: string;
  id?: number;
  listedCompanyId?: number;
  type: string;
}

export interface FinancialStatements {
  BPC?: Attachment[];
  DRE?: Attachment[];
  DFC?: Attachment[];
  CCS?: Attachment[];
}

// Data type
export interface RatingHistoric {
  id: number;
  responsibleId: number;
  responsibleName: string;
  listedCompanyId: number;
  time: string;
  rating: {
    id: string;
    name: string;
  };
}

export interface Indicator {
  id?: number;
  listedCompanyId?: number;
  title: string;
  values: Value[];
}

export interface ServiceProvider {
  id?: number;
  serviceTitle: string;
  companyName: string;
  address: Address;
  telephone: string;
  email: string;
  site: string;
}

export interface Member {
  name: string;
  role: string;
  link: string;
}

interface Address {
  id: number;
  country: string;
  zip: string;
  state: string;
  city: string;
  neighborhood: string;
  street: string;
  number: string;
  additionalInfo: string;
}

interface Company {
  id?: number;
  cnpj: string;
  companyType: string;
  employees: Array<any>;
  name: string;
  advisorTradingName: string;
}

export interface Listing {
  id: number;
  advisor: number;
  company: Company;
  tradingName: string;
  advisorTradingName: string;
  ticker: string;
  state: string;
  sector: string;
  segment: any;
  description: string;
  address: Address;
  telephone: string;
  email: string;
  website: string;
  quantityEmployee: number;
  economicRating: string;
  mainActivity: string;
  foundation: string;
  businessMaturity: string;
  size: string;
  revenueSize: any;
  quantityEmployeeId: any;
  rating: string;
  members: Member[];
  serviceProviders: ServiceProvider[];
  financialStatements: FinancialStatements;
  performanceIndicators: Indicator[];
  advisorTechnicalManager?: number;
  responsibleComplianceAnalyst?: number;
  responsibleComplianceSupervisor?: number;
  auditReport: string;
  auditOpinion: string;
  responsibleAudit: number;
  responsibleCounter: number;
  ratingHistoric: RatingHistoric[];
  createdBy?: string;
  parentId?: number;
  attachments: Attachment[];
  allowDisable: boolean;
}

// State Type
export interface ListingState {
  readonly loading: boolean;
}

const convertArrayOfObjectsToCSV = (array: any[]) => {
  const replacer = (key, value) => (value === null ? '' : value);
  const header = Object.keys(array[0]);
  const csv = [
    header.join(','), // header row first
    ...array.map(row =>
      header
        .map(fieldName => JSON.stringify(row[fieldName], replacer))
        .join(','),
    ),
  ].join('\r\n');

  return csv;
};

const ExportCSV = (array: any[]) => {
  const link = document.createElement('a');
  let csv = convertArrayOfObjectsToCSV(array);
  if (csv == null) return;

  const filename = 'export.csv';

  if (!csv.match(/^data:text\/csv/i)) {
    csv = `data:text/csv;charset=utf-8,${csv}`;
  }

  link.setAttribute('href', encodeURI(csv));
  link.setAttribute('download', filename);
  link.click();
};

export default ExportCSV;

import React from 'react';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';

import { store, persistor } from './store';
import Routes from './routes';

import ToastContainer from './components/ToastContainer';
import HooksWrapper from './hooks';

import './styles/theme.scss';
import './utils/string.utils';

const App: React.FC = () => {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <Router>
          <HooksWrapper>
            <ToastContainer />
            <Routes />
          </HooksWrapper>
        </Router>
      </PersistGate>
    </Provider>
  );
};

export default App;

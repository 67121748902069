import { all, takeLatest, call, put } from 'redux-saga/effects';

import i18n from '../../../i18n';
import api from '../../../services/api';

import { addMessage } from '../toast/actions';
import { AdvisorTypes } from './types';
import { createAdvisorSuccess } from './actions';

export function* createAdvisor({ payload }: any) {
  try {
    const { advisor, navigate } = payload;

    yield call(api.post, '/advisors', advisor);

    yield put(
      addMessage({
        title: i18n.t('toasts:advisorHasBeenCreated'),
        type: 'success',
      }),
    );
    yield put(createAdvisorSuccess());
    navigate('/advisors');
  } catch (error) {
    console.log('Erro ao adicionar Originador!');
  }
}

export function* updateAdvisor({ payload }: any) {
  try {
    const { advisor, navigate } = payload;

    yield call(api.patch, `/advisors/${advisor.id}`, advisor);

    yield put(
      addMessage({
        title: i18n.t('toasts:advisorHasBeenUpdated'),
        type: 'success',
      }),
    );
    navigate(-1);
  } catch (error) {
    console.log('Erro ao atualizar Originador!');
  }
}

export default all([
  takeLatest(AdvisorTypes.CREATE_ADVISOR_REQUEST, createAdvisor),
  takeLatest(AdvisorTypes.UPDATE_ADVISOR_REQUEST, updateAdvisor),
]);

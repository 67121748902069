import { Reducer } from 'redux';
import produce, { Draft } from 'immer';
import { LayoutState, LayoutTypes } from './types';

const INITIAL_STATE: LayoutState = {
  layoutType: 'horizontal',
  topbarTheme: 'light',
  leftSideBarTheme: 'light',
  layoutWidth: 'fluid',
  leftSideBarType: 'default',
  showRightsidebar: false,
};

const reducer: Reducer<LayoutState> = (state = INITIAL_STATE, action) => {
  return produce(state, (draft: Draft<LayoutState>) => {
    switch (action.type) {
      case LayoutTypes.CHANGE_LAYOUT: {
        draft.layoutType = action.payload;
        break;
      }
      case LayoutTypes.CHANGE_SIDEBAR_THEME: {
        draft.leftSideBarTheme = action.payload;
        break;
      }
      case LayoutTypes.CHANGE_LAYOUT_WIDTH: {
        draft.layoutWidth = action.payload.width;
        break;
      }
      case LayoutTypes.CHANGE_SIDEBAR_TYPE: {
        draft.leftSideBarType = action.payload.sidebarType;
        break;
      }
      case LayoutTypes.CHANGE_TOPBAR_THEME: {
        draft.topbarTheme = action.payload;
        break;
      }
      case LayoutTypes.TOGGLE_RIGHT_SIDEBAR: {
        draft.showRightsidebar = action.payload;
        break;
      }
      case LayoutTypes.HIDE_RIGHT_SIDEBAR: {
        draft.showRightsidebar = false;
        break;
      }
      default:
    }
  });
};

export default reducer;

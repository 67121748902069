import React, { useEffect, useState } from 'react';
import {
  Row,
  Col,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  Badge,
} from 'reactstrap';
import useWebSocket from 'react-use-websocket';
import { useSelector } from 'react-redux';

import api from '../../../services/api';
import { ApplicationState } from '../../../store';

interface NotificationMessage {
  id: number;
  createdAt?: Date;
  message: string;
  personId: number;
  readedAt?: Date;
  title: string;
}

const NotificationMenu: React.FC = () => {
  const { id, token } = useSelector<
    ApplicationState,
    { id: number; token: string }
  >(state => ({
    id: state.user.profile?.id,
    token: state.auth.token,
  }));
  const [notifications, setNotifications] = useState<NotificationMessage[]>([]);
  const notReaded = notifications.filter(n => !n.readedAt);
  const [menu, setMenu] = useState<boolean>(false);
  const { lastJsonMessage } = useWebSocket(
    `${
      process.env.NODE_ENV === 'development' ? 'ws' : 'wss'
    }://${process.env.REACT_APP_API_URL.replace(
      /(^\w+:|^)\/\//,
      '',
    )}/channel/${id}?token=${token}`,
    {
      shouldReconnect: closeEvent => true,
    },
  );

  useEffect(() => {
    if (lastJsonMessage) {
      if (lastJsonMessage.message) {
        setNotifications(prevState => [...prevState, lastJsonMessage]);
      } else {
        setNotifications(lastJsonMessage);
      }
    }
  }, [lastJsonMessage]);

  function toggle() {
    setMenu(!menu);
  }

  async function handleDelete(notificationId: number) {
    try {
      await api.delete(`/notifications/${notificationId}`);
      const obj = notifications.filter(message => {
        return message.id !== notificationId;
      });
      setNotifications(obj);
    } catch (error) {
      console.log(error);
    }
  }

  async function handleRead(notificationId: number) {
    try {
      const { data } = await api.patch<NotificationMessage>(
        `/notifications/${notificationId}`,
        {
          read: true,
        },
      );
      const updated = notifications.map(message =>
        message.id === notificationId ? data : message,
      );
      setNotifications(updated);
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <Dropdown isOpen={menu} toggle={toggle} className="d-inline-block ms-1">
      <DropdownToggle
        className="btn header-item noti-icon waves-effect"
        tag="button"
        id="page-header-notifications-dropdown"
      >
        <i className="ti-bell" />
        {!!notReaded.length && (
          <Badge color="danger" pill>
            {notReaded.length}
          </Badge>
        )}
      </DropdownToggle>
      <DropdownMenu end className="dropdown-menu-lg p-0">
        <div className="p-3">
          <Row className="align-items-center">
            <Col>
              <h5 className="m-0">{`Notificações (${notifications.length})`}</h5>
            </Col>
          </Row>
        </div>
        <div
          style={{
            height: '230px',
            overflowY: 'auto',
            scrollBehavior: 'smooth',
          }}
        >
          {notifications.map(notification => (
            <div key={`notification-${notification.id}`}>
              <div className="text-reset notification-item">
                <div
                  className="d-flex"
                  style={{ background: notification.readedAt ? '#eee' : '' }}
                >
                  <div className="flex-grow-1">
                    <h6 className="mb-1">{notification.title}</h6>
                    <div className="text-muted">
                      <p className="mb-1">{notification.message}</p>
                    </div>
                  </div>
                  <div className="flex-shrink-0">
                    <span
                      role="none"
                      onClick={() => {
                        if (!notification.readedAt) handleRead(notification.id);
                      }}
                    >
                      <i className="mdi mdi-eye-outline fs-5 p-1 cursor-pointer text-info" />
                    </span>
                    <span
                      role="none"
                      onClick={() => handleDelete(notification.id)}
                    >
                      <i className="mdi mdi-close-circle-outline fs-5 p-1 cursor-pointer text-danger" />
                    </span>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
        {/* <div className="p-2 border-top">
          <Link href="/#">
            <div className="btn btn-sm btn-link font-size-14 btn-block text-center">
              View all
            </div>
          </Link>
        </div> */}
      </DropdownMenu>
    </Dropdown>
  );
};

export default NotificationMenu;

import React, { useState, useEffect, useCallback, useMemo } from "react";
import { Button, Row, Col, Card, CardBody } from "reactstrap";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";

import SEO from "../../components/SEO";
import SearchBar from "../../components/SearchBar";
import DataTable from "../../components/DataTable";
import { useBreadcrumb } from "../../hooks/useBreadcrumb";
import api from "../../services/api";
import { handleErrorResponse } from "utils/error-handlers";
import { useEditOrder } from "hooks/useEditOrder";
import { formatNumber } from "utils/numbers";
import { formatDateToBrazilian } from "utils/date-time";
import { formatCnpj, formatCpfCnpj } from "utils/documents";
import StatusBadge from "components/StatusBadge";
import { useSelector } from "react-redux";
import { AccessType } from "store/modules/user/types";
import { ApplicationState } from "store";
import { ACCESS_TYPE_BROKER_B0, ACCESS_TYPE_BROKER_B1, ACCESS_TYPE_BROKER_B2, getOrderStatus } from "utils/constants";
import usePermissions from "hooks/usePermissions";
import { dataContext, permission } from "../../utils/permissionEnums";

interface Orders {
  orderId: string;
  assignorDocument: string;
  assignorCorporateName: string;
  orderDate: string;
  ticker: string;
  qtdTokens: number;
  negotiatedUnitPrice: number;
  value: number;
  transfereeDocument: string;
  transfereeCorporateName: string;
  status: string;
  distributor: string;
  distributorDocument: string;
}
interface Request {
  page: number;
  limit: number;
  sort: string;
  count: number;
  content: Orders[];
}

const Orders: React.FC = () => {
  const { t } = useTranslation("orders");
  const navigate = useNavigate();
  const { setBreadcrumbItems } = useBreadcrumb();

  const [ordersData, setOrdersData] = useState<Orders[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [paginationResetDefaultPage, setPaginationResetDefaultPage] = useState<boolean>(false);
  const [perPage, setPerPage] = useState<number>(10);
  const [totalRows, setTotalRows] = useState<number>(0);
  const [persistedFilters, setPersistedFilters] = useState({
    inicioPeriodoParam: "",
    fimPeriodoParam: "",
    tickerParam: "",
    statusOrderParam: "",
    documentCedenteParam: "",
    razaoCedenteParam: "",
    documentCessionarioParam: "",
    razaoCessionarioParam: "",
  });
  const { setOrder } = useEditOrder();
  const userCnpj = useSelector<ApplicationState, any>(
    (state) => state.user.profile.company
  );
  const accessType = useSelector<ApplicationState, AccessType>(
    (state) => state.user.accessType
  );
  const isAccessTypeBroker = useMemo(
    () =>
      [ACCESS_TYPE_BROKER_B0, ACCESS_TYPE_BROKER_B1, ACCESS_TYPE_BROKER_B2].includes(
        accessType.name
      ),
    [accessType.name]
  );

  const hasPermissionToCreateOrder = usePermissions(
    dataContext.ORDER,
    permission.CREATE
  );

  const getOrders = useCallback(
    async (
      inicioPeriodoParam = persistedFilters.inicioPeriodoParam,
      fimPeriodoParam = persistedFilters.fimPeriodoParam,
      tickerParam = persistedFilters.tickerParam,
      statusOrderParam = persistedFilters.statusOrderParam,
      documentCedenteParam = persistedFilters.documentCedenteParam,
      razaoCedenteParam = persistedFilters.razaoCedenteParam,
      documentCessionarioParam = persistedFilters.documentCessionarioParam,
      razaoCessionarioParam = persistedFilters.razaoCessionarioParam,
      page = 1,
      size = perPage
    ) => {
      try {
        console.log('getOrders');
        setLoading(true);

        let urlWithParameters = `/orders?page=${page}&limit=${size}`;

        if (isAccessTypeBroker) {
          urlWithParameters += `&distributorDocument=${userCnpj}`;
        }

        if (inicioPeriodoParam) {
          urlWithParameters += `&initialDate=${inicioPeriodoParam}`;
        }
        if (fimPeriodoParam) {
          urlWithParameters += `&endDate=${fimPeriodoParam}`;
        }
        if (tickerParam) {
          urlWithParameters += `&ticker=${tickerParam}`;
        }
        if (statusOrderParam) {
          urlWithParameters += `&state=${statusOrderParam}`;
        }
        if (documentCedenteParam) {
          urlWithParameters += `&assignorDocument=${documentCedenteParam}`;
        }
        if (razaoCedenteParam) {
          urlWithParameters += `&assignorCorporateName=${razaoCedenteParam}`;
        }
        if (documentCessionarioParam) {
          urlWithParameters += `&transfereeDocument=${documentCessionarioParam}`;
        }
        if (razaoCessionarioParam) {
          urlWithParameters += `&transfereeCorporateName=${razaoCessionarioParam}`;
        }

        const { data: orderList } = await api.get<Request>(urlWithParameters);

        setOrdersData(orderList.content);
        setTotalRows(orderList.count);
        setPersistedFilters({
          inicioPeriodoParam: inicioPeriodoParam,
          fimPeriodoParam: fimPeriodoParam,
          tickerParam: tickerParam,
          statusOrderParam: statusOrderParam,
          documentCedenteParam: documentCedenteParam,
          razaoCedenteParam: razaoCedenteParam,
          documentCessionarioParam: documentCessionarioParam,
          razaoCessionarioParam: razaoCessionarioParam
        });

      } catch (error) {
        const { description } = handleErrorResponse(error);
        setOrdersData([]);
        setTotalRows(0);
        console.log("[Orders][getOrders]", description);
      } finally {
        setLoading(false);
      }
    },
    [
      perPage,
      persistedFilters.inicioPeriodoParam,
      persistedFilters.fimPeriodoParam
    ]
  );

  const handleFetchData = ({
    initialDate,
    endDate,
    ticker,
    state,
    assignorDocument,
    assignorCorporateName,
    transfereeDocument,
    transfereeCorporateName
  }: any) => {
    console.log('handleFetchData');

    setPaginationResetDefaultPage(!paginationResetDefaultPage);
    getOrders(
      initialDate,
      endDate,
      ticker,
      state,
      assignorDocument,
      assignorCorporateName,
      transfereeDocument,
      transfereeCorporateName
    );

  };

  const handlePageChange = (page) => {
    console.log('handlePageChange');
    // getOrders(
    //   undefined,
    //   undefined,
    //   undefined,
    //   undefined,
    //   undefined,
    //   undefined,
    //   undefined,
    //   undefined,
    //   page);
    getOrders(
      persistedFilters.inicioPeriodoParam,
      persistedFilters.fimPeriodoParam,
      persistedFilters.tickerParam,
      persistedFilters.statusOrderParam,
      persistedFilters.documentCedenteParam,
      persistedFilters.razaoCedenteParam,
      persistedFilters.documentCessionarioParam,
      persistedFilters.razaoCessionarioParam,
      page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    console.log('handlePerRowsChange');
    setPerPage(newPerPage);
    // getOrders(
    //   undefined,
    //   undefined,
    //   undefined,
    //   undefined,
    //   undefined,
    //   undefined,
    //   undefined,
    //   undefined,
    //   page,
    //   newPerPage
    // );
  };

  const handleAddOrder = () => {
    navigate("/orders/order");
  };

  // const hasPermissionToCreateOrder = usePermissions(
  //   dataContext.USER, //TODO: Atualizar permission order
  //   permission.CREATE
  // );

  useEffect(() => {
    setBreadcrumbItems({
      title: "orders",
      items: [
        { title: "home", link: "/dashboard" },
        { title: "orders", link: "/orders" },
      ],
    });
  }, [setBreadcrumbItems]);

  useEffect(() => {
    console.log('useEffect');
    getOrders(
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      1,
      perPage
    );
  }, [getOrders]);

  return (
    <>
      <SEO title={t("orders")} shouldIndexPage={false} />

      <Row>
        <Col xs="12" className="mb-3">
          {/* <div className="d-flex flex-sm-row justify-content-sm-end"> */}
          <div className="d-flex flex-sm-row justify-content-sm-between align-items-center">
            {hasPermissionToCreateOrder ? (
              <Button
                id="newOrder"
                size="md"
                color="primary"
                className="waves-effect waves-light"
                onClick={handleAddOrder}
              >
                <i className="mdi mdi-plus me-2" />
                {t("newOrder")}
              </Button>
            ) : (
              <div></div>
            )}

            <div style={{ "width": "430px" }}>
              <SearchBar
                dropdownLg={true}
                fields={[
                  {
                    key: "initialDate",
                    label: t("initialDate"),
                    variant: "date",
                  },
                  {
                    key: "endDate",
                    label: t("endDate"),
                    variant: "date",
                  },
                  {
                    key: "ticker",
                    label: t("ticker"),
                    variant: "text",
                  },
                  {
                    key: "state",
                    label: t("state"),
                    variant: "select",
                    options: getOrderStatus(t),
                  },
                  {
                    key: "assignorDocument",
                    label: t("assignorDocument"),
                    variant: "text",
                  },
                  {
                    key: "assignorCorporateName",
                    label: t("assignorCorporateName"),
                    variant: "text",
                  },
                  {
                    key: "transfereeDocument",
                    label: t("transfereeDocument"),
                    variant: "text",
                  },
                  {
                    key: "transfereeCorporateName",
                    label: t("transfereeCorporateName"),
                    variant: "text",
                  }
                ]}
                onSearch={handleFetchData}
              />
            </div>

          </div>
        </Col>
        <Col xs="12">
          <Card>
            <CardBody>
              <DataTable
                columns={[
                  {
                    name: t("id"),
                    cell: ({ orderId }) => (
                      <Link to={`/orders/${orderId}`}>
                        <Button
                          id={`order_${orderId}`}
                          type="button"
                          color="info"
                          outline
                          size="sm"
                          className="waves-effect waves-light"
                        >
                          {orderId}
                          <i className="mdi mdi-square-edit-outline ms-1" />
                        </Button>
                      </Link>
                    ),
                    button: true,
                    center: true,
                    sortable: true,
                    width: '350px'
                  },
                  {
                    name: t("assignor"),
                    selector: (row) => `${formatCpfCnpj(row.assignorDocument)} - ${row.assignorCorporateName}`,
                    center: true,
                    sortable: true,
                    width: '300px'
                  },
                  {
                    name: t("date"),
                    selector: (row) => row.orderDate,
                    cell: ({ orderDate }) =>
                      formatDateToBrazilian(new Date(orderDate)),
                    center: true,
                    sortable: true,
                    width: '300px'
                  },
                  {
                    name: t("ticker"),
                    selector: (row) => row.ticker,
                    center: true,
                    sortable: true,
                    width: '200px'
                  },
                  {
                    name: t("quantity"),
                    selector: (row) => row.qtdTokens,
                    right: true,
                    sortable: true,
                    width: '150px'
                  },
                  {
                    name: t("unitValue"),
                    selector: (row) => row.negotiatedUnitPrice,
                    cell: ({ negotiatedUnitPrice }) =>
                      `R$ ${formatNumber({
                        numericValue: negotiatedUnitPrice,
                        precision: 8
                      })}`,
                    right: true,
                    sortable: true,
                    width: '200px'
                  },
                  {
                    name: t("value"),
                    selector: (row) => row.value,
                    cell: ({ value }) =>
                      `R$ ${formatNumber({
                        numericValue: value,
                      })}`,
                    right: true,
                    sortable: true,
                    width: '200px'
                  },
                  {
                    name: t("transferee"),
                    selector: (row) => `${formatCpfCnpj(row.transfereeDocument)} - ${row.transfereeCorporateName}`,
                    center: true,
                    sortable: true,
                    width: '300px'
                  },
                  {
                    name: t("state"),
                    selector: (row) => `${t(row.status, { ns: "common" })}`,
                    center: true,
                    sortable: true,
                    cell: ({ status }) => <StatusBadge state={status} />,
                    width: '100px'
                  },
                  {
                    name: t("distributor"),
                    selector: (row) => `${formatCnpj(row.distributorDocument)} - ${row.distributor}`,
                    center: true,
                    sortable: true,
                    omit: isAccessTypeBroker,
                    width: '350px'
                  },
                ]}
                data={ordersData}
                progressPending={loading}
                paginationServer
                paginationResetDefaultPage={paginationResetDefaultPage}
                paginationTotalRows={totalRows}
                onChangePage={handlePageChange}
                onChangeRowsPerPage={handlePerRowsChange}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Orders;

import React, { useRef, useEffect, useState, useCallback } from "react";
import ReactSelect, {
  OptionTypeBase,
  Props as SelectProps,
  OptionsType,
} from "react-select";
import { useField } from "@unform/core";
import { useTranslation } from "react-i18next";

interface Props extends Omit<SelectProps<OptionTypeBase>, "isMulti"> {
  label: string;
  name: string;
  isMulti?: boolean;
  options: OptionsType<OptionTypeBase>;
  onChangeValue?(e): void;
  required?: boolean;
  disabled?: boolean;
}

const SelectAsync: React.FC<Props> = ({
  name,
  label,
  isMulti,
  options,
  onChangeValue,
  required = false,
  disabled = false,
  ...rest
}) => {
  const { t } = useTranslation("common");

  const { fieldName, defaultValue, registerField, error } = useField(name);

  const selectRef = useRef(null);
  const [valueG, setValue] = useState(defaultValue);

  const searchInOptions = useCallback(
    (val) => {
      if (!val) return undefined;
      if (Array.isArray(val)) {
        const selectedList = options.filter((option) =>
          val.find((x) => x.id === option.value)
        );
        if (selectedList && onChangeValue) onChangeValue(selectedList);
        console.log("selectedList: ", selectedList)
        return selectedList;
      }
      const selected = options.find(
        (option) => option.value === val || option.value === val.id
      );
      if (selected && onChangeValue) onChangeValue(selected);
      console.log("selected: ", selected)
      return selected;
    },
    [options, onChangeValue]
  );

  const handleClickLabel = () => {
    if (selectRef.current) {
      selectRef.current.select.focus();
    }
  };

  useEffect(() => {
    if (options.length) {
      setValue(searchInOptions(defaultValue));
    }
  }, [options, defaultValue, searchInOptions]);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: selectRef.current,
      getValue: (ref: any) => {
        if (isMulti) {
          if (!ref.state.value) {
            return [];
          }
          return ref.state.value;
        }
        const temp = ref.select.getValue();
        if (temp.length) return temp[0]?.value;
        return temp[0];
      },
      setValue: (ref, value) => {
        ref.select.setValue(value || null);
      },
      clearValue: (ref) => {
        ref.select.clearValue();
      },
    });
  }, [fieldName, registerField, isMulti]);

  return (
    <div className="mb-3 full-width">
      {label && (
        <label
          onClick={handleClickLabel}
          htmlFor={`${fieldName}-select`}
        >{`${label}${required ? " *" : ""}`}</label>
      )}
      <ReactSelect
        ref={selectRef}
        aria-invalid={error !== undefined ? "true" : "false"}
        id={`${fieldName}-select`}
        inputId={`${fieldName}-input`}
        instanceId={fieldName}
        isDisabled={disabled}
        name={fieldName}
        options={options}
        isMulti={isMulti}
        value={valueG}
        classNamePrefix="react-select"
        onChange={(e) => {
          setValue(e);
          if (onChangeValue) onChangeValue(e);
        }}
        placeholder={`${t("select")}...`}
        styles={{
          control: (provided, state) => ({
            ...provided,
            borderColor: error ? "#BE0041" : "hsl(0, 0%, 80%)",
            backgroundColor: state.isDisabled
              ? "#e9ecef"
              : provided.backgroundColor,
          }),
          placeholder: (provided, state) => ({
            color: state.isDisabled ? "#5b626b" : provided.color,
          }),
        }}
        openMenuOnFocus
        {...rest}
      />
      {error && <span className="error">{error}</span>}
    </div>
  );
};
export default SelectAsync;

import { action } from 'typesafe-actions';
import { NavigateFunction } from 'react-router-dom';
import { OrderTypes, Order, CreatedOrder } from './types';

export const createOrderRequest = (
  order: Order,
  navigate: NavigateFunction,
): TypeSafeAction<OrderTypes> =>
  action(OrderTypes.CREATE_ORDER_REQUEST, { order, navigate });

export const createOrderSuccess = (
): TypeSafeAction<OrderTypes> =>
  action(OrderTypes.CREATE_ORDER_SUCCESS);

export const createOrderFailure = (): TypeSafeAction<OrderTypes> =>
  action(OrderTypes.CREATE_ORDER_FAILURE);

export const clearCreatedOrder = (): TypeSafeAction<OrderTypes> =>
  action(OrderTypes.CLEAR_CREATED_ORDER);

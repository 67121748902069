import React, { useState } from "react";
import { Controller, Control, get } from "react-hook-form";

export interface Props {
  name: string;
  label?: string;
  control: Control<any>;
  required?: boolean;
}

type InputProps = JSX.IntrinsicElements["input"] & Props;

const InputHookForm: React.FC<InputProps> = ({
  name,
  label,
  control,
  required = false,
  ...rest
}) => (
  <Controller
    name={name}
    control={control}
    defaultValue=""
    render={({ field: { onBlur, ...restField }, formState: { errors } }) => {
      const error = get(errors, name);

      const [isFocused, setIsFocused] = useState(false);

      const getStyles = (pStyle) => {
        if (isFocused) {
          return {
            ...pStyle,
            borderColor: "#2684FF",
            boxShadow: "0 0 0 1px #2684FF",
          };
        }

        return { ...pStyle };
      };

      const handleBlur = () => {
        setIsFocused(false);

        if (onBlur) {
          onBlur();
        }
      };

      return (
        <div className="mb-3">
          {label && (
            <label htmlFor={name}>{`${label}${required ? " *" : ""}`}</label>
          )}
          <input
            id={name}
            className={`form-control ${error ? "is-invalid" : ""}`}
            aria-invalid={error !== undefined ? "true" : "false"}
            onFocus={() => setIsFocused(true)}
            onBlur={handleBlur}
            style={getStyles(rest.style)}
            {...restField}
            {...rest}
          />
          {error && <span className="error">{error.message}</span>}
        </div>
      );
    }}
    {...rest}
  />
);

export default InputHookForm;

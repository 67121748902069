import React, { useState, useEffect, useMemo } from "react";
import { Row, Col, Card, CardBody, Button } from "reactstrap";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import SEO from "../../components/SEO";
import SearchBar from "../../components/SearchBar";
import DataTable from "../../components/DataTable";
import { useBreadcrumb } from "../../hooks/useBreadcrumb";
import ExportCSV from "../../utils/ExportCSV";
import data from "./mock/offers.json";
import { ApplicationState } from "../../store";

const Reservations: React.FC = () => {
  const { t } = useTranslation("reservations");

  const { setBreadcrumbItems } = useBreadcrumb();
  const currentAccessType = useSelector<ApplicationState, string>(
    (state) => state.user.profile.accessType
  );

  const [loading, setLoading] = useState(false);
  const [reservationList, setReservationList] = useState<any[]>([]);
  const [tickerFiltro, setTickerFiltro] = useState("");
  const [companyNameFiltro, setCompanyNameFiltro] = useState("");
  const [advisorFilter, setAdvisorFilter] = useState("");

  const subHeaderComponentMemo = useMemo(
    () => (
      <Button
        className="waves-effect waves-light text-info float-end rounded-pill mb-1"
        color="light"
        outline
        size="lg"
        onClick={() => ExportCSV(reservationList)}
      >
        <i className="fas fa-print me-1" />
        csv
      </Button>
    ),
    [reservationList]
  );

  const formatCurrency = (value: number) =>
    new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
    }).format(value);

  useEffect(() => {
    setBreadcrumbItems({
      title: "manageReservations",
      items: [
        { title: "home", link: "/dashboard" },
        { title: "systemReservations", link: "/reservations" },
      ],
    });
  }, [setBreadcrumbItems]);

  useEffect(() => {
    (() => {
      setLoading(true);
      setTimeout(() => {
        const filter = (filterCriteria: string, content: string) =>
          filterCriteria.length === 0 ||
          (filterCriteria.length > 0 &&
            content
              .toLocaleLowerCase()
              .includes(filterCriteria.toLocaleLowerCase()));

        setReservationList(
          data.filter(
            (r) =>
              filter(tickerFiltro, r.ticker) &&
              filter(companyNameFiltro, r.companyName) &&
              filter(advisorFilter, r.investmentAdvisor)
          )
        );
        setLoading(false);
      }, 1000);
    })();
  }, [tickerFiltro, companyNameFiltro, advisorFilter]);

  return (
    <>
      <SEO title={t("reservations")} shouldIndexPage={false} />

      <Row>
        <Col xs="12" className="mb-3">
          <div className="d-flex flex-sm-row justify-content-sm-between flex-column">
            <div />

            <SearchBar
              fields={[
                {
                  key: "ticker",
                  label: "Ticker",
                  variant: "text",
                },
                {
                  key: "companyTradingName",
                  label: t("companyName"),
                  variant: "text",
                },
                {
                  ...(currentAccessType !== "B2" && {
                    key: "advisor",
                    label: t("advisor"),
                    variant: "text",
                  }),
                },
              ]}
              onSearch={({ ticker, companyTradingName, advisor }) => {
                setTickerFiltro(ticker);
                setCompanyNameFiltro(companyTradingName);
                setAdvisorFilter(advisor);
              }}
            />
          </div>
        </Col>
        <Col xs="12">
          <Card>
            <CardBody>
              <DataTable
                subHeader
                subHeaderComponent={subHeaderComponentMemo}
                columns={[
                  {
                    name: "Ticker",
                    cell: ({ id, ticker }) => (
                      <Link to={`/reservations/${id}`}>
                        <Button
                          id={`reservation_${id}`}
                          type="button"
                          color="info"
                          outline
                          size="sm"
                          className="waves-effect waves-light"
                        >
                          {ticker}
                          <i className="mdi mdi-square-edit-outline ms-1" />
                        </Button>
                      </Link>
                    ),
                    button: true,
                  },
                  {
                    name: t("companyName"),
                    selector: (row) => row.companyName, // TODO: Quando integrar com o backend, exibir o nome fantasia. Caso não tenha, exibir a razão social.
                    center: true,
                    sortable: true,
                  },
                  {
                    name: t("investmentAdvisor"),
                    selector: (row) => row.investmentAdvisor,
                    center: true,
                    sortable: true,
                    omit: currentAccessType === "B2",
                  },
                  {
                    name: t("totalValueOffered"),
                    center: true,
                    sortable: true,
                    cell: ({ totalValueOffered }) =>
                      formatCurrency(totalValueOffered),
                  },
                  {
                    name: t("reservedValue"),
                    center: true,
                    sortable: true,
                    cell: ({ reservedValue }) => formatCurrency(reservedValue),
                  },
                ]}
                data={reservationList}
                progressPending={loading}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Reservations;

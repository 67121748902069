import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Card, CardBody, Col, Badge, Row } from 'reactstrap';
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Line,
  LineChart,
  Pie,
  PieChart,
  Area,
  AreaChart,
  PolarAngleAxis,
  PolarGrid,
  PolarRadiusAxis,
  Radar,
  RadarChart,
} from 'recharts';

import SEO from '../components/SEO';
import { useBreadcrumb } from '../hooks/useBreadcrumb';
import { ApplicationState } from 'store';
import { Profile } from 'store/modules/user/types';
import { dataContext } from '../utils/permissionEnums';

const Dashboard: React.FC = () => {
  const { setBreadcrumbItems } = useBreadcrumb();
  const reports = [
    {
      title: 'Emissores Listados ',
      titleDesc: 'Publicações Ativas',
      icon: 'mdi-cube-outline',
      result: '11%',
      value: '998',
      desc: 'último mês',
      color: 'success',
    },
    {
      title: 'Emissão Ativas',
      titleDesc: 'Ativos em Negociação',
      icon: 'mdi-buffer',
      result: '20%',
      value: '596',
      desc: 'último mês',
      color: 'success',
    },
    {
      title: 'Valor em Circulação',
      titleDesc: 'BRL milhões',
      icon: 'mdi-tag-text-outline',
      result: '2%',
      value: '8.54 Bi',
      desc: 'último mês',
      color: 'warning',
    },
    {
      title: 'Usuários',
      titleDesc: 'Cadastrados no Sistema',
      icon: 'mdi-tag-text-outline',
      result: '4%',
      value: '865 K',
      desc: 'último mês',
      color: 'info',
    },
  ];
  const COLORS = ['#8884d8', '#82ca9d', '#28BBE3'];
  const chart1 = [
    {
      name: 'Janeiro',
      uv: 65,
    },
    {
      name: 'Fevereiro',
      uv: 59,
    },
    {
      name: 'Março',
      uv: 82,
    },
    {
      name: 'Abril',
      uv: 45,
    },
    {
      name: 'Maio',
      uv: 55,
    },
    {
      name: 'Junho',
      uv: 78,
    },
    {
      name: 'Julho',
      uv: 49,
    },
  ];
  const chart2 = [
    {
      name: '2009',
      uv: 4000,
      pv: 2400,
      amt: 2400,
    },
    {
      name: '2010',
      uv: 3000,
      pv: 1398,
      amt: 2210,
    },
    {
      name: '2011',
      uv: 2000,
      pv: 9800,
      amt: 2290,
    },
    {
      name: '2012',
      uv: 2780,
      pv: 3908,
      amt: 2000,
    },
    {
      name: '2013',
      uv: 1890,
      pv: 4800,
      amt: 2181,
    },
    {
      name: '2014',
      uv: 2390,
      pv: 3800,
      amt: 2500,
    },
    {
      name: '2015',
      uv: 3490,
      pv: 4300,
      amt: 2100,
    },
  ];
  const chart3 = [
    { name: 'Manhã 7:00 às 12:00', value: 400 },
    { name: 'Tarde 12:01 às 21:00', value: 300 },
    { name: 'Noite 21:01 às 06:59', value: 300 },
  ];
  const chart4 = [
    {
      subject: 'Commoditie Natural',
      A: 120,
      B: 110,
      fullMark: 100,
    },
    {
      subject: 'Commoditie Agrícola',
      A: 98,
      B: 130,
      fullMark: 100,
    },
    {
      subject: 'Dívida Pré-Fixada',
      A: 86,
      B: 130,
      fullMark: 100,
    },
    {
      subject: 'Dívida Pós-Fixada',
      A: 99,
      B: 100,
      fullMark: 100,
    },
    {
      subject: 'Commoditie Financeira',
      A: 85,
      B: 90,
      fullMark: 100,
    },
    {
      subject: 'Dívida Conversível',
      A: 65,
      B: 85,
      fullMark: 100,
    },
    {
      subject: 'Commoditie Mineral',
      A: 65,
      B: 85,
      fullMark: 100,
    },
  ];
  const profile = useSelector<ApplicationState, Profile>(
    (state) => state.user.profile
  );


  useEffect(() => {
    setBreadcrumbItems({
      title: 'homePage',
      items: [{ title: 'dashboard', link: '/dashboard' }],
    });
  }, [setBreadcrumbItems]);

  return (
    <>
      <SEO title="Dashboard" shouldIndexPage={false} />
      {profile.companyType.includes(dataContext.ADM)?   <Row>
        {reports.map((report, key) => (
          <Col md="6" lg="4" xl="3" key={key}>
            <Card className="mini-stat bg-primary">
              <CardBody className="mini-stat-img">
                <div className="mini-stat-icon">
                  <i className={`mdi ${report.icon} float-end`} />
                </div>
                <div className="text-white">
                  <h6 className="text-uppercase mb-1 font-size-16">
                    {report.title}
                  </h6>
                  <h6 className="mb-3 fw-normal">{report.titleDesc}</h6>
                  <h2 className="mb-4">{report.value}</h2>
                  <Badge color={report.color}>{report.result}</Badge>
                  <span className="ms-2">{report.desc}</span>
                </div>
              </CardBody>
            </Card>
          </Col>
        ))}
      </Row>:''}
      {profile.companyType.includes(dataContext.ADM)?  <Row>
        <Col xs="12" md="4" lg="6" xxl="4">
          <Card>
            <CardBody>
              <h4 className="card-title">Índice OTC</h4>
              <p className="card-title-desc">
                Média de todas as variações no período
              </p>
              <div className="w-100" style={{ height: 300 }}>
                <ResponsiveContainer>
                  <BarChart data={chart1} margin={{ left: -25 }}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Bar dataKey="uv" fill="#28BBE3" />
                  </BarChart>
                </ResponsiveContainer>
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col xs="12" md="4" lg="6" xxl="4">
          <Card>
            <CardBody>
              <h4 className="card-title">Quantidade de Transações</h4>
              <p className="card-title-desc">
                Número de Transações Realizadas no Período
              </p>
              <div className="w-100" style={{ height: 300 }}>
                <ResponsiveContainer>
                  <LineChart
                    data={chart2}
                    margin={{
                      top: 5,
                      left: -15,
                      bottom: 5,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    <Line
                      type="monotone"
                      dataKey="pv"
                      name="Ordens de Compra de Ativos"
                      stroke="#8884d8"
                      activeDot={{ r: 8 }}
                    />
                    <Line
                      type="monotone"
                      dataKey="uv"
                      name="Depósito em Contas"
                      stroke="#82ca9d"
                    />
                    <Line
                      type="monotone"
                      dataKey="amt"
                      name="Ordens de Venda de Ativos"
                      stroke="#28BBE3"
                    />
                  </LineChart>
                </ResponsiveContainer>
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col xs="12" md="4" lg="6" xxl="4">
          <Card>
            <CardBody>
              <h4 className="card-title">Volume de Transações</h4>
              <p className="card-title-desc">
                Somatório do Valor das Transações Realizadas no Período
              </p>
              <div className="w-100" style={{ height: 300 }}>
                <ResponsiveContainer>
                  <LineChart
                    data={chart2}
                    margin={{
                      top: 5,
                      left: -15,
                      bottom: 5,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    <Line
                      type="monotone"
                      dataKey="pv"
                      name="Ordens de Compra de Ativos"
                      stroke="#8884d8"
                      activeDot={{ r: 8 }}
                    />
                    <Line
                      type="monotone"
                      dataKey="uv"
                      name="Depósito em Contas"
                      stroke="#82ca9d"
                    />
                    <Line
                      type="monotone"
                      dataKey="amt"
                      name="Ordens de Venda de Ativos"
                      stroke="#28BBE3"
                    />
                  </LineChart>
                </ResponsiveContainer>
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col xs="12" md="4" lg="6" xxl="4">
          <Card>
            <CardBody>
              <h4 className="card-title">Horário das Transações</h4>
              <p className="card-title-desc">
                Faixa de Horário das Transações Realizadas no Período
              </p>
              <div className="w-100" style={{ height: 300 }}>
                <ResponsiveContainer>
                  <PieChart width={400} height={400}>
                    <Pie
                      dataKey="value"
                      isAnimationActive={false}
                      data={chart3}
                      cx="50%"
                      cy="50%"
                      outerRadius={80}
                      fill="#8884d8"
                      label
                    >
                      {chart3.map((entry, index) => (
                        <Cell
                          key={`cell-${index}`}
                          fill={COLORS[index % COLORS.length]}
                        />
                      ))}
                    </Pie>
                    {/* <Tooltip /> */}
                    <Legend />
                  </PieChart>
                </ResponsiveContainer>
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col xs="12" md="4" lg="6" xxl="4">
          <Card>
            <CardBody>
              <h4 className="card-title">Ticked Médio das Transações</h4>
              <p className="card-title-desc">
                Valor Médio Unitário das Transações Realizadas no Período
              </p>
              <div className="w-100" style={{ height: 300 }}>
                <ResponsiveContainer>
                  <AreaChart
                    data={chart2}
                    margin={{
                      top: 10,
                      left: -15,
                      bottom: 0,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Area
                      type="monotone"
                      dataKey="pv"
                      stackId="1"
                      stroke="#82ca9d"
                      fill="#82ca9d"
                    />
                    <Area
                      type="monotone"
                      dataKey="amt"
                      stackId="1"
                      stroke="#8884d8"
                      fill="#8884d8"
                    />
                  </AreaChart>
                </ResponsiveContainer>
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col xs="12" md="4" lg="6" xxl="4">
          <Card>
            <CardBody>
              <h4 className="card-title">Número de Correntistas</h4>
              <p className="card-title-desc">
                Número de Contas Ativas no Período
              </p>
              <div className="w-100" style={{ height: 300 }}>
                <ResponsiveContainer>
                  <AreaChart
                    data={chart2}
                    margin={{
                      top: 10,
                      left: -15,
                      bottom: 0,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Area
                      type="monotone"
                      dataKey="pv"
                      stackId="1"
                      stroke="#82ca9d"
                      fill="#82ca9d"
                    />
                    <Area
                      type="monotone"
                      dataKey="amt"
                      stackId="1"
                      stroke="#8884d8"
                      fill="#8884d8"
                    />
                  </AreaChart>
                </ResponsiveContainer>
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col xs="12" md="4" lg="6" xxl="4">

          <Card>
            <CardBody>
              <h4 className="card-title">Volume Ofertado por Tipo</h4>
              <p className="card-title-desc">
                Valor Total Ofertado por Tipo de Instrumento
              </p>
              <div className="w-100" style={{ height: 300 }}>
                <ResponsiveContainer>
                  <RadarChart cx="50%" cy="50%" outerRadius="80%" data={chart4}>
                    <PolarGrid />
                    <PolarAngleAxis dataKey="subject" />
                    <PolarRadiusAxis />
                    <Radar
                      name="Valor"
                      dataKey="A"
                      stroke="#8884d8"
                      fill="#8884d8"
                      fillOpacity={0.6}
                    />
                    <Tooltip />
                  </RadarChart>
                </ResponsiveContainer>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>:''}
    </>
  );
};

export default Dashboard;

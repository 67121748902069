import React, { Dispatch, SetStateAction, useEffect } from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Col,
} from "reactstrap";
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

import Input from "../HookFormFields/Input";
import InputMask from "../HookFormFields/InputMask";
import InputPercentage from "../HookFormFields/InputPercentage";
import Select from "components/HookFormFields/Select";
import { Guarantor } from "../../store/modules/offer/types";
import { isValidCNPJ, isValidCPF } from "../../utils/validateDocuments";

interface Props {
  isAddGuarantor: boolean;
  setIsAddGuarantor: Dispatch<SetStateAction<boolean>>;
  onChange(obj: Guarantor): void;
}

const GuarantorModal: React.FC<Props> = ({
  isAddGuarantor,
  setIsAddGuarantor,
  onChange,
}) => {
  const { t } = useTranslation("offers");

  const {
    handleSubmit,
    control,
    clearErrors,
    formState: { errors },
    reset,
    trigger,
    watch,
  } = useForm<Guarantor & { documentType: string }>({
    defaultValues: {
      guarantorName: "",
      guarantorCpf: "",
      guarantorCompanyName: "",
      guarantorCnpj: "",
      guarantorPercentage: "",
      documentType: "",
    },
    resolver: yupResolver(
      Yup.object().shape({
        guarantorName: Yup.string()
          .nullable()
          .when("documentType", {
            is: "CPF",
            then: Yup.string().required(t("requiredField")),
            otherwise: Yup.string().optional(),
          }),
        guarantorCpf: Yup.string()
          .nullable()
          .when(["documentType"], {
            is: "CPF",
            then: Yup.string()
              .required(t("requiredField"))
              .test({
                message: t("invalidCpf"),
                test: (value) => {
                  if (value) {
                    return isValidCPF(value);
                  }

                  return false;
                },
              }),
            otherwise: Yup.string().optional(),
          }),
        guarantorCompanyName: Yup.string()
          .nullable()
          .when("documentType", {
            is: "CNPJ",
            then: Yup.string().required(t("requiredField")),
            otherwise: Yup.string().optional(),
          }),
        guarantorCnpj: Yup.string()
          .nullable()
          .when(["documentType"], {
            is: "CNPJ",
            then: Yup.string()
              .required(t("requiredField"))
              .test({
                message: t("invalidCnpj"),
                test: (value) => {
                  if (value) {
                    return isValidCNPJ(value);
                  }

                  return false;
                },
              }),
            otherwise: Yup.string().optional(),
          }),
        guarantorPercentage: Yup.string()
          .required(t("requiredField"))
          .test({
            test(value, ctx) {
              const numberValue = Number(value);

              if (numberValue <= 0) {
                return ctx.createError({
                  message: t("valueMustBeGreaterThan0"),
                });
              }
              return true;
            },
          }),
        documentType: Yup.string().required(t("requiredField")),
      })
    ),
  });

  const watchingDocumentType = watch("documentType");

  const getInputDocumentByDocumentType = () => {
    if (!watchingDocumentType) {
      return null;
    }

    if (watchingDocumentType === "CPF") {
      return (
        <InputMask
          label="CPF"
          name="guarantorCpf"
          control={control}
          format="###.###.###-##"
          required
        />
      );
    }

    return (
      <InputMask
        label="CNPJ"
        name="guarantorCnpj"
        control={control}
        format="##.###.###/####-##"
        required
      />
    );
  };

  const getInputNameByDocumentType = () => {
    if (!watchingDocumentType) {
      return null;
    }

    if (watchingDocumentType === "CPF") {
      return (
        <Input
          label={t("guarantorName")}
          name="guarantorName"
          control={control}
          required
        />
      );
    }

    return (
      <Input
        label={t("guarantorCompanyName")}
        name="guarantorCompanyName"
        control={control}
        required
      />
    );
  };


  const handleCancel = () => {
    setIsAddGuarantor(false);

    clearErrors();
  };

  const submitGuarantor: SubmitHandler<Guarantor> = (data) => {
    const { documentType, ...dataToSend } = data;

    if (documentType === "CPF") {
      dataToSend.guarantorCnpj = null;
    } else {
      dataToSend.guarantorCpf = null;
    }

    onChange(dataToSend);

    setIsAddGuarantor(false);
    reset();
  };

  useEffect(() => {
    return () => {
      clearErrors();
    };
  }, []);

  useEffect(() => {
    if (errors && Object.keys(errors).length > 0) {
      trigger();
    }
  }, [i18next.language]);

  return (
    <Modal
      isOpen={isAddGuarantor}
      toggle={() => setIsAddGuarantor(false)}
      size="lg"
      centered
    >
      <ModalHeader className="justify-content-center">
        {t("addGuarantor")}
      </ModalHeader>

      <ModalBody>
        <Row>
          <Col xs="12" md="6">
            <Select
              label={t("documentType")}
              name="documentType"
              control={control}
              options={[
                { label: "CPF", value: "CPF" },
                { label: "CNPJ", value: "CNPJ" },
              ]}
              required
            />
          </Col>

          <Row>
            <Col xs="12" md="6">
              {getInputNameByDocumentType()}
            </Col>

            <Col xs="12" md="6">
              {getInputDocumentByDocumentType()}
            </Col>
            <Col xs="12" md="6">
              <InputPercentage
                label={t("guarantorPercentage")}
                name="guarantorPercentage"
                decimalScale={2}
                isAllowed={({ value }) => Number(value) <= 100}
                control={control}
                isNumericString
                required
              />
            </Col>
          </Row>

        </Row>
      </ModalBody>

      <ModalFooter>
        <Button id="closeGuarantorModal" color="none" onClick={handleCancel}>
          {t("cancel")}
        </Button>
        <Button
          id="addGuarantorModal"
          color="primary"
          onClick={handleSubmit(submitGuarantor)}
        >
          {t("addGuarantor")}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default GuarantorModal;

import { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { Card, CardBody, Col, Row } from "reactstrap";
import { useSelector } from "react-redux";

import { Form } from "@unform/web";
import ActionBar from "components/ActionBar";
import DataTable from "components/DataTable";
import NaturalPersonTab from "components/listingNaturalPerson/create/NaturalPerson";
import SEO from "components/SEO";
import Spinner from "components/Spinner";
import { useBreadcrumb } from "hooks/useBreadcrumb";
import { useEditNaturalPerson } from "hooks/useEditNaturalPerson";
import usePermissions from "hooks/usePermissions";
import api from "services/api";
import { ApplicationState } from "store";
import { NaturalPerson } from "store/modules/naturalPerson/types";
import { AccessType } from "store/modules/user/types";
import { formatCnpj } from "utils/documents";
import { handleErrorResponse } from "utils/error-handlers";
import { dataContext, permission } from "utils/permissionEnums";
import { ACCESS_TYPE_BROKER_B0, ACCESS_TYPE_BROKER_B1, ACCESS_TYPE_BROKER_B2 } from "utils/constants";

const NaturalPersonDetails: React.FC = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const { t } = useTranslation("persons");

    const { setBreadcrumbItems } = useBreadcrumb();
    const { setNaturalPerson: setNaturalPersonHook } = useEditNaturalPerson();

    const formRef = useRef(null);

    const [ naturalPerson, setNaturalPerson] = useState<NaturalPerson>(null);

    const accessType = useSelector<ApplicationState, AccessType>(
      (state) => state.user.accessType
    );

    const isAccessTypeBroker = useMemo(
      () =>
        [ACCESS_TYPE_BROKER_B0, ACCESS_TYPE_BROKER_B1, ACCESS_TYPE_BROKER_B2].includes(
          accessType.name
        ),
      [accessType.name]
    );

    const hasPermissionToUpdate = usePermissions(
      dataContext.INVESTOR_COMPANY,
      permission.UPDATE
    );

    const editListing = () => {
      setNaturalPersonHook(naturalPerson);

      navigate("/naturalPerson/person");
    };

    async function handleAction(
      type: "REFUSE" | "BLOCK" | "PUBLISH" | "SUBMIT" | "UPDATE",
      reason?: string
    ) {
      editListing();
      // if (type === "UPDATE") editListing
      // else if (type === "REFUSE") takeAction(type, reason);
      // else takeAction(type);
    }

    useEffect(() => {
      setBreadcrumbItems({
        title: "companyDetails",
        items: [
          { title: "home", link: "/dashboard" },
          { title: "persons", link: "/persons" },
          { title: "naturalPersonDetails", link: "" },
        ],
      });
    }, [setBreadcrumbItems]);

    useEffect(() => {
      async function getNaturalPerson() {
        try {
          const { data } = await api.get<NaturalPerson>(`/investor_people/${id}`);

          data.distributors = data.distributors.map(t => {
            return {
              value: {
                value: t.id,
                label: `${formatCnpj(t.cnpj)} - ${t.name}`,
                removable: false
              },
              label: `${formatCnpj(t.cnpj)} - ${t.name}`
            }
          })

          setNaturalPerson(data);
          setNaturalPersonHook(data);
        } catch (error) {
          const { description } = handleErrorResponse(error);

          console.log("[NaturalPersonDetails][getNaturalPerson]", description);
        }
      }

      getNaturalPerson();
    }, [id]);

    if (!naturalPerson?.id) return <Spinner />;

    return (
      <>
        <SEO
          title={t("companyDetails", { ns: "breadcrumb" })}
          shouldIndexPage={false}
        />

        <Row>
          <Col xs="12">
            <div className="text-end mb-4">
              <div className="button-items">
                <ActionBar
                  actions={[
                    {
                      permission: hasPermissionToUpdate,
                      action: permission.UPDATE,
                    },
                  ]}
                  onClick={handleAction}
                />
              </div>
            </div>
          </Col>
        </Row>

        <Card>
          <CardBody>
            <Form ref={formRef} onSubmit={() => ({})} initialData={naturalPerson}>
              <NaturalPersonTab isReadOnly formRef={formRef} distributors={naturalPerson.distributors} />
            </Form>
            { !isAccessTypeBroker && (
              <>
                <h4 className="card-title my-4 text-uppercase">
                  {t("association")}
                </h4>
                <DataTable
                  columns={[
                    {
                      name: 'Distribuidor',
                      selector: (row) => row.label,
                      center: true,
                      sortable: true,
                    },
                  ]}
                  data={naturalPerson.distributors}
                  pagination={naturalPerson.distributors.length > 10}
                />
              </>
            )}
          </CardBody>
        </Card>
      </>
    );
};

export default NaturalPersonDetails

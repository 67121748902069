import React, { useEffect, useState, useCallback } from "react";
import { Button, Row, Col, Card, CardBody } from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import SEO from "../../components/SEO";
import DataTable from "../../components/DataTable";
import SearchBar from "../../components/SearchBar";
import StatusBadge from "../../components/StatusBadge";
import { useBreadcrumb } from "../../hooks/useBreadcrumb";
import usePermissions from "../../hooks/usePermissions";
import { useEditAdvisor } from "../../hooks/useEditAdvisor";
import api from "../../services/api";
import { dataContext, permission } from "../../utils/permissionEnums";

interface Advisor {
  id: number;
  name: string;
  quantityEmployee: number;
  quantityEmployeeId: any;
  quantityListedCompanies: number;
  state: string;
  type: string;
}

interface Request {
  page: number;
  limit: number;
  sort: string;
  count: number;
  content: Advisor[];
}

const Advisors: React.FC = () => {
  const { setBreadcrumbItems } = useBreadcrumb();
  const { t } = useTranslation("advisors");
  const [publish, setPublish] = useState(false);
  const [advisorsData, setAdvisorsData] = useState<Advisor[] | null>(null);
  const [selectedAdvisors, setSelectedAdvisors] = useState<Advisor[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [perPage, setPerPage] = useState<number>(10);
  const [totalRows, setTotalRows] = useState<number>(0);
  const [persistedFilters, setPersistedFilters] = useState({
    officeName: "",
  });
  const { setAdvisor } = useEditAdvisor();
  const hasPermissionToPublish = usePermissions(
    dataContext.ADVISOR,
    permission.PUBLISH
  );
  const hasPermissionToCreate = usePermissions(
    dataContext.ADVISOR,
    permission.CREATE
  );

  useEffect(() => {
    setBreadcrumbItems({
      title: "advisors",
      items: [
        { title: "home", link: "/dashboard" },
        { title: "advisors", link: "/advisors" },
      ],
    });
  }, [setBreadcrumbItems]);

  const getAdvisors = useCallback(
    async (
      officeNameParam = persistedFilters.officeName,
      page = 1,
      sort = "id:desc",
      size = perPage
    ) => {
      setLoading(true);

      const { data: advisorList } = await api.get<Request>(
        `/advisors/pages?companyName=${officeNameParam}&page=${page}&limit=${size}&sort=${sort}`
      );

      setAdvisorsData(advisorList.content);
      setTotalRows(advisorList.count);
      setPersistedFilters({
        officeName: officeNameParam,
      });

      setLoading(false);
    },
    [perPage, persistedFilters.officeName]
  );

  useEffect(() => {
    getAdvisors();
  }, [getAdvisors]);

  async function publishAdvisors() {
    try {
      const listIds = selectedAdvisors.map((advisor) => advisor.id);
      await api.patch("/advisors", {
        ids: listIds,
      });
      getAdvisors();
      setPublish(false);
    } catch (error) {
      console.log(error);
    }
  }

  const handleFetchData = ({ officeName }: any) => {
    getAdvisors(officeName);
  };

  const handlePageChange = (page) => {
    getAdvisors(undefined, page, "id:desc");
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setPerPage(newPerPage);
    getAdvisors(undefined, page, newPerPage, "id:desc");
  };

  return (
    <>
      <SEO title={t("advisors")} shouldIndexPage={false} />

      {publish ? (
        <SweetAlert
          warning
          showCancel
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          title={t("doYouWantToPublishTheSelectedProfiles")}
          onConfirm={publishAdvisors}
          onCancel={() => {
            setPublish(false);
          }}
        />
      ) : null}

      <Row>
        <Col xs="12" className="mb-3">
          <div className="d-flex flex-md-row justify-content-md-between flex-column align-items-center">
            <div>
              {hasPermissionToCreate && (
                <Link to="/advisors/advisor">
                  <Button
                    id="addAdvisor"
                    size="md"
                    color="primary"
                    className="waves-effect waves-light me-3"
                    onClick={() => setAdvisor(null)}
                  >
                    <i className="mdi mdi-plus me-2" />
                    {t("newAdvisor")}
                  </Button>
                </Link>
              )}
              {hasPermissionToPublish && (
                <Button
                  id="publishAdvisor"
                  size="md"
                  color="primary"
                  disabled={!selectedAdvisors.length}
                  className="waves-effect waves-light"
                  onClick={() => {
                    setPublish(true);
                  }}
                >
                  {t("publish")}
                </Button>
              )}
            </div>

            <SearchBar
              fields={[
                {
                  key: "officeName",
                  label: t("name"),
                  variant: "text",
                },
              ]}
              onSearch={handleFetchData}
            />
          </div>
        </Col>
        <Col xs="12">
          <Card>
            <CardBody>
              <DataTable
                columns={[
                  {
                    name: t("id", { ns: "common" }),
                    cell: ({ id }) => (
                      <Link to={`/advisors/${id}`}>
                        <Button
                          key={id}
                          id={`advisor_${id}`}
                          type="button"
                          color="info"
                          size="sm"
                          outline
                          className="waves-effect waves-light"
                        >
                          {id}
                          <i className="mdi mdi-square-edit-outline ms-1" />
                        </Button>
                      </Link>
                    ),
                    button: true,
                  },
                  {
                    name: t("originatorCorporateName"),
                    selector: (row) => row.name,
                    center: true,
                    sortable: true,
                  },
                  {
                    name: t("type"),
                    selector: (row) => row.type,
                    center: true,
                    sortable: true,
                  },
                  // A pedido da US 1155 não será mais exibido a quantidade investidores PJ e de colaboradores
                  // {
                  //   name: t("numberCompanies"),
                  //   selector: (row) => row.quantityListedCompanies,
                  //   center: true,
                  //   sortable: true,
                  // },
                  // {
                  //   name: t("numberEmployees"),
                  //   selector: (row) => row.quantityEmployee ? row.quantityEmployee : row.quantityEmployeeId.name,
                  //   center: true,
                  //   sortable: true,
                  // },
                  {
                    name: t("status"),
                    selector: (row) => row.state,
                    center: true,
                    sortable: true,
                    cell: ({ state }) => <StatusBadge state={state} />,
                  },
                ]}
                data={advisorsData || []}
                selectableRows={hasPermissionToPublish}
                disabledCondition={(row) => row.state !== "SUBMITTED"}
                onChange={(e) => setSelectedAdvisors(e.selectedRows)}
                progressPending={loading}
                paginationServer
                paginationTotalRows={totalRows}
                onChangePage={handlePageChange}
                onChangeRowsPerPage={handlePerRowsChange}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Advisors;

import { all, takeLatest, call, put } from 'redux-saga/effects';

import i18n from '../../../i18n';
import api from '../../../services/api';
import { clearCreatedUser, createUserSuccess, updateProfileSuccess } from './actions';
import { addMessage } from '../toast/actions';
import { ProfileTypes } from './types';

export function* updateProfile({ payload }: any) {
  try {
    const { profile, isCurrentUser, navigate } = payload;
    const response = yield call(api.put, `/people/${profile.id}`, profile);

    yield put(
      addMessage({
        title: i18n.t('toasts:profileUpdated'),
        description: i18n.t('toasts:theProfileDataHasBeenUpdated'),
        type: 'success',
      }),
    );
    if (isCurrentUser) {
      yield put(updateProfileSuccess(response.data));
      navigate('/profile');
    } else {
      navigate(-1);
    }
  } catch (error) {
    console.log('Erro ao atualizar dados!');
  }
}

export function* createUser({ payload }: any) {
  try {
    const { profile, navigate } = payload;
    const { data } = yield call(api.post, '/people/keycloak', profile);

    yield put(createUserSuccess({ login: data.name, password: data.password }));
    navigate('/users');
  } catch (error) {
    console.log('Erro ao adicionar usuário!');
    yield put(clearCreatedUser());
  }
}

export function* changeProfilePicture({ payload }: any) {
  try {
    const { image, id } = payload;
    const { data } = yield call(api.patch, `/people/${id}`, {
      profilePicture: image,
    });
    yield put(updateProfileSuccess(data));
  } catch (error) {
    addMessage({
      title: 'Erro ao alterar imagem!',
      type: 'error',
    });
  }
}

export default all([
  takeLatest(ProfileTypes.UPDATE_PROFILE_REQUEST, updateProfile),
  takeLatest(ProfileTypes.CREATE_USER_REQUEST, createUser),
  takeLatest(ProfileTypes.CHANGE_PROFILE_PICTURE, changeProfilePicture),
]);

import { useState, useEffect } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import SweetAlert from "react-bootstrap-sweetalert";
import Input from 'components/HookFormFields/Input';
import { useForm } from 'react-hook-form';
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useDispatch } from 'react-redux';
import { addMessage } from 'store/modules/toast/actions';
import api from 'services/api';
import { useNavigate, useParams } from 'react-router-dom';
import CloseIcon from '@material-ui/icons/Close';
import { Button } from 'reactstrap';
import { CircularProgress } from '@material-ui/core';
import MaskedInput from 'components/HookFormFields/MaskedInput';
import { tickerRegex } from 'utils/constants';

interface Props {
  open: boolean;
  onClose: () => void;
  tickerParam: string;
}
const regexTicker = /[A-Z]{4}\d\-\d{3}/;

const InvalidateDialog: React.FC<Props> = (props) => {
  const navigate = useNavigate();
  const { t } = useTranslation("common");
  const dispatch = useDispatch();
  const { id } = useParams();
  const { open, onClose, tickerParam } = props;
  const [loading, setLoading] = useState<boolean>(false);
  const [showSweetAlert, setShowSweetAlert] = useState(false);
  const [isConfirmEnabled, setIsConfirmEnabled] = useState(false);
  const { watch, reset, handleSubmit, control } = useForm<{ id: number; ticker: string; reason: string }>({
    defaultValues: {
      id: parseInt(id),
      ticker: "",
      reason: "",
    },
    resolver: yupResolver(
      Yup.object().shape({
        ticker: Yup.string()
        .required(t("requiredField"))
        .matches(regexTicker, t("validTicker")) // Using regex validation
        .test("match-ticker", t("validTicker"), value => {
          return value.toLowerCase().trim() === tickerParam.toLowerCase().trim();
        }),
        reason: Yup.string().required(t("requiredField")),
      })
    ),
  });

  const watchValidation =  watch("ticker") && watch("reason");

  useEffect(() => {
    if (watchValidation) {
      setIsConfirmEnabled(true);
    } else {
      setIsConfirmEnabled(false);
    }
  }, [watchValidation]);


  const handleClose = () => {
    cleanSubmit();
    props.onClose();
  };

  const handleConfirm = (data: { id: number; ticker: string; reason: string }) => {
    setShowSweetAlert(true);
  };

  const handleSweetAlertClose = () => {
    setShowSweetAlert(false);
    onClose();
  };

  const onSubmit = async (data: { id: number; ticker: string; reason: string;  }) => {
    setLoading(true);
    handleConfirm(data);
    await invalidateTicker(data);
    setLoading(false);
  };

  const cleanSubmit = () => {
    reset();
  }

  const invalidateTicker = async (data: { id: number; ticker: string; reason: string; }) => {
    try {
      const { status } = await api.patch(`/offers/${data.id}/invalidate`, {
        "ticker": data.ticker,
        "reason": data.reason,
      });

      if (status === 200) {
        dispatch(
          addMessage({
            title: t("successInvalidation"),
            type: "success",
          })
        );
        navigate("/offers");
      }
    } catch (error) {
      console.log(error);
    } finally {
      handleSweetAlertClose();
    }
  };

  return (
    <div>
      <Dialog open={open} onClose={onClose} PaperProps={{ style: { width: "500px" } }}>
        <DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            style={{ position: 'absolute', top: 8, right: 8, color: 'gray' }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <MaskedInput
            label={t("ticker")}
            name="ticker"
            control={control}
            mask={tickerRegex}
            required
          />
          <Input
            label={t("reasonForInvalidation")}
            name="reason"
            control={control}
            type="text"
            required
          />
        </DialogContent>
        <DialogActions>
          <Button
            color="info"
            className="waves-effect waves-light"
            onClick={cleanSubmit}>{t("clean")}
          </Button>
          <Button
            color="info"
            className="waves-effect waves-light me-4"
            disabled={!isConfirmEnabled || loading}
            onClick={handleSubmit((data) => handleConfirm(data))} type="submit">{t("confirm")}
          </Button>
        </DialogActions>
      </Dialog>
      {showSweetAlert && (
        <div>
          <SweetAlert
            showCancel={!loading}
            showConfirm={!loading}
            title={t("confirmInvalidation")}
            cancelBtnBsStyle={loading ? "danger disabled" : "danger"}
            cancelBtnText={t("no")}
            confirmBtnBsStyle="success"
            confirmBtnText={t("yes")}
            onConfirm={handleSubmit(onSubmit)}
            onCancel={() => setShowSweetAlert(false)}
            disabled={loading}
          >
            {loading ? <CircularProgress /> : null}
          </SweetAlert>
        </div>
      )}
    </div>
  );
}

export default InvalidateDialog;

// Action Types
export enum LayoutTypes {
  CHANGE_LAYOUT = '@layout/CHANGE_LAYOUT',
  CHANGE_SIDEBAR_THEME = '@layout/CHANGE_SIDEBAR_THEME',
  CHANGE_LAYOUT_WIDTH = '@layout/CHANGE_LAYOUT_WIDTH',
  CHANGE_SIDEBAR_TYPE = '@layout/CHANGE_SIDEBAR_TYPE',
  CHANGE_TOPBAR_THEME = '@layout/CHANGE_TOPBAR_THEME',
  TOGGLE = '@layout/TOGGLE',
  TOGGLE_RIGHT_SIDEBAR = '@layout/TOGGLE_RIGHT_SIDEBAR',
  HIDE_RIGHT_SIDEBAR = '@layout/HIDE_RIGHT_SIDEBAR',
}

// State Type
export interface LayoutState {
  readonly layoutType: string;
  readonly topbarTheme: string;
  readonly leftSideBarTheme: string;
  readonly layoutWidth: string;
  readonly leftSideBarType: string;
  readonly showRightsidebar: boolean;
}

import { createContext, Dispatch, SetStateAction, useContext, useMemo, useState } from "react";
import { NaturalPerson } from "store/modules/naturalPerson/types";

interface EditNaturalPersonContextData {
    naturalPerson: NaturalPerson;
    setNaturalPerson: Dispatch<SetStateAction<NaturalPerson>>;
    hasNaturalPerson: boolean;
}

const EditNaturalPersonContext = createContext<EditNaturalPersonContextData>(
    {} as EditNaturalPersonContextData
);

export const EditNaturalPersonProvider: React.FC = ({ children }) => {
  const [naturalPerson, setNaturalPerson] = useState<NaturalPerson>(null);
  const editNaturalProviderValue = useMemo(
    () => ({ naturalPerson, setNaturalPerson, hasNaturalPerson: !!naturalPerson }),
    [naturalPerson, setNaturalPerson]
  );

  return (
    <EditNaturalPersonContext.Provider value={editNaturalProviderValue}>
      {children}
    </EditNaturalPersonContext.Provider>
  );
};

export function useEditNaturalPerson(): EditNaturalPersonContextData {
    const context = useContext(EditNaturalPersonContext);
  
    if (!context) {
      throw new Error(
        "useEditNaturalPerson must be used within an EditNaturalPersonProvider"
      );
    }
  
    return context;
}


  
import React from 'react';
import { useLocation, Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { ApplicationState } from '../store';

interface OwnProps {
  hasPermission?: boolean;
  children: JSX.Element;
}

const RouteWrapper: React.FC<OwnProps> = ({
  hasPermission = true,
  children,
}) => {
  const location = useLocation();
  const isSigned = useSelector<ApplicationState, boolean>(
    state => state.auth.isSigned,
  );
  const isFirstAccess = useSelector<ApplicationState, boolean>(
    state => state.user.profile?.updatePassword,
  );

  if (!isSigned) {
    // Redirect the users to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/auth/login" state={{ from: location }} />;
  }

  if (!hasPermission) {
    return <Navigate to="/dashboard" />;
  }

  if (isFirstAccess) {
    return <Navigate to="/auth/new-password" />;
  }

  return children;
};

export default RouteWrapper;

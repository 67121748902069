import React, { useRef, useEffect } from 'react';
import { useField } from '@unform/core';

interface Props {
  name: string;
  label?: string;
}
type CheckboxProps = JSX.IntrinsicElements['input'] & Props;

const Checkbox: React.FC<CheckboxProps> = ({ name, label, ...rest }) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const { fieldName, defaultValue, registerField, error } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      getValue: ref => {
        return ref.checked;
      },
      setValue: (ref, value) => {
        ref.checked = value;
      },
      clearValue: ref => {
        ref.checked = false;
      },
    });
  }, [fieldName, registerField]);

  return (
    <div className="form-check mb-3">
      <input
        className={`form-check-input ${error && 'is-invalid'}`}
        type="checkbox"
        id={fieldName}
        ref={inputRef}
        defaultChecked={defaultValue}
        {...rest}
      />
      {label && (
        <label className="form-check-label" htmlFor={fieldName}>
          {label}
        </label>
      )}
      {error && <span className="error">{error}</span>}
    </div>
  );
};

export default Checkbox;

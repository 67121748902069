import React, { useState, useEffect, useCallback, useMemo } from "react";
import { Button, Card, CardBody, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import SweetAlert from "react-bootstrap-sweetalert";
import { useTranslation } from "react-i18next";

import SEO from "../../components/SEO";
import DataTable from "../../components/DataTable";
import SearchBar from "../../components/SearchBar";
import StatusBadge from "../../components/StatusBadge";
import { useBreadcrumb } from "../../hooks/useBreadcrumb";
import { useEditListing } from "../../hooks/useEditListing";
import usePermissions from "../../hooks/usePermissions";
import { dataContext, permission } from "../../utils/permissionEnums";
import api from "../../services/api";
import { handleErrorResponse } from "utils/error-handlers";
import { useSelector } from "react-redux";
import { ApplicationState } from "store";
import { AccessType } from "store/modules/user/types";
import {
  ACCESS_TYPE_ADMIN_A0,
  ACCESS_TYPE_BROKER_B0,
  ACCESS_TYPE_BROKER_B1,
  ACCESS_TYPE_BROKER_B2,
} from "utils/constants";

interface ListedCompaniesList {
  id: number;
  advisorCompanyName: string;
  name: string;
  rating: string;
  sector: string;
  size: string;
  state: string;
  ticker: string;
}

interface Request {
  page: number;
  limit: number;
  sort: string;
  count: number;
  content: ListedCompaniesList[];
}

const Listing: React.FC = () => {
  const { t } = useTranslation("listings");
  const { setBreadcrumbItems } = useBreadcrumb();
  const { setListing } = useEditListing();
  const accessType = useSelector<ApplicationState, AccessType>(
    (state) => state.user.accessType
  );
  const [publish, setPublish] = useState(false);
  const [selectedListedCompanies, setSelectedListedCompanies] = useState<ListedCompaniesList[]>([]);
  const [listingsData, setListingsData] = useState<ListedCompaniesList[]>([]);
  const [loading, setLoading] = useState(false);
  const [perPage, setPerPage] = useState(10);
  const [totalRows, setTotalRows] = useState(0);
  const [persistedFilters, setPersistedFilters] = useState({
    name: "",
    advisorName: "",
    ticker: "",
  });

  const hasPermissionToCreate = usePermissions(
    dataContext.LISTED_COMPANY,
    permission.CREATE
  );

  const hasPermissionToPublish = usePermissions(
    dataContext.LISTED_COMPANY,
    permission.PUBLISH
  );

  const getListings = useCallback(
    async ({
      nameParam = persistedFilters.name,
      advisorNameParam = persistedFilters.advisorName,
      tickerParam = persistedFilters.ticker,
      page = 1,
      limit = perPage,
    }: {
      nameParam?: string;
      advisorNameParam?: string;
      tickerParam?: string;
      page?: number;
      limit?: number;
      signal?: AbortSignal;
    }) => {
      try {
        setLoading(true);

        const { data: userList } = await api.get<Request>("/listedcompanies", {
          params: {
            name: nameParam,
            advisorName: advisorNameParam,
            ticker: tickerParam,
            companyType: [
              ACCESS_TYPE_BROKER_B0,
              ACCESS_TYPE_BROKER_B1,
              ACCESS_TYPE_BROKER_B2,
            ].includes(accessType.name)
              ? "BROKER"
              : "",
            page: page,
            limit,
            sort: "id:desc",
          },
        });

        setListingsData(userList.content);
        setTotalRows(userList.count);
        setPersistedFilters({
          name: nameParam,
          advisorName: advisorNameParam,
          ticker: tickerParam,
        });
      } catch (error) {
        const { description } = handleErrorResponse(error);

        console.log("[Listing][getListings]", description);
      } finally {
        setLoading(false);
      }
    },
    [perPage, persistedFilters.advisorName, persistedFilters.name]
  );

  const handleFetchData = ({ name, advisorName, ticker }: any) => {
    getListings({ nameParam: name, advisorNameParam: advisorName, tickerParam: ticker });
  };

  const handlePageChange = (page) => {
    getListings({ page });
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setPerPage(newPerPage);

    getListings({ page, limit: newPerPage });
  };

  const filteredListingsData = useMemo(() => {
    return listingsData.filter(
      (listing) =>
        !(listing.state === "DISABLED" && accessType.name !== ACCESS_TYPE_ADMIN_A0)
    );
  }, [listingsData, accessType.name]);

  useEffect(() => {
    setBreadcrumbItems({
      title: "listings",
      items: [
        { title: "home", link: "/dashboard" },
        { title: "listings", link: "/listing" },
      ],
    });
  }, [setBreadcrumbItems]);

  useEffect(() => {
    const controller = new AbortController();

    getListings({ signal: controller.signal });

    return () => {
      controller.abort();
    };
  }, [getListings]);

  async function publishListings() {
    try {
      const listIds = selectedListedCompanies.map((listing) => listing.id);
      await api.patch("/listedcompanies/publish", {
        ids: listIds,
      });
      getListings({});
      setPublish(false);
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <>
      <SEO title={t("listings")} shouldIndexPage={false} />

      {publish ? (
        <SweetAlert
          warning
          showCancel
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          title={t("doYouWantToPublishTheSelectedListedCompanies")}
          onConfirm={publishListings}
          onCancel={() => {
            setPublish(false);
          }}
        />
      ) : null}

      <Row>
        <Col xs="12" className="mb-3">
          <div className="d-flex flex-md-row justify-content-md-between flex-column align-items-center">
            <div>
              {hasPermissionToCreate && (
                <Link to="/listings/listing">
                  <Button
                    id="addListing"
                    size="md"
                    color="primary"
                    className="waves-effect waves-light me-3"
                    onClick={() => setListing(null)}
                  >
                    <i className="mdi mdi-plus me-2" />
                    {t("newListing")}
                  </Button>
                </Link>
              )}

              {hasPermissionToPublish && (
                <Button
                  id="publishListings"
                  size="md"
                  color="primary"
                  disabled={!selectedListedCompanies.length}
                  className="waves-effect waves-light"
                  onClick={() => {
                    setPublish(true);
                  }}
                >
                  {t("publish")}
                </Button>
              )}
            </div>

            <SearchBar
              fields={[
                {
                  key: "name",
                  label: t("listingCompanyName"),
                  variant: "text",
                },
                {
                  key: "advisorName",
                  label: t("advisorCompanyName"),
                  variant: "text",
                },
                {
                  key: "ticker",
                  label: t("alphaCode"),
                  variant: "text",
                },
              ]}
              onSearch={handleFetchData}
            />
          </div>
        </Col>

        <Col xs="12">
          <Card>
            <CardBody>
              <DataTable
                columns={[
                  {
                    name: t("alphaCode"),
                    cell: ({ ticker, id }) => (
                      <Link to={`/listings/${id}`}>
                        <Button
                          key={ticker}
                          type="button"
                          color="info"
                          size="sm"
                          outline
                          className="waves-effect waves-light"
                        >
                          {ticker}
                          <i className="mdi mdi-square-edit-outline ms-1" />
                        </Button>
                      </Link>
                    ),
                    button: true,
                    width: "150px",
                  },
                  {
                    name: t("listingCompanyName"),
                    selector: (row) => row.name,
                    center: true,
                    sortable: true,
                  },
                  {
                    name: t("advisorCompanyName"),
                    selector: (row) => row.advisorCompanyName,
                    center: true,
                    sortable: true,
                  },
                  {
                    name: t("status"),
                    selector: (row) => row.state,
                    center: true,
                    sortable: true,
                    cell: ({ state }) => <StatusBadge state={state} />,
                  },
                ]}
                data={filteredListingsData}
                selectableRows={hasPermissionToPublish}
                disabledCondition={(row) => row.state !== "SUBMITTED"}
                onChange={(e) => setSelectedListedCompanies(e.selectedRows)}
                progressPending={loading}
                paginationTotalRows={totalRows}
                onChangePage={handlePageChange}
                onChangeRowsPerPage={handlePerRowsChange}
                paginationServer
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Listing;

import React, {
  createContext,
  useState,
  useContext,
  Dispatch,
  SetStateAction,
  useMemo,
} from 'react';
import { Order } from '../store/modules/order/types';

interface EditOrderContextData {
  order: Order;
  setOrder: Dispatch<SetStateAction<Order>>;
  hasOrder: boolean;
}

const EditOrderContext = createContext<EditOrderContextData>(
  {} as EditOrderContextData,
);

export const EditOrderProvider: React.FC = ({ children }) => {
  const [order, setOrder] = useState<Order>(null);
  const orderProviderValue = useMemo(
    () => ({ order, setOrder, hasOrder: !!order }),
    [order, setOrder],
  );

  return (
    <EditOrderContext.Provider value={orderProviderValue}>
      {children}
    </EditOrderContext.Provider>
  );
};

export function useEditOrder(): EditOrderContextData {
  const context = useContext(EditOrderContext);

  if (!context) {
    throw new Error('useEditOrder must be used within an EditOrderProvider');
  }

  return context;
}

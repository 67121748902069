import React, { useState, useEffect } from 'react';
import { Button, TabContent, TabPane } from 'reactstrap';
import { useTranslation } from 'react-i18next';

import SEO from '../../components/SEO';
import CurrentAccount from '../../components/CurrentAccount';
import { useBreadcrumb } from '../../hooks/useBreadcrumb';

const CurrentAccountPage: React.FC = () => {
  const { setBreadcrumbItems } = useBreadcrumb();
  const { t } = useTranslation('currentAccount');
  const tabs = [
    {
      id: 1,
      name: t('currentAccount'),
    },
    {
      id: 2,
      name: 'PIX',
    },
    {
      id: 3,
      name: t('transference'),
    },
  ];
  const [activeTab, setActiveTab] = useState(tabs[0].id);

  useEffect(() => {
    setBreadcrumbItems({
      title: 'currentAccount',
      items: [
        { title: 'home', link: '/dashboard' },
        { title: 'currentAccount', link: '/currentAccount' },
      ],
    });
  }, [setBreadcrumbItems]);

  return (
    <>
      <SEO title={t('currentAccount')} shouldIndexPage={false} />

      <div className="button-items text-center text-md-start mb-2">
        {tabs.map(({ id, name }) => (
          <Button
            key={id}
            className="waves-effect waves-light rounded-pill px-3"
            color={activeTab === id ? 'secondary' : 'none'}
            onClick={() => setActiveTab(id)}
            size="sm"
          >
            {name}
          </Button>
        ))}
      </div>

      <TabContent activeTab={activeTab}>
        <TabPane tabId={1}>
          <CurrentAccount
            account={{
              agency: '0001',
              accountNum: '9827465-2',
              totalBalance: 1000,
              freeToUse: 300,
              blockedInFlow: 700,
            }}
          />
        </TabPane>
        <TabPane tabId={2}>Sem Dados</TabPane>
        <TabPane tabId={3}>Sem Dados</TabPane>
      </TabContent>
    </>
  );
};

export default CurrentAccountPage;

import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Row,
  UncontrolledCollapse,
  Input as InputUncontrolled,
  Label,
  Button,
  InputGroup,
  FormGroup,
} from "reactstrap";
import { v4 as uuidv4 } from "uuid";
import NumberFormat from "react-number-format";
import { useDispatch, useSelector } from "react-redux";
import { Form } from "@unform/web";
import { useTranslation } from "react-i18next";

import TextArea from "components/FormFields/TextArea";
import Attachments from "components/offer/Attachments";
import { ApplicationState } from "store";
import { Profile } from "store/modules/user/types";
import ActionBar from "components/ActionBar";
import DataTable from "../../components/DataTable";
import DatePicker from "../../components/FormFields/DatePicker";
import Input from "../../components/FormFields/Input";
import InputMask from "../../components/FormFields/InputMask";
import InputPercentage from "../../components/FormFields/InputPercentage";
import SEO from "../../components/SEO";
import Spinner from "../../components/Spinner";
import { useBreadcrumb } from "../../hooks/useBreadcrumb";
import { useEditOffer } from "../../hooks/useEditOffer";
import { addMessage } from "../../store/modules/toast/actions";
import usePermissions from "../../hooks/usePermissions";
import { dataContext, permission } from "../../utils/permissionEnums";
import { Offer, SelectValue } from "../../store/modules/offer/types";
import { Listing } from "../../store/modules/listing/types";
import api from "../../services/api";
import { handleErrorResponse } from "utils/error-handlers";
import { formatCnpj, formatCpf } from "utils/documents";
import { formatNumber } from "utils/numbers";

const OfferDetails: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const { t } = useTranslation("offers");

  const { setBreadcrumbItems } = useBreadcrumb();
  const { setOffer: setOfferHook } = useEditOffer();

  const [offer, setOffer] = useState<any>({} as any);
  const [listing, setListing] = useState<Listing>({} as Listing);
  const [percentage, setPercentage] = useState<number>(0);
  const [showAlert, setShowAlert] = useState<boolean>(false);

  const hasPermissionToBlock = usePermissions(
    dataContext.OFFER,
    permission.BLOCK,
    offer.state
  );
  const hasPermissionToSubmit = usePermissions(
    dataContext.OFFER,
    permission.SUBMIT,
    offer.state
  );
  const hasPermissionToUpdate = usePermissions(
    dataContext.OFFER,
    permission.UPDATE,
    offer.state
  );

  const hasPermissionToInvalidate = usePermissions(
    dataContext.OFFER,
    permission.INVALIDATE
  );

  const profile = useSelector<ApplicationState, Profile>(
    (state) => state.user.profile
  );
  const [currency, setCurrency] = useState<SelectValue[]>([]);

  const checkPermission = () => {
    return hasPermissionToUpdate && (
      !profile?.accessType.startsWith("C") ||
      (offer?.state === "SUBMITTED" || offer?.state === "DRAFT")
    );
  };

  const checkPermissionSettled = () => {
    return (
      profile?.accessType.startsWith("A") && offer?.state === "ISSUED"
    );
  }

  const checkPermissionInvalidate = (state) => {
    return hasPermissionToInvalidate && ["PUBLISHED", "REVISION", "APPROVED"].includes(state) && offer.allowInvalidate
  }

  const formatOption = (option: Boolean) => {
    return option ? t("yes") : t("no");
  }

  function editOffer() {
    setOfferHook(offer);
    navigate(`/offers/offer/${offer.id}`);
  }

  async function takeAction(type, reason?) {
    const { data } = await api.patch(`/offers/${offer.id}/state`, {
      changeState: {
        action: type,
        ...(reason && { message: reason }),
      },
    });
    setOffer(data);
    dispatch(
      addMessage({
        title: t("offerHasBeenUpdated"),
        type: "info",
      })
    );
  }

  async function handleAction(
    type: "REFUSE" | "BLOCK" | "PUBLISH" | "SUBMIT" | "UPDATE" | "PUBLISH_IPO",
    reason?: string
  ) {
    if (type === "UPDATE") editOffer();
    else if (type === "REFUSE") takeAction(type, reason);
    else if (type === "SUBMIT") {
      takeAction(type);
      setShowAlert(true);
    } else takeAction(type);
  }

  useEffect(() => {
    setBreadcrumbItems({
      title: "offerDetails",
      items: [
        { title: "home", link: "/dashboard" },
        { title: "offers", link: "/offers" },
        { title: "offerDetails", link: "" },
      ],
    });
  }, [setBreadcrumbItems]);

  useEffect(() => {
    const controller = new AbortController();

    async function getPageData() {
      try {
        const { data: offerData } = await api.get<Offer>(`/offers/${id}`, {
          signal: controller.signal,
        });
        const { data: currencyData } = await api.get<SelectValue[]>(
          "/currencies/dropdown",
          {
            signal: controller.signal,
          }
        );
        const { data: listingData } = await api.get<Listing>(
          `/listedcompanies/${offerData.listedCompany}`,
          {
            signal: controller.signal,
          }
        );

        setOffer(offerData);
        setCurrency(currencyData);
        setPercentage(() => {
          const { id: idRemoved, ...objCopy } = offerData;
          const objLength = Object.entries(objCopy).length;
          const objLengthFilled = Object.entries(objCopy).filter(
            ([k, v], i) => !!v
          ).length;
          const result = (objLengthFilled / objLength) * 100;

          return parseFloat(result.toFixed(2));
        });
        setListing(listingData);
      } catch (error) {
        const { description } = handleErrorResponse(error);

        console.log("[OfferDetails][getPageData]", description);
      }
    }

    getPageData();

    return () => {
      controller.abort();
    };
  }, [id]);

  if (!offer?.id && !listing?.id) return <Spinner />;

  return (
    <>
      <SEO
        title={t("offerDetails", { ns: "breadcrumb" })}
        shouldIndexPage={false}
      />

      <Row>
        <Col xs="12" md="6">
          <p />
        </Col>
        <Col xs="12" md="6">
          <div className="text-end mb-4">
            <div className="button-items">
              <ActionBar
                actions={[
                  {
                    permission: false,
                    action: permission.BLOCK,
                  },
                  {
                    permission: hasPermissionToSubmit,
                    action: permission.SUBMIT,
                  },
                  {
                    permission: checkPermission(),
                    action: permission.UPDATE,
                  },
                  {
                    permission: checkPermissionSettled(),
                    action: permission.SETTLED,
                  },
                  {
                    permission: checkPermissionInvalidate(offer?.state),
                    action: permission.INVALIDATE,
                  },
                ]}
                onClick={handleAction}
                fieldsProps={{ticker: offer.ticker ?? "", instrumentType: offer.instrumentType ?? "", transactionExpirationDate: offer.transactionExpirationDate ?? ""}}
              />
            </div>
          </div>
        </Col>
      </Row>

      <Form onSubmit={() => ({})} initialData={offer}>
        <Card>
          <CardBody>
            <h4 className="card-title mb-4 text-info text-uppercase">
              {t("listingDetails")}
            </h4>

            <Row>
              <Col xs="12" md="4" className="mb-3">
                <Label className="text-capitalize">{t("tradingName")}</Label>
                <InputUncontrolled
                  className="form-control"
                  value={listing?.tradingName || ""}
                  disabled
                />
              </Col>
              <Col xs="12" md="4" className="mb-3">
                <Label className="text-capitalize">{t("companyName")}</Label>
                <InputUncontrolled
                  className="form-control"
                  value={listing?.company?.name || ""}
                  disabled
                />
              </Col>
              <Col xs="12" md="4" className="mb-3">
                <Label className="text-capitalize">CNPJ</Label>
                <NumberFormat
                  className="form-control"
                  value={listing?.company?.cnpj || ""}
                  format="##.###.###/####-##"
                  disabled
                />
              </Col>
            </Row>
          </CardBody>
        </Card>

        <Card>
          <CardBody>
            <h4 className="card-title mb-4 text-info text-uppercase">
              {t("issueInformation")}
            </h4>

            <Row>
              <Col xs="12" md="4">
                <Input
                  label="Ticker"
                  name="ticker"
                  maxLength={8}
                  value={offer.ticker}
                  disabled
                />
              </Col>
              <Col xs="12" md="4">
                <Input
                  label={t("contractNumber")}
                  name="contractNumber"
                  maxLength={100}
                  disabled
                />
              </Col>
              <Col xs="12" md="4">
                <Input
                  label={t("investorPaybackTime")}
                  name="investorPaybackTime"
                  value={offer.investorPaybackTime}
                  type="number"
                  min="0"
                  disabled
                />
              </Col>

              <Col xs="12" md="4">
                <Input
                  label={t("leadingDistributor")}
                  name="leadingDistributor"
                  value={offer?.leadingDistributor?.name || ""}
                  disabled
                />
              </Col>
              <Col xs="12" md="4">
                <Input
                  label={t("otherDistributors")}
                  name="offerDistributors"
                  value={offer.offerDistributors
                    .map((item) => item.name)
                    .join()}
                  disabled
                />
              </Col>
              <Col xs="12" md="4">
                <Input
                  label={t("codeISIN")}
                  name="codeISIN"
                  maxLength={12}
                  value={offer.codeISIN}
                  disabled
                />
              </Col>
              <Col xs="12" md="4">
                <Input
                  label={t("isExclusive")}
                  name="isExclusive"
                  value={formatOption(offer.isExclusive)}
                  disabled
                />
              </Col>
            </Row>
          </CardBody>
        </Card>

        <Card>
          <CardBody>
            <h4 className="card-title mb-4 text-info text-uppercase">
              {t("operationFeatures")}
            </h4>

            <Row>
              <Col xs="12" md="4">
                <Input
                  label={t("instrumentType")}
                  name="instrumentType"
                  value={offer.instrumentType.name}
                  disabled
                />
              </Col>
              <Col xs="12" md="4">
                <Input
                  label={t("currencies")}
                  name="currency"
                  value={offer.currency.name}
                  disabled
                />
              </Col>
              <Col xs="12" md="4">
                <InputMask
                  label={t("totalIssuanceAmount")}
                  name="totalIssuanceAmount"
                  thousandSeparator="."
                  decimalSeparator=","
                  value={offer.totalIssuanceAmount}
                  prefix="R$ "
                  isNumericString
                  decimalScale={2}
                  fixedDecimalScale
                  disabled
                />
              </Col>

              <Col xs="12" md="4">
                <Input
                  label={t("cashierRelease")}
                  name="cashierRelease"
                  value={offer.cashierRelease.name}
                  disabled
                />
              </Col>
              <Col xs="12" md="4">
                <Input
                  label={t("remunerationRates")}
                  name="remunerationRate"
                  value={offer.remunerationRate.name}
                  disabled
                />
              </Col>
              <Col xs="12" md="4">
                <InputPercentage
                  label={t("remunerationRatePercent")}
                  name="remunerationRatePercent"
                  value={offer.remunerationRatePercent}
                  decimalScale={6}
                  disabled
                />
              </Col>

              <Col xs="12" md="4">
                <Input
                  label={t("capitalizations")}
                  name="capitalizations"
                  value={offer?.capitalization?.name || ""}
                  disabled
                />
              </Col>
              <Col xs="12" md="4">
                <Input
                  disabled
                  label={t("indexer")}
                  name="indexers"
                  value={offer.indexer?.name || ""}
                />
              </Col>

              <Col xs="12" md="4">
                <Input
                  label={t("indexerApplications")}
                  name="indexerApplications"
                  value={offer.indexerApplication.name}
                  disabled
                />
              </Col>

              <Col xs="12" md="4">
                <InputPercentage
                  disabled
                  fixedDecimalScale
                  isNumericString
                  label={t("cet")}
                  name="totalEffectiveCost"
                  decimalScale={2}
                  value={offer.totalEffectiveCost}
                />
              </Col>
              <Col xs="12" md="4">
                <InputPercentage
                  label={t("reservationsMinimumPercentage")}
                  name="reservationsMinimumPercentage"
                  value={offer.reservationsMinimumPercentage}
                  isNumericString
                  decimalScale={2}
                  fixedDecimalScale
                  disabled
                />
              </Col>
              <Col xs="12" md="4">
                <InputMask
                  label={t("minimumReservationValue")}
                  name="minimumReservationValue"
                  value={offer.minimumReservationValue}
                  decimalScale={2}
                  fixedDecimalScale
                  disabled
                />
              </Col>

              <Col xs="12" md="4">
                <Input
                  label={t("paymentForms")}
                  name="paymentForm"
                  value={offer.paymentForm.name}
                  disabled
                />
              </Col>
              <Col xs="12" md="4">
                <Input
                  label={t("paymentOtherForm")}
                  name="paymentOtherForm"
                  value={offer.paymentOtherForm}
                  disabled
                />
              </Col>
              <Col xs="12" md="4">
                <Input
                  label={t("contractAmortizationFrequency")}
                  name="frequencyPayments"
                  value={offer.frequencyPayment.name}
                  disabled
                />
              </Col>

              <Col xs="12" md="4">
                <Input
                  label={t("interestCalculationCriterias")}
                  name="interestCalculationCriterias"
                  value={offer.interestCalculationCriteria.name}
                  disabled
                />
              </Col>
              <Col xs="12" md="4">
                <InputPercentage
                  label={t("latePaymentInterest")}
                  name="latePaymentInterest"
                  value={offer.latePaymentInterest}
                  disabled
                />
              </Col>
              <Col xs="12" md="4">
                <Input
                  label={t("interestRateApplications")}
                  name="interestRateApplications"
                  value={offer.interestRateApplication.name}
                  disabled
                />
              </Col>

              <Col xs="12" md="4">
                <Input
                  label={t("lateFeeOptions")}
                  name="lateFeeOptions"
                  value={offer.lateFeeOption.name}
                  disabled
                />
              </Col>
              <Col xs="12" md="4">
                {offer.lateFeeOption.name === "Percentual" ? (
                  <InputPercentage
                    label={t("lateFeeValue")}
                    name="lateFeeValue"
                    value={offer.lateFeeValue}
                    disabled
                  />
                ) : (
                  <InputMask
                    label={t("lateFeeValue")}
                    name="lateFeeValue"
                    decimalScale={2}
                    value={offer.lateFeeValue}
                    disabled
                    fixedDecimalScale
                  />
                )}
              </Col>
              <Col xs="12" md="4">
                {/* <Input
                  label={t("numberOfInstallments")}
                  name="numberOfInstallments"
                  maxLength={100}
                  disabled
                /> */}
                <InputMask
                  label={t("numberOfInstallments")}
                  name="numberOfInstallments"
                  decimalScale={0}
                  value={offer.numberOfInstallments}
                  disabled
                  fixedDecimalScale
                />
              </Col>

              <Col xs="12" md="12">
                <TextArea
                  label={t("destinationResources")}
                  name="resourcesDestination"
                  value={offer.resourcesDestination}
                  rows={3}
                  disabled
                />
              </Col>
            </Row>
          </CardBody>
        </Card>

        <Card>
          <CardBody>
            <h4 className="card-title mb-4 text-info text-uppercase">
              {t("tokenizationParams")}
            </h4>

            <Row>
              <Col xs="12" md="4">
                <Input
                  label={t("lotsQuantity")}
                  name="lotsQuantity"
                  value={offer.lotsQuantity}
                  type="number"
                  min="0"
                  disabled
                />
              </Col>
              <Col xs="12" md="4">
                <InputMask
                  label={t("lotPrice")}
                  name="unitValue"
                  className="form-control"
                  value={offer.unitValue}
                  decimalScale={2}
                  prefix="R$ "
                  isNumericString
                  fixedDecimalScale
                  disabled
                />
              </Col>
              <Col xs="12" md="4" />

              <Col xs="12" md="4">
                <InputPercentage
                  label={t("investorMaximumLotsPercentage")}
                  name="investorMaximumLotsPercentage"
                  value={offer.investorMaximumLotsPercentage}
                  decimalScale={2}
                  isNumericString
                  disabled
                />
              </Col>
              <Col xs="12" md="4">
                <InputMask
                  label={t("investorMaximumLotsQuantity")}
                  name="investorMaximumLotsQuantity"
                  className="form-control"
                  value={offer.investorMaximumLotsQuantity}
                  decimalScale={2}
                  isNumericString
                  fixedDecimalScale
                  disabled
                />
              </Col>
            </Row>
          </CardBody>
        </Card>

        <Card>
          <CardBody>
            <h4 className="card-title mb-4 text-info text-uppercase">
              {t("calendar")}
            </h4>

            <Row>
              <Col xs="12" md="3">
                <DatePicker
                  label={t("bookingStartDate")}
                  name="startReservationDate"
                  dateFormat="dd/MM/yyyy"
                  disabled
                />
              </Col>
              <Col xs="12" md="3">
                <DatePicker
                  label={t("reservationDeadline")}
                  name="reservationDeadline"
                  dateFormat="dd/MM/yyyy"
                  disabled
                />
              </Col>
              <Col xs="12" md="3">
                <DatePicker
                  label={t("emissionDate")}
                  name="emissionDate"
                  dateFormat="dd/MM/yyyy"
                  disabled
                />
              </Col>
              <Col xs="12" md="3">
                <DatePicker
                  label={t("transactionExpirationDate")}
                  name="transactionExpirationDate"
                  dateFormat="dd/MM/yyyy"
                  disabled
                />
              </Col>
            </Row>
          </CardBody>
        </Card>

        <Attachments attachments={offer.attachments} detailPage />

        <Card>
          <CardBody>
            <h4 className="card-title mb-4 text-info text-uppercase">
              {t("guarantorsData")}
            </h4>

            {offer.warranties.map((warranty, index) => (
              <Card key={index} className="border shadow-none accordion">
                <CardHeader className="d-flex align-items-center">
                  <span
                    className="cursor-pointer"
                    id={`toggler_${index}`}
                    data-toggle="collapse"
                  >
                    <u>{t(warranty.warrantyType)}</u>
                  </span>
                </CardHeader>

                <UncontrolledCollapse
                  className="border-top"
                  toggler={`#toggler_${index}`}
                >
                  <CardBody>
                    <Form initialData={warranty} onSubmit={() => ({})}>
                      <Row>
                        <Col xs="12" md="6">
                          <FormGroup>
                            <Label for="warrantyType">
                              {t("warrantyType")}
                            </Label>
                            <InputUncontrolled
                              id="warrantyType"
                              defaultValue={t(warranty.warrantyType)}
                              disabled
                            />
                          </FormGroup>
                        </Col>
                        <Col xs="12" md="6">
                          <FormGroup>
                            <Label>{t("warrantyInstrument")}</Label>
                            <InputGroup>
                              <InputUncontrolled
                                id="warrantyInstrument"
                                defaultValue={
                                  warranty?.warrantyInstrumentName ?? ""
                                }
                                disabled
                              />
                              <Button
                                id="download-warrantyInstrument"
                                color="primary"
                                href={warranty.warrantyInstrument}
                                download={
                                  warranty?.warrantyInstrumentName ?? uuidv4()
                                }
                              >
                                <i className="fas fa-download" />
                              </Button>
                            </InputGroup>
                          </FormGroup>
                        </Col>

                      </Row>
                    </Form>
                    <Row>
                      <Col>
                        <Label for="observation">{t("observation")}</Label>
                        <Input
                          name="observation"
                          type="textarea"
                          placeholder={t("observation")}
                          value={warranty.observation}
                          disabled
                        />
                      </Col>
                    </Row>

                    <DataTable
                      pagination={false}
                      columns={[
                        {
                          name: "CPF",
                          center: true,
                          sortable: true,
                          cell: ({ guarantorCpf }) => formatCpf(guarantorCpf),
                        },
                        {
                          name: "CNPJ",
                          center: true,
                          sortable: true,
                          cell: ({ guarantorCnpj }) =>
                            formatCnpj(guarantorCnpj),
                        },
                        {
                          name: t("guarantorPercentage"),
                          center: true,
                          sortable: true,
                          cell: ({ guarantorPercentage }) =>
                            `${formatNumber({
                              numericValue: Number(guarantorPercentage),
                            })} %`,
                        },
                      ]}
                      data={warranty.guarantors}
                    />
                  </CardBody>
                </UncontrolledCollapse>
              </Card>
            ))}
          </CardBody>
        </Card>
      </Form>
    </>
  );
};

export default OfferDetails;

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import SweetAlert from 'react-bootstrap-sweetalert';
import { useNavigate } from 'react-router-dom';

import { useBreadcrumb } from '../../hooks/useBreadcrumb';
import SEO from '../../components/SEO';
import RecsTab from '../../components/recommendations/details/recs';
import RecipientsTab from '../../components/recommendations/details/recipients';
import ResumeTab from '../../components/recommendations/details/resume';

const Recommendations: React.FC = () => {
  const { t } = useTranslation('recommendations');
  const { setBreadcrumbItems } = useBreadcrumb();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(0);
  const [selectedOffer, setSelectedOffer] = useState();
  const [recsList, setRecsList] = useState([]);
  const [submit, setSubmit] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);

  const hasRecommendation = false;

  useEffect(() => {
    setBreadcrumbItems({
      title: hasRecommendation
        ? 'editRecommendation'
        : 'registerNewRecommendation',
      items: [
        { title: 'home', link: '/dashboard' },
        { title: 'systemRecommendations', link: '/recommendations' },
        {
          title: hasRecommendation
            ? 'editRecommendation'
            : 'registerNewRecommendation',
          link: '',
        },
      ],
    });
  }, [setBreadcrumbItems, hasRecommendation]);

  return (
    <>
      <SEO
        title={
          hasRecommendation
            ? t('editRecommendation')
            : t('registerRecommendation')
        }
        shouldIndexPage={false}
      />

      {submit ? (
        <SweetAlert
          warning
          showCancel
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          title={t('attention')}
          onConfirm={() => setSuccess(true)}
          onCancel={() => setSubmit(false)}
        >
          {t('yourRecommendationWillBeSend')}
        </SweetAlert>
      ) : null}
      {success ? (
        <SweetAlert
          success
          title={t('sent')}
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            setSuccess(false);
            navigate('/recommendations');
          }}
        >
          {t('yourReccomendationWasSent')}
        </SweetAlert>
      ) : null}

      <Nav tabs className="nav-tabs-custom border-bottom-0" role="tablist">
        <div className="d-flex mx-auto">
          {[t('recommendation'), t('recipients'), t('confirmation')].map(
            (tab, idx) => (
              <div
                key={`NavRecs-${idx}`}
                className="d-flex flex-column align-items-center me-4"
              >
                <NavItem
                  key={`navItem-${idx}`}
                  className={`rounded-circle p-1 ${
                    activeTab === idx ? 'bg-info' : 'bg-secondary'
                  }`}
                >
                  <NavLink>
                    <div className="text-white">{idx + 1}</div>
                  </NavLink>
                </NavItem>
                {tab}
              </div>
            ),
          )}
        </div>
      </Nav>

      <TabContent activeTab={activeTab}>
        <TabPane key="tabContent-0" tabId={0} className="p-3">
          <RecsTab
            selectedOffer={selectedOffer}
            setSelectedOffer={setSelectedOffer}
            recsList={recsList}
            setRecsList={setRecsList}
          />
        </TabPane>
        <TabPane key="tabContent-1" tabId={1} className="p-3">
          <RecipientsTab />
        </TabPane>
        <TabPane key="tabContent-2" tabId={2} className="p-3">
          <ResumeTab />
        </TabPane>
      </TabContent>
      <div className="mb-5 text-end px-3">
        <div>
          <Button
            className="waves-effect waves-light"
            color="info"
            size="md"
            disabled={activeTab === 0}
            onClick={() => setActiveTab(oldState => oldState - 1)}
          >
            {t('previous')}
          </Button>
          <Button
            className="waves-effect waves-light ms-3"
            size="md"
            color="info"
            onClick={() => {
              if (activeTab === 2) setSubmit(true);
              else setActiveTab(oldState => oldState + 1);
            }}
          >
            {activeTab === 2 ? t('submitForApproval') : t('next')}
          </Button>
        </div>
      </div>
    </>
  );
};

export default Recommendations;

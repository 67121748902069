import axios, { AxiosError } from 'axios';
import i18n from '../i18n';

import { store } from '../store';
import { setToken, signOut } from '../store/modules/auth/actions';
import { addMessage } from '../store/modules/toast/actions';

let isRefreshing = false;
let failedRequestsQueue = [];

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

api.interceptors.request.use(config => {
  if (!i18n.languages) {
    config.headers.common['Accept-Language'] = 'pt-BR;q=1';
  } else {
    config.headers.common['Accept-Language'] = i18n?.languages
      .map(locale => {
        if (locale === i18n?.language) return `${locale};q=1`;
        return `${locale};q=0.8`;
      })
      .join(',');
  }

  return config;
});



api.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (error.response.status === 401) {
      const { isSigned } = store.getState().auth;
      const authenticate = error.response.headers['www-authenticate'];
      const originalConfig = error.config;

      if (authenticate) {
        if (!isRefreshing) {
          isRefreshing = true;

          try {
            failedRequestsQueue.forEach(request =>
              request.onSuccess(authenticate),
            );
            failedRequestsQueue = [];

            store.dispatch(setToken(authenticate));

            return new Promise((resolve, reject) => {
              error.config.headers.Authorization = `Bearer ${authenticate}`;
              resolve(api(error.config));
            });
          } catch (err) {
            failedRequestsQueue.forEach(request => request.onFailure(err));
            failedRequestsQueue = [];
          } finally {
            isRefreshing = false;
          }
        }
      } else if (isSigned) {
        if (!failedRequestsQueue.length) {
          store.dispatch(
            addMessage({
              title: i18n.t('toasts:expiredTokenTitle'),
              description: i18n.t('toasts:expiredTokenDescription'),
              type: 'error',
            }),
          );
          setTimeout(() => {
            store.dispatch(signOut());
          }, 4000);
        }
      } else if (!isSigned) {
        return Promise.reject(error);
      }

      return new Promise((resolve, reject) => {
        failedRequestsQueue.push({
          onSuccess: (token: string) => {
            originalConfig.headers.Authorization = `Bearer ${token}`;

            resolve(api(originalConfig));
          },
          onFailure: (err: AxiosError) => {
            reject(err);
          },
        });
      });
    }
    if (error.response.status === 400 || error.response.status === 500) {
      if (error.response.data.error) {
        store.dispatch(
          addMessage({
            title: i18n.t('toasts:error'),
            description: error.response.data.error,
            type: 'error',
          }),
        );
      } else {
        error.response.data.forEach(errorMessage => {
          store.dispatch(
            addMessage({
              title: i18n.t('toasts:error'),
              description: errorMessage,
              type: 'error',
            }),
          );
        });
      }
    }

    return Promise.reject(error);
  },
);

export default api;

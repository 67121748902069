import { Reducer } from 'redux';
import produce, { Draft } from 'immer';
import { ListingState, ListingTypes } from './types';

const INITIAL_STATE: ListingState = {
  loading: false,
};

const reducer: Reducer<ListingState> = (state = INITIAL_STATE, action) => {
  return produce(state, (draft: Draft<ListingState>) => {
    switch (action.type) {
      case ListingTypes.CREATE_LISTING_REQUEST: {
        draft.loading = true;
        break;
      }
      case ListingTypes.CREATE_LISTING_SUCCESS: {
        draft.loading = false;
        break;
      }
      case ListingTypes.CREATE_COMPANIES_REQUEST: {
        draft.loading = true;
        break;
      }
      case ListingTypes.CREATE_COMPANIES_SUCCESS: {
        draft.loading = false;
        break;
      }
      case ListingTypes.UPDATE_LISTING_REQUEST: {
        draft.loading = true;
        break;
      }
      case ListingTypes.UPDATE_LISTING_SUCCESS: {
        draft.loading = false;
        break;
      }
      case ListingTypes.CREATE_NATURAL_PERSON_REQUEST: {
        draft.loading = false;
        break;
      }
      case ListingTypes.CREATE_COMPANIES_SUCCESS: {
        draft.loading = false;
        break;
      }
      case ListingTypes.UPDATE_NATURAL_PERSON_REQUEST: {
        draft.loading = false;
        break;
      }
      case ListingTypes.UPDATE_NATURAL_PERSON_SUCCESS: {
        draft.loading = false;
        break;
      }
      default:
    }
  });
};

export default reducer;

import { all, takeLatest, call, put } from "redux-saga/effects";

import i18n from "../../../i18n";
import api from "../../../services/api";

import { addMessage } from "../toast/actions";
import { OrderTypes } from "./types";
import {
  createOrderFailure,
  createOrderSuccess,
} from "./actions";

export function* createOrder({ payload }: any) {
  try {
    const { order, navigate } = payload;

    yield call(api.post, "/orders", order);

    yield put(
      addMessage({
        title: i18n.t("orderHasBeenCreated", { ns: "toasts" }),
        type: "success",
      })
    );

    yield put(createOrderSuccess());

    navigate("/orders");
  } catch (error) {
    // yield put(
    //   addMessage({
    //     title: i18n.t("errorOnCreateOrder", { ns: "toasts" }),
    //     type: "error",
    //   })
    // );
    console.log('Erro ao criar ordem!');
    yield put(createOrderFailure());
  }
}

export default all([
  takeLatest(OrderTypes.CREATE_ORDER_REQUEST, createOrder),
]);

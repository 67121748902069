import { NavigateFunction } from 'react-router-dom';

// Action Types
export enum AuthTypes {
  SIGN_IN_REQUEST = '@auth/SIGN_IN_REQUEST',
  SIGN_IN_MFA_REQUEST = '@auth/SIGN_IN_MFA_REQUEST',
  SIGN_IN_SUCCESS = '@auth/SIGN_IN_SUCCESS',
  SIGN_IN_FAILURE = '@auth/SIGN_IN_FAILURE',
  SIGN_UP_REQUEST = '@auth/SIGN_UP_REQUEST',
  SET_TOKEN = '@auth/SET_TOKEN',
  SET_CREDENTIALS = '@auth/SET_CREDENTIALS',
  SET_RESPONSE_DATA = '@auth/SET_RESPONSE_DATA',
  SET_LOADING = '@auth/SET_LOADING',
  SIGN_OUT = '@auth/SIGN_OUT',
  RESET_IS_SIGNED = '@auth/RESET_IS_SIGNED',
  CLEAR_LOADING = '@auth/CLEAR_LOADING',
}

// Data type
export interface AuthPayload {
  remember?: boolean;
  name?: string;
  username?: string;
  deviceName?: string;
  password?: string;
  totpInitialCode?: string;
  encodedTotpSecret?: string;
  totp?: string;
  overwrite?: boolean;
  navigate?: NavigateFunction;
}

// State Type
export interface AuthState {
  readonly name: string;
  readonly isSigned: boolean;
  readonly token: string;
  readonly loading: boolean;
  readonly error: boolean;
  readonly credentials: {
    name?: '',
    password: '',
    username?: '',
  }
  readonly responseData: MfaResponse | null;
}

export interface RequestData {
  name: string;
  password: string;
}

export interface MfaResponse {
  encodedTotpSecret: string;
  totpSecretQRCode: string;
  token: string;
}

export interface MfaSetupState {
  pathname: any;
  totpSecretQRCode: string;
  encodedTotpSecret: string;
  token: string;
}

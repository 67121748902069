import React, { useState, useEffect, useRef, useMemo } from "react";
import { Card, CardBody, Row, Col, Button, Label, Spinner } from "reactstrap";
import LoadingButton from "./../../components/Button";
import { useDispatch, useSelector } from "react-redux";
import { FormHandles, SubmitHandler } from "@unform/core";
import { Form } from "@unform/web";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

import { ApplicationState } from "../../store";
import { addMessage } from "../../store/modules/toast/actions";
import InputMask from "../../components/FormFields/InputMask";
import Select from "../../components/FormFields/Select";
import SEO from "../../components/SEO";
import { useBreadcrumb } from "../../hooks/useBreadcrumb";
import api from "../../services/api";
import { useEditOrder } from "../../hooks/useEditOrder";
import { OrderState } from "store/modules/order/types";
import { createOrderRequest } from "store/modules/order/actions";
import { AccessType } from "store/modules/user/types";
import { ACCESS_TYPE_BROKER_B0, ACCESS_TYPE_BROKER_B1, ACCESS_TYPE_BROKER_B2 } from "utils/constants";
import { formatNumber } from "utils/numbers";
import { formatCpfCnpj } from "utils/documents";

const CreateEditOrder: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation("orders");

  const { setBreadcrumbItems } = useBreadcrumb();
  const { hasOrder, order, setOrder } = useEditOrder();

  const formRef = useRef<FormHandles>(null);

  const [assignorList, setAssignorList] = useState([]);
  const [transfereeList, setTransfereeList] = useState([]);
  const [distributorsList, setDistributorsList] = useState([]);
  const [tickersList, setTickersList] = useState([]);
  const [selectedTicker, setSelectedTicker] = useState<string | null>(null);

  const orderState = useSelector<ApplicationState, OrderState>((s) => s.order);
  const [loading, setLoading] = useState(false);
  const [loadingAssignors, setLoadingAssignors] = useState(false);
  const [loadingTransferee, setLoadingTransferee] = useState(false);
  const [loadingTickers, setLoadingTickers] = useState(false);

  const [maxQtdTickers, setMaxQtdTickers] = useState(0);
  const [qtdTokens, setQtdTokens] = useState(0);
  const [pu, setPu] = useState(0);
  const [valorNegocio, setValorNegocio] = useState('0,00');

  const accessType = useSelector<ApplicationState, AccessType>(
    (state) => state.user.accessType
  );
  const isAccessTypeBroker = useMemo(
    () =>
      [ACCESS_TYPE_BROKER_B0, ACCESS_TYPE_BROKER_B1, ACCESS_TYPE_BROKER_B2].includes(
        accessType.name
      ),
    [accessType.name]
  );


  useEffect(() => {
    setDefaultValues();
    setBreadcrumbItems({
      title: hasOrder ? "editOrder" : "registerNewOrder",
      items: [
        { title: "home", link: "/dashboard" },
        { title: "orders", link: "/orders" },
        { title: hasOrder ? "editOrder" : "registerNewOrder", link: "" },
      ],
    });
  }, [setBreadcrumbItems, hasOrder]);

  function setDefaultValues() {
    formRef.current.setFieldValue('quantity', 0);
    formRef.current.setFieldValue('unitValue', 0);
  }

  useEffect(() => {

    const controller = new AbortController();

    async function getPageData() {
      try {

        const { data: distributors } = await api.get("/brokers", {
          signal: controller.signal,
        });

        // const { data: tickers } = await api.get("/tickers", {
        //   signal: controller.signal,
        // });

        if (isAccessTypeBroker) {
          getInvestors();
        }

        setDistributorsList(
          distributors.map((distributors) => ({ label: distributors.company.name, value: distributors }))
        );

        // setTickersList(
        //   tickers.map((ticker) => ({ label: ticker.ticker, value: ticker.ticker }))
        // );

      } catch (error) {
        console.log(error)
        dispatch(
          addMessage({
            title: t("error"),
            description: t("itWasNotPossibleToBringInitialData"),
            type: "error",
          })
        );
      }
    }

    getPageData();

    return () => {
      controller.abort();
    };
  }, [dispatch, t]);

  async function getInvestors() {

    try {

      const controller = new AbortController();

      setLoadingTransferee(true);
      setLoadingAssignors(true);

      const { data: investors } = await api.get("/investor_company/investorbroker/0", {
        signal: controller.signal,
      });

      setTransfereeList(
        investors.map((investor) => ({ label: `${formatCpfCnpj(investor.investorDocumentNumber)} - ${investor.investorName}`, value: investor }))
      );

      setAssignorList(
        investors.map((investor) => ({ label: `${formatCpfCnpj(investor.investorDocumentNumber)} - ${investor.investorName}`, value: investor }))
      );

      setLoadingTransferee(false);
      setLoadingAssignors(false);

    } catch (error) {
      console.log(error)
      setLoadingTransferee(false);
      setLoadingAssignors(false);
      dispatch(
        addMessage({
          title: t("error"),
          description: t("itWasNotPossibleToBringInitialData"),
          type: "error",
        })
      );
    }

  }

  const handleDistributorChange = async (data) => {
    try {

      const brokerId = data.value.id;
      const assignor = formRef.current.getFieldValue('assignor');

      const controller = new AbortController();

      if (brokerId) {

        formRef.current.setFieldValue('assignor', null);

        setLoadingAssignors(true);

        const { data: investorsAssignor } = await api.get(`/investor_company/investorbroker/${brokerId}/order/assignor`, {
          signal: controller.signal,
        });

        setAssignorList(
          investorsAssignor.map((investor) => ({ label: `${formatCpfCnpj(investor.investorDocumentNumber)} - ${investor.investorName}`, value: investor }))
        );

        setLoadingAssignors(false);
      }

    } catch (error) {
      console.log(error)
      setLoadingAssignors(false);
      setLoadingTickers(false);
      // dispatch(
      //   addMessage({
      //     title: t("error"),
      //     description: t("itWasNotPossibleToBringInitialData"),
      //     type: "error",
      //   })
      // );
    }
  }

  const handleAssignorChange = async (data) => {
    try {

      const brokerId = formRef.current.getFieldValue('distributor') ? formRef.current.getFieldValue('distributor').id : 0;
      const assignor = data?.value;

      const controller = new AbortController();

      if (
        (assignor && brokerId != 0 && !isAccessTypeBroker) ||
        (assignor && brokerId === 0 && isAccessTypeBroker)
      ) {
        formRef.current.setFieldValue('ticker', null);

        setLoadingTickers(true);

        const { data: tickers } = await api.get(`/investor_company/investorbroker/${brokerId}/investorcompany/${assignor.brokerInvestorId}`, {
          signal: controller.signal,
        });

        setTickersList(
          tickers.map((ticker) => ({ label: ticker.ticker, value: ticker }))
        );

        setLoadingTickers(false);
        setSelectedTicker(null);
      }

    } catch (error) {
      console.log(error)
      setLoadingTickers(false);
      setTickersList([]);
      // dispatch(
      //   addMessage({
      //     title: t("error"),
      //     description: t("itWasNotPossibleToBringInitialData"),
      //     type: "error",
      //   })
      // );
    }
  }

  const getTransferees = async (ticker: string) => {
    try {
      setLoadingTransferee(true);

      const brokerId = formRef.current.getFieldValue('distributor') ? formRef.current.getFieldValue('distributor').id : 0;

      const { data: investorsTransferee } = await api.get(`/investor_company/investorbroker/${brokerId}/order/transferee/${ticker}`);

      setTransfereeList(
        investorsTransferee.map((investor) => ({ label: `${formatCpfCnpj(investor.investorDocumentNumber)} - ${investor.investorName}`, value: investor }))
      );

      setLoadingTransferee(false);
    } catch (error) {
      console.log(error);
      setLoadingTransferee(false);
      setTransfereeList([]);
    }
  }

  const handleTickerChange = async (data) => {
    console.log(data)

    if (data) {
      setMaxQtdTickers(data.value.count);
      getTransferees(data.value.ticker);
    } else {
      setSelectedTicker(null);
    }
  }

  const handleSubmit: SubmitHandler<any> = async (data) => {
    try {

      formRef.current.setErrors({});

      let maxQtdTickers = data.ticker ? data.ticker.count : null;
      console.log(maxQtdTickers)

      const schema = Yup.object().shape({
        distributor: Yup.object()
          .when([], {
            is: () => !isAccessTypeBroker,
            then: Yup.object().required(t("requiredField")),
          }),
        ticker: Yup.object().required(t("requiredField")),
        assignor: Yup.object().required(t("requiredField")),
        transferee: Yup.object().required(t("requiredField")),
        quantity: Yup.number().when(['assignor'], {
          is: (assignor: any) => {
            if (assignor) {
              return true;
            }
          },
          then: Yup.number().max(maxQtdTickers, t('maxQtd') + maxQtdTickers).min(1, t('minQtd') + '0'),
          otherwise: Yup.number().optional(),
        }),
        unitValue: Yup.string().required(t("requiredField")),
      });
      await schema.validate(data, {
        abortEarly: false,
      });

      let obj: any = {
        assignorDocument: data.assignor.investorDocumentNumber,
        assignorCorporateName: data.assignor.investorName,
        ticker: data.ticker.ticker,
        qtdTokens: Number(data.quantity),
        negotiatedUnitPrice: Number(data.unitValue),
        transfereeDocument: data.transferee.investorDocumentNumber,
        transfereeCorporateName: data.transferee.investorName,
        distributor: data.distributor ? data.distributor.company.name : null,
        distributorDocument: data.distributor ? data.distributor.company.cnpj : null
      };

      console.log('handleSubmit -> ', obj)

      setLoading(true);
      dispatch(
        createOrderRequest(
          {
            ...obj,
          },
          navigate
        ),
      );

    } catch (err) {

      const validationErrors = {};

      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
        dispatch(
          addMessage({
            title: t("attention"),
            description: t("checkFields"),
            type: "info",
          })
        );

        formRef.current
          .getFieldRef(Object.getOwnPropertyNames(validationErrors)[0])
          .focus();
      }
    }
  };

  function handleCancel() {
    // setOrder(null);
    navigate(`/orders`);
  }

  function onChangePu(value) {
    if (value.floatValue) {
      setPu(value.floatValue);
    } else {
      setPu(0);
    }
    calculateValorNegocio(qtdTokens, value.floatValue || 0);
  }

  function onChangeQtdTokens(value) {
    if (value.floatValue) {
      setQtdTokens(value.floatValue);
    } else {
      setQtdTokens(0);
    }
    calculateValorNegocio(value.floatValue || 0, pu);
  }

  function calculateValorNegocio(qtdTokens: number, pu: number) {
    const calculated = qtdTokens * pu;
    setValorNegocio(formatNumber({
      numericValue: calculated,
      precision: 2
    }))
  }

  useEffect(() => {
    if (selectedTicker) {
      getTransferees(selectedTicker);
    }
  }, [selectedTicker]);

  useEffect(() => {
    const errors = formRef.current?.getErrors();

    if (errors && Object.keys(errors).length > 0) {
      formRef.current.submitForm();
    }
  }, [i18next.language]);

  return (
    <>
      <SEO
        title={
          hasOrder
            ? t("editOrder", { ns: "breadcrumb" })
            : t("registerNewOrder", { ns: "breadcrumb" })
        }
        shouldIndexPage={false}
      />

      <Form
        ref={formRef}
        onSubmit={handleSubmit}
        initialData={hasOrder ? order : {}}
      >
        <Card>
          <CardBody>
            <h4 className="card-title mb-4 text-uppercase">
              {t("orderData")}
            </h4>

            {!isAccessTypeBroker ?
              <Row>
                <Col xs="12" md="4">
                  <Select
                    label={t("distributor")}
                    name="distributor"
                    options={distributorsList}
                    isDisabled={hasOrder}
                    required
                    onChangeValue={handleDistributorChange}
                  />
                </Col>
              </Row> : null}
            <Row>
              <Col xs="12" md="4">
                <div className="align-items-center full-width">
                  <Select
                    label={t("assignor")}
                    name="assignor"
                    options={assignorList}
                    isDisabled={hasOrder || !assignorList.length}
                    required
                    onChange={handleAssignorChange}
                  />
                  {loadingAssignors && (
                    <Spinner
                      className="ml-1"
                      size="sm"
                    />
                  )
                  }
                </div>

              </Col>

              <Col xs="12" md="4">
                <div className="align-items-center full-width">
                  <Select
                    label={t("ticker")}
                    name="ticker"
                    options={tickersList}
                    isDisabled={hasOrder || !tickersList.length}
                    required
                    onChangeValue={handleTickerChange}
                  />
                  {loadingTickers && (
                    <Spinner
                      className="ml-1"
                      size="sm"
                    />
                  )
                  }
                </div>
              </Col>

              <Col xs="12" md="4">
                <div className="align-items-center full-width">
                  <Select
                    label={t("transferee")}
                    name="transferee"
                    options={transfereeList}
                    isDisabled={hasOrder || !transfereeList.length}
                    required
                  />
                  {loadingTransferee && (
                    <Spinner
                      className="ml-1"
                      size="sm"
                    />
                  )
                  }
                </div>
              </Col>
              <Col xs="12" md="4">

                <InputMask
                  isNumericString
                  fixedDecimalScale
                  label={t("quantity")}
                  name="quantity"
                  decimalScale={0}
                  required
                  onChange={onChangeQtdTokens}
                />
                {(maxQtdTickers > 0) && (
                  <span>{t("hintQtd")} 1 - {maxQtdTickers}</span>
                )}
              </Col>
              <Col xs="12" md="4">
                <InputMask
                  isNumericString
                  fixedDecimalScale
                  label={t("PU")}
                  name="unitValue"
                  thousandSeparator="."
                  decimalSeparator=","
                  decimalScale={8}
                  prefix="R$ "
                  required
                  onChange={onChangePu}
                />
              </Col>
              <Col xs="12" md="4">
                <Label>{t("orderValue")}</Label>
                <p>{'R$ ' + valorNegocio}</p>
              </Col>
            </Row>
          </CardBody>
        </Card>

        <div className="mb-5 text-end">
          <div className="justify-content-end">
            <LoadingButton
              id="save"
              className="waves-effect waves-light btn-lg me-3 btn-loader-sm"
              type="submit"
              loading={orderState.loading}
              disabled={orderState.loading}
              color='info'
              block={true}
            >
              {t("save")}
            </LoadingButton>
            <Button
              id="cancel"
              type="button"
              className="waves-effect waves-light btn-lg"
              color="secondary"
              onClick={handleCancel}
              outline
            >
              {t("cancel")}
            </Button>
          </div>
        </div>
      </Form>
    </>
  );
};

export default CreateEditOrder;

import i18next from "i18next";
import { ReactNode, useEffect, useState } from "react";
import { SubmitHandler, useController, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { OptionsType, OptionTypeBase } from "react-select";
import { Button, Dropdown, DropdownMenu, DropdownToggle } from "reactstrap";

import { formatDateToISO8601 } from "utils/date-time";
import DatePicker from "./HookFormFields/DatePicker";
import Input from "./HookFormFields/Input";
import MaskedInput from "./HookFormFields/MaskedInput";
import Select from "./HookFormFields/Select";

type FieldVariant = "text" | "date" | "select" | "pattern";

type SearchBarField = {
  key: string;
  label: string;
  variant: FieldVariant;
  options?: OptionsType<OptionTypeBase>;
  mask?: string | Array<string | RegExp>;
  onChange?: (event) => void;
};

type SearchBarProps = {
  fields: Array<SearchBarField>;
  onSearch: (fields) => void;
  dropdownLg?: boolean;
};

const SearchBar = ({ fields, onSearch, dropdownLg }: SearchBarProps) => {
  const { t } = useTranslation("common");
  const {
    control,
    formState: { errors },
    handleSubmit,
    trigger,
    setValue,
  } = useForm();

  const {
    field: { onChange: onChangeTicker },
  } = useController({
    name: "ticker",
    control,
  });

  const [menu, setMenu] = useState(false);
  const [filters, setFilters] = useState<any>("");

  const toggle = () => setMenu((state) => !state);

  const getInputBy = ({
    key,
    label,
    variant,
    options,
    mask,
  }: SearchBarField): ReactNode => {
    if (variant === "date") {
      return (
        <DatePicker
          label={label}
          name={key}
          control={control}
          dateFormat="dd/MM/yyyy"
          placeholder="dd/mm/aaaa"
        />
      );
    }

    if (variant === "select") {
      return (
        <Select label={label} name={key} control={control} options={options} />
      );
    }

    if (variant === "pattern") {
      if (key === "ticker") {
        return (
          <MaskedInput
            label={label}
            name={key}
            control={control}
            mask={mask}
            onChange={(e) => {
              onChangeTicker(e.target.value.toUpperCase());
            }}
          />
        );
      }

      return (
        <MaskedInput label={label} name={key} control={control} mask={mask} />
      );
    }

    return <Input label={label} name={key} control={control} />;
  };

  const onSubmit: SubmitHandler<any> = (data) => {
    toggle();

    const usedFilters = fields
      .filter(({ key }) => data[key])
      .map(({ label }) => label)
      .join(", ");
    setFilters(usedFilters);

    fields
      .filter(({ key }) => data[key])
      .forEach(({ key, variant }) => {
        if (variant === "date") {
          data[key] = formatDateToISO8601(data[key]);
          // Backend accept dates on this format: `DD-MM-YYYY`
          data[key] = data[key].split("-").reverse().join("-");
        }
      });

    onSearch(data);
  };

  const clearForm = () => {
    fields.forEach(({ key, variant }) => {
      if (variant === "date") {
        setValue(key, null);
      } else {
        setValue(key, "");
      }
    });

    handleSubmit(onSubmit)();
  };

  useEffect(() => {
    if (errors && Object.keys(errors).length > 0) {
      trigger();
    }
  }, [i18next.language]);

  return (
    <Dropdown
      isOpen={menu}
      toggle={() => ({})}
      onMouseUp={(e) => {
        e.stopPropagation();
      }}
    >
      <DropdownToggle tag="div">
        <div className="app-search py-0 my-2">
          <div
            role="none"
            id="searchBar"
            data-test="datatable-input"
            className={`position-relative waves-effect ${dropdownLg ? 'full-width' : ''}`}
            onClick={toggle}
          >
            <i
              className="fa fa-search position-absolute px-3"
              style={{
                lineHeight: "38px",
              }}
            />
            <input
              type="text"
              className="form-control px-5 bg-white border border-muted"
              placeholder={t("search")}
              value={filters}
              disabled
            />
            <span
              role="none"
              className="p-0 mdi mdi-arrow-down-drop-circle text-info"
            />
          </div>
        </div>
      </DropdownToggle>

      <form onSubmit={handleSubmit(onSubmit)}>
        <DropdownMenu className={dropdownLg ? 'dropdown-menu-xl' : ''}>

          <div className="container py-2 px-4">
            <div className="row row-cols-4">
              {fields.map((field) => (
                <div
                  key={`searchBarItem-${field.key}`}
                  role="menuitem"
                  className={dropdownLg ? 'col-6' : 'col-12'}
                >
                  {getInputBy(field)}
                </div>
              ))}
            </div>
          </div>
          {/* {fields.map((field) => (
            <div
              key={`searchBarItem-${field.key}`}
              role="menuitem"
              className="py-2 px-4"
            >
              {getInputBy(field)}
            </div>
          ))} */}

          <div role="menuitem" className="py-2 px-4 d-flex justify-content-end">
            <Button
              id="clear-button"
              className="waves-effect waves-light me-2"
              onClick={clearForm}
              color="info"
            >
              <i className="mdi mdi-eraser me-1" />
              {t("clear")}
            </Button>
            <Button
              id="search-button"
              className="waves-effect waves-light"
              color="info"
              type="submit"
            >
              <i className="mdi mdi-magnify me-1" />
              {t("search")}
            </Button>
          </div>
        </DropdownMenu>
      </form>
    </Dropdown>
  );
};

export default SearchBar;

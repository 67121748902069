import React, { useState, useRef, useEffect } from "react";
import { Container, Row, Col, Card, CardBody, Button } from "reactstrap";
import { FormHandles } from "@unform/core";
import { Form } from "@unform/web";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useLocation } from "react-router-dom";

import { ApplicationState } from "../../store";
import { AuthState, AuthTypes } from "../../store/modules/auth/types";
import Input from "../../components/FormFields/Input";
import InputGroupPassword from "../../components/FormFields/InputGroupPassword";
import { clearLoading, resetIsSigned, setCredentials, setResponseData  } from "../../store/modules/auth/actions";
import SEO from "../../components/SEO";

import logoBlue from "../../assets/images/logo-blue.png";
import loginBG from "../../assets/images/login_bg.png";

import PdfTerms from '../../assets/docs/termos_de_uso.pdf';
import api from "services/api";

const Login: React.FC = () => {
  const navigate = useNavigate();
  const formRef = useRef<FormHandles>(null);
  const { t } = useTranslation("auth");
  const dispatch = useDispatch();
  const auth = useSelector<ApplicationState, AuthState>((s) => s.auth);
  const [isRemember, setIsRemember] = useState<boolean>(!!auth.name);

  useEffect(() => {
    const savedUsername = localStorage.getItem('username');
    if (savedUsername) {
      formRef.current.setFieldValue('name', savedUsername);
      setIsRemember(true);
    }
  }, []);


  useEffect(() => {
    dispatch(resetIsSigned());
  }, []);


  async function verifyUserCredentials({name, password}) {
    try {
      dispatch({ type: AuthTypes.SET_LOADING });
      const response = await api.post("/keycloak/mfa-verify-user", { name, password });
      if (response.status === 200) {
        if (isRemember) {
          localStorage.setItem('username', name);
        } else {
          localStorage.removeItem('username');
        }

        if (response.data === "CONFIGURE_TOTP") {
          const mfaResponse = await api.post('/keycloak/mfa/generate', {
            username: name,
            password: password,
          });

          dispatch(setResponseData(mfaResponse.data));
          dispatch(setCredentials(name, password));
          navigate(`/auth/mfa-setup/${name}`);
        } else {
          dispatch({ type: AuthTypes.CLEAR_LOADING });
          dispatch(setCredentials(name, password));
          navigate('/auth/mfa-verify');
        }
      }
    } catch (error) {
      dispatch({ type: AuthTypes.CLEAR_LOADING });
      console.error(error);
    }
  }


  async function handleSubmit(data) {
    try {
      // Remove all previous errors
      formRef.current.setErrors({});

      const schema = Yup.object().shape({
        name: Yup.string().required(t("requiredField")),
        password: Yup.string().required(t("requiredField")),
      });
      await schema.validate(data, {
        abortEarly: false,
      });
      // Validation passed
      await verifyUserCredentials(data);

    } catch (err) {
      const validationErrors = {};

      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }
    }
  }

  return (
    <div className="vh-100 d-flex justify-content-center align-items-center bg-white"
      style={{
        backgroundImage: `url(${loginBG})`,
        backgroundPosition: "center bottom",
        backgroundRepeat: "repeat-x",
      }}
    >
      <SEO title={t("Portal OTC Admin")} shouldExcludeTitleSuffix={true} />
      <Container>
        <Row className="justify-content-center">
          <Col md="8" lg="6" xl="5">
            <Card className="overflow-hidden">
              <CardBody className="pt-0">
                <h3 className="text-center mt-4">
                  <a href="\" className="logo logo-admin">
                    <img src={logoBlue} height="30" alt="logo" />
                  </a>
                </h3>
                <div className="p-3">
                  <h4 className="font-size-18 mb-1 text-center text-info">
                    {t("welcome")}
                  </h4>
                  <Form
                    ref={formRef}
                    className="form-horizontal mt-4"
                    onSubmit={handleSubmit}
                  >
                    <Input
                      label={t("user")}
                      name="name"
                      placeholder={t("enterUsername")}
                      defaultValue={auth.name}
                    />

                    <InputGroupPassword
                      label={t("password")}
                      name="password"
                      placeholder={t("enterPassword")}
                    />

                    <div className="mb-3 row mt-4">
                      <Col xs="6">
                        <div className="form-check">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="customControlInline"
                            checked={isRemember}
                            onChange={(e) => setIsRemember(e.target.checked)}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="customControlInline"
                          >
                            {t("rememberUsername")}
                          </label>
                        </div>
                      </Col>
                      <Col xs="6" className="text-end">
                        <Button
                          id="login"
                          color="info"
                          className="w-md waves-effect waves-light d-inline-flex justify-content-center align-items-center"
                          type="submit"
                          disabled={auth.loading}
                        >
                          {auth.loading && (
                            <span
                              className="spinner-border spinner-border-sm text-white me-1"
                              role="status"
                              aria-hidden="true"
                            />
                          )}
                          {t("logIn")}
                        </Button>
                      </Col>
                    </div>
                    <div className="mt-5">
                      <a className="hyperlink" href={PdfTerms} target="_blank">{t("terms")}</a>
                    </div>
                    <div className="mt-3">
                      <Link id="forgotPassword" to="/auth/forget-password">
                        <div className="text-muted">
                          <i className="mdi mdi-lock me-1" />
                          {t("forgotPasswordOrUsername")}
                        </div>
                      </Link>
                      {/* <div className="text-muted mt-2">
                        <i className="mdi mdi-login me-1" />
                        Ainda não possui a sua conta?
                        <Link href="/auth/register"> Fazer agora.</Link>
                      </div> */}
                    </div>
                  </Form>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Login;

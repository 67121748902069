import DataTable from "components/DataTable";
import SearchBar from "components/SearchBar";
import SEO from "components/SEO";
import { useBreadcrumb } from "hooks/useBreadcrumb";
import { useEditNaturalPerson } from "hooks/useEditNaturalPerson";
import usePermissions from "hooks/usePermissions";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Button, Card, CardBody, Col, Row } from "reactstrap";
import api from "services/api";
import { formatCpf } from "utils/documents";
import { handleErrorResponse } from "utils/error-handlers";
import { dataContext, permission } from "utils/permissionEnums";

export interface NaturalPerson {
    name: string;
    cpf: string;
    id: number;
}

export interface RootObject {
    cpf: string;
    name: string;
    id: number;
}

interface Request {
    page: number;
    limit: number;
    sort: string;
    count: number;
    content: RootObject[];
}

const NaturalPersonList: React.FC = () => {
    const { t } = useTranslation("persons");
    const [perPage, setPerPage] = useState<number>(10);
    const [loading, setLoading] = useState<boolean>(false);
    const { setBreadcrumbItems } = useBreadcrumb();
    const { setNaturalPerson: setNaturalPersonHook } = useEditNaturalPerson();

    const [listingsData, setListingsData] = useState<RootObject[]>([]);
    const [totalRows, setTotalRows] = useState<number>(0);

    const [persistedFilters, setPersistedFilters] = useState({
        id: "",
        name: "",
        cpf: "",
        distributorName: ""
    });

    const hasPermissionToCreate = usePermissions(
        dataContext.LISTED_COMPANY,
        permission.CREATE
    );

    const handleFetchData = ({ id, name, cpf, distributorName }: any) => {
        getNaturalPersons({
          idParam: id,
          nameParam: name,
          cpfParam: cpf,
          distributorNameParam: distributorName,
        });
    };

    const handlePageChange = (page) => {
        getNaturalPersons({ page });
    };

    const handlePerRowsChange = async (newPerPage, page) => {
        getNaturalPersons({ page, limit: newPerPage });
      };

    const getNaturalPersons = useCallback(
        async({
            idParam = persistedFilters.id,
            nameParam = persistedFilters.name,
            cpfParam = persistedFilters.cpf,
            distributorNameParam = persistedFilters.distributorName,
            page = 1,
            limit = perPage,
            signal,
        }: {
            idParam?: string;
            nameParam?: string;
            cpfParam?: string;
            distributorNameParam?: string;
            page?: number;
            limit?: number;
            signal?: AbortSignal;
        }) => {
            try {
                setLoading(true);
                
                const { data: userList } = await api.get<Request>("/investor_people", {
                    params: {
                      id: idParam,
                      name: nameParam,
                      cpf: cpfParam,
                      distributorName: distributorNameParam,
                      page,
                      limit,
                      sort: "id:desc",
                    },
                    signal,
                  });

                setListingsData(userList.content);
                setTotalRows(userList.count);
                setPersistedFilters({
                    id: idParam,
                    name: nameParam,
                    cpf: cpfParam,
                    distributorName: distributorNameParam
                });
                setPerPage(limit);
            } catch(error) {
                const { description } = handleErrorResponse(error);
                console.log("[NaturalPersonList][getNaturalPersons]", description);
            } finally {
                setLoading(false);
            }          
        }, [
            perPage,
            persistedFilters.id,
            persistedFilters.cpf,
            persistedFilters.name,
            persistedFilters.distributorName
        ]
    )

    useEffect(() => {
        getNaturalPersons({});
      }, [getNaturalPersons]);
    
      useEffect(() => {
        setBreadcrumbItems({
          title: "persons",
          items: [
            { title: "home", link: "/dashboard" },
            { title: "persons", link: "/persons" },
          ],
        });
      }, [setBreadcrumbItems]);

    return (
        <>
            <SEO
                title={t("persons", { ns: "breadcrumb" })}
                shouldIndexPage={false}
            />

            <Row>
                <Col xs="12" className="mb-3">
                    <div className="d-flex flex-md-row justify-content-md-between flex-column align-items-center">
                        <div>
                        {hasPermissionToCreate && (
                            <Link to="/naturalPerson/person">
                                <Button
                                    id="addListing"
                                    size="md"
                                    color="primary"
                                    className="waves-effect waves-light me-3"
                                    onClick={() => setNaturalPersonHook(null)}
                                >
                                    <i className="mdi mdi-plus me-2" />
                                    {t("newNaturalInvestor")}
                                </Button>
                            </Link>
                        )}
                        </div>

                            <SearchBar
                                fields={[
                                    {
                                    key: "id",
                                    label: t("id"),
                                    variant: "text",
                                    },
                                    {
                                    key: "cpf",
                                    label: t("cpf"),
                                    variant: "pattern",
                                    mask: "999.999.999-99",
                                    }, 
                                    {
                                    key: "name",
                                    label: t("name"),
                                    variant: "text",
                                    },
                                    {
                                    key: "distributorName",
                                    label: t("distributors"),
                                    variant: "text",
                                    },
                            ]}
                            onSearch={handleFetchData}
                           />
                        </div>
                    </Col>

                    <Col xs="12">
                        <Card>
                            <CardBody>
                                <DataTable
                                    columns={[
                                        {
                                            name: t("id", { ns: "common" }),
                                            cell: ({ id }) => (
                                                <Link to={`/naturalPerson/${id}`}>
                                                <Button
                                                    key={id}
                                                    type="button"
                                                    color="info"
                                                    size="sm"
                                                    outline
                                                    className="waves-effect waves-light"
                                                >
                                                    {id}
                                                    <i className="mdi mdi-square-edit-outline ms-1" />
                                                </Button>
                                                </Link>
                                            ),
                                            button: true,
                                        },
                                        {
                                            name: t("cpfNaturalPerson", { ns: "common" }),
                                            selector: (row) => formatCpf(row.cpf),
                                            center: true,
                                            sortable: false,
                                        },
                                        {
                                            name: t("nameNaturalPerson", { ns: "common" }),
                                            selector: (row) => row.name,
                                            center: true,
                                            sortable: true,
                                        },
                                    ]}
                                    data={listingsData}
                                    progressPending={loading}
                                    paginationServer
                                    paginationTotalRows={totalRows}
                                    onChangePage={handlePageChange}
                                    onChangeRowsPerPage={handlePerRowsChange}
                                />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
        </>
  );
}

export default NaturalPersonList;
import { action } from 'typesafe-actions';
import { NavigateFunction } from 'react-router-dom';
import { AdvisorTypes, Advisor } from './types';

export const updateAdvisorRequest = (
  advisor: Advisor,
  navigate: NavigateFunction,
): TypeSafeAction<AdvisorTypes> =>
  action(AdvisorTypes.UPDATE_ADVISOR_REQUEST, { advisor, navigate });

export const updateAdvisorSuccess = (
  advisor: Advisor,
): TypeSafeAction<AdvisorTypes> =>
  action(AdvisorTypes.UPDATE_ADVISOR_SUCCESS, { advisor });

export const createAdvisorRequest = (
  advisor: Advisor,
  navigate: NavigateFunction,
): TypeSafeAction<AdvisorTypes> =>
  action(AdvisorTypes.CREATE_ADVISOR_REQUEST, { advisor, navigate });

export const createAdvisorSuccess = (): TypeSafeAction<AdvisorTypes> =>
  action(AdvisorTypes.CREATE_ADVISOR_SUCCESS);

export const changeAdvisorPictureRequest = ({
  image,
  id,
}): TypeSafeAction<AdvisorTypes> =>
  action(AdvisorTypes.CHANGE_ADVISOR_PICTURE, { image, id });

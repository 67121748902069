// Action Types
export enum OrderTypes {
  CREATE_ORDER_REQUEST = '@order/CREATE_ORDER_REQUEST',
  CREATE_ORDER_SUCCESS = '@order/CREATE_ORDER_SUCCESS',
  CREATE_ORDER_FAILURE = '@order/CREATE_ORDER_FAILURE',

  CLEAR_CREATED_ORDER = '@order/CLEAR_CREATED_ORDER',
}

export interface Order {
  orderId: string;
  status: string;
  assignorDocument: string;
  assignorCorporateName: string;
  orderDate: string;
  ticker: string;
  qtdTokens: number;
  negotiatedUnitPrice: number;
  value: number;
  transfereeDocument: string;
  transfereeCorporateName: string;
  distributor: string;
  distributorDocument: string;
};

export interface CreatedOrder {
  assignorDocument: string;
  assignorCorporateName: string;
  orderDate: string;
  ticker: string;
  qtdTokens: number;
  negotiatedUnitPrice: number;
  value: number;
  transfereeDocument: string;
  transfereeCorporateName: string;
  distributor: string;
  distributorDocument: string;
};

// State Type
export interface OrderState {
  readonly loading: boolean;
}

import React, { useRef, useEffect, useState, useCallback } from "react";
import {
  OptionTypeBase,
  Props as SelectProps,
  OptionsType,
} from "react-select";
import CreatableSelect from "react-select/creatable";
import { useField } from "@unform/core";
import { useTranslation } from "react-i18next";

interface Props extends Omit<SelectProps<OptionTypeBase>, "isMulti"> {
  label: string;
  name: string;
  isMulti?: boolean;
  options: OptionsType<OptionTypeBase>;
  onChangeValue?(e): void;
  required?: boolean;
}

const SelectAsync: React.FC<Props> = ({
  name,
  label,
  isMulti,
  options,
  onChangeValue,
  required = false,
  ...rest
}) => {
  const { t } = useTranslation("common");

  const { fieldName, defaultValue, registerField, error } = useField(name);

  const selectRef = useRef(null);
  const [currentValue, setValue] = useState(defaultValue);

  const searchInOptions = useCallback(
    (val) => {
      if (!val) return undefined;
      if (Array.isArray(val)) {
        const selectedList = options.filter((option) =>
          val.find((x) => x.id === option.value)
        );
        if (selectedList && onChangeValue) onChangeValue(selectedList);
        return selectedList;
      }
      const selected = options.find(
        (option) => option.value === val || option.value === val.id
      );
      if (selected && onChangeValue) onChangeValue(selected);
      return selected;
    },
    [options, onChangeValue]
  );

  const handleClickLabel = () => {
    if (selectRef.current) {
      selectRef.current.select.focus();
    }
  };

  useEffect(() => {
    if (options.length && defaultValue) {
      setValue(searchInOptions(defaultValue));
    }
  }, [options, defaultValue, searchInOptions]);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: selectRef.current,
      getValue: (ref: any) => {
        return ref.state.value || undefined;
      },
      setValue: (ref, value) => {
        ref.select.setValue(value || null);
      },
    });
  }, [fieldName, registerField, rest.isMulti]);

  return (
    <div className="mb-3">
      <label onClick={handleClickLabel} htmlFor={`${fieldName}-select`}>
        {`${label}${required ? " *" : ""}`}
      </label>
      <CreatableSelect
        id={`${fieldName}-select`}
        inputId={`${fieldName}-input`}
        instanceId={fieldName}
        value={currentValue}
        ref={selectRef}
        isMulti={isMulti}
        classNamePrefix="react-select"
        options={options}
        onChange={(e) => {
          setValue(e);
          if (onChangeValue) onChangeValue(e);
        }}
        formatCreateLabel={(value) => t("createOption", { value })}
        placeholder={`${t("select")}...`}
        styles={{
          control: (provided, state) => ({
            ...provided,
            borderColor: error ? "#BE0041" : "hsl(0, 0%, 80%)",
            backgroundColor: state.isDisabled
              ? "#e9ecef"
              : provided.backgroundColor,
          }),
        }}
        openMenuOnFocus
        {...rest}
      />
      {error && <span className="error">{error}</span>}
    </div>
  );
};
export default SelectAsync;

import React, { useState, useEffect, useMemo, useLayoutEffect } from "react";
import {
  Card,
  CardBody,
  Row,
  Col,
  Button,
  Input as InputUncontrolled,
  Label,
} from "reactstrap";
import NumberFormat from "react-number-format";
import { useDispatch, useSelector } from "react-redux";
import { SubmitHandler, useController, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios from "axios";
import i18next from "i18next";

import SEO from "../../components/SEO";
import Input from "../../components/HookFormFields/Input";
import InputMask from "../../components/HookFormFields/InputMask";
import InputPercentage from "../../components/HookFormFields/InputPercentage";
import Select from "../../components/HookFormFields/Select";
import DatePicker from "../../components/HookFormFields/DatePicker";
import TextArea from "../../components/HookFormFields/TextArea";
import Attachments from "../../components/offer/Attachments";
import MandatoryWarranty from "../../components/offer/MandatoryWarranty";
import Warranties from "../../components/offer/Warranties";
import GuarantorModal from "../../components/offer/GuarantorModal";
import WarrantyModal from "../../components/offer/WarrantyModal";
import SweetAlert from "react-bootstrap-sweetalert";
import { useBreadcrumb } from "../../hooks/useBreadcrumb";
import { useEditOffer } from "../../hooks/useEditOffer";
import { addMessage } from "../../store/modules/toast/actions";
import {
  Warranty,
  Guarantor,
  Offer,
  FormData,
  Attachment,
} from "../../store/modules/offer/types";
import {
  updateOfferRequest,
  createOfferRequest,
  patchPublishedOfferRequest,
} from "../../store/modules/offer/actions";
import api from "../../services/api";
import { ApplicationState } from "store";
import { AccessType, Profile } from "store/modules/user/types";
import {
  ACCESS_TYPE_ADMIN_A0,
  ACCESS_TYPE_ORIGINATOR_C0,
  ACCESS_TYPE_ORIGINATOR_C4,
  tickerRegex,
} from "utils/constants";
import MaskedInput from "components/HookFormFields/MaskedInput";

interface LabelValue {
  label: string;
  value: string;
}

interface Broker extends LabelValue {
  data: {
    brokerCNPJ: string;
    brokerName: string;
  };
}

interface ListedCompany {
  value: number;
  label: string;
  data: {
    id: number;
    companyId: number;
    cnpj: string;
    name: string;
    tradingName: string;
    type: string;
  };
}

const regexTicker = /[A-Z]{4}\d\-\d{3}/;

const CreateEditOffer: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation(["offers", "breadcrumb"]);
  const { id } = useParams();

  const accessType = useSelector<ApplicationState, AccessType>(
    (state) => state.user.accessType
  );
  const profile = useSelector<ApplicationState, Profile>(
    (state) => state.user.profile
  );

  const { setBreadcrumbItems } = useBreadcrumb();
  const { hasOffer, offer, setOffer } = useEditOffer();

  const isAccessTypeOriginator = useMemo(
    () =>
      [ACCESS_TYPE_ORIGINATOR_C0, ACCESS_TYPE_ORIGINATOR_C4].includes(
        accessType.name
      ),
    [accessType.name]
  );

  const isAccessTypeAdmin = useMemo(
    () => [ACCESS_TYPE_ADMIN_A0].includes(accessType.name),
    [accessType.name]
  );

  const isSubmittedOffer = offer?.state === "SUBMITTED";
  const stateToDisableEdit = offer?.state === "PUBLISHED" || offer?.state === "ISSUED" || offer?.state === "REVISION" || offer?.state === "APPROVED";
  const isIssuedOrRevisionOrApprovedOffer = offer?.state === "ISSUED" || offer?.state === "REVISION" || offer?.state === "APPROVED";

  /**
   * Validates if user's access type is necessary to fulfill some specific field.
   *
   * @param field - Input that should be validated.
   * @param fieldType - Type of the input that should be validated. Possible values are `"text"` or `"number"`.
   * @returns a boolean. `true` means that validated field is OK.
   */
  const isValidationOK = ({
    field,
    fieldType = "text",
  }: {
    field: string;
    fieldType?: "text" | "number";
  }) => {
    if (fieldType === "text") {
      if (isAccessTypeAdmin) {
        if (!offer?.state || offer?.state === "DRAFT") {
          return true;
        }

        if (field.length > 0) {
          return true;
        }

        return false;
      }

      if (isAccessTypeOriginator) {
        if (
          !offer?.state ||
          ["DRAFT", "SUBMITTED", "PUBLISHED", "LIQUIDATE", "LIQUIDATE_IN_PROGRESS"].includes(offer?.state)
        ) {
          return true;
        }

        if (field.length > 0) {
          return true;
        }

        return false;
      }

      return false;
    }

    if (isAccessTypeAdmin) {
      if (!offer?.state || offer?.state === "DRAFT,") {
        return true;
      }

      if (Number(field) > 0) {
        return true;
      }

      return false;
    }

    if (isAccessTypeOriginator) {
      if (
        !offer?.state ||
        ["DRAFT", "SUBMITTED", "PUBLISHED", "LIQUIDATE", "LIQUIDATE_IN_PROGRESS"].includes(offer?.state)
      ) {
        return true;
      }

      if (Number(field) > 0) {
        return true;
      }

      return false;
    }

    return false;
  };

  const maxPercentageValidation = Yup.string()
    .required(t("requiredField"))
    .test({
      test(value, ctx) {
        const numberValue = Number(value);

        if (numberValue <= 0) {
          return ctx.createError({
            message: t("valueMustBeGreaterThan0"),
          });
        }

        if (numberValue > 999.99) {
          return ctx.createError({
            message: t("valueMustBeLessThan", { target: "999,99%" }),
          });
        }

        return true;
      },
    });

  const maxNumberValidation = Yup.string()
    .required(t("requiredField"))
    .test({
      test(value, ctx) {
        const numberValue = Number(value);

        if (numberValue <= 0) {
          return ctx.createError({
            message: t("valueMustBeGreaterThan0"),
          });
        }

        if (numberValue > 9999999999999.99) {
          return ctx.createError({
            message: t("valueMustBeLessThan", {
              target: "9.999.999.999.999,99",
            }),
          });
        }

        return true;
      },
    });

  const getDefaultValues = (data) => {
    const offer = data;

    return {
      ...offer,
      ticker: offer?.ticker.trim().length > 0 ? offer?.ticker : "",
      instrumentType: offer?.instrumentType?.id,
      leadingDistributor: offer?.leadingDistributor?.id,
      offerDistributors: offer?.offerDistributors.map((i) => i.id) ?? [],
      codeISIN: offer?.codeISIN || "",
      indexer: offer?.indexer?.id,
      currency: offer?.currency?.id,
      frequencyPayments:
        offer?.frequencyPayments?.id || offer?.frequencyPayment?.id,
      cashierRelease: offer?.cashierRelease?.id,
      amortizationSystem: offer?.amortizationSystem?.id,
      investorRemunerationFrequency: offer?.investorRemunerationFrequency?.id,
      remunerationRatePercent: offer?.remunerationRatePercent || "",
      totalEffectiveCost: offer?.totalEffectiveCost || "",
      investorMaximumLotsPercentage: offer?.investorMaximumLotsPercentage,
      remunerationRate: offer?.remunerationRate?.id,
      capitalizations: offer?.capitalizations?.id || offer?.capitalization?.id,
      paymentForm: offer?.paymentForms?.id || offer?.paymentForm?.id,
      paymentOtherForm: offer?.paymentOtherForm || "",
      interestCalculationCriterias:
        offer?.interestCalculationCriterias?.id ||
        offer?.interestCalculationCriteria?.id,
      lateFeeOptions: offer?.lateFeeOptions?.id || offer?.lateFeeOption?.id,
      indexerApplications:
        offer?.indexerApplications?.id || offer?.indexerApplication?.id,
      interestRateApplications:
        offer?.interestRateApplications?.id ||
        offer?.interestRateApplication?.id,
      // mandatoryWarranty: offer?.warranties?.find(
      //   (e) => e.warrantyType === "FIDEJUSSORIA_AVAL"
      // ),
      warranties: offer?.warranties,
      isExclusive: offer?.isExclusive ? "YES" : "NO"
    };
  };

  const {
    handleSubmit,
    control,
    watch,
    setValue,
    clearErrors,
    trigger,
    reset,
    formState: { errors },
  } = useForm<any>({
    defaultValues: getDefaultValues(offer),
    resolver: yupResolver(
      Yup.object().shape({
        listedCompany: Yup.string().nullable().required(t("requiredField")),
        instrumentType: Yup.string().nullable().required(t("requiredField")),
        totalIssuanceAmount: maxNumberValidation,
        lotsQuantity: Yup.string().required(t("requiredField")),
        investorMaximumLotsPercentage: maxPercentageValidation,
        remunerationRate: Yup.string().nullable().required(t("requiredField")),
        remunerationRatePercent: maxPercentageValidation,
        reservationsMinimumPercentage: maxPercentageValidation,
        frequencyPayments: Yup.string().required(t("requiredField")),
        paymentForm: Yup.string().required(t("requiredField")),
        paymentOtherForm: Yup.string().when(["paymentForm"], {
          is: (paymentForm: string) => {
            if (paymentForm) {
              return paymentFormsList
                .find(({ value }) => value === paymentForm)
                .label.includes("Outra Forma");
            }
          },
          then: Yup.string().required(t("requiredField")),
          otherwise: Yup.string().optional(),
        }),
        interestCalculationCriterias: Yup.string()
          .nullable()
          .required(t("requiredField")),
        latePaymentInterest: maxPercentageValidation,
        lateFeeOptions: Yup.string().nullable().required(t("requiredField")),
        lateFeeValue: Yup.string().when(["lateFeeOptions"], {
          is: (lateFeeOptions: string) => {
            if (lateFeeOptions) {
              return (
                lateFeeOptionsList.find(({ value }) => value === lateFeeOptions)
                  .label === "Percentual"
              );
            }
          },
          then: maxPercentageValidation,
          otherwise: maxNumberValidation,
        }),
        indexer: Yup.string().when(["remunerationRate"], {
          is: (remunerationRate: string) => {
            if (remunerationRate) {
              return (
                remunerationRatesList.find(
                  ({ value }) => value === remunerationRate
                ).label === "Pós-fixada"
              );
            }
          },
          then: Yup.string().required(t("requiredField")),
          otherwise: Yup.string().optional(),
        }),
        indexerApplications: Yup.string()
          .nullable()
          .required(t("requiredField")),
        interestRateApplications: Yup.string()
          .nullable()
          .required(t("requiredField")),
        currency: Yup.string().nullable().required(t("requiredField")),
        capitalizations: Yup.string().required(t("requiredField")),
        cashierRelease: Yup.string().nullable().required(t("requiredField")),
        // mandatoryWarranty: Yup.object().shape({
        //   warrantyInstrument: Yup.string().required(t("requiredField")),
        // }),
        startReservationDate: Yup.date()
          .nullable()
          .required(t("requiredField")),
        transactionExpirationDate: Yup.date()
          .nullable()
          .required(t("requiredField"))
          .test({
            test(value, ctx){
              if(emissionDate > value){
                return ctx.createError({
                  message: t("expirationDateEmissionDateError"),
                });
              }
              return true;
          }}),
        numberOfInstallments: maxNumberValidation,
        reservationDeadline: Yup.date()
          .nullable()
          .test({
            test(value, ctx) {
              if (isSubmittedOffer && isAccessTypeAdmin && !value) {
                return ctx.createError({
                  message: t("requiredField"),
                });
              }

              return true;
            },
          }),
        emissionDate: Yup.date()
          .nullable()
          .test({
            test(value, ctx) {
              if (isSubmittedOffer && isAccessTypeAdmin && !value) {
                return ctx.createError({
                  message: t("requiredField"),
                });
              }

              return true;
            },
          }),
        leadingDistributor: Yup.string().test({
          test: (pLeadingDistributor) =>
            isValidationOK({ field: pLeadingDistributor }),
          message: t("requiredField"),
        }),
        investorPaybackTime: Yup.string().test({
          test: (pInvestorPaybackTime) =>
            isValidationOK({
              field: pInvestorPaybackTime,
              fieldType: "number",
            }),
          message: t("requiredField"),
        }),
        totalEffectiveCost: Yup.string().test({
          test: (pTotalEffectiveCost) =>
            isValidationOK({
              field: pTotalEffectiveCost,
              fieldType: "number",
            }),
          message: t("requiredField"),
        }),
        ticker: Yup.string().test({
          test(value, ctx) {
            if (isAccessTypeAdmin) {
              if (!value && offer?.state === "DRAFT") {
                return true
              }
              if (!value && offer?.state !== "DRAFT") {
                return ctx.createError({
                  message: t("requiredField"),
                });
              }
              if (value) {
                if (!regexTicker.test(value)) {
                  return ctx.createError({
                    message: t("tickerFormatInvalidExampleTickerValid"),
                  });
                }
              }
              return true;
            }
            if (isAccessTypeOriginator) {
              return true;
            }
            return false;
          },
        }),
        contractNumber: Yup.string().test({
          test: (pContractNumber) => isValidationOK({ field: pContractNumber }),
          message: t("requiredField"),
        }),
        isExclusive: Yup.string()
          .nullable()
          .required(t("requiredField")),
      })
    ),
  });
  const [
    listedCompany,
    totalVolumeOffered,
    lotsQuantity,
    leadingDistributor,
    paymentForm,
    investorMaximumLotsPercentage,
    remunerationRate,
    reservationsMinimumPercentage,
    totalIssuanceAmount,
    lateFeeOptions,
    currency,
    isExclusive,
    emissionDate,
    frequencyPayments,
    numberOfInstallments
  ] = watch([
    "listedCompany",
    "totalIssuanceAmount",
    "lotsQuantity",
    "leadingDistributor",
    "paymentForm",
    "investorMaximumLotsPercentage",
    "remunerationRate",
    "reservationsMinimumPercentage",
    "totalIssuanceAmount",
    "lateFeeOptions",
    "currency",
    "isExclusive",
    "emissionDate",
    "frequencyPayments",
    "numberOfInstallments"
  ]);

  const {
    field: { onChange: onChangeTicker },
  } = useController({
    name: "ticker",
    control,
  });
  const [transactionExpirationDate, setTransactionExpirationDate] = useState<Date | null>(null);
  const [isAddGuarantor, setIsAddGuarantor] = useState(false);
  const [isAddWarranty, setIsAddWarranty] = useState(false);
  const [hasNoWarranty, setHasNoWarranty] = useState(false);
  const [warrGuarantors, setWarrGuarantors] = useState<Guarantor[]>(
    hasOffer
    ? offer.warranties?.find((e) => e.warrantyType)
      ?.guarantors
    : []
  );
  const [listedCompanyList, setListedCompList] = useState<ListedCompany[]>([]);
  const [instTypeList, setInstTypeList] = useState<LabelValue[]>([]);
  const [currencyList, setCurrencyList] = useState<LabelValue[]>([]);
  const [cashierReleaseList, setCashierReleaseList] = useState<LabelValue[]>(
    []
  );
  const [remunerationRatesList, setRemunerationRatesList] = useState<
    LabelValue[]
  >([]);
  const [capitalizationsList, setCapitalizationsList] = useState<LabelValue[]>(
    []
  );
  const [frequencyPaymentsList, setFrequencyPaymentsList] = useState<
    LabelValue[]
  >([]);
  const [paymentFormsList, setPaymentFormsList] = useState<LabelValue[]>([]);
  const [interestCalcCriteriasList, setInterestCalcCriteriasList] = useState<
    LabelValue[]
  >([]);
  const [lateFeeOptionsList, setLateFeeOptionsList] = useState<LabelValue[]>(
    []
  );
  const [interestRateApplicationsList, setInterestRateApplicationsList] =
    useState<LabelValue[]>([]);
  const [indexerApplicationsList, setIndexerApplicationsList] = useState<
    LabelValue[]
  >([]);
  const [investRemunFreq, setInvestRemunFreq] = useState<LabelValue[]>([]);
  const [indexersList, setIndexersList] = useState<LabelValue[]>([]);
  const [leadingDistributors, setLeadingDistributors] = useState<Broker[]>([]);
  const [distributors, setDistributors] = useState<Broker[]>([]);
  const [selectedListing, setSelectedListing] = useState<ListedCompany>();
  const [attachments, setAttachments] = useState<Attachment[]>(
    hasOffer ? offer.attachments : []
  );
  const [warrantyList, setWarrantyList] = useState<Warranty[]>(
    hasOffer
    ? offer.warranties.filter((e) => e.warrantyType)
    : []
  );

  const transformIdValue = (obj: LabelValue) => {
    if (!obj) return undefined;

    return {
      id: obj.value,
      name: obj.label,
    };
  };

  const onSubmit: SubmitHandler<FormData> = async ({
    // mandatoryWarranty,
    publishDate,
    ...rest
  }) => {
    // const mandatoryWarrantyFinal: Warranty = {
    //   ...mandatoryWarranty,
    //   warrantyType: "FIDEJUSSORIA_AVAL",
    //   guarantors: warrGuarantors,
    // };
    if (!warrantyList || warrantyList.length === 0) {
      setHasNoWarranty(true);
      return;
    }

    let newObj = {
      listedCompany: rest.listedCompany,
      ticker: rest.ticker,
      codeISIN: rest.codeISIN,
      state: offer?.state ? offer.state : "DRAFT",
      totalIssuanceAmount: rest.totalIssuanceAmount,
      startReservationDate: rest.startReservationDate,
      transactionExpirationDate: rest.transactionExpirationDate,
      numberOfInstallments: rest.numberOfInstallments,
      lotsQuantity: rest.lotsQuantity,
      unitValue: rest.unitValue,
      minimumReservationValue: rest?.minimumReservationValue || 0,
      emissionDate: rest.emissionDate,
      reservationDeadline: rest.reservationDeadline,
      resourcesDestination: rest.resourcesDestination,
      investorMaximumLotsPercentage: rest.investorMaximumLotsPercentage,
      investorPaybackTime: rest.investorPaybackTime,
      investorMaximumLotsQuantity: rest.investorMaximumLotsQuantity,
      reservationsMinimumPercentage: rest?.reservationsMinimumPercentage || 0,
      remunerationRatePercent: rest.remunerationRatePercent,
      totalEffectiveCost: rest.totalEffectiveCost,
      contractNumber: rest.contractNumber,
      latePaymentInterest: rest.latePaymentInterest,
      lateFeeValue: rest.lateFeeValue,
      currency: transformIdValue(
        currencyList.find((e) => e.value === rest.currency)
      ),
      cashierRelease: transformIdValue(
        cashierReleaseList.find((e) => e.value === rest.cashierRelease)
      ),
      remunerationRate: transformIdValue(
        remunerationRatesList.find((e) => e.value === rest.remunerationRate)
      ),
      capitalization: transformIdValue(
        capitalizationsList.find((e) => e.value === rest.capitalizations)
      ),

      paymentForm: transformIdValue(
        paymentFormsList.find((e) => e.value === rest.paymentForm)
      ),
      paymentOtherForm: rest.paymentOtherForm.length
        ? rest.paymentOtherForm
        : null,

      interestCalculationCriteria: transformIdValue(
        interestCalcCriteriasList.find(
          (e) => e.value === rest.interestCalculationCriterias
        )
      ),
      lateFeeOption: transformIdValue(
        lateFeeOptionsList.find((e) => e.value === rest.lateFeeOptions)
      ),
      indexer: transformIdValue(
        indexersList.find((e) => e.value === rest.indexer)
      ),
      indexerApplication: transformIdValue(
        indexerApplicationsList.find(
          (e) => e.value === rest.indexerApplications
        )
      ),

      interestRateApplication: transformIdValue(
        interestRateApplicationsList.find(
          (e) => e.value === rest.interestRateApplications
        )
      ),
      instrumentType: transformIdValue(
        instTypeList.find((e) => e.value === rest.instrumentType)
      ),
      frequencyPayment: transformIdValue(
        frequencyPaymentsList.find((e) => e.value === rest.frequencyPayments)
      ),

      leadingDistributor: {
        id: rest.leadingDistributor,
      },
      offerDistributors: rest.offerDistributors.map((e) => ({ id: e })),
      attachments: attachments,
      warranties: [
        // mandatoryWarrantyFinal,
        ...warrantyList.map(
          ({
            id,
            guarantors,
            warrantyInstrument,
            warrantyType,
            warrantyInstrumentName,
            observation,
            status
          }) => ({
            id,
            guarantors,
            warrantyInstrument,
            warrantyType,
            warrantyInstrumentName,
            observation,
            status
          })
        ),
      ],
      isExclusive: rest.isExclusive === "YES"
    } as any;

    if (hasOffer) {
      // When Published, user can only update `reservationDeadline` field
      // if (offer?.state === "PUBLISHED") {
      //   dispatch(
      //     patchPublishedOfferRequest({ ...newObj, id: offer.id }, navigate)
      //   );

      //   return;
      // }

      dispatch(updateOfferRequest({ ...newObj, id: offer.id }, navigate));
    } else {
      /**
       * There is a rule on Backend that specifies these
       * three fields below to be null when the user access type
       * is Originator (`C0` or `C4`).
       */
      if (isAccessTypeOriginator) {
        newObj = {
          ...newObj,
          totalEffectiveCost: null,
          leadingDistributor: null,
          investorPaybackTime: null,
        };
      }

      dispatch(createOfferRequest(newObj, navigate));
    }
  };

  const onError = (_errors, _e) => {
    dispatch(
      addMessage({
        title: t("attention"),
        description: t("checkFields"),
        type: "info",
      })
    );
  };

  const handleCancel = () => {
    setOffer(null);

    navigate(-1);
  };

  const handleKeyOnlyNumber = (event) => {
    if (!/^\d$/.test(event.key)) {
      event.preventDefault();
    }
  };

  const handleNumberOfInstallmentsChange = (event) => {
    suggestDueDate(event);
  }

  const handleFrequencyOfPaymentChange = (event) => {
    let frequencyOption = transformIdValue(
      frequencyPaymentsList.find((e) => e.value === frequencyPayments)
    )
    if(frequencyOption.name == "No Vencimento do Contrato"){
      setValue("numberOfInstallments", "1");
      setTransactionExpirationDate(null);
      setValue("transactionExpirationDate", null);
    }
    suggestDueDate();
  }

  const handleEmissionDateChange = () =>{
    suggestDueDate();
  }

  function suggestDueDate(installmentsChange = null){
    const frequencyOption = transformIdValue(
      frequencyPaymentsList.find((e) => e.value === frequencyPayments)
    )

    if(frequencyPayments == null || emissionDate == null || numberOfInstallments == null || frequencyPayments.name == "No Vencimento do Contrato")
      return;

    let period = definePeriod(frequencyOption?.name);
    let number = Number(numberOfInstallments);
    
    if(installmentsChange != null)
      number = installmentsChange.target.value
    
    if(number == 0 || period == 0){
      setTransactionExpirationDate(null);
      setValue("transactionExpirationDate", null);
      return;
    }

    let newDate = new Date(emissionDate);
    let dueDate = new Date(newDate.setMonth(newDate.getMonth() + (period * number)));
    setValue("transactionExpirationDate",dueDate);
    setTransactionExpirationDate(dueDate);
  }

  const handleExpirationDateChange = (event) => {
    setTransactionExpirationDate(event);
  }

  function definePeriod(frequency){

    if(frequency == "Mensal"){
      return 1;
    }
    if(frequency == "Bimestral"){
      return 2;
    }
    if(frequency == "Trimestral"){
      return 3;
    }
    if(frequency == "Semestral"){
      return 6;
    }
    if(frequency == "Anual"){
      return 12;
    }
    return 0;
  }
  function isPaymentOnContractExpiration(){
    const frequencyOption = transformIdValue(
      frequencyPaymentsList.find((e) => e.value === frequencyPayments)
    )
    return frequencyOption?.name == "No Vencimento do Contrato";
  }

  const isPaymentOnContractDueDate = isPaymentOnContractExpiration() || stateToDisableEdit;

  const isPaymentOtherForm =
    paymentForm &&
    paymentFormsList.length > 0 &&
    paymentFormsList
      .find(({ value }) => value === paymentForm)
      .label.includes("Outra Forma");

  const isPostFixedRemunerationRate =
    remunerationRate &&
    remunerationRatesList.length > 0 &&
    remunerationRatesList.find((e) => e.value === remunerationRate).label ===
    "Pós-fixada";

  const isLateFeeOptionsPercent =
    lateFeeOptions &&
    lateFeeOptionsList.length > 0 &&
    lateFeeOptionsList.find((e) => e.value === lateFeeOptions).label ===
    "Percentual";

  const isCurrencyReal =
    currencyList &&
    currencyList.length > 0 &&
    currencyList.find(({ value }) => value === currency)?.label === "Real";

  useEffect(() => {
    setBreadcrumbItems({
      title: hasOffer ? "editOffer" : "registerNewOffer",
      items: [
        { title: "home", link: "/dashboard" },
        { title: "offers", link: "/offers" },
        { title: hasOffer ? "editOffer" : "registerNewOffer", link: "" },
      ],
    });
  }, [setBreadcrumbItems, hasOffer]);

  useEffect(() => {
    setSelectedListing(
      listedCompanyList.find((e) => e.value === listedCompany)
    );
  }, [listedCompanyList, listedCompany]);

  useEffect(() => {
    const getPageInfo = async () => {
      try {
        const [
          currencies,
          instruments,
          cashierRelease,
          remunerationRates,
          capitalizations,
          indexers,
          frequencyPayments,
          paymentForms,
          interestCalcCriterias,
          lateFeeOptions,
          indexerApplications,
          interestRateApplications,
          dists,
          companies,
          investorRemunFreq,
          leadingDists,
        ] = await axios.all([
          api.get("/currencies/dropdown"),
          api.get("/instrumenttype/dropdown"),
          api.get("/cashierreleases/dropdown"),
          api.get("/remunerationrates/dropdown"),
          api.get("/capitalizations/dropdown"),
          api.get("/indexers/dropdown"),
          api.get("/frequencypayments/dropdown"),
          api.get("/paymentforms/dropdown"),
          api.get("/interestcalculationcriterias/dropdown"),
          api.get("/latefeeoptions/dropdown"),
          api.get("/indexerapplications/dropdown"),
          api.get("/interestrateapplications/dropdown"),
          api.get("/offers/leadingdistributor"),
          api.get("/listedcompanies?state=PUBLISHED"),
          api.get("/investorremunerationfrequency/dropdown"),
          api.get("/offers/leadingdistributor"),
        ]);
        setCurrencyList(currencies.data);
        setInstTypeList(instruments.data);
        setCashierReleaseList(cashierRelease.data);
        setRemunerationRatesList(remunerationRates.data);
        setCapitalizationsList(capitalizations.data);
        setIndexersList(indexers.data);
        setFrequencyPaymentsList(frequencyPayments.data);
        setPaymentFormsList(paymentForms.data);
        setInterestCalcCriteriasList(interestCalcCriterias.data);
        setLateFeeOptionsList(lateFeeOptions.data);
        setIndexerApplicationsList(indexerApplications.data);
        setInterestRateApplicationsList(interestRateApplications.data);
        setDistributors(dists.data);
        setListedCompList(
          companies.data.map((item) => ({
            value: item.id,
            label: item.tradingName,
            data: {
              id: item.id,
              companyId: item.company.id,
              cnpj: item.company.cnpj,
              name: item.company.name,
              tradingName: item.tradingName,
              type: item.company.companyType,
            },
          }))
        );
        setInvestRemunFreq(investorRemunFreq.data);
        setLeadingDistributors(leadingDists.data);
      } catch (error) {
        dispatch(
          addMessage({
            title: t("error"),
            description: t("unableToLoadData"),
            type: "error",
          })
        );
      }
    };

    getPageInfo();
  }, [dispatch]);

  useEffect(() => {
    if (!isPostFixedRemunerationRate) {
      setValue("indexer", "");
      clearErrors("indexer");
    }

    if (isPostFixedRemunerationRate) {
      const exponentialCap = capitalizationsList.find(
        ({ label }) => label === "Exponencial"
      );

      setValue("capitalizations", exponentialCap?.value || "");
      clearErrors("capitalizations");

      if (hasOffer) {
        setValue("indexer", String(offer?.indexer?.id));
        setValue("capitalizations", String(offer?.capitalization?.id));
      }
    }
  }, [remunerationRate, isPostFixedRemunerationRate]);

  useEffect(() => {
    if (!isPaymentOtherForm && !hasOffer) {
      setValue("paymentOtherForm", "");
      clearErrors("paymentOtherForm");
    }

    if (offer?.paymentOtherForm) {
      setValue("paymentOtherForm", offer?.paymentOtherForm);
    }
  }, [paymentForm, offer?.paymentOtherForm]);

  useEffect(() => {
    if (errors && Object.keys(errors).length > 0) {
      trigger();
    }
  }, [i18next.language]);

  useLayoutEffect(() => {
    const controller = new AbortController();

    if (id) {
      (async () => {
        const { data } = await api.get<Offer>(`/offers/${id}`, {
          signal: controller.signal,
        });

        reset(getDefaultValues(data));
        setOffer(data as any);
        setWarrantyList(data.warranties)
      })();
    }

    return () => {
      controller.abort();
    };
  }, [id, reset]);

  return (
    <>
      <SEO
        title={
          hasOffer
            ? t("editOffer", { ns: "breadcrumb" })
            : t("registerNewOffer", { ns: "breadcrumb" })
        }
        shouldIndexPage={false}
      />

      {isAddGuarantor && (
        <GuarantorModal
          isAddGuarantor={isAddGuarantor}
          setIsAddGuarantor={setIsAddGuarantor}
          onChange={(obj) =>
            setWarrGuarantors((oldValue) => [...oldValue, obj])
          }
        />
      )}

      {isAddWarranty && (
        <WarrantyModal
          isAddWarranty={isAddWarranty}
          setIsAddWarranty={setIsAddWarranty}
          onChange={(e) => setWarrantyList((oldValue) => [...oldValue, e])}
        />
      )}

      {hasNoWarranty && (
        <SweetAlert
          warning
          confirmBtnText="OK"
          confirmBtnBsStyle="primary"
          title={t("warrantyRequired")}
          onConfirm={() => setHasNoWarranty(false)}
        >
          {t("mustHaveAtLeastOneGuarantee")}
        </SweetAlert>
      )}

      <form noValidate>
        <Card>
          <CardBody>
            <h4 className="card-title mb-4 text-uppercase">
              {t("listingDetails")}
            </h4>

            <Row>
              <Select
                label={t("listingName")}
                name="listedCompany"
                control={control}
                options={listedCompanyList}
                placeholder={
                  stateToDisableEdit
                    ? listedCompanyList.find(
                      ({ value }) => value === offer.listedCompany
                    )?.label ?? ""
                    : null
                }
                isDisabled={stateToDisableEdit}
                required={!stateToDisableEdit}
              />
              <Col xs="12" md="4" className="mb-3">
                <Label className="text-capitalize">{t("tradingName")}</Label>
                <InputUncontrolled
                  className="form-control"
                  value={selectedListing?.data.tradingName || ""}
                  disabled
                />
              </Col>
              <Col xs="12" md="4" className="mb-3">
                <Label className="text-capitalize">{t("companyName")}</Label>
                <InputUncontrolled
                  className="form-control"
                  value={selectedListing?.data.name || ""}
                  disabled
                />
              </Col>
              <Col xs="12" md="4" className="mb-3">
                <Label className="text-capitalize">CNPJ</Label>
                <NumberFormat
                  className="form-control"
                  value={selectedListing?.data.cnpj || ""}
                  format="##.###.###/####-##"
                  disabled
                />
              </Col>
            </Row>
          </CardBody>
        </Card>

        <Card>
          <CardBody>
            <h4 className="card-title mb-4 text-uppercase">
              {t("exclusiveUseOTCMarket")}
            </h4>

            <Row>
              <Col xs="12" md="4">
                <MaskedInput
                  label="Ticker"
                  name="ticker"
                  control={control}
                  mask={tickerRegex}
                  onChange={(e) => {
                    onChangeTicker(e.target.value.toUpperCase());
                  }}
                  disabled={isAccessTypeOriginator || stateToDisableEdit}
                  required={isSubmittedOffer && isAccessTypeAdmin}
                />
              </Col>
              <Col xs="12" md="4">
                <Input
                  label={t("contractNumber")}
                  name="contractNumber"
                  control={control}
                  type="text"
                  maxLength={100}
                  disabled={isAccessTypeOriginator || stateToDisableEdit}
                  required={isSubmittedOffer && isAccessTypeAdmin}
                />
              </Col>
              <Col xs="12" md="4">
                <Input
                  label={t("investorPaybackTime")}
                  name="investorPaybackTime"
                  control={control}
                  type="number"
                  min="0"
                  onKeyDown={handleKeyOnlyNumber}
                  maxLength={9}
                  disabled={isAccessTypeOriginator || stateToDisableEdit}
                  required={isSubmittedOffer && isAccessTypeAdmin}
                />
              </Col>

              <Col xs="12" md="4">
                <Select
                  label={t("leadingDistributor")}
                  name="leadingDistributor"
                  control={control}
                  options={leadingDistributors}
                  placeholder={
                    stateToDisableEdit
                      ? offer?.leadingDistributor?.name ?? ""
                      : null
                  }
                  isDisabled={isAccessTypeOriginator || stateToDisableEdit}
                  required={isSubmittedOffer && isAccessTypeAdmin}
                />
              </Col>
              <Col xs="12" md="4">
                <Select
                  label={t("otherDistributors")}
                  name="offerDistributors"
                  control={control}
                  options={distributors}
                  isOptionDisabled={({ value }) => value === leadingDistributor}
                  placeholder={
                    stateToDisableEdit
                      ? offer?.offerDistributors
                        ?.map(({ name }) => name)
                        .join(", ") ?? ""
                      : t("selectOneOrMore")
                  }
                  isDisabled={!isAccessTypeAdmin || stateToDisableEdit}
                  isMulti
                />
              </Col>
              <Col xs="12" md="4">
                <Input
                  label={t("codeISIN")}
                  name="codeISIN"
                  control={control}
                  type="text"
                  maxLength={12}
                  disabled={!isAccessTypeAdmin || isIssuedOrRevisionOrApprovedOffer}
                />
              </Col>
              <Col xs="12" md="4">
                <Select
                    label={t("isExclusive")}
                    name="isExclusive"
                    control={control}
                    options={[{label: t("yes"), value: 'YES'}, {label: t("no"), value: 'NO'}]}
                    isOptionDisabled={({ value }) => value === isExclusive}
                    placeholder={
                      stateToDisableEdit
                        ? offer?.isExclusive.toString() ?? ""
                        : null
                    }
                    isDisabled={!isAccessTypeAdmin || stateToDisableEdit}
                    required
                  />
              </Col>
            </Row>
          </CardBody>
        </Card>

        <Card>
          <CardBody>
            <h4 className="card-title mb-4 text-uppercase">
              {t("operationFeatures")}
            </h4>

            <Row>
              <Col xs="12" md="4">
                <Select
                  label={t("instrumentType")}
                  name="instrumentType"
                  control={control}
                  options={instTypeList}
                  // isOptionDisabled={(option) => !option.label.includes("CCB")}
                  placeholder={
                    stateToDisableEdit ? offer?.instrumentType?.name ?? "" : null
                  }
                  isDisabled={stateToDisableEdit || offer?.instrumentType != null}
                  required
                />
              </Col>
              <Col xs="12" md="4">
                <Select
                  label={t("currencies")}
                  name="currency"
                  control={control}
                  options={currencyList}
                  placeholder={
                    stateToDisableEdit ? offer?.currency?.name ?? "" : null
                  }
                  isDisabled={stateToDisableEdit}
                  required
                />
              </Col>
              <Col xs="12" md="4">
                <InputMask
                  label={t("totalIssuanceAmount")}
                  name="totalIssuanceAmount"
                  control={control}
                  decimalScale={2}
                  isAllowed={({ value }) => Number(value) <= 9999999999999.99}
                  disabled={stateToDisableEdit}
                  prefix={isCurrencyReal ? "R$ " : "$ "}
                  isNumericString
                  fixedDecimalScale
                  required
                />
              </Col>

              <Col xs="12" md="4">
                <Select
                  label={t("cashierRelease")}
                  name="cashierRelease"
                  control={control}
                  options={cashierReleaseList}
                  placeholder={
                    stateToDisableEdit ? offer?.cashierRelease?.name ?? "" : null
                  }
                  isDisabled={stateToDisableEdit}
                  required
                />
              </Col>
              <Col xs="12" md="4">
                <Select
                  label={t("remunerationRates")}
                  name="remunerationRate"
                  control={control}
                  options={remunerationRatesList}
                  placeholder={
                    stateToDisableEdit
                      ? offer?.remunerationRate?.name ?? ""
                      : null
                  }
                  isDisabled={stateToDisableEdit}
                  required
                />
              </Col>
              <Col xs="12" md="4">
                <InputPercentage
                  label={t("remunerationRatePercent")}
                  name="remunerationRatePercent"
                  decimalScale={6}
                  isAllowed={({ value }) => Number(value) <= 999.99}
                  control={control}
                  disabled={stateToDisableEdit}
                  required
                  isNumericString
                />
              </Col>

              <Col xs="12" md="4">
                <Select
                  label={t("capitalizations")}
                  name="capitalizations"
                  control={control}
                  options={capitalizationsList}
                  isOptionDisabled={({ label }) =>
                    isPostFixedRemunerationRate && label === "Linear"
                  }
                  placeholder={
                    stateToDisableEdit ? offer?.capitalization?.name ?? "" : null
                  }
                  isDisabled={stateToDisableEdit}
                  required
                />
              </Col>
              <Col xs="12" md="4">
                <Select
                  label={t("indexer")}
                  name="indexer"
                  control={control}
                  options={indexersList}
                  placeholder={
                    stateToDisableEdit ? offer?.indexer?.name ?? "" : null
                  }
                  isDisabled={!isPostFixedRemunerationRate || stateToDisableEdit}
                  required={isPostFixedRemunerationRate}
                />
              </Col>
              <Col xs="12" md="4">
                <Select
                  label={t("indexerApplications")}
                  name="indexerApplications"
                  control={control}
                  options={indexerApplicationsList}
                  placeholder={
                    stateToDisableEdit
                      ? offer?.indexerApplication?.name ?? ""
                      : null
                  }
                  isDisabled={stateToDisableEdit}
                  required
                />
              </Col>

              <Col xs="12" md="4">
                <InputPercentage
                  isNumericString
                  label={t("cet")}
                  name="totalEffectiveCost"
                  control={control}
                  isAllowed={({ value }) => Number(value) <= 999.99}
                  decimalScale={2}
                  disabled={isAccessTypeOriginator || stateToDisableEdit}
                  required={isSubmittedOffer && isAccessTypeAdmin}
                />
              </Col>
              <Col xs="12" md="4">
                <InputPercentage
                  isNumericString
                  label={t("reservationsMinimumPercentage")}
                  name="reservationsMinimumPercentage"
                  control={control}
                  isAllowed={({ value }) => Number(value) <= 100}
                  decimalScale={2}
                  placeholder={t("reservationsMinimumPercentage")}
                  disabled={stateToDisableEdit}
                  required
                />
              </Col>
              <Col xs="12" md="4">
                <InputMask
                  label={t("minimumReservationValue")}
                  name="minimumReservationValue"
                  control={control}
                  className="form-control"
                  value={
                    totalIssuanceAmount * (reservationsMinimumPercentage / 100)
                  }
                  thousandSeparator="."
                  decimalSeparator=","
                  decimalScale={2}
                  prefix=""
                  isNumericString
                  fixedDecimalScale
                  disabled
                />
              </Col>

              <Col xs="12" md="4">
                <Select
                  label={t("paymentForms")}
                  name="paymentForm"
                  control={control}
                  options={paymentFormsList}
                  placeholder={
                    stateToDisableEdit ? offer?.paymentForm?.name ?? "" : null
                  }
                  isDisabled={stateToDisableEdit}
                  required
                />
              </Col>
              <Col xs="12" md="4">
                <Input
                  label={t("paymentOtherForm")}
                  name="paymentOtherForm"
                  control={control}
                  disabled={!isPaymentOtherForm || stateToDisableEdit}
                  required={isPaymentOtherForm}
                />
              </Col>
              <Col xs="12" md="4">
                <Select
                  label={t("contractAmortizationFrequency")}
                  name="frequencyPayments"
                  control={control}
                  options={frequencyPaymentsList}
                  onBlur={handleFrequencyOfPaymentChange}
                  placeholder={
                    stateToDisableEdit
                      ? offer?.frequencyPayment?.name ?? ""
                      : null
                  }
                  isDisabled={stateToDisableEdit}
                  required
                />
              </Col>

              <Col xs="12" md="4">
                <Select
                  label={t("interestCalculationCriterias")}
                  name="interestCalculationCriterias"
                  control={control}
                  options={interestCalcCriteriasList}
                  placeholder={
                    stateToDisableEdit
                      ? offer?.interestCalculationCriteria?.name ?? ""
                      : null
                  }
                  isDisabled={stateToDisableEdit}
                  required
                />
              </Col>
              <Col xs="12" md="4">
                <InputPercentage
                  label={t("latePaymentInterest")}
                  name="latePaymentInterest"
                  control={control}
                  placeholder={t("percentage")}
                  decimalScale={2}
                  isAllowed={({ value }) => Number(value) <= 999.99}
                  isNumericString
                  disabled={stateToDisableEdit}
                  required
                />
              </Col>
              <Col xs="12" md="4">
                <Select
                  label={t("interestRateApplications")}
                  name="interestRateApplications"
                  control={control}
                  options={interestRateApplicationsList}
                  placeholder={
                    stateToDisableEdit
                      ? offer?.interestRateApplication?.name ?? ""
                      : null
                  }
                  isDisabled={stateToDisableEdit}
                  required
                />
              </Col>

              <Col xs="12" md="4">
                <Select
                  label={t("lateFeeOptions")}
                  name="lateFeeOptions"
                  control={control}
                  options={lateFeeOptionsList}
                  placeholder={
                    stateToDisableEdit ? offer?.lateFeeOption?.name ?? "" : null
                  }
                  isDisabled={stateToDisableEdit}
                  required
                />
              </Col>
              <Col xs="12" md="4">
                {isLateFeeOptionsPercent ? (
                  <InputPercentage
                    isNumericString
                    label={t("lateFeeValue")}
                    name="lateFeeValue"
                    control={control}
                    decimalScale={2}
                    isAllowed={({ value }) => Number(value) <= 9999999999999.99}
                    disabled={stateToDisableEdit}
                    required
                  />
                ) : (
                  <InputMask
                    isNumericString
                    fixedDecimalScale
                    label={t("lateFeeValue")}
                    name="lateFeeValue"
                    control={control}
                    thousandSeparator="."
                    decimalSeparator=","
                    decimalScale={2}
                    isAllowed={({ value }) => Number(value) <= 9999999999999.99}
                    disabled={stateToDisableEdit}
                    required
                  />
                )}
              </Col>
              <Col xs="12" md="4">
                {/* <Input
                  label={t("numberOfInstallments")}
                  name="numberOfInstallments"
                  control={control}
                  type="number"
                  maxLength={100}
                  required
                /> */}
                <InputMask
                  isNumericString
                  fixedDecimalScale
                  label={t("numberOfInstallments")}
                  name="numberOfInstallments"
                  onKeyUp={handleNumberOfInstallmentsChange}
                  control={control}
                  decimalScale={0}
                  isAllowed={({ value }) => Number(value) <= 9999999999999.99}
                  disabled={isPaymentOnContractDueDate}
                  required
                />
              </Col>

              <Col xs="12" md="12">
                <TextArea
                  label={t("destinationResources")}
                  name="resourcesDestination"
                  id="resourcesDestination"
                  control={control}
                  placeholder={t("describeDestinationResources")}
                  rows={3}
                  disabled={stateToDisableEdit}
                />
              </Col>
            </Row>
          </CardBody>
        </Card>

        <Card>
          <CardBody>
            <h4 className="card-title mb-4 text-uppercase">
              {t("tokenizationParams")}
            </h4>

            <Row>
              <Col xs="12" md="4">
                <Input
                  label={t("lotsQuantity")}
                  name="lotsQuantity"
                  control={control}
                  type="number"
                  min="0"
                  onKeyDown={handleKeyOnlyNumber}
                  placeholder="0"
                  disabled={stateToDisableEdit}
                  required
                />
              </Col>
              <Col xs="12" md="4">
                <InputMask
                  label={t("lotPrice")}
                  name="unitValue"
                  control={control}
                  className="form-control"
                  value={totalVolumeOffered / lotsQuantity}
                  prefix="R$ "
                  decimalScale={2}
                  placeholder="0,00"
                  isNumericString
                  fixedDecimalScale
                  disabled
                />
              </Col>
              <Col xs="12" md="4" />

              <Col xs="12" md="4">
                <InputPercentage
                  label={t("investorMaximumLotsPercentage")}
                  name="investorMaximumLotsPercentage"
                  control={control}
                  decimalScale={2}
                  isAllowed={({ value }) => Number(value) <= 100}
                  disabled={stateToDisableEdit}
                  isNumericString
                  required
                />
              </Col>
              <Col xs="12" md="4">
                <InputMask
                  label={t("investorMaximumLotsQuantity")}
                  name="investorMaximumLotsQuantity"
                  control={control}
                  className="form-control"
                  value={lotsQuantity * (investorMaximumLotsPercentage / 100)}
                  thousandSeparator="."
                  decimalSeparator=","
                  isNumericString
                  decimalScale={0}
                  fixedDecimalScale
                  disabled
                />
              </Col>
            </Row>
          </CardBody>
        </Card>

        <Card>
          <CardBody>
            <h4 className="card-title mb-4 text-uppercase">{t("calendar")}</h4>

            <Row>
              <Col xs="12" md="3">
                <DatePicker
                  label={t("bookingStartDate")}
                  name="startReservationDate"
                  control={control}
                  placeholder="dd/mm/aaaa"
                  dateFormat="dd/MM/yyyy"
                  disabled={stateToDisableEdit}
                  required
                />
              </Col>
              <Col xs="12" md="3">
                <DatePicker
                  label={t("reservationDeadline")}
                  name="reservationDeadline"
                  control={control}
                  placeholder="dd/mm/aaaa"
                  dateFormat="dd/MM/yyyy"
                  required={isSubmittedOffer && isAccessTypeAdmin}
                  disabled={isIssuedOrRevisionOrApprovedOffer}
                />
              </Col>
              <Col xs="12" md="3">
                <DatePicker
                  label={t("emissionDate")}
                  name="emissionDate"
                  control={control}
                  placeholder="dd/mm/aaaa"
                  dateFormat="dd/MM/yyyy"
                  onCalendarClose={handleEmissionDateChange}
                  disabled={stateToDisableEdit}
                  required={isSubmittedOffer && isAccessTypeAdmin}
                />
              </Col>
              <Col xs="12" md="3">
                <DatePicker
                  label={t("transactionExpirationDate")}
                  name="transactionExpirationDate"
                  onSelect={handleExpirationDateChange}
                  value={transactionExpirationDate?.toLocaleString().split(',')[0]}
                  control={control}
                  placeholder="dd/mm/aaaa"
                  dateFormat="dd/MM/yyyy"
                  disabled={stateToDisableEdit}
                  required
                />
              </Col>
            </Row>
          </CardBody>
        </Card>

        <Attachments
          attachments={attachments}
          setAttachments={setAttachments}
        />

        {/* <MandatoryWarranty
          warrGuarantors={warrGuarantors}
          setWarrGuarantors={setWarrGuarantors}
          setIsAddGuarantor={setIsAddGuarantor}
          control={control}
        /> */}

        <Warranties
          setIsAddWarranty={setIsAddWarranty}
          warrantyList={warrantyList}
          setWarrantyList={setWarrantyList}
        />
      </form>

      <div className="mb-5 text-end">
        <div>
          <Button
            id="save"
            color="info"
            size="lg"
            className="waves-effect waves-light me-3"
            onClick={handleSubmit(onSubmit, onError)}
          >
            {t("save")}
          </Button>
          <Button
            id="cancel"
            type="button"
            color="secondary"
            size="lg"
            className="waves-effect waves-light"
            onClick={handleCancel}
            outline
          >
            {t("cancel")}
          </Button>
        </div>
      </div>
    </>
  );
};

export default CreateEditOffer;


import { action } from 'typesafe-actions';
import { NavigateFunction } from 'react-router-dom';
import { ProfileTypes, Profile, CreatedUser } from './types';

export const updateProfileRequest = (
  profile: Profile,
  isCurrentUser: boolean,
  navigate: NavigateFunction,
): TypeSafeAction<ProfileTypes> =>
  action(ProfileTypes.UPDATE_PROFILE_REQUEST, {
    profile,
    isCurrentUser,
    navigate,
  });

export const updateProfileSuccess = (
  profile: Profile,
): TypeSafeAction<ProfileTypes> =>
  action(ProfileTypes.UPDATE_PROFILE_SUCCESS, { profile });

export const createUserRequest = (
  profile: Profile,
  navigate: NavigateFunction,
): TypeSafeAction<ProfileTypes> =>
  action(ProfileTypes.CREATE_USER_REQUEST, { profile, navigate });

export const createUserSuccess = (
  obj: CreatedUser,
): TypeSafeAction<ProfileTypes> =>
  action(ProfileTypes.CREATE_USER_SUCCESS, obj);

export const clearCreatedUser = (): TypeSafeAction<ProfileTypes> =>
  action(ProfileTypes.CLEAR_CREATED_USER);

export const changeProfilePictureRequest = ({
  image,
  id,
}): TypeSafeAction<ProfileTypes> =>
  action(ProfileTypes.CHANGE_PROFILE_PICTURE, { image, id });

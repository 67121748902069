import React, { useRef, Dispatch, SetStateAction } from "react";
import { Card, CardBody, Button } from "reactstrap";
import { useDispatch } from "react-redux";

import { useTranslation } from "react-i18next";
import DataTable from "../DataTable";
import { addMessage } from "../../store/modules/toast/actions";
import { Attachment } from "../../store/modules/offer/types";
import { MB_IN_BYTES } from "utils/numbers";
import { useEditOffer } from "hooks/useEditOffer";

interface Props {
  attachments: Attachment[];
  setAttachments?: Dispatch<SetStateAction<Attachment[]>>;
  detailPage?: boolean;
}

const Attachments: React.FC<Props> = ({ attachments, setAttachments,  detailPage = false}) => {
  const { t } = useTranslation("offers");
  const dispatch = useDispatch();
  const inputFileRef = useRef(null);

  const { offer } = useEditOffer();
  const stateToDisableEdit = offer?.state === "PUBLISHED" || offer?.state === "ISSUED" || offer?.state === "REVISION";

  function fileToBase64(file): Promise<string> {
    const reader = new FileReader();
    reader.readAsDataURL(file);

    return new Promise((resolve) => {
      reader.onloadend = () => {
        resolve(reader.result.toString());
      };
    });
  }

  const extractData = async (e) => {
    const [file] = e.target.files;

    if (file.size > 2 * MB_IN_BYTES) {
      e.currentTarget.value = "";

      dispatch(
        addMessage({
          title: t("attention"),
          description: `${t("maxFileSizeMessage")} 2MB`,
          type: "info",
        })
      );

      return;
    }

    const attachment = await fileToBase64(file);
    const obj: Attachment = {
      attachmentName: file.name,
      attachment,
    };

    if (setAttachments) {
      setAttachments((oldState) => [...oldState, obj]);
    }
  };

  function bytesToSize(bytes: number): string {
    const sizes = ["Bytes", "KB", "MB", "GB", "TB"];

    if (bytes === 0) return "0 Byte";

    const i = Math.floor(Math.log(bytes) / Math.log(1024));

    return `${Math.round(bytes / 1024 ** i)} ${sizes[i]}`;
  }

  const remove = (rowIndex: number) => {
    if (setAttachments) {
      setAttachments((oldState) =>
        oldState.filter((rat, idx) => idx !== rowIndex)
      );
    }
  };

  return (
    <Card>
      <CardBody>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <h4 className="card-title mb-4 text-uppercase">{t("attachments")}</h4>

          <Button
            id="addAttachment"
            className="waves-effect waves-light"
            color="primary"
            size="md"
            onClick={() => inputFileRef.current?.click()}
            disabled={stateToDisableEdit  || detailPage}
          >
            <i className="mdi mdi-plus me-2" />
            {t("addAttachment")}
          </Button>
        </div>

        <input
          id="addAttachment-input"
          ref={inputFileRef}
          onChange={extractData}
          type="file"
          style={{ display: "none" }}
        />

        <DataTable
          columns={[
            {
              name: t("fileName"),
              selector: (row) => row.attachmentName,
              center: true,
              sortable: true,
            },
            {
              name: t("fileSize"),
              cell: ({ attachment }) => {
                const test = atob(
                  attachment.substring(attachment.indexOf(",") + 1)
                );

                return bytesToSize(test.length);
              },
              center: true,
              sortable: true,
            },
            {
              name: "",
              cell: (row, rowIndex) => (
                <>
                  <Button
                    id={`downloadAttachment-${rowIndex}`}
                    className="waves-effect waves-light m-1"
                    color="info"
                    size="sm"
                    href={row.attachment}
                    download={row.attachmentName}
                    outline
                  >
                    <i className="fas fa-download" />
                  </Button>

                  {setAttachments ? (
                    <Button
                      id={`removeAttachment-${rowIndex}`}
                      className="waves-effect waves-light m-1"
                      color="danger"
                      size="sm"
                      onClick={() => remove(rowIndex)}
                    >
                      <i className="fas fa-trash-alt" />
                    </Button>
                  ) : null}
                </>
              ),
              button: true,
            },
          ]}
          data={attachments}
        />
      </CardBody>
    </Card>
  );
};

export default Attachments;

import { BreadcrumbProvider } from "./useBreadcrumb";
import { EditUserProvider } from "./useEditUser";
import { EditAdvisorProvider } from "./useEditAdvisor";
import { EditListingProvider } from "./useEditListing";
import { EditOfferProvider } from "./useEditOffer";
import { EditBrokerProvider } from "./useEditBroker";
import { EditCommissionProvider } from "./useEditCommission";
import { InvestorProvider } from "./useInvestor";
import { EditCompanyProvider } from "./useEditCompany";
import { EditNaturalPersonProvider } from "./useEditNaturalPerson";

const HooksWrapper: React.FC = ({ children }) => (
  <BreadcrumbProvider>
    <EditUserProvider>
      <EditAdvisorProvider>
        <EditCompanyProvider>
          <EditNaturalPersonProvider>
            <EditListingProvider>
             <EditOfferProvider>
              <EditBrokerProvider>
                <EditCommissionProvider>
                    <InvestorProvider>{children}</InvestorProvider>
                  </EditCommissionProvider>
                </EditBrokerProvider>
              </EditOfferProvider>
            </EditListingProvider>
          </EditNaturalPersonProvider>
        </EditCompanyProvider>
      </EditAdvisorProvider>
    </EditUserProvider>
  </BreadcrumbProvider>
);

export default HooksWrapper;

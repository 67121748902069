import React, { useState, useEffect, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { Form } from "@unform/web";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";

import SEO from "../../components/SEO";
import Input from "../../components/FormFields/Input";
import InputMask from "../../components/FormFields/InputMask";
import Select from "../../components/FormFields/Select";
import ActionBar from "../../components/ActionBar";
import AddressCard from "../../components/AddressCard";
import ContactInfoCard from "../../components/ContactInfoCard";
import CurrentAccount from "../../components/CurrentAccount";
import DataTable from "../../components/DataTable";
import Spinner from "../../components/Spinner";
import { useBreadcrumb } from "../../hooks/useBreadcrumb";
import { useEditBroker } from "../../hooks/useEditBroker";
import { addMessage } from "../../store/modules/toast/actions";
import usePermissions from "../../hooks/usePermissions";
import { dataContext, permission } from "../../utils/permissionEnums";
import { Broker } from "../../store/modules/broker/types";
import api from "../../services/api";
import imageUsrPlaceholder from "../../assets/images/usr-placeholder.jpeg";
import { ApplicationState } from "store";
import { Profile } from "store/modules/user/types";

interface LabelValue {
  label: string;
  value: string;
}

interface BrokerOffer {
  ticker: string;
  amountRaised: number;
  volumeOffered: number;
  saleValue: number;
  term: string;
  listedCompany: {
    tradingName: string;
    name: string;
    cnpj: string;
  };
  state: string;
}

const BrokerDetails: React.FC = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation("brokers");

  const { setBreadcrumbItems } = useBreadcrumb();
  const { setBroker: setBrokerHook } = useEditBroker();

  const linkedOffersDataTable = useRef(null);
  const formRef = useRef(null);

  const [broker, setBroker] = useState<Broker>({} as Broker);
  const [linkedOffers, setLinkedOffers] = useState<BrokerOffer[]>([]);
  const [certifications, setCertifications] = useState<LabelValue[]>([]);
  const [showExtract, setShowExtract] = useState(false);
  const profile = useSelector<ApplicationState, Profile>(
    (state) => state.user.profile
  );

  const hasPermissionToRefuse = usePermissions(
    dataContext.BROKER,
    permission.REFUSE,
    broker.state
  );
  const hasPermissionToBlock = usePermissions(
    dataContext.BROKER,
    permission.BLOCK,
    broker.state
  );
  const hasPermissionToPublish = usePermissions(
    dataContext.BROKER,
    permission.PUBLISH,
    broker.state
  );
  const hasPermissionToSubmit = usePermissions(
    dataContext.BROKER,
    permission.SUBMIT,
    broker.state
  );
  const hasPermissionToUpdate = usePermissions(
    dataContext.BROKER,
    permission.UPDATE,
    broker.state
  );

  function editBroker() {
    setBrokerHook(broker);
    navigate("/brokers/broker");
  }

  async function takeAction(type, reason?) {
    const { data } = await api.patch(`/brokers/${broker.id}/state`, {
      changeState: {
        action: type,
        ...(reason && { message: reason }),
      },
    });
    setBroker(data);
    dispatch(
      addMessage({
        title: t("brokerHasBeenUpdated"),
        type: "info",
      })
    );
  }

  async function handleAction(
    type: "REFUSE" | "BLOCK" | "PUBLISH" | "SUBMIT" | "UPDATE",
    reason?: string
  ) {
    if (type === "UPDATE") editBroker();
    else if (type === "REFUSE") takeAction(type, reason);
    else takeAction(type);
  }

  function scrollToLinkedOffers() {
    linkedOffersDataTable.current.scrollIntoView({ behavior: "smooth" });
  }

  useEffect(() => {
    setBreadcrumbItems({
      title: "brokerDetails",
      items: [
        { title: "home", link: "/dashboard" },
        { title: "brokers", link: "/brokers" },
        { title: "brokerDetails", link: "/" },
      ],
    });
  }, [setBreadcrumbItems]);

  useEffect(() => {
    const controller = new AbortController();

    async function getPageData() {
      try {
        const { data: brokerData } = await api.get<Broker>(`/brokers/${id}`, {
          signal: controller.signal,
        });
        const { data: brokerOffers } = await api.get<BrokerOffer[]>(
          `/brokers/${id}/offers`,
          { signal: controller.signal }
        );
        const { data: certificationList } = await api.get<LabelValue[]>(
          "/certifications/dropdown",
          { signal: controller.signal }
        );

        setBroker(brokerData);
        setLinkedOffers(
          brokerOffers.map((obj) => ({
            ...obj,
            term: format(new Date(obj.term || null), "dd/MM/yyyy"),
          }))
        );
        setCertifications(certificationList);
      } catch (error) {
        console.log(error);
      }
    }

    getPageData();

    return () => {
      controller.abort();
    };
  }, [id]);

  if (!broker?.id) return <Spinner />;

  return (
    <>
      <SEO
        title={t("brokerDetails", { ns: "breadcrumb" })}
        shouldIndexPage={false}
      />

      <Modal
        isOpen={showExtract}
        toggle={() => setShowExtract(false)}
        size="xl"
        centered
      >
        <ModalHeader className="justify-content-center">
          {t("accountStatement")}
        </ModalHeader>
        <ModalBody>
          <CurrentAccount
            account={{
              accountNum: "9827465-2",
              agency: "0001",
              blockedInFlow: 700,
              freeToUse: 300,
              totalBalance: 1000,
            }}
          />
        </ModalBody>
        <ModalFooter>
          <Button
            id="closeExtract"
            color="secondary"
            onClick={() => setShowExtract(false)}
          >
            {t("close")}
          </Button>
        </ModalFooter>
      </Modal>

      <Row>
        <Col xs="12" md="12">
          <div className="text-end mb-4">
            <div className="button-items">
              <ActionBar
                actions={[
                  {
                    permission: hasPermissionToRefuse,
                    action: permission.REFUSE,
                  },
                  {
                    permission: hasPermissionToBlock && profile.accessType == "A0",
                    action: permission.BLOCK,
                  },
                  {
                    permission: hasPermissionToPublish,
                    action: permission.PUBLISH,
                  },
                  {
                    permission: hasPermissionToSubmit,
                    action: permission.SUBMIT,
                  },
                  {
                    permission: hasPermissionToUpdate,
                    action: permission.UPDATE,
                  },
                ]}
                onClick={handleAction}
              />
            </div>
          </div>
        </Col>
      </Row>

      <Form ref={formRef} onSubmit={() => ({})} initialData={broker}>
        <div className="d-flex mb-4 flex-column flex-md-row">
          <Col
            xs="12"
            md="6"
            className="p-4 bg-white shadow-sm rounded d-flex justify-content-between"
          >
            <div className="d-flex flex-column justify-content-around text-info">
              <small>{t("companyName")}</small>
              <h4 className="mb-1">{broker.company.name}</h4>

              <small>{t("registration")}</small>
              <h4 className="mb-1">{broker.id}</h4>

              <small>{t("tradingName")}</small>
              <h4 className="mb-1">{broker.tradingName}</h4>
            </div>

            <div className="d-flex flex-column justify-content-between">
              {/* 18/04/2023 - Em pedido da US 86 não apresentar a opção Emissões vinculadas na tela */}
              {/* <Button
                id="linkedOffersRoll"
                size="sm"
                color="primary"
                className="text-end d-flex justify-content-center"
                onClick={scrollToLinkedOffers}
              >
                {t("linkedOffers")}
              </Button> */}

              {/* 18/04/2023 - Em pedido da US 86 não apresentar a opção Mostrar extrato */}
              {/* {broker.state === "PUBLISHED" && (
                <Button
                  id="showExtract"
                  className="waves-effect waves-light"
                  color="info"
                  size="sm"
                  onClick={() => setShowExtract(true)}
                >
                  {t("showExtract")}
                </Button>
              )} */}
            </div>
          </Col>
          <Col
            xs="12"
            md="6"
            className="p-4 bg-info text-white rounded-end text-uppercase"
          >
            <h4 className="text-uppercase">{t("unitResponsible")}</h4>
            <Row>
              <Col
                sm="12"
                md="12"
                className="d-flex flex-row align-items-center justify-content-around py-2"
              >
                {broker.unitResponsible ? (
                  <>
                    <img
                      src={
                        broker.unitResponsible?.profilePicture ||
                        imageUsrPlaceholder
                      }
                      alt="Financial responsible profile picture"
                      className="rounded-circle avatar-md border border-3 border-white float-start me-2"
                    />
                    <div>
                      <small>{t("companyName")}</small>
                      <p className="mb-1">{broker.unitResponsible?.name}</p>
                      <small>{t("role")}</small>
                      <p className="mb-1">{broker.unitResponsible?.role}</p>
                    </div>

                    <div>
                      <small>{t("registration")}</small>
                      <p className="mb-1">{broker.unitResponsible?.login}</p>
                      <small>{t("accessType")}</small>
                      <p className="mb-1">
                        {broker.unitResponsible?.accessType}
                      </p>
                    </div>
                  </>
                ) : (
                  <div>{t("noResponsible")}</div>
                )}
              </Col>
            </Row>
          </Col>
        </div>

        <Card>
          <CardBody>
            <h4 className="card-title text-info mb-4 text-uppercase">
              {t("brokerRegistrationData")}
            </h4>

            <Row>
              <Col xs="12" md="4">
                <Input
                  label={t("companyName")}
                  name="company.name"
                  type="text"
                  disabled
                />
              </Col>
              <Col xs="12" md="4">
                <Input
                  label={t("tradingName")}
                  name="tradingName"
                  type="text"
                  disabled
                />
              </Col>
              <Col xs="12" md="4">
                <InputMask
                  label="CNPJ"
                  name="company.cnpj"
                  format="##.###.###/####-##"
                  disabled
                />
              </Col>
            </Row>
            <Row>
              <Col xs="12" md="4">
                <Input
                  label={t("officeType")}
                  name="officeType.name"
                  disabled
                />
              </Col>
              <Col xs="12" md="4">
                <Select
                  label={t("certifications")}
                  name="certifications"
                  options={certifications}
                  isMulti
                  isDisabled
                />
              </Col>
            </Row>
          </CardBody>
        </Card>

        <ContactInfoCard titleColor="text-info" disabled isCellphoneRequired={false} isTelefoneRequired={false} />

        <AddressCard formRef={formRef} titleColor="text-info" disabled />
      </Form>

      {/* 18/04/2023 - Em pedido da US 86 não apresentar o item emissões vinculadas na tela. */}
      {/* <Card>
        <CardBody>
          <h4 className="card-title text-info mb-4 text-uppercase">
            {t("linkedOffers")}
          </h4>
          <div ref={linkedOffersDataTable}>
            <DataTable
              columns={[
                {
                  name: "Ticker",
                  selector: (row) => row.ticker,
                  center: true,
                  sortable: true,
                },
                {
                  name: t("tradingName"),
                  selector: (row) => row.listedCompany.tradingName,
                  center: true,
                  sortable: true,
                },
                {
                  name: t("offerTerm"),
                  selector: (row) => row.term,
                  center: true,
                  sortable: true,
                },
                {
                  name: t("volumeOffered"),
                  selector: (row) => row.volumeOffered,
                  center: true,
                  sortable: true,
                },
                {
                  name: t("amountRaised"),
                  selector: (row) => row.amountRaised,
                  center: true,
                  sortable: true,
                },
                {
                  name: t("status"),
                  selector: (row) => row.state,
                  center: true,
                  sortable: true,
                },
              ]}
              data={linkedOffers}
            />
          </div>
        </CardBody>
      </Card> */}
    </>
  );
};

export default BrokerDetails;

import React from 'react';

interface SpinnerProps {
  hasHeight?: boolean;
}

const Spinner: React.FC<SpinnerProps> = ({ hasHeight = true }) => {
  return (
    <div
      className={`d-flex justify-content-center align-items-center p-3 ${
        hasHeight ? 'vh-100' : ''
      }`}
    >
      <div className="spinner-chase">
        <div className="chase-dot" />
        <div className="chase-dot" />
        <div className="chase-dot" />
        <div className="chase-dot" />
        <div className="chase-dot" />
        <div className="chase-dot" />
      </div>
    </div>
  );
};

export default Spinner;

import React from "react";
import { Card, CardBody, Row, Col } from "reactstrap";
import { useTranslation } from "react-i18next";

import Input from "./FormFields/Input";
import InputMask from "./FormFields/InputMask";

interface ContactInfoProps {
  disabled?: boolean;
  prefix?: string;
  titleColor?: string;
  showCard?: boolean;
  isUser?: boolean;
  isCellphoneRequired?: boolean;
  isTelefoneRequired?: boolean;
}

const ContactInfoCard: React.FC<ContactInfoProps> = ({
  disabled = false,
  prefix = "",
  titleColor,
  showCard = true,
  isUser = false,
  isCellphoneRequired = true,
  isTelefoneRequired = true
}) => {
  const { t } = useTranslation();

  return (
    <Card style={{ display: showCard ? "flex" : "contents" }}>
      <CardBody className={`${showCard ? "" : "p-0"}`}>
        <h4 className={`card-title mb-4 text-uppercase ${titleColor || ""}`}>
          {t("contactInformation")}
        </h4>
        <Row>
          <Col xs="12" md="4">
            <Input
              label="Email"
              name={prefix.concat("email")}
              type="email"
              placeholder={t("enterEmail")}
              disabled={disabled}
              required
            />
          </Col>
          <Col xs="12" md="4">
            <InputMask
              label={t("cellphone")}
              name={prefix.concat("cellphone")}
              format="+## (##) #####-####"
              placeholder={t("enterCellphone")}
              disabled={disabled}
              required={isCellphoneRequired}
            />
          </Col>
          <Col xs="12" md="4">
            <InputMask
              label={t("telephone")}
              name={prefix.concat("telephone")}
              format="+## (##) ####-####"
              placeholder={t("enterTelephone")}
              disabled={disabled}
              required={isTelefoneRequired}
            />
          </Col>
        </Row>
        <Row>
          <Col xs="12" md="4">
            <Input
              label={isUser ? "LinkedIn" : "Site"}
              name={prefix.concat("website")}
              type="text"
              placeholder={t("enterSite")}
              disabled={disabled}
            />
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export default ContactInfoCard;

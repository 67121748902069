import React, { useState, useEffect } from "react";
import { Card, CardBody, Row, Col, Button } from "reactstrap";
import { useTranslation } from "react-i18next";
import { Form } from "@unform/web";

import DataTable from "./DataTable";
import Select from "./FormFields/Select";
import ExportCSV from "../utils/ExportCSV";

interface Extract {
  id: number;
  date: string;
  description: string;
  value: number;
  type: string;
  status: string;
}

interface ICurrentAccount {
  agency: string;
  accountNum: string;
  totalBalance: number;
  freeToUse: number;
  blockedInFlow: number;
}

interface Props {
  account: ICurrentAccount;
}

const CurrentAccount: React.FC<Props> = ({
  account: { agency, accountNum, totalBalance, freeToUse, blockedInFlow },
}) => {
  const { t } = useTranslation("currentAccount");
  const [data, setData] = useState<Extract[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    setTimeout(() => {
      setData([]);
      setLoading(false);
    }, 1000);
  }, []);

  return (
    <>
      <div className="d-flex flex-column flex-md-row justify-content-between">
        <Card className="d-none">
          <CardBody>
            <span>{t("agency", { number: agency })}</span>
            <h5>{t("currentAccountNum", { number: accountNum })}</h5>
          </CardBody>
        </Card>

        <div className="d-flex flex-column flex-md-row d-none">
          <Card className="bg-primary text-white">
            <CardBody>
              <span>{t("totalBalance")}</span>
              <h5>
                {new Intl.NumberFormat("pt-BR", {
                  style: "currency",
                  currency: "BRL",
                }).format(totalBalance)}
              </h5>
            </CardBody>
          </Card>
          <Card className="bg-success text-white ms-md-3">
            <CardBody>
              <span>{t("freeToUse")}</span>
              <h5>
                {new Intl.NumberFormat("pt-BR", {
                  style: "currency",
                  currency: "BRL",
                }).format(freeToUse)}
              </h5>
            </CardBody>
          </Card>
          <Card className="bg-warning text-white ms-md-3">
            <CardBody>
              <span>{t("blockedInFlowOrOperations")}</span>
              <h5>
                {new Intl.NumberFormat("pt-BR", {
                  style: "currency",
                  currency: "BRL",
                }).format(blockedInFlow)}
              </h5>
            </CardBody>
          </Card>
        </div>
      </div>

      <Card>
        <CardBody>
          <Form onSubmit={() => ({})}>
            <Row>
              <Col xs="12" md="4">
                <Select
                  disabled
                  label={t("period")}
                  name="period"
                  options={[
                    { label: t("7LastDays"), value: "1" },
                    { label: t("30LastDays"), value: "2" },
                    { label: t("1Year"), value: "3" },
                  ]}
                />
              </Col>
              <Col xs="12" md="4" className="offset-md-4 mb-3">
                <Button
                  className="waves-effect waves-light float-end"
                  color="primary"
                  onClick={() => ExportCSV(data)}
                  disabled
                >
                  <i className="fas fa-print me-1" />
                  {t("exportExtract")}
                </Button>
              </Col>
            </Row>
          </Form>
          <DataTable
            columns={[
              {
                name: "ID",
                selector: (row) => row.id,
                center: true,
                sortable: true,
              },
              {
                name: t("date"),
                selector: (row) => row.date,
                center: true,
                sortable: true,
              },
              {
                name: t("description"),
                selector: (row) => row.description,
                center: true,
                sortable: true,
              },
              {
                name: t("value"),
                selector: (row) => row.value,
                center: true,
                sortable: true,
              },
              {
                name: t("type"),
                selector: (row) => row.type,
                center: true,
                sortable: true,
              },
              {
                name: "Status",
                selector: (row) => row.status,
                center: true,
                sortable: true,
              },
            ]}
            data={data}
            progressPending={loading}
            conditionalRowStyles={[
              {
                when: ({ type }) => type === "D",
                style: {
                  color: "red",
                },
              },
              {
                when: ({ type }) => type === "C",
                style: {
                  color: "green",
                },
              },
            ]}
          />
        </CardBody>
      </Card>
    </>
  );
};

export default CurrentAccount;

import React, { useState, useEffect, useRef } from 'react';
import {
  Button,
  Row,
  Col,
  Card,
  CardBody,
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import SweetAlert from 'react-bootstrap-sweetalert';
import { useTranslation } from 'react-i18next';
import { useNavigate, Link } from 'react-router-dom';
import { Form } from '@unform/web';

import { ApplicationState } from '../store';
import { changeProfilePictureRequest } from '../store/modules/user/actions';
import { addMessage } from '../store/modules/toast/actions';
import { Profile } from '../store/modules/user/types';
import SEO from '../components/SEO';
import Input from '../components/FormFields/Input';
import InputMask from '../components/FormFields/InputMask';
import { useBreadcrumb } from '../hooks/useBreadcrumb';
import { useEditUser } from '../hooks/useEditUser';

import imageUsrPlaceholder from '../assets/images/usr-placeholder.jpeg';

const ProfileComponent: React.FC = () => {
  const { t } = useTranslation('profile');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const inputFileRef = useRef(null);
  const [image, setImage] = useState<string>('');
  const [isRemove, setIsRemove] = useState<boolean>(false);
  const [imageDropdown, setImageDropdown] = useState<boolean>(false);
  const profile = useSelector<ApplicationState, Profile>(
    state => state.user.profile,
  );
  const { setBreadcrumbItems } = useBreadcrumb();
  const { setUser } = useEditUser();

  useEffect(() => {
    setBreadcrumbItems({
      title: 'profile',
      items: [
        { title: 'home', link: '/dashboard' },
        { title: 'profile', link: '/profile' },
      ],
    });
  }, [setBreadcrumbItems]);

  function editUser() {
    setUser(profile);
    navigate('/users/user');
  }

  async function handleImageChange(e) {
    const extensions = ['image/jpeg', 'image/jpg', 'image/png'];

    if (!e.currentTarget.files.length) return;

    if (e.target.files[0].size > 2097152) {
      e.currentTarget.value = '';
      dispatch(
        addMessage({
          title: t('warning'),
          description: t('maxFileSize', { size: 2 }),
          type: 'info',
        }),
      );
      return;
    }

    if (!extensions.includes(e.target.files[0].type)) {
      e.currentTarget.value = '';
      dispatch(
        addMessage({
          title: t('warning'),
          description: t('fileTypeNotSupported'),
          type: 'info',
        }),
      );
      return;
    }

    const converted = await fileToBase64(e.target.files[0]);
    setImage(converted);
  }

  function changeImage(prop = '') {
    dispatch(
      changeProfilePictureRequest({
        id: profile.id,
        image: prop,
      }),
    );
    setImage(null);
    setIsRemove(false);
    inputFileRef.current.value = null;
  }

  function fileToBase64(file): Promise<string> {
    const reader = new FileReader();
    reader.readAsDataURL(file);

    return new Promise(resolve => {
      reader.onloadend = () => {
        resolve(reader.result.toString());
      };
    });
  }

  return (
    <div>
      <SEO title={t('profile')} shouldIndexPage={false} />

      {image ? (
        <SweetAlert
          warning
          showCancel
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          title={t('wantToChangeImage')}
          onConfirm={() => changeImage(image)}
          onCancel={() => setImage(null)}
        >
          <img className="avatar-lg rounded-pill" src={image} alt="user" />
        </SweetAlert>
      ) : null}

      {isRemove ? (
        <SweetAlert
          warning
          showCancel
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          title={t('wantToDeleteImage')}
          onConfirm={() => changeImage()}
          onCancel={() => setIsRemove(!isRemove)}
        />
      ) : null}

      <Row>
        <Col xl="12">
          <div className="text-end mb-4">
            <div className="button-items">
              <Link to="/auth/new-password">
                <Button
                  id="changePassword"
                  type="button"
                  color="info"
                  size="sm"
                  className="waves-effect waves-light rounded-pill px-3"
                >
                  {t('changePassword')}
                  <i className="mdi mdi-pencil-outline ms-1" />
                </Button>
              </Link>
              <Button
                id="editProfile"
                type="button"
                color="info"
                size="sm"
                className="waves-effect waves-light rounded-pill px-3"
                onClick={editUser}
              >
                {t('edit')}
                <i className="mdi mdi-pencil-outline ms-1" />
              </Button>
            </div>
          </div>
        </Col>
        <Col xl="4" md="6">
          <Card className="directory-card card">
            <div>
              <div className="directory-bg text-center">
                <div className="directory-overlay">
                  <img
                    className="rounded-circle avatar-xxl img-thumbnail"
                    src={profile.profilePicture || imageUsrPlaceholder}
                    alt="user"
                  />
                  <ButtonDropdown
                    isOpen={imageDropdown}
                    toggle={() => setImageDropdown(!imageDropdown)}
                    className="position-absolute color-bg-primary rounded-2 color-text-primary"
                    style={{ bottom: '43%', left: '55%' }}
                  >
                    <DropdownToggle
                      color="primary"
                      className="btn-sm"
                      id="pictureMenu"
                    >
                      <i className="mdi mdi-pencil-outline me-1" />
                      {t('edit')}
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem
                        id="changePicture"
                        onClick={() => inputFileRef.current.click()}
                      >
                        {t('changePicture')}
                      </DropdownItem>
                      <DropdownItem
                        id="deletePicture"
                        onClick={() => setIsRemove(true)}
                      >
                        {t('deletePicture')}
                      </DropdownItem>
                    </DropdownMenu>
                  </ButtonDropdown>
                  <input
                    id="changePicture-input"
                    ref={inputFileRef}
                    type="file"
                    hidden
                    onChange={handleImageChange}
                    accept=".jpg,.jpeg,.png"
                  />
                </div>
              </div>

              <div className="directory-content text-center p-4">
                <p className=" mt-4">{t(`common:${profile.role}`)}</p>
                <h5 className="font-size-16">{profile.name}</h5>
                <p className="text-muted">{profile.professionalSummary}</p>
                {profile.website && (
                  <p>
                    <a
                      href={`https://${profile.website}`}
                      rel="noreferrer"
                      target="_blank"
                    >
                      <i className="fab fa-linkedin text-info fs-5" />
                    </a>
                  </p>
                )}
              </div>
            </div>
          </Card>
        </Col>
        <Col xl="8" md="6">
          <Card>
            <CardBody>
              <Form onSubmit={() => ({})} initialData={profile}>
                <Row>
                  <Col xl="6">
                    <Input label={t('name')} name="name" disabled />
                    <Input label="Email" name="email" disabled />
                    <InputMask
                      label={t('cellphone')}
                      name="cellphone"
                      format="+## (##) #####-####"
                      disabled
                    />
                    <InputMask
                      label={t('telephone')}
                      name="telephone"
                      format="+## (##) ####-####"
                      disabled
                    />
                    <InputMask
                      label="Cpf"
                      format="###.###.###-##"
                      name="cpf"
                      disabled
                    />
                    <Input
                      label={t('companyName')}
                      name={
                        profile.companyTradingName
                          ? 'companyTradingName'
                          : 'companyName'
                      }
                      disabled
                    />
                    <Input label={t('role')} name="role" disabled />
                  </Col>
                  <Col xl="6">
                    <Input label={t('street')} name="address.street" disabled />
                    <Input label={t('number')} name="address.number" disabled />
                    <Input
                      label={t('additionalInfo')}
                      name="address.additionalInfo"
                      disabled
                    />
                    <Input label={t('city')} name="address.city" disabled />
                    <InputMask
                      label={t('zip')}
                      name="address.zip"
                      format="#####-###"
                      disabled
                    />
                    <Input label={t('state')} name="address.state" disabled />
                  </Col>
                </Row>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default ProfileComponent;

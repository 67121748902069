import { all, takeLatest, call, put } from 'redux-saga/effects';

import i18n from '../../../i18n';
import api from '../../../services/api';

import { addMessage } from '../toast/actions';
import { CommissionTypes } from './types';
import { createCommissionSuccess, updateCommissionSuccess } from './actions';

export function* createCommission({ payload }: any) {
  try {
    const { commission, navigate } = payload;

    yield call(api.post, '/commissions', commission);

    yield put(
      addMessage({
        title: i18n.t('toasts:commissionHasBeenCreated'),
        type: 'success',
      }),
    );
    yield put(createCommissionSuccess());
    navigate('/commissions');
  } catch (error) {
    console.log('Erro ao adicionar commission!');
  }
}

export function* updateCommission({ payload }: any) {
  try {
    const { commission, navigate } = payload;

    yield call(api.put, `/commissions`, commission);

    yield put(
      addMessage({
        title: i18n.t('toasts:commissionHasBeenUpdated'),
        type: 'success',
      }),
    );
    yield put(updateCommissionSuccess());
    navigate(-1);
  } catch (error) {
    console.log('Erro ao atualizar commission!');
  }
}

export default all([
  takeLatest(CommissionTypes.CREATE_COMMISSION_REQUEST, createCommission),
  takeLatest(CommissionTypes.UPDATE_COMMISSION_REQUEST, updateCommission),
]);

import { action } from 'typesafe-actions';
import { AuthTypes, AuthPayload, MfaResponse  } from './types';
import { Profile, AccessType } from '../user/types';

export const signInRequest = (data: AuthPayload): TypeSafeAction<AuthTypes> =>
  action(AuthTypes.SIGN_IN_REQUEST, data);

  export const signInMfaRequest = (data: AuthPayload): TypeSafeAction<AuthTypes> =>
  action(AuthTypes.SIGN_IN_MFA_REQUEST, data);

export const signInSuccess = (
  token: string,
  profile: Profile,
  accessType: AccessType,
  name: string,
): TypeSafeAction<AuthTypes> =>
  action(AuthTypes.SIGN_IN_SUCCESS, { token, profile, accessType, name });

export const signInFailure = (): TypeSafeAction<AuthTypes> =>
  action(AuthTypes.SIGN_IN_FAILURE);

export const signUpRequest = (data: AuthPayload): TypeSafeAction<AuthTypes> =>
  action(AuthTypes.SIGN_UP_REQUEST, data);

export const signOut = (url?: string): TypeSafeAction<AuthTypes> =>
  action(AuthTypes.SIGN_OUT, url);

export const setToken = (token: string): TypeSafeAction<AuthTypes> =>
  action(AuthTypes.SET_TOKEN, token);

export const clearLoading = (): TypeSafeAction<AuthTypes> =>
  action(AuthTypes.CLEAR_LOADING);

export const setCredentials = (name: string, password: string) => ({
  type: AuthTypes.SET_CREDENTIALS,
  payload: { name, password},
});

export const setResponseData = (responseData: MfaResponse) => ({
  type: AuthTypes.SET_RESPONSE_DATA,
  payload: responseData,
});

export const setLoading = (): TypeSafeAction<AuthTypes> =>
  action(AuthTypes.SET_LOADING);

export const resetIsSigned = (): TypeSafeAction<AuthTypes> =>
  action(AuthTypes.RESET_IS_SIGNED);

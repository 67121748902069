import React, { useState, useRef, useEffect } from "react";
import { Container, Row, Col, Card, CardBody, Button } from "reactstrap";
import { FormHandles } from "@unform/core";
import { Form } from "@unform/web";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation, useParams } from "react-router-dom";

import { ApplicationState } from "../../../store";
import { AuthState, AuthTypes } from "../../../store/modules/auth/types";
import Input from "../../../components/FormFields/Input";
import {
  clearLoading,
  resetIsSigned,
  signInMfaRequest
} from "../../../store/modules/auth/actions";
import SEO from "../../../components/SEO";

import logoBlue from "../../../assets/images/logo-blue.png";
import loginBG from "../../../assets/images/login_bg.png";
import QRCode from "./qrcode";

const MfaSetup: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const formRef = useRef<FormHandles>(null);
  const { t } = useTranslation("auth");
  const dispatch = useDispatch();
  const auth = useSelector<ApplicationState, AuthState>((s) => s.auth);
  const { encodedTotpSecret, totpSecretQRCode } = auth.responseData;

  const state = location.state as { from: Location };
  const from = state ? state.from.pathname : "/auth/login";

  useEffect(() => {
    if (auth.isSigned) {
      dispatch({ type: AuthTypes.CLEAR_LOADING });
      dispatch(resetIsSigned());
      navigate(from, { replace: true });
    }
    dispatch(clearLoading()); //Limpar loader
    console.log(auth);
  }, [auth.isSigned, from, navigate]);

  async function handleSubmit(data) {
    try {
      dispatch({ type: AuthTypes.SET_LOADING });
      // Remove all previous errors
      formRef.current.setErrors({});

      const schema = Yup.object().shape({
        totpInitialCode: Yup.string().
          matches(/^[0-9]{1,6}$/, t("invalidNumber"))
          .required(t("requiredField")),
      });

      await schema.validate(data, {
        abortEarly: false,
      });
      // Validation passed
      const { name, password } = auth.credentials;

      dispatch(
        signInMfaRequest({
          username: name,
          password: password,
          totpInitialCode: data.totpInitialCode,
          encodedTotpSecret: encodedTotpSecret,
        })
      );
    } catch (err) {
      const validationErrors = {};

      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }
    }
  }

  return (
    <div
      className="vh-100 d-flex justify-content-center align-items-center bg-white"
      style={{
        backgroundImage: `url(${loginBG})`,
        backgroundPosition: "center bottom",
        backgroundRepeat: "repeat-x",
      }}
    >
      <SEO title={t("mfaSetup")} />
      <Container>
        <Row className="justify-content-center">
          <Col md="8" lg="6" xl="5">
            <Card className="overflow-hidden">
              <CardBody className="pt-0">
                <h3 className="text-center mt-4">
                  <a href="\" className="logo logo-admin">
                    <img src={logoBlue} height="30" alt="logo" />
                  </a>
                </h3>

                <div className="p-3 mb-4">
                  <p className="font-size-13 mb-1 font-weight-semibold">
                    {t("stepOne")}: <span className="font-weight-medium">{t("openAppTOTP")}</span>
                  </p>
                  <QRCode qrCodeImage={totpSecretQRCode} />
                  <p className="font-size-13 mb-1 font-weight-semibold">
                    {t("stepTwo")}: <span className="font-weight-medium">{t("codeAppTOTP")}</span>
                  </p>

                  <Form
                    ref={formRef}
                    className="form-horizontal mt-3"
                    onSubmit={handleSubmit}
                  >
                    <Input
                      label={t("code")}
                      name="totpInitialCode"
                      placeholder={t("Código*")}
                      type="text"
                      maxLength={6}
                      pattern="\d*"
                    />
                    <div className="mt-3">
                      <Button
                        id="login"
                        color="info"
                        className="w-100 waves-effect waves-light d-inline-flex justify-content-center align-items-center"
                        type="submit"
                        disabled={auth.loading}
                      >
                        {auth.loading && (
                          <span
                            className="spinner-border spinner-border-sm text-white me-1"
                            role="status"
                            aria-hidden="true"
                          />
                        )}
                        {t("logIn")}
                      </Button>
                    </div>
                  </Form>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default MfaSetup;

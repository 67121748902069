import React, {
  createContext,
  useState,
  useContext,
  Dispatch,
  SetStateAction,
  useMemo,
} from 'react';

export interface InvestorRegistrationContext {
  id: string;
  address: string;
  cellNumber: string;
  email: string;
  lastName: string;
  motherName: string;
  nationality: string;
  placeOfBirth: string;
  profession: string;
  sexo: string;
  userValidateLogin: string;
}

interface InvestorContextData {
  investor: InvestorRegistrationContext;
  setInvestor: Dispatch<SetStateAction<InvestorRegistrationContext>>;
  hasInvestor: boolean;
}

const InvestorContext = createContext<InvestorContextData>(
  {} as InvestorContextData,
);

export const InvestorProvider: React.FC = ({ children }) => {
  const [investor, setInvestor] = useState<InvestorRegistrationContext>(null);
  const investorProviderValue = useMemo(
    () => ({ investor, setInvestor, hasInvestor: !!investor }),
    [investor, setInvestor],
  );

  return (
    <InvestorContext.Provider value={investorProviderValue}>
      {children}
    </InvestorContext.Provider>
  );
};

export function useInvestor(): InvestorContextData {
  const context = useContext(InvestorContext);

  if (!context) {
    throw new Error('useInvestor must be used within an InvestorProvider');
  }

  return context;
}

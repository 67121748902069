import React, { useEffect, useState } from "react";
import { Button, Row, Col, Card, CardBody, Badge } from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import format from "date-fns/format";

import DataTable from "../../components/DataTable";
import SEO from "../../components/SEO";
import SearchBar from "../../components/SearchBar";
import StatusBadge from "../../components/StatusBadge";
import { useBreadcrumb } from "../../hooks/useBreadcrumb";

interface Recommendation {
  id: number;
  date: Date;
  title: string;
  recommendedValue: number;
  totalRecipients: number;
  state: string;
}

const Recommendations: React.FC = () => {
  const { setBreadcrumbItems } = useBreadcrumb();
  const { t } = useTranslation("recommendations");
  const [publish, setPublish] = useState(false);
  const [recsData, setRecsData] = useState<Recommendation[] | null>(null);
  const [selectedRecs, setSelectedRecs] = useState<Recommendation[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const hasPermissionToPublish = true;
  const hasPermissionToCreate = true;

  useEffect(() => {
    setBreadcrumbItems({
      title: "manageRecommendations",
      items: [
        { title: "home", link: "/dashboard" },
        { title: "recommendations", link: "/recommendations" },
      ],
    });
  }, [setBreadcrumbItems]);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setRecsData(
        [
          {
            id: 1,
            date: "2021-02-08T23:45:29Z",
            title: "id",
            recommendedValue: 15975,
            totalRecipients: 164,
            state: "APPROVED",
          },
          {
            id: 2,
            date: "2021-02-18T15:21:58Z",
            title: "habitasse",
            recommendedValue: 78655,
            totalRecipients: 158,
            state: "COMPLETED",
          },
          {
            id: 3,
            date: "2021-09-09T08:56:26Z",
            title: "dolor",
            recommendedValue: 61494,
            totalRecipients: 206,
            state: "APPROVED",
          },
          {
            id: 4,
            date: "2021-11-18T20:45:36Z",
            title: "ac",
            recommendedValue: 80685,
            totalRecipients: 221,
            state: "REQUESTED",
          },
          {
            id: 5,
            date: "2021-09-05T02:07:39Z",
            title: "id",
            recommendedValue: 15174,
            totalRecipients: 152,
            state: "APPROVED",
          },
          {
            id: 6,
            date: "2021-09-19T22:28:29Z",
            title: "semper",
            recommendedValue: 76782,
            totalRecipients: 258,
            state: "REQUESTED",
          },
          {
            id: 7,
            date: "2021-09-19T15:13:27Z",
            title: "aliquam",
            recommendedValue: 12817,
            totalRecipients: 280,
            state: "REQUESTED",
          },
          {
            id: 8,
            date: "2021-01-13T04:56:31Z",
            title: "ut",
            recommendedValue: 96933,
            totalRecipients: 158,
            state: "COMPLETED",
          },
          {
            id: 9,
            date: "2021-03-01T04:14:01Z",
            title: "mi",
            recommendedValue: 21588,
            totalRecipients: 145,
            state: "APPROVED",
          },
          {
            id: 10,
            date: "2021-08-27T22:51:30Z",
            title: "amet",
            recommendedValue: 50623,
            totalRecipients: 291,
            state: "REQUESTED",
          },
        ].map((r) => ({
          ...r,
          date: new Date(r.date),
        }))
      );
      setLoading(false);
    }, 1000);
  }, []);

  return (
    <>
      <SEO title={t("recommendations")} shouldIndexPage={false} />

      {publish ? (
        <SweetAlert
          warning
          showCancel
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          title={t("doYouWantToPublishTheSelectedRecs")}
          onConfirm={() => ({})} // publishRecommendations
          onCancel={() => {
            setPublish(false);
          }}
        />
      ) : null}

      <Row>
        <Col xs="12" className="mb-3">
          <div className="d-flex flex-md-row justify-content-md-between flex-column align-items-center">
            <div>
              {hasPermissionToCreate && (
                <Link to="/recommendations/recommendation">
                  <Button
                    id="addRecommendation"
                    size="md"
                    color="primary"
                    className="waves-effect waves-light me-3"
                  >
                    <i className="mdi mdi-plus me-2" />
                    {t("newRecommendation")}
                  </Button>
                </Link>
              )}
              {hasPermissionToPublish && (
                <Button
                  id="publishRecommendation"
                  size="md"
                  color="primary"
                  disabled={!selectedRecs.length}
                  className="waves-effect waves-light"
                  onClick={() => {
                    setPublish(true);
                  }}
                >
                  {t("publish")}
                </Button>
              )}
            </div>

            <SearchBar
              fields={[
                {
                  key: "recommendationTitle",
                  label: t("recommendationTitle"),
                  variant: "text",
                },
              ]}
              onSearch={() => ({})}
            />
          </div>
        </Col>
        <Col xs="12">
          <Card>
            <CardBody>
              <DataTable
                columns={[
                  {
                    name: "ID",
                    cell: ({ id }) => (
                      <Link to={`/recommendations/${id}`}>
                        <Button
                          key={id}
                          id={`recommendation_${id}`}
                          type="button"
                          color="info"
                          size="sm"
                          outline
                          className="waves-effect waves-light"
                        >
                          {id}
                          <i className="mdi mdi-square-edit-outline ms-1" />
                        </Button>
                      </Link>
                    ),
                    button: true,
                  },
                  {
                    name: t("date"),
                    center: true,
                    sortable: true,
                    cell: ({ date }) => format(date, "dd/MM/yyyy"),
                  },
                  {
                    name: t("recommendationTitle"),
                    selector: (row) => row.title,
                    center: true,
                    sortable: true,
                  },
                  {
                    name: t("recommendedValue"),
                    selector: (row) => row.recommendedValue,
                    center: true,
                    sortable: true,
                  },
                  {
                    name: t("totalRecipients"),
                    selector: (row) => row.totalRecipients,
                    center: true,
                    sortable: true,
                  },
                  {
                    name: t("status"),
                    selector: (row) => row.state,
                    center: true,
                    sortable: true,
                    cell: ({ state }) => <StatusBadge state={state} />,
                  },
                ]}
                data={(recsData || []).map((r) => ({
                  ...r,
                  recommendedValue: new Intl.NumberFormat("pt-BR", {
                    style: "currency",
                    currency: "BRL",
                  }).format(r.recommendedValue),
                }))}
                selectableRows={hasPermissionToPublish}
                disabledCondition={(row) => row.state !== "SUBMITTED"}
                onChange={(e) => setSelectedRecs(e.selectedRows)}
                progressPending={loading}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Recommendations;

import React, { useState } from 'react';
import { Card, CardBody, Input, FormGroup, Label, Row, Col } from 'reactstrap';
import { useTranslation } from 'react-i18next';

import DataTable from '../../DataTable';

const Resume: React.FC = () => {
  const { t } = useTranslation('recommendations');
  const [resumeList] = useState(
    [1, 2, 3, 4].map(i => ({
      id: i,
      ticker: '123',
      companyName: 'Nome da Empresa',
      assetTerm: '26/10/2021',
      valueRecommended: 'R$ 5.000,00',
      percentageRecommended: '3%',
    })),
  );

  return (
    <Card>
      <CardBody>
        <h4 className="card-title mb-4 text-uppercase">
          {t('recommendationResume')}
        </h4>

        <DataTable
          columns={[
            {
              name: 'Ticker',
              selector: row => row.ticker,
              center: true,
              sortable: true,
            },
            {
              name: t('companyName'),
              selector: row => row.companyName,
              center: true,
              sortable: true,
            },
            {
              name: t('assetTerm'),
              selector: row => row.assetTerm,
              center: true,
              sortable: true,
            },
            {
              name: t('recommendedValue'),
              selector: row => row.valueRecommended,
              center: true,
              sortable: true,
            },
            {
              name: t('recommendedPercentual'),
              selector: row => row.percentageRecommended,
              center: true,
              sortable: true,
            },
          ]}
          data={resumeList}
        />

        <Row>
          <Col xs="4">
            <FormGroup>
              <Label>{t('impactedNum')}</Label>
              <Input value="4" readOnly disabled />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col xs="4">
            <FormGroup check>
              <Input type="checkbox" />
              <Label>{t('sendByEmail')}</Label>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col xs="4">
            <FormGroup check>
              <Input type="checkbox" />
              <Label>{t('sendByNotification')}</Label>
            </FormGroup>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export default Resume;

// Action Types
export enum CommissionTypes {
  CREATE_COMMISSION_REQUEST = '@user/CREATE_COMMISSION_REQUEST',
  CREATE_COMMISSION_SUCCESS = '@user/CREATE_COMMISSION_SUCCESS',

  UPDATE_COMMISSION_REQUEST = '@user/UPDATE_COMMISSION_REQUEST',
  UPDATE_COMMISSION_SUCCESS = '@user/UPDATE_COMMISSION_SUCCESS',
}

interface BrokerPercentage {
  id?: number;
  broker: any;
  percentage: number;
}

interface OfferDetails {
  id: number;
  ticker: string;
  term: string;
  volumeOffered: number;
  saleValue: number;
  listedCompany: {
    tradingName: string;
    name: string;
    cnpj: string;
  };
  state: string;
}

interface CommissionDetail {
  broker: {
    tradingName: string;
    name: string;
    cnpj: string;
  };
  percentage: number;
}

export interface Commission {
  offer: OfferDetails;
  commissions: CommissionDetail[];
  sumOfCommissions: number;
  amountOfReceivers: number;
}

export interface CommissionBody {
  id?: number;
  offer: number;
  values: BrokerPercentage[];
}

// State Type
export interface CommissionState {
  readonly loading: boolean;
}

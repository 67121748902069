import React, { useEffect, useState, useRef } from "react";
import { Card, CardBody, Button } from "reactstrap";
import { Form } from "@unform/web";
import { SubmitHandler, FormHandles } from "@unform/core";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

import Tabs from "../../components/Tabs";
import ProfileTab from "../../components/listingCompany/create/ProfileTab";
import FinancialStatementTab from "../../components/listingCompany/create/FinancialStatementTab";
import FinancialIndicatorsTab from "../../components/listingCompany/create/FinancialIndicatorsTab";
import RatingHistoryTab from "../../components/listingCompany/create/RatingHistoryTab";
import AuditorsOpinionTab from "../../components/listingCompany/create/AuditorsOpinionTab";
import SEO from "../../components/SEO";
import { useBreadcrumb } from "../../hooks/useBreadcrumb";
import { useEditListing } from "../../hooks/useEditListing";
import {
  Member,
  ServiceProvider,
  Indicator,
  FinancialStatements,
  Attachment,
} from "../../store/modules/listing/types";
import {
  createListingRequest,
  updateListingRequest,
} from "../../store/modules/listing/actions";
import { ApplicationState } from "../../store";
import { addMessage } from "../../store/modules/toast/actions";
import usePermissions from "../../hooks/usePermissions";
import { dataContext, permission } from "../../utils/permissionEnums";
import ServiceProviderAndMembers from "../../components/listingCompany/create/ServiceProviderAndMembers";
import { isValidCNPJ } from "utils/validateDocuments";

interface LabelValue {
  value: string;
  label: string;
}

const CreateEditListing: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation("listings");

  const formRef = useRef<FormHandles>();
  const formRefAuditor = useRef<FormHandles>();

  const profileId = useSelector<ApplicationState, number>(
    (state) => state.user.profile.id
  );
  const { setBreadcrumbItems } = useBreadcrumb();
  const { hasListing, listing, setListing } = useEditListing();

  const [members, setMembers] = useState<Member[]>(listing?.members || []);
  const [serviceProviders, setServiceProviders] = useState<ServiceProvider[]>(
    listing?.serviceProviders || []
  );
  const [rating, setRating] = useState<LabelValue[]>([]);
  const [financialIndicators, setFinancialIndicators] = useState<Indicator[]>(
    listing?.performanceIndicators || []
  );

  const hasPermissionToCreateRating = usePermissions(
    dataContext.RATING_LISTED_COMPANY,
    permission.CREATE
  );

  const [attachments, setAttachments] = useState<Attachment[]>(
    hasListing ? listing.attachments : []
  );

  const isNewListing = !listing;

  const handleSubmit: SubmitHandler = async (data) => {
    try {
      const newData = {
        ...data,
        ...formRefAuditor.current?.getData(), // edit only
      };

      const schema = Yup.object().shape({
        advisor: Yup.string().required(t("requiredField")),
        address: Yup.object().shape({
          zip: Yup.string().test(
            "len",
            t("invalidValue"),
            ({ length }) => length === 0 || length === 8
          ),
        }),
        tradingName: Yup.string().required(t("requiredField")),
        company: Yup.object().shape({
          name: Yup.string().required(t("requiredField")),
          cnpj: Yup.string()
            .nullable()
            .required(t("requiredField"))
            .test({
              message: t("invalidValue"),
              test: (value) => {
                if (value) {
                  return isValidCNPJ(value);
                }

                return false;
              },
            }),
        }),
        ticker: Yup.string().required(t("requiredField")),
        telephone: Yup.string().test({
          message: t("invalidValue"),
          test: (value) => {
            if(value != ""){
              if(value.length < 12){
                return false;
              }
            }
            return true;
        }}),
        email: Yup.string().required(t("requiredField")),
      });

      await schema.validate(newData, {
        abortEarly: false,
      });

      const lastRating = listing?.ratingHistoric?.[listing.ratingHistoric.length - 1]?.rating?.id;

      const newObj = {
        ...newData,
        state: listing?.state ? listing?.state : "DRAFT",
        parentId: listing?.parentId,
        company: {
          ...listing?.company,
          ...newData.company,
          companyType: "LISTED_COMPANY",
        },
        address: isEmpty(newData.address) ? null: {
          ...newData.address,
          id: listing?.address?.id,
        },
        members,
        serviceProviders,
        performanceIndicators: financialIndicators,
        rating: listing?.rating,
        attachments: attachments,
        segment: newData.segment === undefined || newData.segment === "" ? null : {
          id: newData.segment
        },
        quantityEmployeeId: newData.quantityEmployeeId === undefined || newData.quantityEmployeeId === "" ? null: {
          id: newData.quantityEmployeeId
        },
        revenueSize: newData.revenueSize === undefined || newData.revenueSize === "" ? null : {
          id: newData.revenueSize
        },
        auditReport: await getReport(newData.auditReport),
        ...(rating.length && {
          ratingHistoric: [
            ...(listing?.ratingHistoric || []),
            ...rating.map((e) => {
              if (e.value !== lastRating) {
                return {
                  listedCompanyId: listing?.id || undefined,
                  rating: {
                    id: e.value,
                    name: e.label,
                  },
                  responsibleId: profileId,
                };
              }
              return null;
            }).filter(e => e !== null),
          ],
        }),
      };

      if (hasListing) {
        dispatch(updateListingRequest({ id: listing.id, ...newObj }, navigate));
      } else {
        dispatch(createListingRequest(newObj, navigate));
      }
    } catch (err) {
      const validationErrors = {};

      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
        dispatch(
          addMessage({
            title: t("attention"),
            description: t("checkFields"),
            type: "info",
          })
        );
        formRef.current
          .getFieldRef(Object.getOwnPropertyNames(validationErrors)[0])
          .focus();
      }
    }
  };

  async function getReport(dataAuditReport) {
    if (dataAuditReport) {
      const base64 = await fileToBase64(dataAuditReport);
      return base64;
    }
    if (hasListing) return listing?.auditReport;
    return "";
  }

  function isEmpty(obj) {
    return Object.values(obj).every(
      (x) => x === null || x === "" || x === undefined
    );
  }

  function handleCancel() {
    setListing(null);
    navigate(-1);
  }

  function fileToBase64(file): Promise<string> {
    const reader = new FileReader();
    reader.readAsDataURL(file);

    return new Promise((resolve) => {
      reader.onloadend = () => {
        resolve(reader.result.toString());
      };
    });
  }

  useEffect(() => {
    const errors = formRef.current?.getErrors();
    const auditorErrors = formRefAuditor.current?.getErrors();

    if (errors && Object.keys(errors).length > 0) {
      formRef.current.submitForm();
    }

    if (auditorErrors && Object.keys(auditorErrors).length > 0) {
      formRefAuditor.current.submitForm();
    }
  }, [i18next.language]);

  useEffect(() => {
    setBreadcrumbItems({
      title: hasListing ? "editListing" : "registerNewListing",
      items: [
        { title: "home", link: "/dashboard" },
        { title: "listings", link: "/listings" },
        { title: hasListing ? "editListing" : "registerNewListing", link: "" },
      ],
    });
  }, [setBreadcrumbItems, hasListing]);

  return (
    <>
      <SEO
        title={
          hasListing
            ? t("editListing", { ns: "breadcrumb" })
            : t("registerNewListing", { ns: "breadcrumb" })
        }
        shouldIndexPage={false}
      />

      <Card>
        <CardBody>
          <Tabs
            tabs={[
              {
                id: 1,
                title: t("companyProfile"),
                icon: "fas fa-building",
                content: (
                  <>
                    <Form
                      ref={formRef}
                      onSubmit={handleSubmit}
                      initialData={hasListing ? listing : {}}
                    >
                    <ProfileTab formRef={formRef} isNewListing={isNewListing} />
                    </Form>
                    <ServiceProviderAndMembers
                      members={members}
                      setMembers={setMembers}
                      serviceProviders={serviceProviders}
                      setServiceProviders={setServiceProviders}
                    />
                  </>
                ),
              },
              {
                id: 2,
                title: t("financialStatement"),
                icon: "fas fa-chart-line",
                content: (
                  <FinancialStatementTab
                    attachments={attachments}
                    setAttachments={setAttachments}
                  />
                ),
              },
              // {
              //   id: 3,
              //   title: t("financialIndicators"),
              //   icon: "fas fa-chart-line",
              //   content: (
              //     <FinancialIndicatorsTab
              //       financialIndicators={financialIndicators}
              //       setFinancialIndicators={setFinancialIndicators}
              //     />
              //   ),
              // },
              hasPermissionToCreateRating && {
                id: 4,
                title: t("rating"),
                icon: "fas fa-star",
                content: (
                  <RatingHistoryTab rating={rating} setRating={setRating} />
                ),
              },
              // hasListing && {
              //   id: 5,
              //   title: t("auditorsOpinion"),
              //   icon: "fas fa-comment-dots",
              //   content: (
              //     <Form
              //       ref={formRefAuditor}
              //       onSubmit={() => ({})}
              //       initialData={listing}
              //     >
              //       <AuditorsOpinionTab />
              //     </Form>
              //   ),
              // },
            ]}
          />
        </CardBody>
      </Card>

      <div className="mb-5 text-end">
        <div>
          <Button
            className="waves-effect waves-light me-3"
            color="info"
            size="lg"
            onClick={() => formRef.current.submitForm()}
          >
            {t("save")}
          </Button>
          <Button
            className="waves-effect waves-light"
            size="lg"
            color="secondary"
            onClick={handleCancel}
            outline
          >
            {t("cancel")}
          </Button>
        </div>
      </div>
    </>
  );
};

export default CreateEditListing;

import { Dispatch, SetStateAction, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { LabelValue } from "store/modules/naturalPerson/types";
import { FormHandles, SubmitHandler } from "@unform/core";
import * as Yup from "yup";
import { addMessage } from "store/modules/toast/actions";
import i18next from "i18next";
import { Button, Col, Row } from "reactstrap";
import Input from "../FormFields/Input";
import { Form } from "@unform/web";
import Select from "components/FormFields/Select";
import DataTable from "components/DataTable";

interface Props {
    currentDistributors: any[];
    setCurrentDistributors: Dispatch<SetStateAction<any[]>>;
    listDistributors: LabelValue[];
    setListDistributors: Dispatch<SetStateAction<LabelValue[]>>;
    lockListDistributors: any[];
}

const NaturalPersonDistributors: React.FC<Props> = ({
    currentDistributors,
    setCurrentDistributors,
    listDistributors,
    setListDistributors,
    lockListDistributors
  }) => {
    const { t } = useTranslation("persons");
    const dispatch = useDispatch();
    const distributorRef = useRef<FormHandles>(null);
    
    const handleDistributor: SubmitHandler<any> = async (data, { reset }) => {
        try {
          distributorRef.current.setErrors({});
    
          const formatted = {
            id: data.distributor.id,
            label: data.distributor.label,
            removable: data.distributor.removable
          }
    
          setCurrentDistributors((oldState) => [...oldState, formatted]);
          setListDistributors((oldState) => [...oldState.filter(f => f.value.id !== formatted.id)]);
    
          reset();
        } catch (err) {
          const validationErrors = {};
    
          if (err instanceof Yup.ValidationError) {
            err.inner.forEach((error) => {
              validationErrors[error.path] = error.message;
            });
            distributorRef.current.setErrors(validationErrors);
            dispatch(
              addMessage({
                title: t("attention"),
                description: t("checkFields"),
                type: "info",
              })
            );
            distributorRef.current
              .getFieldRef(Object.getOwnPropertyNames(validationErrors)[0])
              .focus();
          }
        }
      };

      const handleRemove: any = async (rowIndex) => {
        try {
          const newCurrentDistributors = currentDistributors.filter((e, i) => i !== rowIndex);
          setCurrentDistributors(newCurrentDistributors);
    
          const newListDistributors = lockListDistributors.filter(f => !newCurrentDistributors.some((c: any) => c.id == f.value.id));
          setListDistributors(newListDistributors);
        } catch (err) {
          console.error(err)
        }
      };

      useEffect(() => {
        const errors = distributorRef.current?.getErrors();
    
        if (errors && Object.keys(errors).length > 0) {
          distributorRef.current.submitForm();
        }
    
      }, [i18next.language]);

      return (
        <>
          <h4 className="card-title my-4 text-uppercase">
            {t("association")}
          </h4>
          <Form ref={distributorRef} onSubmit={handleDistributor}>
            <div className="p-3 border rounded border-secondary mb-4">
              <div className="d-none">
                <Input name="id" />
              </div>
              <Row>
                <Col xs="12" md="4">
                  <Select
                    label={t("distributors")}
                    name="distributor"
                    options={listDistributors}
                    placeholder={t("selectOneOrMore")}
                  />
                </Col>
                <Col xs="12" md="12">
                  <Button
                    className="waves-effect waves-light"
                    color="primary"
                    type="submit"
                  >
                    <i className="mdi mdi-plus me-2" />
                    {t("add")}
                  </Button>
                </Col>
              </Row>
            </div>
          </Form>
          <DataTable
            noTableHead
            columns={[
              {
                name: t("name"),
                selector: (row) => row.label,
                center: true,
                sortable: true,
              },
              {
                name: "",
                button: true,
                cell: (row, rowIndex) => {
                  if (row.removable) {
                    return <Button
                      className="waves-effect waves-light m-1"
                      color="danger"
                      size="sm"
                      onClick={() => {handleRemove(rowIndex)}}
                    >
                      <i className="fas fa-trash-alt" />
                    </Button>
                  }
                }
              },
            ]}
            data={currentDistributors}
            pagination={currentDistributors.length > 10}
          />
        </>
      );
  }
  

export default NaturalPersonDistributors;
import React from "react";
import { Row, Col, Card, CardBody } from "reactstrap";
import { useTranslation } from "react-i18next";
import { FormHandles } from "@unform/core";

import Input from "../../FormFields/Input";
import InputMask from "../../FormFields/InputMask";
import AddressCard from "../../AddressCard";
import Select from "../../../components/FormFields/Select";
import { LabelValue } from "store/modules/company/types";
import { useEditCompany } from "hooks/useEditCompany";

interface ProfileTabProps {
  formRef: React.MutableRefObject<FormHandles>;
  distributors: LabelValue[];
  isReadOnly?: boolean;
}

const CompaniesTab: React.FC<ProfileTabProps> = ({
  formRef,
  distributors,
  isReadOnly = false,
}) => {
  const { t } = useTranslation("companies");
  const {hasCompany} = useEditCompany();

  return (
    <>
      <h4 className="card-title mb-4 text-uppercase">{t("generalData")}</h4>
      <Row className="mb-4">
        <Col xs="12" md="4">
          <InputMask
            label="CNPJ"
            name="company.cnpj"
            format="##.###.###/####-##"
            disabled={hasCompany || isReadOnly}
            required
          />
        </Col>
        <Col xs="12" md="4">
          <Input
            label={t("tradingName")}
            name="tradingName"
            disabled={isReadOnly}
            required
          />
        </Col>
        <Col xs="12" md="4">
          <Input
            label={t("companyName")}
            name="company.companyName"
            disabled={isReadOnly}
            required
          />
        </Col>
      </Row>

      <AddressCard formRef={formRef} showCard={false} disabled={isReadOnly} />

      <Card style={{ display: "contents" }}>
        <CardBody style={{ padding: "0px" }}>
          <h4 className="card-title my-4 text-uppercase">
            {t("contactInformation")}
          </h4>
          <Row>
            <Col xs="12" md="4">
              <Input
                label="Email"
                name="email"
                type="email"
                placeholder={t("enterEmail")}
                disabled={isReadOnly}
                required
              />
            </Col>
            <Col xs="12" md="4">
              <InputMask
                label={t("telephone")}
                name="telephone"
                format="+## (##) ####-####"
                placeholder={t("enterTelephone")}
                disabled={isReadOnly}
              />
            </Col>

            <Col xs="12" md="4">
              <InputMask
                label={t("cellphone")}
                name="cellphone"
                format="+## (##) #####-####"
                placeholder={t("enterphone")}
                disabled={isReadOnly}
              />
            </Col>
            <Col xs="12" md="4">
              <Input
                label="Site"
                name="website"
                type="text"
                placeholder={t("enterSite")}
                disabled={isReadOnly}
              />
            </Col>
          </Row>
        </CardBody>
      </Card>

      {/* <Card style={{ display: "contents" }}>
        <CardBody style={{ padding: "0px" }}>
          <h4 className="card-title my-4 text-uppercase">
            {t("association")}
          </h4>
          <Row>
            <Col xs="12" md="4">
              <Select
                label={t("distributors")}
                name="distributors"
                options={distributors}
                placeholder={t("selectOneOrMore")}
                isMulti
                isDisabled={isReadOnly}
              />
            </Col>
          </Row>
        </CardBody>
      </Card> */}
    </>
  );
};

export default CompaniesTab;

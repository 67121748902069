import React from "react";
import { Routes, Navigate, Route } from "react-router-dom";

import IssueReservations from "pages/offers/issueReservation";
import RouteWrapper from "./RouteWrapper";
import VerticalLayout from "../components/Layout/VerticalLayout";

import usePermissions from "../hooks/usePermissions";
import { dataContext, permission } from "../utils/permissionEnums";

import Dashboard from "../pages/dashboard";
import Profile from "../pages/profile";

import Login from "../pages/auth/login";
import ForgetPassword from "../pages/auth/forget-password";
import NewPassword from "../pages/auth/new-password";
import ResetPassword from "../pages/auth/reset-password";
import Logout from "../pages/auth/logout";

import Users from "../pages/users";
import CreateEditUser from "../pages/users/user";
import UserDetails from "../pages/users/userDetails";

import Advisors from "../pages/advisors";
import CreateEditAdvisor from "../pages/advisors/advisor";
import AdvisorDetails from "../pages/advisors/advisorDetails";

import Listings from "../pages/listings";
import CreateEditListing from "../pages/listings/listing";
import ListingDetails from "../pages/listings/listingDetails";

import CompanyList from "../pages/companies";
import CreateEditCompany from "../pages/companies/company";
import CompanyDetails from "../pages/companies/companyDetails";

import Offers from "../pages/offers";
import CreateEditOffer from "../pages/offers/offer";
import OfferDetails from "../pages/offers/offerDetails";

import Orders from "../pages/orders";
import CreateOrder from "../pages/orders/order";

import Brokers from "../pages/brokers";
import CreateEditBroker from "../pages/brokers/broker";
import BrokerDetails from "../pages/brokers/brokerDetails";

import Commissions from "../pages/commissions";
import CreateEditCommission from "../pages/commissions/commission";
import CommissionDetails from "../pages/commissions/commissionDetails";

import AccountHolders from "../pages/accountHolders";

import Reservations from "../pages/reservations";
import ReservationDetails from "../pages/reservations/reservationDetails";

import Investors from "../pages/investors";
import InvestorDetails from "../pages/investors/investorDetails";

import Recommendations from "../pages/recommendations";
import CreateEditRecommendation from "../pages/recommendations/recommendation";
import RecommendationDetails from "../pages/recommendations/recommendationDetails";

import CurrentAccount from "../pages/currentAccount";

import Approvals from "../pages/approvals";
import ApprovalsDetails from "../pages/approvals/approvalsDetails";
import OrderDetails from "pages/orders/orderDetails";

import NaturalPersonList from "pages/naturalPerson";
import CreateEditNaturalPerson from "pages/naturalPerson/naturalPerson";
import NaturalPersonDetails from "pages/naturalPerson/naturalPersonDetails";

import MfaSetup from "../pages/auth/mfa/mfaSetup";
import MfaVerify from "../pages/auth/mfa/mfaVerify";
import MfaLogin from "pages/auth/mfa/mfaLogin";


const RoutesComponent = () => {
  const hasPermissionToReadAdvisor = usePermissions(
    dataContext.ADVISOR,
    permission.READ
  );
  const hasPermissionToReadUser = usePermissions(
    dataContext.USER,
    permission.READ
  );
  const hasPermissionToReadListing = usePermissions(
    dataContext.LISTED_COMPANY,
    permission.READ
  );
  const hasPermissionToReadBroker = usePermissions(
    dataContext.BROKER,
    permission.READ
  );
  const hasPermissionToReadOffer = usePermissions(
    dataContext.OFFER,
    permission.READ
  );
  const hasPermissionToReadCommission = usePermissions(
    dataContext.COMMISSION,
    permission.READ
  );
  const hasPermissionToReadReservation = usePermissions(
    dataContext.RESERVATION,
    permission.READ
  );
  const hasPermissionToReadInvestors = usePermissions(
    dataContext.INVESTOR_USER,
    permission.READ
  );
  const hasPermissionToReadOrder = usePermissions(
    dataContext.ORDER,
    permission.READ
  );

  return (
    <Routes>
      <Route path="auth">
        <Route path="login" element={<Login />} />
        <Route path="mfa-configure/:name" element={<MfaLogin />} />
        <Route path="mfa-setup/:name" element={<MfaSetup />} />
        <Route path="mfa-verify" element={<MfaVerify />} />
        <Route path="forget-password" element={<ForgetPassword />} />
        <Route path="new-password" element={<NewPassword />} />
        <Route path="reset-password" element={<ResetPassword />} />
        <Route path="logout" element={<Logout />} />
      </Route>

      <Route element={<VerticalLayout />}>
        <Route path="/" element={<Navigate to="/auth/login" />} />
        <Route
          path="dashboard"
          element={
            <RouteWrapper>
              <Dashboard />
            </RouteWrapper>
          }
        />
        <Route
          path="profile"
          element={
            <RouteWrapper>
              <Profile />
            </RouteWrapper>
          }
        />

        <Route
          path="users"
          element={
            <RouteWrapper hasPermission={hasPermissionToReadUser}>
              <Users />
            </RouteWrapper>
          }
        />
        <Route
          path="users/user"
          element={
            <RouteWrapper hasPermission={hasPermissionToReadUser}>
              <CreateEditUser />
            </RouteWrapper>
          }
        />
        <Route
          path="users/:id"
          element={
            <RouteWrapper hasPermission={hasPermissionToReadUser}>
              <UserDetails />
            </RouteWrapper>
          }
        />

        <Route
          path="advisors"
          element={
            <RouteWrapper hasPermission={hasPermissionToReadAdvisor}>
              <Advisors />
            </RouteWrapper>
          }
        />
        <Route
          path="advisors/advisor"
          element={
            <RouteWrapper hasPermission={hasPermissionToReadAdvisor}>
              <CreateEditAdvisor />
            </RouteWrapper>
          }
        />
        <Route
          path="advisors/:id"
          element={
            <RouteWrapper hasPermission={hasPermissionToReadAdvisor}>
              <AdvisorDetails />
            </RouteWrapper>
          }
        />

        <Route
          path="companies"
          element={
            <RouteWrapper hasPermission={hasPermissionToReadListing}>
              <CompanyList />
            </RouteWrapper>
          }
        />
        <Route
          path="companies/company"
          element={
            <RouteWrapper hasPermission={hasPermissionToReadListing}>
              <CreateEditCompany />
            </RouteWrapper>
          }
        />
        <Route
          path="companies/:id"
          element={
            <RouteWrapper hasPermission={hasPermissionToReadListing}>
              <CompanyDetails />
            </RouteWrapper>
          }
        />

        <Route
          path="persons"
          element={
            <RouteWrapper hasPermission={hasPermissionToReadListing}>
              <NaturalPersonList />
            </RouteWrapper>
          }
        />
        <Route
          path="naturalPerson/person"
          element={
            <RouteWrapper hasPermission={hasPermissionToReadListing}>
              <CreateEditNaturalPerson />
            </RouteWrapper>
          }
        />
        <Route
          path="naturalPerson/:id"
          element={
            <RouteWrapper hasPermission={hasPermissionToReadListing}>
              <NaturalPersonDetails />
            </RouteWrapper>
          }
        />


        <Route
          path="listings"
          element={
            <RouteWrapper hasPermission={hasPermissionToReadListing}>
              <Listings />
            </RouteWrapper>
          }
        />
        <Route
          path="listings/listing"
          element={
            <RouteWrapper hasPermission={hasPermissionToReadListing}>
              <CreateEditListing />
            </RouteWrapper>
          }
        />
        <Route
          path="listings/:id"
          element={
            <RouteWrapper hasPermission={hasPermissionToReadListing}>
              <ListingDetails />
            </RouteWrapper>
          }
        />

        <Route
          path="offers"
          element={
            <RouteWrapper hasPermission={hasPermissionToReadOffer}>
              <Offers />
            </RouteWrapper>
          }
        />
        <Route
          path="offers/offer"
          element={
            <RouteWrapper hasPermission={hasPermissionToReadOffer}>
              <CreateEditOffer />
            </RouteWrapper>
          }
        />
        <Route
          path="offers/offer/:id"
          element={
            <RouteWrapper hasPermission={hasPermissionToReadOffer}>
              <CreateEditOffer />
            </RouteWrapper>
          }
        />
        <Route
          path="offers/:id"
          element={
            <RouteWrapper hasPermission={hasPermissionToReadOffer}>
              <OfferDetails />
            </RouteWrapper>
          }
        />
        <Route
          path="offers/:id/reservations/"
          element={
            <RouteWrapper hasPermission={hasPermissionToReadOffer}>
              <IssueReservations />
            </RouteWrapper>
          }
        />
        <Route
          path="orders"
          element={
            <RouteWrapper hasPermission={hasPermissionToReadOrder}>
              <Orders />
            </RouteWrapper>
          }
        />
        <Route
          path="orders/order"
          element={
            <RouteWrapper hasPermission={hasPermissionToReadOrder}>
              <CreateOrder />
            </RouteWrapper>
          }
        />
        <Route
          path="orders/:id"
          element={
            <RouteWrapper hasPermission={hasPermissionToReadOrder}>
              <OrderDetails />
            </RouteWrapper>
          }
        />
        <Route
          path="brokers"
          element={
            <RouteWrapper hasPermission={hasPermissionToReadBroker}>
              <Brokers />
            </RouteWrapper>
          }
        />
        <Route
          path="brokers/broker"
          element={
            <RouteWrapper hasPermission={hasPermissionToReadBroker}>
              <CreateEditBroker />
            </RouteWrapper>
          }
        />
        <Route
          path="brokers/:id"
          element={
            <RouteWrapper hasPermission={hasPermissionToReadBroker}>
              <BrokerDetails />
            </RouteWrapper>
          }
        />

        <Route
          path="commissions"
          element={
            <RouteWrapper hasPermission={hasPermissionToReadCommission}>
              <Commissions />
            </RouteWrapper>
          }
        />
        <Route
          path="commissions/commission"
          element={
            <RouteWrapper hasPermission={hasPermissionToReadCommission}>
              <CreateEditCommission />
            </RouteWrapper>
          }
        />
        <Route
          path="commissions/:id"
          element={
            <RouteWrapper hasPermission={hasPermissionToReadCommission}>
              <CommissionDetails />
            </RouteWrapper>
          }
        />

        <Route
          path="accountHolders"
          element={
            <RouteWrapper>
              <AccountHolders />
            </RouteWrapper>
          }
        />

        <Route
          path="reservations"
          element={
            <RouteWrapper hasPermission={hasPermissionToReadReservation}>
              <Reservations />
            </RouteWrapper>
          }
        />
        <Route
          path="reservations/:id"
          element={
            <RouteWrapper hasPermission={hasPermissionToReadReservation}>
              <ReservationDetails />
            </RouteWrapper>
          }
        />

        <Route
          path="investors"
          element={
            <RouteWrapper hasPermission={hasPermissionToReadInvestors}>
              <Investors />
            </RouteWrapper>
          }
        />
        <Route
          path="investor/:id"
          element={
            <RouteWrapper hasPermission={hasPermissionToReadInvestors}>
              <InvestorDetails />
            </RouteWrapper>
          }
        />

        <Route
          path="recommendations"
          element={
            <RouteWrapper hasPermission>
              <Recommendations />
            </RouteWrapper>
          }
        />

        <Route
          path="recommendations/recommendation"
          element={
            <RouteWrapper hasPermission>
              <CreateEditRecommendation />
            </RouteWrapper>
          }
        />

        <Route
          path="recommendations/:id"
          element={
            <RouteWrapper hasPermission>
              <RecommendationDetails />
            </RouteWrapper>
          }
        />

        <Route
          path="currentAccount"
          element={
            <RouteWrapper hasPermission>
              <CurrentAccount />
            </RouteWrapper>
          }
        />

        <Route
          path="approvals"
          element={
            <RouteWrapper hasPermission>
              <Approvals />
            </RouteWrapper>
          }
        />

        <Route
          path="approvals/:id"
          element={
            <RouteWrapper hasPermission>
              <ApprovalsDetails />
            </RouteWrapper>
          }
        />

        <Route
          path="*"
          element={
            <main style={{ padding: "1rem" }}>
              <h1>404</h1>
            </main>
          }
        />
      </Route>
    </Routes>
  );
};

export default RoutesComponent;

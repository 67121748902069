import { Reducer } from 'redux';
import produce, { Draft } from 'immer';
import { AdvisorState, AdvisorTypes } from './types';

const INITIAL_STATE: AdvisorState = {
  loading: false,
};

const reducer: Reducer<AdvisorState> = (state = INITIAL_STATE, action) => {
  return produce(state, (draft: Draft<AdvisorState>) => {
    switch (action.type) {
      case AdvisorTypes.UPDATE_ADVISOR_SUCCESS: {
        draft.loading = false;
        break;
      }
      case AdvisorTypes.CREATE_ADVISOR_REQUEST: {
        draft.loading = true;
        break;
      }
      case AdvisorTypes.CREATE_ADVISOR_SUCCESS: {
        draft.loading = false;
        break;
      }
      default:
    }
  });
};

export default reducer;

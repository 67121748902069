import React, {
  ChangeEvent,
  useRef,
  useEffect,
  useCallback,
  useState,
} from "react";
import { useField } from "@unform/core";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

interface Props {
  name: string;
  label?: string;
}

type InputProps = JSX.IntrinsicElements["input"] & Props;
const ImageInput: React.FC<InputProps> = ({
  name,
  label,
  onFocus,
  onBlur,
  ...rest
}) => {
  const { fieldName, registerField, defaultValue, error } = useField(name);

  const inputRef = useRef<HTMLInputElement>(null);

  const [isFits, setIsFits] = useState(false);
  const [preview, setPreview] = useState(defaultValue);
  const [isFocused, setIsFocused] = useState(false);

  const getStyles = (pStyle) => {
    if (isFocused) {
      return {
        ...pStyle,
        borderColor: "#2684FF",
        boxShadow: "0 0 0 1px #2684FF",
      };
    }

    return { ...pStyle };
  };

  const handleFocus = (e) => {
    setIsFocused(true);

    if (onFocus) {
      onFocus(e);
    }
  };

  const handleBlur = (e) => {
    setIsFocused(false);

    if (onBlur) {
      onBlur(e);
    }
  };

  const handlePreview = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file) {
      setPreview(null);
    }
    try {
      const previewURL = URL.createObjectURL(file);
      setPreview(previewURL);
    } catch (err) {
      setPreview(undefined);
    }
  }, []);

  useEffect(() => {
    setPreview(defaultValue || "");
  }, [defaultValue]);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: "files[0]",
      clearValue(ref: HTMLInputElement) {
        ref.value = "";
        setPreview(null);
      },
      setValue(_: HTMLInputElement, value: string) {
        setPreview(value);
      },
    });
  }, [fieldName, registerField]);

  return (
    <>
      {isFits ? (
        <Lightbox
          mainSrc={preview}
          reactModalStyle={{
            overlay: {
              zIndex: 1002,
            },
          }}
          enableZoom={false}
          onCloseRequest={() => setIsFits(false)}
        />
      ) : null}
      <div className="mb-3">
        {label && <label htmlFor={fieldName}>{label}</label>}
        <input
          id={`${name}-input`}
          className={`filestyle form-control p-1 ${error && "is-invalid"}`}
          type="file"
          ref={inputRef}
          onChange={handlePreview}
          onFocus={handleFocus}
          onBlur={handleBlur}
          style={getStyles(rest.style)}
          {...rest}
        />
        {preview && (
          <img
            id={fieldName}
            className="img-fluid"
            src={preview}
            alt="Preview"
            role="none"
            onClick={() => setIsFits(true)}
            width="50"
          />
        )}
        {error && <span className="error">{error}</span>}
      </div>
    </>
  );
};
export default ImageInput;

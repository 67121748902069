import React from 'react';
import { useSelector } from 'react-redux';

// Import Scrollbar
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';

import { ApplicationState } from '../../../store';
import SidebarContent from './SidebarContent';

const Navbar: React.FC = () => {
  const leftSideBarType = useSelector<ApplicationState, string>(
    state => state.layout.leftSideBarType,
  );

  return (
    <div className="vertical-menu">
      <div data-simplebar className="h-100">
        {leftSideBarType !== 'condensed' && leftSideBarType !== 'icon' ? (
          <PerfectScrollbar>
            <SidebarContent />
          </PerfectScrollbar>
        ) : (
          <SidebarContent />
        )}
      </div>
    </div>
  );
};

export default Navbar;

import { action } from 'typesafe-actions';
import { NavigateFunction } from 'react-router-dom';
import { CommissionTypes, CommissionBody } from './types';

export const updateCommissionRequest = (
  commission: CommissionBody,
  navigate: NavigateFunction,
): TypeSafeAction<CommissionTypes> =>
  action(CommissionTypes.UPDATE_COMMISSION_REQUEST, { commission, navigate });

export const updateCommissionSuccess = (): TypeSafeAction<CommissionTypes> =>
  action(CommissionTypes.UPDATE_COMMISSION_SUCCESS);

export const createCommissionRequest = (
  commission: CommissionBody,
  navigate: NavigateFunction,
): TypeSafeAction<CommissionTypes> =>
  action(CommissionTypes.CREATE_COMMISSION_REQUEST, { commission, navigate });

export const createCommissionSuccess = (): TypeSafeAction<CommissionTypes> =>
  action(CommissionTypes.CREATE_COMMISSION_SUCCESS);

import React, { useState, useRef } from 'react';
import { Container, Row, Col, Card, CardBody, Alert, Button } from 'reactstrap';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import Input from '../../components/FormFields/Input';
import SEO from '../../components/SEO';
import api from '../../services/api';

import logoBlue from '../../assets/images/logo-blue.png';
import loginBG from "../../assets/images/login_bg.png";

const ForgetPassword: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const [isSent, setSend] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const { t } = useTranslation('auth');
  const navigate = useNavigate();

  async function handleSubmit(data) {
    setLoading(true);
    try {
      formRef.current.setErrors({});

      const schema = Yup.object().shape({
        email: Yup.string()
          .email(t('wrongFormat'))
          .required(t('requiredField')),
      });
      await schema.validate(data, {
        abortEarly: false,
      });

      await api.post(`/recovery-password-keycloak?email=${data.email}`, {
        redirectLink: `${window.location.hostname}/auth/reset-password`,
      });
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach(error => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }
    } finally {
      setLoading(false);
      setSend(true);
    }
  }

  return (
    <>
      <SEO title={t('forgotAccount')} />
      <div className="vh-100 d-flex justify-content-center align-items-center"
        style={{
          backgroundImage: `url(${loginBG})`,
          backgroundPosition: 'center bottom',
          backgroundRepeat: 'repeat-x',
          height: '100%',
          backgroundColor: '#fff'
        }}
      >
        <Container>
          <Row className="justify-content-center">
            <Col md="8" lg="6" xl="5">
              <Card className="overflow-hidden">
                <CardBody className="pt-0">
                  <h3 className="text-center mt-4">
                    <a href="/" className="logo logo-admin">
                      <img src={logoBlue} height="30" alt="logo" />
                    </a>
                  </h3>
                  <div className="p-3">
                    {isSent ? (
                      <>
                        <h4 className="text-muted font-size-18 mb-3 text-center">
                          {t('messageSent')}
                        </h4>
                        <Alert className="text-center" color="success">
                          {t('aMessageHasBeenForwardedToYourEmailIfValidUser')}
                        </Alert>
                      </>
                    ) : (
                      <>
                        <h4 className="text-muted font-size-18 mb-3 text-center">
                          {t('resetYourPassword')}
                        </h4>
                        <Alert className="text-center" color="info">
                          {t('enterYourRecoveryEmail')}
                        </Alert>
                      </>
                    )}

                    {/* {loginError && (
                      <Alert color="danger">
                        Esse email não está cadastrado em nossos registros
                      </Alert>
                    )} */}

                    {isSent ? (
                      <Row className="justify-content-center my-4 py-4">
                        <Link to="/auth/login">
                          <Button
                            id="login"
                            color="info"
                            className="w-md waves-effect waves-light"
                          >
                            {t('logIn')}
                          </Button>
                        </Link>
                      </Row>
                    ) : (
                      <Form
                        ref={formRef}
                        className="form-horizontal mt-4"
                        onSubmit={handleSubmit}
                      >
                        <Input
                          label="Email"
                          name="email"
                          placeholder={t('enterEmail')}
                        />

                        <div className="mb-3 row">
                          <div className="col-12 d-flex justify-content-between">
                            <Button
                              id="goBack"
                              color="info"
                              className="w-md waves-effect waves-light"
                              onClick={() => navigate(-1)}
                            >
                              {t('goBack')}
                            </Button>
                            <Button
                              id="next"
                              color="info"
                              disabled={loading}
                              type="submit"
                              className="w-md waves-effect waves-light"
                            >
                              {loading && (
                                <span
                                  className="spinner-border spinner-border-sm text-white me-1"
                                  role="status"
                                  aria-hidden="true"
                                />
                              )}
                              {t('next')}
                            </Button>
                          </div>
                        </div>
                      </Form>
                    )}
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default ForgetPassword;

import { TFunction } from "i18next";

export const ACCESS_TYPE_ADMIN_A0 = "A0";

export const ACCESS_TYPE_BROKER_B0 = "B0";
export const ACCESS_TYPE_BROKER_B1 = "B1";
export const ACCESS_TYPE_BROKER_B2 = "B2";

export const ACCESS_TYPE_ORIGINATOR_C0 = "C0";
export const ACCESS_TYPE_ORIGINATOR_C4 = "C4";

export const WARRANTY_TYPES = [
  "FIDEJUSSORIA_AVAL",
  "FIDEJUSSORIA_CAUCAO",
  "FIDEJUSSORIA_FIANÇA",
  "REAL_ALIENACAO_FIDUCIARIA",
  "REAL_ANTECIPACAO_RECEBIVEIS",
  "REAL_ATIVOS_FINANCEIROS",
  "REAL_APOLICE_SEGUROS",
  "REAL_FIANCA_BANCARIA",
  "REAL_HIPOTECA",
  "REAL_PENHOR_DE_EQUIPAMENTOS",
  "REAL_PENHOR_DE_MAQUINARIO_AGRICOLA",
  "REAL_PENHOR_DE_MAQUINARIO_INDUSTRIAL",
  "REAL_PENHOR_DE_REBANHO",
  "REAL_PENHOR_DE_SAFRA",
];

export const regexUpperChar = /[a-zA-Z]/;
export const regexNumber = /[0-9]/;
export const newRegex = /^[a-zA-Z0-9-]+$/;

export const tickerRegex = [
  newRegex,
  newRegex,
  newRegex,
  newRegex,
  newRegex,
  newRegex,
  newRegex,
  newRegex,
  newRegex,
];

export const getApprovalStatus = (t: TFunction) => {
  return [
    { label: t("APPROVED", { ns: "common" }), value: "APPROVED" },
    // { label: t("ISSUED", { ns: "common" }), value: "ISSUED" },
    { label: t("REJECTED", { ns: "common" }), value: "REJECTED" },
    {
      label: t("WAITING_APPROVAL", { ns: "common" }),
      value: "WAITING_APPROVAL",
    },
  ];
};

export const getApprovalRequestTypes = (t: TFunction) => {
  return [
    {
      label: t("OFFER_CONSOLIDATION", { ns: "common" }),
      value: "OFFER_CONSOLIDATION",
    },
    {
      label: t("OFFER_PUBLICATION", { ns: "common" }),
      value: "OFFER_PUBLICATION",
    },
  ];
};

export const getReservationStatus = (t: TFunction) => {
  return [
    {
      label: t("CONFIRMED", { ns: "common" }),
      value: "CONFIRMED",
    },
    {
      label: t("SUBMITTED", { ns: "common" }),
      value: "SUBMITTED",
    },
    {
      label: t("CANCELED", { ns: "common" }),
      value: "CANCELED",
    },
  ];
};

export const getOrderStatus = (t: TFunction) => {
  return [
    { label: t("CREATED", { ns: "common" }), value: "CREATED" },
    { label: t("EXECUTED", { ns: "common" }), value: "EXECUTED" },
    { label: t("EXPIRED", { ns: "common" }), value: "EXPIRED" },
  ];
};


export const getOptionsTrueOrFalse = (t: TFunction) => {
  return [
    { label: t("yes", { ns: "common" }), value: "YES" },
    { label: t("no", { ns: "common" }), value: "NO" },
  ];
};


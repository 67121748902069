import React, { useState, useEffect } from 'react';
import { Row, Col, Card, CardBody } from 'reactstrap';
import Format from 'date-fns/format';
import { useTranslation } from 'react-i18next';
import { Form } from '@unform/web';

import Input from '../../FormFields/Input';
import DatePicker from '../../FormFields/DatePicker';
import DataTable from '../../DataTable';
import api from '../../../services/api';
import { Listing } from '../../../store/modules/listing/types';

interface Log {
  action: string;
  dateTime: string;
  user: string;
}

interface PagedLogs {
  content: Log[];
  count: number;
  limit: number;
  page: number;
  sort: string;
}

interface TabProps {
  listing: Listing;
  tableRef: React.MutableRefObject<any>;
}

const CompanyProfileTab: React.FC<TabProps> = ({ listing, tableRef }) => {
  const { t } = useTranslation('listings');
  const [listingLogs, setListingLogs] = useState<Log[]>([]);

  useEffect(() => {
    const getListingLogs = async (id: number) => {
      try {
        const { data } = await api.get<PagedLogs>(
          `/listedcompanies/${id}/logs?page=1&limit=10&sort=time:asc`,
        );
        const logsResult = data.content.map(log => ({
          ...log,
          dateTime: Format(new Date(log.dateTime), 'dd/MM/yyyy HH:mm:ss OOOO'),
        }));
        setListingLogs(logsResult);
      } catch (error) {
        console.log(error);
      }
    };

    getListingLogs(listing.id);
  }, [listing.id]);

  return (
    <>
      <h4 className="card-title text-info mb-4 text-uppercase">
        {t('generalCompanyData')}
      </h4>

      <Form onSubmit={() => ({})} initialData={listing}>
        <Row>
          <Col xs="12" sm="4">
            <Input label={t('economicRating')} name="economicRating" disabled />
          </Col>
          <Col xs="12" sm="4">
            <Input label={t('mainActivity')} name="mainActivity" disabled />
          </Col>
          <Col xs="12" sm="4">
            <DatePicker
              label={t('foundation')}
              dateFormat="dd/MM/yyyy"
              name="foundation"
              disabled
            />
          </Col>
          <Col xs="12" sm="4">
            <Input
              label={t('employeeQuantity')}
              name="quantityEmployee"
              disabled
            />
          </Col>
          <Col xs="12" sm="4">
            <Input label={t('revenueSize')} name="size" disabled />
          </Col>
          <Col xs="12" sm="4">
            <Input
              label={t('businessMaturity')}
              name="businessMaturity"
              disabled
            />
          </Col>
        </Row>
      </Form>

      {listing.members.length > 0 && (
        <>
          <h4 className="card-title text-info mb-4 text-uppercase">
            {t('managementMembers')}
          </h4>
          <DataTable
            className="mb-2"
            highlightOnHover
            noTableHead
            pagination={false}
            data={listing.members}
            columns={[
              {
                name: '',
                selector: row => row.name,
                center: true,
              },
              {
                name: '',
                selector: row => row.role,
                center: true,
                sortable: true,
              },
              {
                name: '',
                selector: row => row.link,
                center: true,
                sortable: true,
              },
            ]}
          />
        </>
      )}

      {listing.serviceProviders.length > 0 && (
        <>
          <h4 className="card-title text-info mb-4 text-uppercase">
            {t('serviceProviders')}
          </h4>
          <Row>
            {listing.serviceProviders.map(
              ({
                id,
                companyName,
                serviceTitle,
                address,
                telephone,
                email,
                site,
              }) => (
                <Col xs="12" sm="4" key={id}>
                  <Card
                    className="bg-info text-white"
                    key={`serviceProviderList_${companyName}`}
                  >
                    <CardBody>
                      <div className="card-title mb-2 d-flex">
                        <h4>{serviceTitle}</h4>
                      </div>
                      <div>
                        <p className="text-success">{companyName}</p>
                        <div>
                          <p className="mb-0">
                            <small>
                              {[address.street, address.number]
                                .filter(Boolean)
                                .join(', ')}
                            </small>
                          </p>
                          <p className="mb-0">
                            <small>
                              {[address.city, address.state, address.zip]
                                .filter(Boolean)
                                .join(', ')}
                            </small>
                          </p>
                          <p className="mb-0">
                            <small>{telephone}</small>
                          </p>
                          <p className="mb-0">
                            <small>{email}</small>
                          </p>
                          <p className="mb-0">
                            <small>{site}</small>
                          </p>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              ),
            )}
          </Row>
        </>
      )}

      <h4 ref={tableRef} className="card-title text-info mb-4 text-uppercase">
        {t('activitiesLog')}
      </h4>
      <DataTable
        columns={[
          {
            name: 'Ticker',
            selector: row => row.user,
            center: true,
          },
          {
            name: t('date'),
            selector: row => row.dateTime,
            center: true,
            sortable: true,
          },
          {
            name: t('action'),
            selector: row => row.action,
            center: true,
            sortable: true,
          },
        ]}
        data={listingLogs}
      />
    </>
  );
};

export default CompanyProfileTab;

import React, { useRef, useEffect, useState, useMemo } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Row, Col, Card, CardBody } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";

import { Form } from "@unform/web";
import { useTranslation } from "react-i18next";

import api from "../../services/api";
import SEO from "../../components/SEO";
import ActionBar from "../../components/ActionBar";
import Spinner from "../../components/Spinner";
import { useBreadcrumb } from "../../hooks/useBreadcrumb";
import usePermissions from "../../hooks/usePermissions";
import { dataContext, permission } from "../../utils/permissionEnums";
import { addMessage } from "../../store/modules/toast/actions";
import { Company } from "store/modules/company/types";
import { ACCESS_TYPE_BROKER_B0, ACCESS_TYPE_BROKER_B1, ACCESS_TYPE_BROKER_B2 } from "utils/constants";
import { handleErrorResponse } from "utils/error-handlers";
import { formatCnpj } from "utils/documents";
import { useEditCompany } from "hooks/useEditCompany";
import CompaniesTab from "components/listingCompany/create/Companies";
import { ApplicationState } from "store";
import { LabelValue } from "store/modules/company/types";
import { AccessType } from "store/modules/user/types";
import CompanyDistributors from "components/company/CompanyDistributors";
import DataTable from "components/DataTable";

const CompanyDetails: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const { t } = useTranslation("companies");

  const { setBreadcrumbItems } = useBreadcrumb();
  const { setCompany: setCompanyHook } = useEditCompany();

  const formRef = useRef(null);

  const [company, setCompany] = useState<Company>(null);
  const [distributors, setDistributors] = useState<LabelValue[]>([]);

  const accessType = useSelector<ApplicationState, AccessType>(
    (state) => state.user.accessType
  );

  const isAccessTypeBroker = useMemo(
    () =>
      [ACCESS_TYPE_BROKER_B0, ACCESS_TYPE_BROKER_B1, ACCESS_TYPE_BROKER_B2].includes(
        accessType.name
      ),
    [accessType.name]
  );

  const hasPermissionToUpdate = usePermissions(
    dataContext.INVESTOR_COMPANY,
    permission.UPDATE
  );

  const editListing = () => {
    setCompanyHook(company);

    navigate("/companies/company");
  };

  async function takeAction(type, reason?) {
    const { data } = await api.patch(`/listedcompanies/${company.id}/state`, {
      changeState: {
        action: type,
        ...(reason && { message: reason }),
      },
    });

    setCompany(data);
    dispatch(
      addMessage({
        title: t("listingUpdated"),
        type: "info",
      })
    );
  }

  async function handleAction(
    type: "REFUSE" | "BLOCK" | "PUBLISH" | "SUBMIT" | "UPDATE",
    reason?: string
  ) {
    if (type === "UPDATE") editListing();
    else if (type === "REFUSE") takeAction(type, reason);
    else takeAction(type);
  }

  useEffect(() => {
    setBreadcrumbItems({
      title: "companyDetails",
      items: [
        { title: "home", link: "/dashboard" },
        { title: "companies", link: "/companies" },
        { title: "companyDetails", link: "" },
      ],
    });
  }, [setBreadcrumbItems]);

  useEffect(() => {
    async function getCompany() {
      try {
        const { data } = await api.get<Company>(`/investor_company/${id}`);

        data.distributors = data.distributors.map(t => {
          return {
            value: {
              value: t.id,
              label: `${formatCnpj(t.cnpj)} - ${t.name}`,
              removable: false
            },
            label: `${formatCnpj(t.cnpj)} - ${t.name}`
            // associationDate: '22/01/2016', //TODO sprint 4
          }
        })

        setCompany(data);
        setCompanyHook(data);
      } catch (error) {
        const { description } = handleErrorResponse(error);

        console.log("[CompanyDetails][getCompany]", description);
      }
    }

    getCompany();
  }, [id]);

  if (!company?.id) return <Spinner />;

  return (
    <>
      <SEO
        title={t("companyDetails", { ns: "breadcrumb" })}
        shouldIndexPage={false}
      />

      <Row>
        <Col xs="12">
          <div className="text-end mb-4">
            <div className="button-items">
              <ActionBar
                actions={[
                  {
                    permission: hasPermissionToUpdate,
                    action: permission.UPDATE,
                  },
                ]}
                onClick={handleAction}
              />
            </div>
          </div>
        </Col>
      </Row>

      <Card>
        <CardBody>
          <Form ref={formRef} onSubmit={() => ({})} initialData={company}>
            <CompaniesTab isReadOnly formRef={formRef} distributors={distributors} />
          </Form>
          { !isAccessTypeBroker && (
            <>
              <h4 className="card-title my-4 text-uppercase">
              {t("association")}
              </h4>
              <DataTable
                columns={[
                  {
                    name: 'Distribuidor',
                    selector: (row) => row.label,
                    center: true,
                    sortable: true,
                  },
                  // { //TODO sprint 4
                  //   name: 'Data de associação',
                  //   selector: (row) => row.associationDate,
                  //   center: true,
                  //   sortable: true,
                  // },
                ]}
                data={company.distributors}
                pagination={company.distributors.length > 10}
              />
            </>
          )}
        </CardBody>
      </Card>
    </>
  );
};

export default CompanyDetails;

import React, { useState, useEffect, useCallback } from "react";
import {
  Row,
  Col,
  Input as InputUncontrolled,
  Label,
  Card,
  CardBody,
} from "reactstrap";
import NumberFormat from "react-number-format";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { FormHandles } from "@unform/core";

import Input from "../../FormFields/Input";
import InputMask from "../../FormFields/InputMask";
import TextArea from "../../FormFields/TextArea";
import DatePicker from "../../FormFields/DatePicker";
import Select from "../../FormFields/Select";
import AddressCard from "../../AddressCard";
import api from "../../../services/api";
import usePermissions from "../../../hooks/usePermissions";
import { dataContext, permission } from "../../../utils/permissionEnums";
import { handleErrorResponse } from "utils/error-handlers";
import { Pageable } from "common/common.types";
import { AdvisorList } from "store/modules/advisor/types";

interface SelectedAdvisor {
  label: string;
  name: string;
  cnpj: string;
}

interface ProfileTabProps {
  formRef: React.MutableRefObject<FormHandles>;
  isNewListing: boolean;
}

const ProfileTab: React.FC<ProfileTabProps> = ({ formRef, isNewListing }) => {
  const { t } = useTranslation("listings");

  const [advisors, setAdvisors] = useState<{ label: string; value: number }[]>(
    []
  );
  const [sectors, setSectors] = useState<{ label: string; value: number }[]>(
    []
  );
  const [employeeQuantity, setEmployeeQuantity] = useState<{ label: string; value: number }[]>(
    []
  );
  const [sizes, setSizes] = useState<{ label: string; value: number }[]>(
    []
  );
  const [digitCount, setDigitCount] = useState(0);
  const [isCellphoneFormat, setIsCellphoneFormat] = useState(false);
  const [hasTyped, setIfTyped] = useState(false);
  const [userList, setUserList] = useState<{
    selectedAdvisor: SelectedAdvisor;
    c0List: any[];
    a1List: any[];
    a2List: any[];
  }>({
    selectedAdvisor: {
      label: "",
      cnpj: "",
      name: "",
    },
    c0List: [],
    a1List: [],
    a2List: [],
  });

  const hasPermissionToRead = usePermissions(
    dataContext.LISTED_COMPANY,
    permission.READ
  );
  const hasPermissionToUpdate = usePermissions(
    dataContext.LISTED_COMPANY,
    permission.UPDATE
  );

  const getUsers = useCallback(
    async (param) => {
      try {
        const advisorId = formRef.current.getFieldValue("advisor");

        if (advisorId && advisorId !== param.value)
          formRef.current.setFieldValue("advisorTechnicalManager", undefined);

        const [usersC0, usersA1, usersA2, advisorResponse] = await axios.all([
          api.get(`/companies/${param.value}/people?accessType=C0`),
          api.get(`/companies/${param.value}/people?accessType=A1`),
          api.get(`/companies/${param.value}/people?accessType=A2`),
          api.get(`/advisors/${param.value}`),
        ]);

        setUserList({
          selectedAdvisor: {
            label: param.label,
            name: advisorResponse.data.company.name,
            cnpj: advisorResponse.data.company.cnpj,
          },
          c0List: usersC0.data,
          a1List: usersA1.data,
          a2List: usersA2.data,
        });
      } catch (error) {
        const { description } = handleErrorResponse(error);

        console.log("[ProfileTab][getUsers]", description);
      }
    },
    [formRef]
  );

  async function getSectors() {
    try {
      const { data } = await api.get<any>("/segments/dropdown");
      setSectors(data);
    } catch (error) {
      const { description } = handleErrorResponse(error);
      console.log("[ProfileTab][getSectors]", description);
    }
  }

  async function getEmployeeQuantity() {
    try {
      const { data } = await api.get<any>("/quantityEmployee/dropdown");
      setEmployeeQuantity(data);
    } catch (error) {
      const { description } = handleErrorResponse(error);
      console.log("[ProfileTab][getEmployeeQuantity]", description);
    }
  }

  async function getSizes() {
    try {
      const { data } = await api.get<any>("/revenueSize/dropdown");
      setSizes(data);
    } catch (error) {
      const { description } = handleErrorResponse(error);
      console.log("[ProfileTab][getSizes]", description);
    }
  }

  const formatNumber = isCellphoneFormat ? "+## (##) #####-####" : "+## (##) ####-####";
  useEffect(() => {
    const controller = new AbortController();

    (async () => {
      try {
        const { data } = await api.get<Pageable<AdvisorList>>(
          "/advisors/pages",
          {
            signal: controller.signal,
            params: {
              page: 1,
              limit: 100_000_000,
            },
          }
        );

        setAdvisors(
          data.content.map(({ id, tradingName }) => ({
            label: tradingName,
            value: id,
          }))
        );

        getSectors();
        getEmployeeQuantity();
        getSizes();
      } catch (error) {
        const { description } = handleErrorResponse(error);

        console.log("[ProfileTab][getAdvisors]", description);
      }
    })();

    return () => {
      controller.abort();
    };
  }, []);

  return (
    <>
      <h4 className="card-title mb-4 text-uppercase">{t("advisor")}</h4>
      <Row className="my-4">
        <Col xs="12" md="4">
          <Select
            label={t("tradingName")}
            name="advisor"
            options={advisors}
            onChangeValue={getUsers}
            required
          />
        </Col>
        <Col xs="12" md="4" className="mb-3">
          <Label className="text-capitalize">{t("companyName")}</Label>
          <InputUncontrolled
            className="form-control"
            value={userList.selectedAdvisor.name}
            disabled
          />
        </Col>
        <Col xs="12" md="4" className="mb-3">
          <Label className="text-capitalize">CNPJ</Label>
          <NumberFormat
            className="form-control"
            format="##.###.###/####-##"
            value={userList.selectedAdvisor.cnpj}
            disabled
          />
        </Col>

        <Col xs="12" md="4">
          <Input label={t("focalPoint")} name="focalPoint" />
        </Col>
        <Col xs="12" md="4">
          <Input label={t("secondaryContact")} name="secondaryContact" />
        </Col>
        {/* <Col xs="12" md="4">
          <Select
            label={t("responsibleComplianceSupervisor")}
            name="responsibleComplianceSupervisor"
            options={userList.a1List}
            disabled={!hasPermissionToRead && !hasPermissionToUpdate}
            isClearable
          />
        </Col> */}
      </Row>

      <h4 className="card-title my-4 text-uppercase">{t("generalData")}</h4>
      <Row>
        <Col xs="12" md="4">
          <Input label={t("tradingName")} name="tradingName" required />
        </Col>
        <Col xs="12" md="4" className="mb-3">
          <Input label={t("companyName")} name="company.name" required />
        </Col>
        <Col xs="12" md="4">
          <InputMask
            label="CNPJ"
            name="company.cnpj"
            format="##.###.###/####-##"
            required={isNewListing}
            disabled={!isNewListing}
          />
        </Col>
      </Row>
      <Row>
        <Col xs="12" md="4">
          <Input label={t("companyAlphaCode")} name="ticker" maxLength={4} required />
        </Col>
        <Col xs="12" md="8">
          <Select
            label={t("sector")}
            name="segment"
            isClearable
            options={sectors}
          />
        </Col>
      </Row>
      <Row>
        <Col xs="12" md="12">
          <TextArea label={t("description")} name="description" rows={5} />
        </Col>
      </Row>
      <Row>
        <Col xs="12" md="4">
          {/* <Input
            label={t("employeeQuantity")}
            name="quantityEmployee"
            type="number"
            min={0}
          /> */}
          <Select
            label={t("employeeQuantity")}
            name="quantityEmployeeId"
            isClearable
            options={employeeQuantity}
          />
        </Col>
        <Col xs="12" md="4">
          <Input label={t("economicRating")} name="economicRating" />
        </Col>
        <Col xs="12" md="4">
          <Input label={t("mainActivity")} name="mainActivity" />
        </Col>
      </Row>
      <Row className="mb-4">
        <Col xs="12" md="4">
          <DatePicker
            label={t("foundation")}
            name="foundation"
            dateFormat="dd/MM/yyyy"
            maxDate={new Date()}
          />
        </Col>
        <Col xs="12" md="4">
          <Select
            label={t("revenueSize")}
            name="revenueSize"
            isClearable
            options={sizes}
          />
        </Col>
        {/* <Col xs="12" md="4">
          <Input label={t("businessMaturity")} name="businessMaturity" />
        </Col> */}
      </Row>

      <AddressCard formRef={formRef} showCard={false} />

      <Card style={{ display: "contents" }}>
        <CardBody style={{ padding: "0px" }}>
          <h4 className="card-title my-4 text-uppercase">
            {t("contactInformation")}
          </h4>
          <Row>
            <Col xs="12" md="4">
              <Input
                label="Email"
                name="email"
                type="email"
                placeholder={t("enterEmail")}
                required
              />
            </Col>
            <Col xs="12" md="4">
              <InputMask
                label={t("telephone")}
                name="telephone"
                mask={ !hasTyped ? " " : "_"}
                format={ !hasTyped ? "+## (##) #########" : formatNumber}
                onChange={(e) => {setDigitCount(e.value.length)}}
                onKeyUp={e => {setIsCellphoneFormat(digitCount >= 12)}}
                onBlur={(e) => {setIfTyped(true); setIsCellphoneFormat(!(digitCount <= 12))}}
                onFocus={(e) => {setIfTyped(false)}}
                placeholder={t("enterTelephone")}
              />
            </Col>
            <Col xs="12" md="4">
              <Input
                label="Site"
                name="website"
                type="text"
                placeholder={t("enterSite")}
              />
            </Col>
          </Row>
        </CardBody>
      </Card>
    </>
  );
};

export default ProfileTab;

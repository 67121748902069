import { action } from "typesafe-actions";
import { NavigateFunction } from "react-router-dom";
import { OfferTypes, Offer } from "./types";

export const updateOfferRequest = (
  offer: Offer,
  navigate: NavigateFunction
): TypeSafeAction<OfferTypes> =>
  action(OfferTypes.UPDATE_OFFER_REQUEST, { offer, navigate });

export const updateOfferSuccess = (): TypeSafeAction<OfferTypes> =>
  action(OfferTypes.UPDATE_OFFER_SUCCESS);

export const createOfferRequest = (
  offer: Offer,
  navigate: NavigateFunction
): TypeSafeAction<OfferTypes> =>
  action(OfferTypes.CREATE_OFFER_REQUEST, { offer, navigate });

export const createOfferSuccess = (): TypeSafeAction<OfferTypes> =>
  action(OfferTypes.CREATE_OFFER_SUCCESS);

export const patchPublishedOfferRequest = (
  offer: Pick<Offer, "reservationDeadline" | "id">,
  navigate: NavigateFunction
): TypeSafeAction<OfferTypes> =>
  action(OfferTypes.PATCH_PUBLISHED_OFFER_REQUEST, {
    offer,
    navigate,
  });

export const patchPublishedOfferSuccess = (): TypeSafeAction<OfferTypes> =>
  action(OfferTypes.PATCH_PUBLISHED_OFFER_SUCCESS);

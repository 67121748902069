import { useState } from 'react';
import moment from 'moment';
import { Typography } from '@material-ui/core';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import SweetAlert from "react-bootstrap-sweetalert";
import Input from 'components/HookFormFields/Input';
import { useForm } from 'react-hook-form';
import InputMask from 'components/HookFormFields/InputMask';
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useDispatch } from 'react-redux';
import { addMessage } from 'store/modules/toast/actions';
import api from 'services/api';
import { useNavigate, useParams } from 'react-router-dom';
import CloseIcon from '@material-ui/icons/Close';
import { Button } from 'reactstrap';
import DatePicker from "../../components/HookFormFields/DatePicker";
import { CircularProgress } from '@material-ui/core';

interface Props {
  open: boolean;
  onClose: () => void;
  tickerParam: string;
  instrumentTypeParam: {
    name: string;
    code: number;
    id: string;
  };
  transactionExpirationDateParam: string;
}

const LiquidateDialog: React.FC<Props> = (props) => {
  const { open, onClose, tickerParam, instrumentTypeParam, transactionExpirationDateParam } = props;
  const { id } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation("common");
  const [loading, setLoading] = useState<boolean>(false);
  const [showSweetAlert, setShowSweetAlert] = useState(false);
  const dispatch = useDispatch();
  const currentDate = moment();
  const shouldShowLCIMessage = () => {
    return instrumentTypeParam.name === 'LCI' && currentDate.isBefore(moment(transactionExpirationDateParam));
  };
  const sweetAlertTitle = shouldShowLCIMessage() ? t("earlyLciLiquidationWarning") : t("confirmSettled");

  const { reset, handleSubmit, control } = useForm<{ id: number; ticker: string; liquidateOfferValue: string; liquidateReason: string }>({
    defaultValues: {
      id: parseInt(id),
      ticker: "",
      liquidateOfferValue: "",
      liquidateReason: "",
    },
    resolver: yupResolver(
      Yup.object().shape({
        ticker: Yup.string().required(t("requiredField")).test(
          "match-ticker",
          t("validTicker"),
          function (value) {
            return value.toLowerCase().trim() === tickerParam.toLowerCase().trim();
          }
        ),
        liquidateOfferValue: Yup.string().nullable().required(t("requiredField")),
        liquidateReason: Yup.string().required(t("requiredField")),
      })
    ),
  });

  const handleClose = () => {
    cleanSubmit();
    props.onClose();
  };

  const handleConfirm = (data: { id: number; ticker: string; liquidateOfferValue: string; liquidateReason: string }) => {
    setShowSweetAlert(true);
  };

  const handleSweetAlertClose = () => {
    setShowSweetAlert(false);
    onClose();
  };

  const onSubmit = async (data: { id: number; ticker: string; liquidateOfferValue: string; liquidateReason: string; liquidateDate: string }) => {
    setLoading(true);
    handleConfirm(data);
    await liquidateTicker(data);
    setLoading(false);
  };

  const cleanSubmit = () => {
    reset();
  }

  const liquidateTicker = async (data: { id: number; ticker: string; liquidateOfferValue: string; liquidateReason: string; liquidateDate: string}) => {
    try {
      const { status } = await api.put(`/offers/${data.id}/liquidate`, {
        "settleRequestDTO": {
          "liquidateOfferValue": parseInt(data.liquidateOfferValue),
          "liquidateReason": data.liquidateReason,
          "liquidateDate": data.liquidateDate
        }
      });

      if (status === 201) {
        dispatch(
          addMessage({
            title: t("successSettled"),
            type: "success",
          })
        );
        navigate("/offers");
      }
    } catch (error) {
      console.log(error);
    } finally {
      handleSweetAlertClose();
    }
  };

  return (
    <div>
      <Dialog open={open} onClose={onClose} PaperProps={{ style: { width: "500px" } }}>
        <DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            style={{ position: 'absolute', top: 8, right: 8, color: 'gray' }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Input
            label={t("ticker")}
            name="ticker"
            control={control}
            type="text"
            required
          />
          <InputMask
            label={t("amountLiquidation")}
            name="liquidateOfferValue"
            control={control}
            thousandSeparator="."
            decimalSeparator=","
            prefix="R$ "
            isNumericString
            required
          />
          <Input
            label={t("reasonForLiquidation")}
            name="liquidateReason"
            control={control}
            type="text"
            required
          />
          <DatePicker
            label={t("liquidateDate")}
            name="liquidateDate"
            control={control}
            placeholder="dd/mm/aaaa"
            dateFormat="dd/MM/yyyy"
            required
          />
        </DialogContent>
        <DialogActions>
          <Button
            color="info"
            className="waves-effect waves-light"
            onClick={cleanSubmit}>
            {t("clean")}
          </Button>
          <Button
            color="info"
            className="waves-effect waves-light me-4"
            onClick={handleSubmit((data) => handleConfirm(data))} type="submit">
            {t("confirm")}
          </Button>
        </DialogActions>
      </Dialog>
      {showSweetAlert && (
        <div>
          <SweetAlert
            showCancel={!loading}
            showConfirm={!loading}
            title={t("confirmSettled")}
            cancelBtnBsStyle={loading ? "danger disabled" : "danger"}
            cancelBtnText={t("no")}
            confirmBtnBsStyle="success"
            confirmBtnText={t("yes")}
            onConfirm={handleSubmit(onSubmit)}
            onCancel={() => setShowSweetAlert(false)}
            disabled={loading}
          >
            {loading ? <CircularProgress /> : null}
            {shouldShowLCIMessage() && (
              <Typography style={{ color: 'red' }}>
                {t("earlyLciLiquidationWarning")}
              </Typography>
            )}
          </SweetAlert>
        </div>
      )}
    </div>
  );
}

export default LiquidateDialog;

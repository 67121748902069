import { Reducer } from "redux";
import produce, { Draft } from "immer";
import { OfferState, OfferTypes } from "./types";

const INITIAL_STATE: OfferState = {
  loading: false,
};

const reducer: Reducer<OfferState> = (state = INITIAL_STATE, action) => {
  return produce(state, (draft: Draft<OfferState>) => {
    switch (action.type) {
      case OfferTypes.CREATE_OFFER_REQUEST: {
        draft.loading = true;
        break;
      }
      case OfferTypes.CREATE_OFFER_SUCCESS: {
        draft.loading = false;
        break;
      }
      case OfferTypes.UPDATE_OFFER_REQUEST: {
        draft.loading = true;
        break;
      }
      case OfferTypes.UPDATE_OFFER_SUCCESS: {
        draft.loading = false;
        break;
      }
      case OfferTypes.PATCH_PUBLISHED_OFFER_REQUEST: {
        draft.loading = true;
        break;
      }
      case OfferTypes.PATCH_PUBLISHED_OFFER_SUCCESS: {
        draft.loading = false;
        break;
      }
      default:
    }
  });
};

export default reducer;

import React, {
  useRef,
  useState,
  useEffect,
  Dispatch,
  SetStateAction,
} from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import {
  Card,
  CardBody,
  Row,
  Col,
  Button,
  FormGroup,
  Label,
  Input as ControlledInput,
} from "reactstrap";
import NumberFormat from "react-number-format";
import i18next from "i18next";

import Input from "../../HookFormFields/Input";
import InputMask from "../../HookFormFields/InputMask";
import InputPercentage from "../../HookFormFields/InputPercentage";
import Select from "../../HookFormFields/Select";
import DataTable from "../../DataTable";
import api from "../../../services/api";

interface Offer {
  value: string;
  label: string;
  data: {
    listedCompanyName: string;
    listedCompanyCNPJ: string;
    totalAmountOffered: number;
  };
}

interface FormData {
  recommendationTitle: string;
  recommendedValue: number;
  offerTicker: string;
  type: string;
  assetTerm: string;
  recommendedPercentual: number;
}

interface Props {
  selectedOffer: Offer;
  setSelectedOffer: Dispatch<SetStateAction<Offer>>;
  recsList: any[];
  setRecsList: Dispatch<SetStateAction<any[]>>;
}

const Recs: React.FC<Props> = ({
  selectedOffer,
  setSelectedOffer,
  setRecsList,
  recsList,
}) => {
  const { t } = useTranslation("recommendations");
  const {
    handleSubmit,
    control,
    watch,
    formState: { errors },
    trigger,
  } = useForm<FormData>({
    resolver: yupResolver(
      Yup.object().shape({
        offerTicker: Yup.string().required(t("requiredField")),
        type: Yup.string().required(t("requiredField")),
        recommendedPercentual: Yup.number().moreThan(0, t("requiredField")),
      })
    ),
  });
  const [offerList, setOfferList] = useState<Offer[]>([]);
  const offerTicker = watch("offerTicker");

  useEffect(() => {
    async function getData() {
      try {
        const { data } = await api.get<Offer[]>(
          "/offers/dropdown?state=PUBLISHED"
        );
        setOfferList(data);
      } catch (error) {
        console.log(error);
      }
    }
    getData();
  }, []);

  useEffect(() => {
    setSelectedOffer(offerList.find((e) => e.value === offerTicker));
  }, [offerList, offerTicker, setSelectedOffer]);

  const onSubmit: SubmitHandler<FormData> = (data) => {
    setRecsList((oldState) => [
      ...oldState,
      {
        offerTicker: data.offerTicker,
        companyName: selectedOffer.data.listedCompanyName,
        assetTerm: data.assetTerm,
        recommendedPercentual: data.recommendedPercentual,
      },
    ]);
  };

  useEffect(() => {
    if (errors && Object.keys(errors).length > 0) {
      trigger();
    }
  }, [i18next.language]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Card>
        <CardBody>
          <Row>
            <Col xs="12" md="4">
              <Input
                label={t("recommendationTitle")}
                name="recommendationTitle"
                control={control}
                placeholder={t("describeAName")}
              />
            </Col>
            <Col xs="12" md="4">
              <InputMask
                label={t("recommendedValue")}
                name="recommendedValue"
                control={control}
                thousandSeparator="."
                decimalSeparator=","
                prefix="R$ "
                isNumericString
                placeholder={t("common:realBrazilian")}
              />
            </Col>
          </Row>

          <div className="p-2 border rounded border-secondary mb-4">
            <h4 className="card-title mb-4 text-uppercase">
              {t("recommendation")}
            </h4>
            <Row>
              <Col xs="12" md="4">
                <Select
                  label={t("offerTicker")}
                  name="offerTicker"
                  control={control}
                  options={offerList}
                />
              </Col>
              <Col xs="12" md="4" className="mb-3">
                <Label className="text-capitalize">{t("companyName")}</Label>
                <ControlledInput
                  className="form-control"
                  value={selectedOffer?.data.listedCompanyName || ""}
                  disabled
                />
              </Col>
              <Col xs="12" md="4" className="mb-3">
                <Label className="text-capitalize">CNPJ</Label>
                <NumberFormat
                  className="form-control"
                  value={selectedOffer?.data.listedCompanyCNPJ || ""}
                  format="##.###.###/####-##"
                  disabled
                />
              </Col>
            </Row>
            <Row>
              <Col xs="12" md="4">
                <Select
                  label={t("type")}
                  name="type"
                  control={control}
                  options={[
                    {
                      label: t("purchaseOrder"),
                      value: "1",
                    },
                    {
                      label: t("preReleaseReservation"),
                      value: "2",
                    },
                  ]}
                />
              </Col>
              <Col xs="12" md="4">
                <Input
                  label={t("assetTerm")}
                  name="assetTerm"
                  control={control}
                  disabled
                />
              </Col>
              <Col xs="12" md="4" className="mb-3">
                <Label className="text-capitalize">{t("offeredVolume")}</Label>
                <NumberFormat
                  className="form-control"
                  value={selectedOffer?.data.totalAmountOffered}
                  thousandSeparator="."
                  decimalSeparator=","
                  prefix="R$ "
                  disabled
                />
              </Col>
            </Row>
            <Row>
              <Col xs="12" md="4">
                <InputPercentage
                  label={t("recommendedPercentualRequired")}
                  name="recommendedPercentual"
                  control={control}
                />
              </Col>
              <Col xs="12">
                <Button
                  className="waves-effect waves-light mb-3"
                  color="primary"
                  type="submit"
                >
                  <i className="mdi mdi-plus me-2" />
                  {t("add")}
                </Button>
              </Col>
            </Row>
          </div>
          <DataTable
            columns={[
              {
                name: "Ticker",
                selector: (row) => row.offerTicker,
                center: true,
                sortable: true,
              },
              {
                name: t("companyName"),
                selector: (row) => row.companyName,
                center: true,
                sortable: true,
              },
              {
                name: t("assetTerm"),
                selector: (row) => row.assetTerm,
                center: true,
                sortable: true,
              },
              {
                name: t("recommendedPercentual"),
                selector: (row) => row.recommendedPercentual,
                center: true,
                sortable: true,
              },
              {
                name: "",
                button: true,
                cell: (row, rowIndex) => (
                  <Button
                    className="waves-effect waves-light m-1"
                    color="danger"
                    size="sm"
                    onClick={() => {
                      setRecsList((oldState) =>
                        oldState.filter((e, i) => i !== rowIndex)
                      );
                    }}
                  >
                    <i className="fas fa-trash-alt" />
                  </Button>
                ),
              },
            ]}
            data={recsList}
            pagination={false}
          />
        </CardBody>
      </Card>
    </form>
  );
};

export default Recs;

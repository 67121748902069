import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";

import { Card, CardBody, Row, Col, Button, Form, Modal, Spinner } from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";

import api from "../../services/api";
import { Reservation, ReservationInfo } from "store/modules/reservation/types";
import { useBreadcrumb } from "../../hooks/useBreadcrumb";
import SEO from "../../components/SEO";
import Input from "../../components/HookFormFields/Input";
import usePermissions from "../../hooks/usePermissions";
import { dataContext, permission } from "../../utils/permissionEnums";
import SearchBar from "../../components/SearchBar";
import StatusBadge from "../../components/StatusBadge";
import DataTable from "../../components/DataTable";
import { handleErrorResponse } from "utils/error-handlers";
import { Pageable } from "common/common.types";
import { Container } from "./styles";
import { addMessage } from "store/modules/toast/actions";
import { formatNumber } from "utils/numbers";
import { formatDateToBrazilian } from "utils/date-time";
import { getReservationStatus,   ACCESS_TYPE_ORIGINATOR_C0, ACCESS_TYPE_ORIGINATOR_C4, } from "utils/constants";
import { AccessType } from "store/modules/user/types";
import { ApplicationState } from "store";

const IssueReservations = () => {
  const { t } = useTranslation(["offers", "common", "toasts"]);
  const { id } = useParams();
  const dispatch = useDispatch();

  const { control, reset, watch } = useForm({
    defaultValues: { fileCsv: undefined, totalAvailable: "" },
  });
  const watchingTotalAvailable = watch("totalAvailable");

  const { setBreadcrumbItems } = useBreadcrumb();

  const [isReservationsLoading, setIsReservationsLoading] = useState(false);
  const [isConfirming, setIsConfirming] = useState(false);
  const [isCancelling, setIsCancelling] = useState(false);
  const [loading, setIsLoading] = useState(true);
  const [selectedReservation, setSelectedReservation] = useState<Reservation[]>(
    []
  );
  const [offerModal, setOfferModal] = useState(null);
  const [importar, setImportar] = useState(false);
  const [reservations, setReservations] = useState<Pageable<Reservation>>();
  const [isReservation, setIsReservation] = useState(false);
  const [isCancellation, setIsCancellation] = useState(false);
  const [isCancellingReservation, setIsCancellingReservation] = useState(false);
  const [perPage, setPerPage] = useState(10);
  const [datastring, setDatastring] = useState({
    size: 0,
    quantity: 0,
    ammount: 0,
  });
  const [file, setFile] = useState();
  const [toggledClearRows, setToggleClearRows] = useState(false);
  const [persistedFilters, setPersistedFilters] = useState({
    investorName: "",
    status: "",
  });
  const fileInputRef = useRef(null);

  const [reservation] = selectedReservation;

  const hasPermissionToRead = usePermissions(
    dataContext.RESERVATION,
    permission.READ
  );

  const hasPermissionToApprove = usePermissions(
    dataContext.RESERVATION,
    permission.CONFIRM
  );

  const hasPermissionToCancel = usePermissions(
    dataContext.RESERVATION,
    permission.CANCEL
  );

  const hasPermissionToUpload = usePermissions(
    dataContext.RESERVATION,
    permission.UPLOAD
  );

  const accessType = useSelector<ApplicationState, AccessType>(
    (state) => state.user.accessType
  );

  const isAccessTypeOriginator = useMemo(
    () =>
      [ACCESS_TYPE_ORIGINATOR_C0, ACCESS_TYPE_ORIGINATOR_C4].includes(
        accessType.name
      ),
    [accessType.name]
  );

  const handleClearRows = () => {
    setToggleClearRows((prevToggledClearRows) => !prevToggledClearRows);
  };

  const handleChange = (event) => {
    setFile(event.target.files[0]);
  };

  const getImportar = useCallback(async () => {
    setIsLoading(true);
    setIsLoading(false);
  }, []);

  const cancelOfferReservation = async () => {
    try {
      setIsCancellingReservation(true);
      const { investorId, investorName, ...reservationToCancel } = { ...reservation };
      const body = {
        cancelQuantity: 1
      }

      await api.put(`/offers/${id}/reservation/${reservationToCancel.id}/cancel`, body);

      await fetchReservation();
      await fetchOfferReservations({});
    } catch (error) {
      const { description } = handleErrorResponse(error);
      console.log("[IssueReservations][cancelOfferReservation]", description);
    } finally {
      setSelectedReservation([]);
      handleClearRows();
      setIsCancellation(false);
      setIsCancellingReservation(false);
    }
  };

  const fetchReservation = useCallback(async () => {
    try {
      const { data } = await api.get<ReservationInfo>(
        `/offers/${id}/reservations-infos`
      );
      const transformedData: any = data;
      transformedData.totalAvailable = formatNumber({
        numericValue: data.totalAvailable,
      });
      transformedData.totalConfirmed = formatNumber({
        numericValue: data.totalConfirmed,
      });
      transformedData.totalSolicitationAmount = formatNumber({
        numericValue: data.totalSolicitationAmount,
      });

      reset(transformedData);
    } catch (error) {
      const { description } = handleErrorResponse(error);

      console.log("[IssueReservations][fetchReservation]", description);
    }
  }, [id]);

  const fetchOfferReservations = useCallback(
    async ({
      investorNameParam = persistedFilters.investorName,
      statusParam = persistedFilters.status,
      page = 1,
      limit = perPage,
      signal,
    }: {
      investorNameParam?: string;
      statusParam?: string;
      page?: number;
      limit?: number;
      signal?: AbortSignal;
    }) => {
      try {
        setIsReservationsLoading(true);

        const { data } = await api.get<Pageable<Reservation>>(
          `/offers/${id}/reservations`,
          {
            params: {
              investorName: investorNameParam,
              status: statusParam,
              page,
              limit,
            },
            signal,
          }
        );

        setReservations(data);
        setPersistedFilters({
          investorName: investorNameParam,
          status: statusParam,
        });
      } catch (error) {
        const { description } = handleErrorResponse(error);

        console.log("[IssueReservations][fetchOfferReservations]", description);
      } finally {
        setIsReservationsLoading(false);
      }
    },
    [id, perPage, persistedFilters.investorName, persistedFilters.status]
  );

  const fetchValidateImport = async () => {
    try {
      const formData = new FormData();
      formData.append("file", file);
      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };
      const { data } = await api.post(
        `/reservation/${id}/file/validate`,
        formData,
        config
      );

      setDatastring(data);
      setImportar(true);
    } catch (error) {
      const { description } = handleErrorResponse(error);

      console.log("[IssueReservations][fetchValidateImport]", description);
    }
  };

  const fetchImport = async () => {
    try {
      const formData = new FormData();
      formData.append("file", file);
      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };
      await api.post(`/reservation/${id}/file/import`, formData, config);
      await fetchReservation();
      await fetchOfferReservations({});

      setImportar(false);
    } catch (error) {
      console.log(error.message)
    } finally {
      console.log("Limpando arquivo de cachê ao ser importado...")
      setFile(null)
      fileInputRef.current.value = '';
    }
  };

  const mapIdQtd = selectedReservation.reduce((acc, current) => {
    acc[current.id] = current.solicitationQuantity;
    return acc;
  }, {});

  const batchConfirmReservations = async () => {
    try {
      const body = { mapIdQtd };

      // reservationToConfirm.status = "CONFIRMED";
      // reservationToConfirm.offer = Number(id);
      // reservationToConfirm.investor = investorId;
      // reservationToConfirm.confirmationAmount =
      //   reservationToConfirm.solicitationAmount;
      // reservationToConfirm.confirmationDate = new Date();
      // reservationToConfirm.confirmationQuantity =
      //   reservationToConfirm.solicitationQuantity;

      await api.put(`/offers/${id}/reservation/confirm`, body);

      await fetchReservation();
      await fetchOfferReservations({});
    } catch (error) {
      const { description } = handleErrorResponse(error);
      console.log("[IssueReservations][batchConfirmReservations]", description);
    } finally {
      setSelectedReservation([]);
      handleClearRows();
    }
  };

  const handleFetchData = ({ investorName, reservationStatus }: any) => {
    fetchOfferReservations({
      investorNameParam: investorName,
      statusParam: reservationStatus,
    });
  };

  const handlePageChange = (page: number) => {
    fetchOfferReservations({ page });
  };

  const handlePerRowsChange = (newPerPage: number, page: number) => {
    setPerPage(newPerPage);

    fetchOfferReservations({ page, limit: newPerPage });
  };

  const formatDocument = (document) => {
    if (document.length === 11) {
      return document.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})$/, "$1.$2.$3-$4");
    } else if (document.length === 14) {
      return document.replace(
        /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/,
        "$1.$2.$3/$4-$5"
      );
    }

    return document;
  };

  function formatNumberWithPeriodSeparator(value) {
    const parts = value.toFixed(0).split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    return parts.join('');
  }

  useEffect(() => {
    getImportar();
  }, [getImportar]);

  useEffect(() => {
    setBreadcrumbItems({
      title: "reservations",
      items: [
        { title: "home", link: "/dashboard" },
        { title: "offers", link: "/offers" },
        { title: "reservations", link: "/" },
      ],
    });
  }, [setBreadcrumbItems]);

  useEffect(() => {
    const controller = new AbortController();

    fetchReservation();
    fetchOfferReservations({ signal: controller.signal });

    return () => {
      controller.abort();
    };
  }, []);

  const totalSolicitationAmount = selectedReservation.reduce((acc, current) => acc + current.solicitationAmount, 0);

  return (
    <>
      <SEO title={t("offers")} shouldIndexPage={false} />

      {importar && (
        <SweetAlert
          warning
          showCancel
          confirmBtnBsStyle="success"
          confirmBtnText={t("yes")}
          cancelBtnBsStyle="danger"
          cancelBtnText={t("no")}
          title={
            <Trans
              t={t}
              ns="offers"
              components={{ br: <br /> }}
              values={{
                size: datastring?.size ?? "",
                quantity: datastring?.quantity ?? "",
                amount:
                  formatNumber({
                    numericValue: datastring?.ammount,
                    showMark: true,
                  }) ?? "",
              }}
            >
              confirmImport
            </Trans>
          }
          onConfirm={async () => {
            await fetchImport();
            setImportar(false);
          }}
          onCancel={() => setImportar(false)}
        />
      )}

      {isReservation && (
        <SweetAlert
          warning
          showCancel={!isConfirming}
          showConfirm={!isConfirming}
          confirmBtnBsStyle="success"
          confirmBtnText={t("yes")}
          cancelBtnBsStyle="danger"
          cancelBtnText={t("no")}
          title={t("sendReservationInBatches", {
            ns: "offers",
            solicitationAmount: formatNumber({
              numericValue: totalSolicitationAmount,
              showMark: true,
            }),
          })}
          onConfirm={() => {
            setIsConfirming(true);
            batchConfirmReservations()
              .then(() => {
                setIsReservation(false);
              })
              .finally(() => {
                setIsConfirming(false);
              });
          }}
          onCancel={() => setIsReservation(false)}
        >
          {isConfirming && (
            <div className="text-center mt-3">
              <Spinner animation="border" role="status">
                <span className="visually-hidden">{t("loading")}</span>
              </Spinner>
            </div>
          )}
        </SweetAlert>
      )}

    {isCancellation && (
      <SweetAlert
        warning
        showCancel={!isCancellingReservation}
        showConfirm={!isCancellingReservation}
        confirmBtnBsStyle="success"
        confirmBtnText={t("yes")}
        cancelBtnBsStyle="danger"
        cancelBtnText={t("no")}
        title={t("cancelReservationConfirmation", {
          ns: "offers",
        })}
        onConfirm={cancelOfferReservation}
        onCancel={() => setIsCancellation(false)}
      >
        {isCancellingReservation && (
          <div className="text-center mt-3">
            <Spinner animation="border" role="status">
              <span className="visually-hidden">{t("loading")}</span>
            </Spinner>
          </div>
        )}
      </SweetAlert>
    )}

      <Card>
        <CardBody>
          <h4 className="card-title text-info mb-4 text-uppercase">
            {t("issueDataSheet")}
          </h4>

          <Row>
            <Col xs="12" md="3">
              <Input disabled control={control} label="Ticker" name="ticker" />
            </Col>
            <Col xs="12" md="3">
              <Input
                disabled
                control={control}
                label={t("totalReservationRequestedAmount")}
                name="totalSolicitationAmount"
              />
            </Col>
            <Col xs="12" md="3">
              <Input
                disabled
                control={control}
                label={t("totalConfirmed")}
                name="totalConfirmed"
              />
            </Col>
            <Col xs="12" md="3">
              <Input
                disabled
                control={control}
                label={t("totalAvailable")}
                name="totalAvailable"
              />
            </Col>
          </Row>
        </CardBody>
      </Card>

      {isAccessTypeOriginator ? null : (
        <Row>
          <Col xs="12 mb-3">
            <div className="d-flex flex-md-row justify-content-md-between flex-column align-items-center">
              <div className="d-flex flex-md-row justify-content-md-between flex-column align-items-center">
                {hasPermissionToApprove ? (
                  <Button
                    id="approveReservation"
                    size="sm"
                    color="primary"
                    disabled={!selectedReservation.length}
                    className="waves-effect waves-light float-end me-3"
                    onClick={() => {
                      const totalQuantity = selectedReservation.reduce((acc, current) => acc + current.solicitationAmount, 0);
                      if (totalQuantity > Number(watchingTotalAvailable)) {
                        dispatch(
                          addMessage({
                            title: t("error", { ns: "toasts" }),
                            description: t("overpricedSolicitationAmount", {
                              ns: "toasts",
                            }),
                            type: "error",
                          })
                        );

                        return;
                      }

                      setIsReservation(true);
                    }}
                  >
                    <i className="mdi mdi-check me-1" />
                    {t("confirmReservation")}
                  </Button>
                ) : null}

                {hasPermissionToCancel && (
                  <Button
                    id="cancelReservation"
                    size="sm"
                    color="primary"
                    disabled={!selectedReservation.length || selectedReservation.length > 1}
                    className="waves-effect waves-light float-end me-3"
                    onClick={() => setIsCancellation(true)}
                  >
                    <i className="mdi mdi-check me-1" />
                    {t("cancel")}
                  </Button>
                )}

                {hasPermissionToUpload && (
                  <Form className=" me-1">
                    <input
                      id="reservationSheet-input"
                      type="file"
                      ref={fileInputRef}
                      onChange={handleChange}
                    />
                  </Form>
                )}

                {hasPermissionToUpload && (
                  <Button
                    name="import"
                    type="submit"
                    color="primary"
                    onClick={() => {
                      fetchValidateImport();
                    }}
                    disabled={!file}
                  >
                    <i className="mdi mdi-arrow-collapse-up me-1" />
                    {t("import")}
                  </Button>
                )}

                <Modal
                  isOpen={offerModal !== null}
                  toggle={() => setOfferModal(null)}
                  onClosed={() => null}
                  size="xl"
                  centered
                ></Modal>
              </div>

              <SearchBar
                fields={[
                  {
                    key: "investorName",
                    label: t("investorName"),
                    variant: "text",
                  },
                  {
                    key: "reservationStatus",
                    label: "Status",
                    variant: "select",
                    options: getReservationStatus(t),
                  },
                ]}
                onSearch={handleFetchData}
              />
            </div>
          </Col>
        </Row>
      )}

      {isAccessTypeOriginator ? null : (
        <Card>
          <CardBody>
            <h4 className="card-title mb-4 text-uppercase">
              {t("reservationIssue")}
            </h4>

            <Row>
              <Col xs="12">
                <Container>
                  <DataTable
                    className="rdt_TableHeader text-align-items-center"
                    columns={[
                      {
                        name: t("investor"),
                        center: true,
                        sortable: true,
                        selector: ({ id }) => id,
                        cell: ({ investorDocument, investorName }) =>
                        `${formatDocument(investorDocument)} - ${investorName}`,
                      },
                      {
                        name: t("requestDate"),
                        selector: ({ solicitationDate }) => solicitationDate,
                        center: true,
                        sortable: true,
                        cell: ({ solicitationDate }) =>
                          formatDateToBrazilian(solicitationDate),
                      },
                      {
                        name: t("quantityReserved"),
                        center: true,
                        sortable: true,
                        selector: ({ solicitationQuantity }) => solicitationQuantity,
                        cell: ({ solicitationQuantity }) => {
                          if (solicitationQuantity) {
                            return formatNumberWithPeriodSeparator(solicitationQuantity);
                          }
                          return "";
                        },
                      },
                      {
                        name: t("reservationValue"),
                        center: true,
                        sortable: true,
                        selector: ({ solicitationAmount }) => solicitationAmount,
                        cell: ({ solicitationAmount }) => {
                          if (solicitationAmount) {
                            return formatNumber({
                              numericValue: solicitationAmount,
                              showMark: true,
                            });
                          }
                          return "";
                        },
                      },
                      {
                        name: t("broker"),
                        center: true,
                        sortable: true,
                        selector: ({ id }) => id,
                        cell: ({ brokerDocument, brokerName }) =>
                          `${formatDocument(brokerDocument)} - ${brokerName}`,
                      },
                      {
                        name: "Status",
                        selector: ({ status }) =>
                          `${t(status, { ns: "common" })}`,
                        center: true,
                        sortable: true,
                        cell: ({ status }) => <StatusBadge state={status} />,
                      },
                      {
                        name: t("confirmationDate"),
                        selector: ({ confirmationDate }) =>
                          String(confirmationDate),
                        center: true,
                        sortable: true,
                        cell: ({ confirmationDate }) => {
                          if (confirmationDate) {
                            return formatDateToBrazilian(confirmationDate);
                          }
                          return "";
                        },
                      },
                    ]}
                    data={reservations?.content}
                    selectableRows={hasPermissionToRead}
                    disabledCondition={({ status }) => status !== "SUBMITTED"}
                    onChange={(e) => setSelectedReservation(e.selectedRows)}
                    progressPending={isReservationsLoading}
                    paginationTotalRows={reservations?.count}
                    onChangeRowsPerPage={handlePerRowsChange}
                    onChangePage={handlePageChange}
                    clearSelectedRows={toggledClearRows}
                    paginationServer
                  />
                </Container>
              </Col>
            </Row>
          </CardBody>
        </Card>
      )}
    </>
  );
};

export default IssueReservations;

interface QRCodeProps {
  qrCodeImage: string;
}

const QRCode: React.FC<QRCodeProps> = ({ qrCodeImage }) => {

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        padding: "20px",
      }}
    >
      <img
        src={`data:image/png;base64,${qrCodeImage}`}
        alt="QR Code"
        style={{
          height: "90%",
          width: "90%",
          border: "7px solid 	#E8E8E8",
          padding: "10px",
        }}
      />
    </div>
  );
};

export default QRCode;

import React, { useState, useEffect, useRef, useCallback } from "react";
import { Card, CardBody, Row, Col, Button } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { FormHandles, SubmitHandler } from "@unform/core";
import { Form } from "@unform/web";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios from "axios";
import i18next from "i18next";
import LoadingButton from "./../../components/Button";

import { ApplicationState } from "../../store";
import {
  updateProfileRequest,
  createUserRequest,
} from "../../store/modules/user/actions";
import { addMessage } from "../../store/modules/toast/actions";
import { Profile, Document, AccessType, UserState } from "../../store/modules/user/types";
import Input from "../../components/FormFields/Input";
import InputMask from "../../components/FormFields/InputMask";
import Checkbox from "../../components/FormFields/Checkbox";
import Select from "../../components/FormFields/Select";
import TextArea from "../../components/FormFields/TextArea";
import SEO from "../../components/SEO";
import { useBreadcrumb } from "../../hooks/useBreadcrumb";
import api from "../../services/api";
import AddressCard from "../../components/AddressCard";
import ProfessionalDataCard from "../../components/ProfessionalDataCard";
import { useEditUser } from "../../hooks/useEditUser";
import {
  ACCESS_TYPE_ORIGINATOR_C0,
  ACCESS_TYPE_ORIGINATOR_C4,
} from "utils/constants";
import { handleErrorResponse } from "utils/error-handlers";
import { isValidCPF } from "utils/validateDocuments";

const CreateEditUser: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation("users");

  const currentUserLogin = useSelector<ApplicationState, string>(
    (state) => state.user.profile?.login
  );
  const { setBreadcrumbItems } = useBreadcrumb();
  const { hasUser, user: profile, setUser, isCurrentUser } = useEditUser();
  const accessType = useSelector<ApplicationState, AccessType>(
    (state) => state.user.accessType
  );

  const formRef = useRef<FormHandles>(null);

  const [companyNameList, setCompanyNameList] = useState([]);
  const [companyTypeList, setCompanyTypeList] = useState([]);
  const [accessTypeList, setAccessTypeList] = useState([]);
  const [selectedAccessType, setSelectedAccessType] = useState<string>(
    profile?.accessType
  );
  const userState = useSelector<ApplicationState, UserState>((s) => s.user);

  useEffect(() => {
    setBreadcrumbItems({
      title: hasUser ? "editUser" : "registerNewUser",
      items: [
        { title: "home", link: "/dashboard" },
        { title: "users", link: "/users" },
        { title: hasUser ? "editUser" : "registerNewUser", link: "" },
      ],
    });
  }, [setBreadcrumbItems, hasUser]);

  useEffect(() => {
    const controller = new AbortController();

    async function getPageData() {
      try {
        const { data: companyTypes } = await api.get("/companytypes", {
          signal: controller.signal,
        });

        setCompanyTypeList(
          companyTypes.map((type) => ({ label: t(type), value: type }))
        );
      } catch (error) {
        dispatch(
          addMessage({
            title: t("error"),
            description: t("itWasNotPossibleToBringInitialData"),
            type: "error",
          })
        );
      }
    }

    getPageData();

    return () => {
      controller.abort();
    };
  }, [dispatch, t]);

  const getAccessTypesAndCompanies = useCallback(
    async ({ value }) => {
      try {
        const [accessTypes, companies] = await axios.all([
          api.get(`/accesstypes?companyType=${value}`),
          api.get(`/companies?type=${value}`),
        ]);

        setCompanyNameList(
          companies.data.map((companyName) => ({
            value: companyName.data.companyId,
            label: companyName.label,
            data: companyName.data,
          }))
        );
        setAccessTypeList(accessTypes.data);

        const companyId = formRef.current.getFieldValue("companyId");
        const accessType = formRef.current.getFieldValue("accessType");

        if (companyId && !hasUser) {
          formRef.current.setFieldValue("companyId", {});
        }

        if (accessType && !hasUser) {
          formRef.current.setFieldValue("accessType", {});
        }
      } catch (error) {
        const { description } = handleErrorResponse(error);

        console.log(
          "[CreateEditUser][getAccessTypesAndCompanies]",
          description
        );
      }
    },
    [hasUser]
  );

  const handleSubmit: SubmitHandler<Profile> = async (data) => {
    try {
      formRef.current.setErrors({});

      const schema = Yup.object().shape({
        name: Yup.string().required(t("nameRequired")),
        cpf: Yup.string()
          .test({
            test: (pCpf) => isValidCPF(pCpf),
            message: t("invalidCpf"),
          })
          .required(t("cpfRequired")),
        role: Yup.string().required(t("roleRequired")),
        email: Yup.string().email().required(t("emailRequired")),
        companyType: Yup.string().required(t("companyTypeRequired")),
        companyId: Yup.string().required(t("companyNameRequired")),
        accessType: Yup.string().required(t("userAccessTypeRequired")),
        cellphone: Yup.string().required(t("cellphoneRequired")),
      });
      await schema.validate(data, {
        abortEarly: false,
      });
      const company = companyNameList.find(
        (companyName) => companyName.value === data.companyId
      );
      const obj = {
        ...data,
        cpf: data.cpf,
        company: company.data.cnpj,
        companyName: company.label,
        professionalDocument: await getValue(data.professionalDocument),
      };


      if (hasUser) {
        dispatch(
          updateProfileRequest(
            {
              ...profile,
              ...obj,
              profilePicture: profile.profilePicture,
            },
            isCurrentUser,
            navigate
          )
        );
      } else {
        dispatch(
          createUserRequest(
            {
              ...obj,
              login: currentUserLogin,
            },
            navigate
          )
        );
      }
    } catch (err) {
      const validationErrors = {};

      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
        dispatch(
          addMessage({
            title: t("attention"),
            description: t("checkFields"),
            type: "info",
          })
        );

        formRef.current
          .getFieldRef(Object.getOwnPropertyNames(validationErrors)[0])
          .focus();
      }
    }
  };

  async function getValue(obj: Document) {
    const isEmpty = Object.values(obj).every(
      (x) => x === null || x === "" || x === undefined
    );

    if (isEmpty) {
      return null;
    }

    if (obj.documentImage) {
      return {
        ...obj,
        documentImage: await fileToBase64(obj.documentImage),
      };
    }

    return {
      ...obj,
      documentImage: profile?.professionalDocument.documentImage,
    };
  }

  function fileToBase64(file): Promise<string> {
    try {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      return new Promise((resolve) => {
        reader.onloadend = () => {
          resolve(reader.result.toString());
        };
      });
    } catch (error) {
      return file;
    }
  }

  function handleCancel() {
    setUser(null);
    navigate(-1);
  }

  const shouldDisableCompanyTypeOption = ({ value }) => {
    // Advisor can only create advisors.
    if (
      [ACCESS_TYPE_ORIGINATOR_C0, ACCESS_TYPE_ORIGINATOR_C4].includes(
        accessType.name
      )
    ) {
      return companyTypeList
        .filter(({ value }) => value !== "ADVISOR")
        .map(({ value }) => value)
        .includes(value);
    }

    return false;
  };

  useEffect(() => {
    const errors = formRef.current?.getErrors();

    if (errors && Object.keys(errors).length > 0) {
      formRef.current.submitForm();
    }
  }, [i18next.language]);

  return (
    <>
      <SEO
        title={
          hasUser
            ? t("editUser", { ns: "breadcrumb" })
            : t("registerNewUser", { ns: "breadcrumb" })
        }
        shouldIndexPage={false}
      />

      <Form
        ref={formRef}
        onSubmit={handleSubmit}
        initialData={hasUser ? profile : {}}
      >
        <Card>
          <CardBody>
            <h4 className="card-title mb-4 text-uppercase">
              {t("generalUserData")}
            </h4>

            <Row>
              <Col xs="12" md="8">
                <Input
                  label={t("fullName")}
                  name="name"
                  placeholder={t("enterName")}
                  required
                />
              </Col>
              <Col xs="12" md="4">
                <InputMask
                  label="CPF"
                  format="###.###.###-##"
                  placeholder={t("enterCpf")}
                  name="cpf"
                  required
                />
              </Col>
            </Row>
            <Row>
              <Col xs="12" md="4">
                <Input
                  label="Email"
                  name="email"
                  type="email"
                  placeholder={t("enterEmail")}
                  required
                />
              </Col>
              <Col xs="12" md="4">
                <InputMask
                  label={t("cellphone")}
                  name="cellphone"
                  format="+## (##) #####-####"
                  placeholder={t("enterCellphone")}
                  required
                />
              </Col>
              <Col xs="12" md="4">
                <InputMask
                  label={t("phone")}
                  name="telephone"
                  format="+## (##) ####-####"
                  placeholder={t("enterPhone")}
                />
              </Col>
            </Row>
            <Row>
              <Col xs="12" md="4">
                <Select
                  label={t("companyType")}
                  name="companyType"
                  options={companyTypeList}
                  onChangeValue={getAccessTypesAndCompanies}
                  isOptionDisabled={shouldDisableCompanyTypeOption}
                  isDisabled={hasUser}
                  required
                />
              </Col>
              <Col xs="12" md={hasUser ? "6" : "8"}>
                <Select
                  label={t("company")}
                  name="companyId"
                  options={companyNameList}
                  isDisabled={hasUser || !companyNameList.length}
                  required
                />
              </Col>
              {hasUser && (
                <Col xs="12" md="2">
                  <Input
                    label={t("registration")}
                    name="login"
                    type="text"
                    disabled={hasUser}
                  />
                </Col>
              )}
            </Row>
            <Row>
              <Col xs="12" md="4">
                <Input
                  label={t("role")}
                  name="role"
                  type="text"
                  placeholder={t("enterRole")}
                  required
                />
              </Col>
              <Col xs="12" md="4">
                <Select
                  label={t("accessType")}
                  name="accessType"
                  isDisabled={hasUser || !accessTypeList.length}
                  options={accessTypeList}
                  onChangeValue={({ value }) => {
                    setSelectedAccessType(value);
                    if (![ACCESS_TYPE_ORIGINATOR_C0, "C0"].includes(value))
                      formRef.current.setFieldValue("unitResponsible", false);
                  }}
                  required
                />
              </Col>
              <Col xs="12" md="4" className="d-flex align-items-center">
                <Checkbox
                  label={t("unitResponsible")}
                  name="unitResponsible"
                  type="checkbox"
                  disabled={hasUser}
                />
              </Col>
            </Row>
            <Row>
              <Col xs="12" md="12">
                <Input
                  label="LinkedIn"
                  name="website"
                  type="text"
                  placeholder={t("linkedinLink")}
                />
              </Col>
              <Input type="hidden" name="profilePicture" />
            </Row>
            <Row>
              <Col>
                <TextArea
                  label={t("professionalSummary")}
                  name="professionalSummary"
                  rows={5}
                />
              </Col>
            </Row>
          </CardBody>
        </Card>

        <AddressCard formRef={formRef} />

        <ProfessionalDataCard title={t("professionalData")} />

        <div className="mb-5 text-end">
          <div className="justify-content-end">
            {/* <Button
              id="save"
              type="submit"
              color="info"
              className="waves-effect waves-light btn-lg me-3"
            >
              {t("save")}
            </Button> */}
            <LoadingButton
              id="save"
              className="waves-effect waves-light btn-lg me-3 btn-loader-sm"
              type="submit"
              loading={userState.loading}
              disabled={userState.loading}
              color='info'
              block={true}
            >
              {t("save")}
            </LoadingButton>
            <Button
              id="cancel"
              type="button"
              className="waves-effect waves-light btn-lg"
              color="secondary"
              onClick={handleCancel}
              outline
            >
              {t("cancel")}
            </Button>
          </div>
        </div>
      </Form>
    </>
  );
};

export default CreateEditUser;

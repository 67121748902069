import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Spinner from '../../components/Spinner';
import { signOut } from '../../store/modules/auth/actions';

const Logout: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(signOut());
    navigate('/auth/login');
  }, [dispatch, navigate]);

  return <Spinner />;
};

export default Logout;

import React, { Dispatch, SetStateAction, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import format from "date-fns/format";
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { Row, Col, Button } from "reactstrap";
import i18next from "i18next";

import Select from "../../HookFormFields/Select";
import DataTable from "../../DataTable";
import api from "../../../services/api";
import { useEditListing } from "../../../hooks/useEditListing";

interface LabelValue {
  value: string;
  label: string;
}

interface TabProps {
  rating: LabelValue[];
  setRating: Dispatch<SetStateAction<LabelValue[]>>;
}

interface FormData {
  rating: string;
}

const RatingHistoryTab: React.FC<TabProps> = ({ setRating, rating }) => {
  const { listing, setListing } = useEditListing();
  const { t } = useTranslation("listings");
  const {
    handleSubmit,
    control,
    formState: { errors },
    trigger,
  } = useForm<FormData>({
    resolver: yupResolver(
      Yup.object().shape({
        rating: Yup.string().required(t("requiredField")),
      })
    ),
  });
  const [ratingTypes, setRatingTypes] = useState<LabelValue[]>([]);

  useEffect(() => {
    async function getRatingTypes() {
      try {
        const { data } = await api.get<LabelValue[]>("/ratingtype/dropdown");
        setRatingTypes(data);
      } catch (error) {
        console.log(error)
      }
    }

    getRatingTypes();
  }, []);

  useEffect(() => {
    const obj = ratingTypes.find((e) => e?.label === listing?.rating);
    if (obj) {
      setRating(() => [obj]);
    }
  }, [listing?.rating, ratingTypes]);

  const removeRatingHistoric = (rowIndex: number) => {
    if (listing?.ratingHistoric) {
      const updatedRatingHistoric = listing.ratingHistoric.filter(
        (rat, idx) => idx !== rowIndex
      );
      setListing((prevListing) => {
        const newListing = { ...prevListing, ratingHistoric: updatedRatingHistoric };
        return newListing;
      });
    }
  };

  const submitForm: SubmitHandler<FormData> = (data) => {
    const obj = ratingTypes.find((e) => e.value === data.rating);
    // if (!rating.find(e => e.value === data.rating))
    setRating(() => [obj]);
    setListing((prevListing) => {
      return {
        ...prevListing,
        rating: obj.label
      }
    })
  };

  const remove = (index: number) => {
    setRating((prevRating) => prevRating.filter((_, idx) => idx !== index));
    setListing((prevListing) => {
      return {
        ...prevListing,
        rating: null,
      };
    });
  };

  useEffect(() => {
    if (errors && Object.keys(errors).length > 0) {
      trigger();
    }
  }, [i18next.language]);

  return (
    <>
      <h4 className="card-title mb-4 text-uppercase">{t("ratingHistory")}</h4>
      <form onSubmit={handleSubmit(submitForm)}>
        <Row className="p-2 border rounded border-secondary mb-4 mx-0">
          <Col xs="12" md="4">
            <Select
              label={t("ratingAssigned")}
              name="rating"
              control={control}
              options={ratingTypes}
            />
          </Col>
          <Col xs="12">
            <Button
              className="waves-effect waves-light"
              color="primary"
              type="submit"
            >
              <i className="mdi mdi-plus me-2" />
              {t("add")}
            </Button>
          </Col>
        </Row>
      </form>

      <DataTable
        columns={[
          {
            name: t("ratingAssigned"),
            selector: (row) => row.label,
            center: true,
            sortable: true,
          },
          {
            name: "",
            cell: (row, rowIndex) => (
              <Button
                className="waves-effect waves-light m-1"
                color="danger"
                size="sm"
                onClick={() => remove(rowIndex)}
                >
                <i className="fas fa-trash-alt" />
              </Button>
            ),
            button: true,
          },
        ]}
        className="mb-2"
        highlightOnHover
        noHeader
        noTableHead
        pagination={false}
        data={rating}
      />
      <DataTable
        columns={[
          {
            name: t("rating"),
            selector: (row) => row.rating.name,
            center: true,
            sortable: true,
          },
          {
            name: t("responsible"),
            selector: (row) => row.responsibleName,
            center: true,
            sortable: true,
          },
          {
            name: t("date"),
            selector: (row) => row.time,
            center: true,
            sortable: true,
            cell: (row) => format(new Date(row.time || null), "dd/MM/yyyy"),
          },
          {
            name: "",
            cell: (row, rowIndex) => (
              <Button
                className="waves-effect waves-light m-1"
                color="danger"
                size="sm"
                onClick={() => removeRatingHistoric(rowIndex)}
              >
                <i className="fas fa-trash-alt" />
              </Button>
            ),
            button: true,
          },
        ]}
        key={`rating-${rating.length}`}
        data={listing?.ratingHistoric}
        defaultSortFieldId="time"
        defaultSortAsc={false}
      />
    </>
  );
};

export default RatingHistoryTab;

import { Reducer } from 'redux';
import produce, { Draft } from 'immer';
import { AuthState, AuthTypes } from './types';

const INITIAL_STATE: AuthState = {
  name: '',
  isSigned: false,
  token: '',
  loading: false,
  error: false,
  credentials: {
    name: '',
    password: '',
  },
  responseData: null,
};

const reducer: Reducer<AuthState> = (state = INITIAL_STATE, action) => {
  return produce(state, (draft: Draft<AuthState>) => {
    switch (action.type) {
      case AuthTypes.SIGN_IN_REQUEST: {
        draft.loading = true;
        draft.error = false;
        break;
      }
      case AuthTypes.SIGN_IN_MFA_REQUEST: {
        draft.loading = true;
        draft.error = false;
        break;
      }
      case AuthTypes.SIGN_IN_SUCCESS: {
        draft.isSigned = true;
        draft.token = action.payload.token;
        draft.name = action.payload.name;
        draft.loading = false;
        draft.error = false;
        break;
      }
      case AuthTypes.SIGN_IN_FAILURE: {
        draft.isSigned = false;
        draft.token = '';
        draft.loading = false;
        draft.error = true;
        break;
      }
      case AuthTypes.SIGN_OUT: {
        draft.isSigned = INITIAL_STATE.isSigned;
        draft.token = INITIAL_STATE.token;
        draft.loading = INITIAL_STATE.loading;
        draft.error = INITIAL_STATE.error;
        break;
      }
      case AuthTypes.RESET_IS_SIGNED: {
        draft.isSigned = false;
        break;
      }
      case AuthTypes.SET_TOKEN: {
        draft.token = action.payload;
        break;
      }
      case AuthTypes.SET_CREDENTIALS: {
        draft.credentials.name = action.payload.name;
        draft.credentials.password = action.payload.password;
        break;
      }
      case AuthTypes.SET_RESPONSE_DATA:
      return {
        ...state,
        responseData: action.payload,
      };
      case AuthTypes.SET_LOADING: {
        draft.loading = true;
        break;
      }
      case AuthTypes.CLEAR_LOADING: {
        draft.loading = false;
        break;
      }
      default:
    }
  });
};

export default reducer;

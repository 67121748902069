// Action Types
export enum OfferTypes {
  CREATE_OFFER_REQUEST = "@offer/CREATE_OFFER_REQUEST",
  CREATE_OFFER_SUCCESS = "@offer/CREATE_OFFER_SUCCESS",

  UPDATE_OFFER_REQUEST = "@offer/UPDATE_OFFER_REQUEST",
  UPDATE_OFFER_SUCCESS = "@offer/UPDATE_OFFER_SUCCESS",

  PATCH_PUBLISHED_OFFER_REQUEST = "@offer/PATCH_PUBLISHED_OFFER_REQUEST",
  PATCH_PUBLISHED_OFFER_SUCCESS = "@offer/PATCH_PUBLISHED_OFFER_SUCCESS",
}

// Data type
export interface SelectValue {
  name: string;
  id: string;
}

export interface Guarantor {
  id?: number;
  warrantyId?: number;
  guarantorName: string;
  guarantorCompanyName: string;
  guarantorCpf: string | null;
  guarantorCnpj: string | null;
  guarantorPercentage: number | string;
  documentType?: string;
  status: string;
}
export interface Warranty {
  id?: number;
  offerId?: number;
  warrantyType: string;
  warrantyInstrument: string;
  warrantyInstrumentName: string;
  observation?: string;
  guarantors: Guarantor[];
  status: string;
}

export interface Attachment {
  id?: number;
  offerId?: number;
  attachmentUploadDate?: string;
  attachment: string;
  attachmentName: string;
}

export interface FormData {
  listedCompany: number;
  ticker: string;
  codeISIN: string;
  state: string;
  instrumentType: string;
  totalIssuanceAmount: number;
  lotsQuantity: number;
  unitValue: number;
  remunerationRatePercent: number;
  securitizationInstrument: string;
  indexers: string;
  indexer: string;
  minimumReservationValue: number;
  ipoPublishDate: string; // Date
  givesDividendsAndProfitsRights: boolean;
  bookingStartDate: string; // Date
  resourcesDestination: string;
  frequencyPayments: string;
  investorReservationMaximumPercentage: number;
  amortizationSystem: string;
  investorRemunerationFrequency: string;
  leadingDistributor: number;
  offerDistributors: number[];
  publishDate: string;
  investorMaximumLotsPercentage: number;
  startReservationDate: string;
  transactionExpirationDate: string;
  numberOfInstallments: number;
  emissionDate: string;
  reservationDeadline: string;
  investorPaybackTime: number;
  investorMaximumLotsQuantity: number;
  reservationsMinimumPercentage: string;
  reservationsMinimumValue: number;
  totalEffectiveCost: number;
  contractNumber: number;
  floatingRatePercentage: string;
  latePaymentInterest: string;
  lateFeeValue: number;
  currency: string;
  cashierRelease: string;
  remunerationRate: string;
  capitalizations: string;
  paymentForm: string;
  paymentOtherForm?: string;
  interestCalculationCriterias: string;
  lateFeeOptions: string;
  indexerApplications: string;
  interestRateApplications: string;
  interestRateApplication?: string;
  mandatoryWarranty: Warranty;
  warranties: Warranty[];
  attachments: Attachment[];
  isExclusive: String;
}

export interface Offer {
  id?: number;
  parentId?: number;
  listedCompany: number;
  ticker: string;
  codeISIN: string,
  state: string;
  interestRateApplication?: SelectValue;
  frequencyPayment?: SelectValue;
  capitalization?: SelectValue;
  paymentForm?: SelectValue;
  interestCalculationCriteria?: SelectValue;
  lateFeeOption?: SelectValue;
  indexerApplication?: SelectValue;
  instrumentType: SelectValue;
  totalAmountOffered: number;
  miniContractsQuantity: number;
  unitValue: number;
  remunerationRatePercent: number;
  securitizationInstrument?: string;
  indexers: SelectValue;
  indexer?: SelectValue;
  partialDistribution?: boolean;
  minimumReservationValue: number;
  ipoPublishDate: string; // Date
  participationConversionConditions?: number;
  holderMaximumConvertiblePercentage?: number;
  termConversionContractExpiration?: number;
  participationGivesVotingRights?: boolean;
  minimumPercentageVotingRights?: number;
  givesDividendsAndProfitsRights: boolean;
  dividendsAndProfitsDistributionConditions?: number;
  dividendsAndProfitsDistributionFrequency?: string;
  dividendsAndProfitsDistributionBaseDate?: string;
  bookingStartDate: string; // Date
  assetType?: string;
  resourcesDestination: string;
  investorMaximumLotsPercentage: number;
  investorReservationMaximumPercentage: number;
  frequencyPayments: SelectValue;
  amortizationSystem: SelectValue;
  investorRemunerationFrequency: SelectValue;
  startReservationDate: string;
  lotsQuantity: number;
  emissionDate: string;
  reservationDeadline: string;
  investorPaybackTime: number;
  investorMaximumLotsQuantity: number;
  reservationsMinimumPercentage: string;
  reservationsMinimumValue: number;
  totalEffectiveCost: number;
  contractNumber: number;
  floatingRatePercentage: string;
  latePaymentInterest: string;
  lateFeeValue: number;
  currency: SelectValue;
  cashierRelease: SelectValue;
  remunerationRate: SelectValue;
  capitalizations: SelectValue;
  paymentForms: SelectValue;
  paymentOtherForm?: string;
  interestCalculationCriterias: SelectValue;
  lateFeeOptions: SelectValue;
  indexerApplications: SelectValue;
  interestRateApplications: SelectValue;
  leadingDistributor: {
    id: number;
    name?: string;
    cnpj?: string;
  };
  offerDistributors: {
    id: number;
    name?: string;
    officeType?: string;
    state?: string;
  }[];
  publishDate?: string;
  warranties: Warranty[];
  attachments: Attachment[];
  isExclusive: String;
}

// State Type
export interface OfferState {
  readonly loading: boolean;
}

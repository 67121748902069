import React, { useState, useEffect, useCallback } from "react";
import { Button, Card, CardBody, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import SEO from "../../components/SEO";
import DataTable from "../../components/DataTable";
import SearchBar from "../../components/SearchBar";
import { useBreadcrumb } from "../../hooks/useBreadcrumb";
import usePermissions from "../../hooks/usePermissions";
import { dataContext, permission } from "../../utils/permissionEnums";
import { handleErrorResponse } from "../../utils/error-handlers";
import api from "../../services/api";
import { useEditCompany } from "hooks/useEditCompany";
import { formatCnpj } from "utils/documents";

export interface Company {
  name: string;
  cnpj: string;
  companyName: string;
  companyType: string;
  id: number;
  employees: any[];
}

export interface Address {
  country: string;
  zip: string;
  state: string;
  city: string;
  neighborhood: string;
  street: string;
  number: string;
  additionalInfo: string;
  id: number;
}

export interface RootObject {
  company: Company;
  tradingName: string;
  address: Address;
  email: string;
  telephone: string;
  cellphone: string;
  id: number;
}

interface Request {
  page: number;
  limit: number;
  sort: string;
  count: number;
  content: RootObject[];
}

const CompanyList: React.FC = () => {
  const { t } = useTranslation("companies");

  const { setBreadcrumbItems } = useBreadcrumb();
  const { setCompany: setCompanyHook } = useEditCompany();

  const [listingsData, setListingsData] = useState<RootObject[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [perPage, setPerPage] = useState<number>(10);
  const [totalRows, setTotalRows] = useState<number>(0);
  const [persistedFilters, setPersistedFilters] = useState({
    name: "",
    tradingName: "",
    cnpj: "",
    distributorName: ""
  });

  const hasPermissionToCreate = usePermissions(
    dataContext.LISTED_COMPANY,
    permission.CREATE
  );

  const getCompanies = useCallback(
    async ({
      nameParam = persistedFilters.name,
      cnpjParam = persistedFilters.cnpj,
      tradingNameParam = persistedFilters.tradingName,
      distributorNameParam = persistedFilters.distributorName,
      page = 1,
      limit = perPage,
      signal,
    }: {
      nameParam?: string;
      cnpjParam?: string;
      tradingNameParam?: string;
      distributorNameParam?: string;
      page?: number;
      limit?: number;
      signal?: AbortSignal;
    }) => {
      try {
        setLoading(true);

        const { data: userList } = await api.get<Request>("/investor_company", {
          params: {
            companyName: nameParam,
            cnpj: cnpjParam,
            tradingName: tradingNameParam,
            distributorName: distributorNameParam,
            page,
            limit,
            sort: "id:desc",
          },
          signal,
        });

        setListingsData(userList.content);
        setTotalRows(userList.count);
        setPersistedFilters({
          name: nameParam,
          tradingName: tradingNameParam,
          cnpj: cnpjParam,
          distributorName: distributorNameParam
        });
        setPerPage(limit);
      } catch (error) {
        const { description } = handleErrorResponse(error);

        console.log("[CompanyList][getCompanies]", description);
      } finally {
        setLoading(false);
      }
    },
    [
      perPage,
      persistedFilters.tradingName,
      persistedFilters.cnpj,
      persistedFilters.name,
    ]
  );

  const handleFetchData = ({ name, tradingName, cnpj, distributorName }: any) => {
    getCompanies({
      nameParam: name,
      cnpjParam: cnpj,
      tradingNameParam: tradingName,
      distributorNameParam: distributorName,
    });
  };

  const handlePageChange = (page) => {
    getCompanies({ page });
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    getCompanies({ page, limit: newPerPage });
  };

  useEffect(() => {
    getCompanies({});
  }, [getCompanies]);

  useEffect(() => {
    setBreadcrumbItems({
      title: "companies",
      items: [
        { title: "home", link: "/dashboard" },
        { title: "companies", link: "/companies" },
      ],
    });
  }, [setBreadcrumbItems]);

  return (
    <>
      <SEO
        title={t("companies", { ns: "breadcrumb" })}
        shouldIndexPage={false}
      />

      <Row>
        <Col xs="12" className="mb-3">
          <div className="d-flex flex-md-row justify-content-md-between flex-column align-items-center">
            <div>
              {hasPermissionToCreate && (
                <Link to="/companies/company">
                  <Button
                    id="addListing"
                    size="md"
                    color="primary"
                    className="waves-effect waves-light me-3"
                    onClick={() => setCompanyHook(null)}
                  >
                    <i className="mdi mdi-plus me-2" />
                    {t("newListing")}
                  </Button>
                </Link>
              )}
            </div>

            <SearchBar
              fields={[
                {
                  key: "name",
                  label: t("companyName"),
                  variant: "text",
                },
                {
                  key: "tradingName",
                  label: t("tradingName"),
                  variant: "text",
                },
                {
                  key: "cnpj",
                  label: t("cnpj"),
                  variant: "pattern",
                  mask: "99.999.999/9999-99",
                }, 
                {
                  key: "distributorName",
                  label: t("distributor"),
                  variant: "text",
                },
              ]}
              onSearch={handleFetchData}
            />
          </div>
        </Col>

        <Col xs="12">
          <Card>
            <CardBody>
              <DataTable
                columns={[
                  {
                    name: t("id", { ns: "common" }),
                    cell: ({ id }) => (
                      <Link to={`/companies/${id}`}>
                        <Button
                          key={id}
                          type="button"
                          color="info"
                          size="sm"
                          outline
                          className="waves-effect waves-light"
                        >
                          {id}
                          <i className="mdi mdi-square-edit-outline ms-1" />
                        </Button>
                      </Link>
                    ),
                    button: true,
                  },
                  {
                    name: t("tradingName"),
                    selector: (row) => row.tradingName,
                    center: true,
                    sortable: true,
                  },
                  {
                    name: t("companyName"),
                    selector: (row) => row.company.companyName,
                    center: true,
                    sortable: true,
                  },
                  {
                    name: t("CNPJ"),
                    selector: (row) => formatCnpj(row.company.cnpj),
                    center: true,
                    sortable: false,
                  },
                ]}
                data={listingsData}
                progressPending={loading}
                paginationServer
                paginationTotalRows={totalRows}
                onChangePage={handlePageChange}
                onChangeRowsPerPage={handlePerRowsChange}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default CompanyList;

import { Button } from "reactstrap";

export const TrashButton: React.FC<{ onClick: () => void }> = ({ onClick }) => (
  <Button
    className="waves-effect waves-light m-1"
    color="danger"
    size="sm"
    onClick={onClick}
  >
    <i className="fas fa-trash-alt" />
  </Button>
);

export const ConfirmButton: React.FC<{ onClick: () => void }> = ({ onClick }) => (
  <Button
    className="waves-effect waves-light"
    color="success"
    size="sm"
    onClick={onClick}
  >
    <i className="fas fa-check" />
  </Button>
);

export const CancelButton: React.FC<{ onClick: () => void }> = ({ onClick }) => (
  <Button
    className="waves-effect waves-light"
    color="danger"
    size="sm"
    onClick={onClick}
  >
    <i className="fas fa-times" />
  </Button>
);

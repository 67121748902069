import { action } from 'typesafe-actions';
import { LayoutTypes } from './types';

export const changeLayout = (layout: string): TypeSafeAction<LayoutTypes> =>
  action(LayoutTypes.CHANGE_LAYOUT, layout);

export const changeSidebarTheme = (
  theme: string,
): TypeSafeAction<LayoutTypes> =>
  action(LayoutTypes.CHANGE_SIDEBAR_THEME, theme);

export const changeLayoutWidth = (
  width: string,
  layoutType: string,
): TypeSafeAction<LayoutTypes> =>
  action(LayoutTypes.CHANGE_LAYOUT_WIDTH, { width, layoutType });

export const changeSidebarType = (
  sidebarType: string,
  isMobile?: boolean,
): TypeSafeAction<LayoutTypes> =>
  action(LayoutTypes.CHANGE_SIDEBAR_TYPE, { sidebarType, isMobile });

export const changeTopbarTheme = (
  topbarTheme: string,
): TypeSafeAction<LayoutTypes> =>
  action(LayoutTypes.CHANGE_TOPBAR_THEME, topbarTheme);

export const toggleRightSidebar = (
  isToggle: boolean,
): TypeSafeAction<LayoutTypes> =>
  action(LayoutTypes.TOGGLE_RIGHT_SIDEBAR, { isToggle });

export const hideRightSidebar = (): TypeSafeAction<LayoutTypes> =>
  action(LayoutTypes.HIDE_RIGHT_SIDEBAR, null);

import { Reducer } from 'redux';
import produce, { Draft } from 'immer';
import { UserState, ProfileTypes } from './types';
import { AuthTypes } from '../auth/types';

const INITIAL_STATE: UserState = {
  loading: false,
  profile: null,
  accessType: null,
  createdUser: null,
};

const reducer: Reducer<UserState> = (state = INITIAL_STATE, action) => {
  return produce(state, (draft: Draft<UserState>) => {
    switch (action.type) {
      case AuthTypes.SIGN_IN_SUCCESS: {
        draft.profile = action.payload.profile;
        draft.accessType = action.payload.accessType;
        break;
      }
      case ProfileTypes.UPDATE_PROFILE_SUCCESS: {
        draft.profile = action.payload.profile;
        break;
      }
      case ProfileTypes.CREATE_USER_REQUEST: {
        draft.loading = true;
        break;
      }
      case ProfileTypes.CREATE_USER_SUCCESS: {
        draft.loading = false;
        draft.createdUser = action.payload;
        break;
      }
      case ProfileTypes.CLEAR_CREATED_USER: {
        draft.loading = false;
        draft.createdUser = null;
        break;
      }
      case AuthTypes.SIGN_OUT: {
        draft.profile = INITIAL_STATE.profile;
        draft.accessType = INITIAL_STATE.accessType;
        break;
      }
      default:
    }
  });
};

export default reducer;

import React, { useState, useEffect, useCallback } from "react";
import { Row, Col, Card, CardBody, Button } from "reactstrap";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { differenceInDays } from "date-fns";

import SEO from "../../components/SEO";
import DataTable from "../../components/DataTable";
import SearchBar from "../../components/SearchBar";
import StatusBadge from "../../components/StatusBadge";
import { useBreadcrumb } from "../../hooks/useBreadcrumb";
import api from "../../services/api";
import { formatDateToBrazilian } from "utils/date-time";
import { handleErrorResponse } from "utils/error-handlers";
import { addMessage } from "store/modules/toast/actions";
import { useDispatch } from "react-redux";
import { getApprovalRequestTypes, getApprovalStatus } from "utils/constants";

interface Approval {
  id: number;
  pendingDays: number;
  requestDate: string;
  requestType: string;
  requester: string;
  requesterName: string;
  state: string;
}

interface Request {
  page: number;
  limit: number;
  sort: string;
  count: number;
  content: Approval[];
}

const Approvals: React.FC = () => {
  const { t } = useTranslation("approvals");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { setBreadcrumbItems } = useBreadcrumb();

  const [loading, setIsLoading] = useState(true);
  const [approvalsList, setApprovalsList] = useState<Approval[]>([]);
  const [perPage, setPerPage] = useState(10);
  const [totalRows, setTotalRows] = useState(0);
  const [selectedApprovals, setSelectedApprovals] = useState<Approval[]>([]);
  const [persistedFilters, setPersistedFilters] = useState({
    requestDate: undefined,
    requestType: "",
    state: "",
  });

  const getApprovals = useCallback(
    async ({
      dateParam = persistedFilters.requestDate,
      typeParam = persistedFilters.requestType,
      stateParam = persistedFilters.state,
      page = 1,
      limit = perPage,
      signal,
    }: {
      dateParam?: string;
      typeParam?: string;
      stateParam?: string;
      page?: number;
      limit?: number;
      signal?: AbortSignal;
    }) => {
      try {
        setIsLoading(true);

        const userList = await api.get<Request>("/approval", {
          params: {
            requestDate: dateParam,
            requestType: typeParam,
            state: stateParam,
            page: page,
            limit,
            sort: "id:desc",
          },
          signal,
        });

        setApprovalsList(userList.data.content);
        setTotalRows(userList.data.count);
        setPersistedFilters({
          requestDate: dateParam,
          requestType: typeParam,
          state: stateParam,
        });
      } catch (error) {
        const { description } = handleErrorResponse(error);

        setApprovalsList([]);

        dispatch(
          addMessage({
            title: t("error", { ns: "common" }),
            type: "error",
            description,
          })
        );
      } finally {
        setIsLoading(false);
      }
    },
    [
      perPage,
      persistedFilters.requestDate,
      persistedFilters.requestType,
      persistedFilters.state,
    ]
  );

  const handleFetchData = ({ date, type, state }: any) => {
    getApprovals({ dateParam: date, typeParam: type, stateParam: state });
  };

  const handlePageChange = (page) => {
    getApprovals({ page });
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setPerPage(newPerPage);
    getApprovals({ page, limit: newPerPage });
  };

  useEffect(() => {
    setBreadcrumbItems({
      title: "approvals",
      items: [
        { title: "home", link: "/dashboard" },
        { title: "approvals", link: "/approvals" },
      ],
    });
  }, [setBreadcrumbItems]);

  useEffect(() => {
    const controller = new AbortController();

    getApprovals({ signal: controller.signal });

    return () => {
      controller.abort();
    };
  }, [getApprovals]);

  return (
    <>
      <SEO title={t("approvals")} shouldIndexPage={false} />

      <Row>
        <Col xs="12" className="mb-3">
          <div className="d-flex flex-sm-row justify-content-sm-between flex-column">
            <div />

            <SearchBar
              fields={[
                { key: "date", label: t("requestDate"), variant: "date" },
                {
                  key: "type",
                  label: t("requestType"),
                  variant: "select",
                  options: getApprovalRequestTypes(t),
                },
                {
                  key: "state",
                  label: "Status",
                  variant: "select",
                  options: getApprovalStatus(t),
                },
              ]}
              onSearch={handleFetchData}
            />
          </div>
        </Col>

        <Col xs="12">
          <Card>
            <CardBody>
              <DataTable
                columns={[
                  {
                    name: t("id", { ns: "common" }),
                    cell: ({ id }, rowIndex) => (
                      <Button
                        id={`approval-details-${rowIndex}`}
                        onClick={() => navigate(`/approvals/${id}`)}
                        color="info"
                        size="sm"
                        className="waves-effect waves-light"
                        outline
                      >
                        {id}
                        <i className="mdi mdi-square-edit-outline ms-1" />
                      </Button>
                    ),
                    button: true,
                  },
                  {
                    name: t("requester"),
                    selector: (row) => row.requesterName,
                    center: true,
                    sortable: true,
                  },
                  {
                    name: t("pendingDays"),
                    center: true,
                    sortable: true,
                    sortFunction: (a, b) => {
                      if (new Date(b.requestDate) > new Date(a.requestDate))
                        return 1;
                      if (new Date(b.requestDate) < new Date(a.requestDate))
                        return -1;
                      return 0;
                    },
                    cell: ({ requestDate }) =>
                      differenceInDays(new Date(), new Date(requestDate)),
                  },
                  {
                    name: t("requestDate"),
                    center: true,
                    sortable: true,
                    sortFunction: (a, b) => {
                      if (new Date(b.requestDate) > new Date(a.requestDate))
                        return 1;
                      if (new Date(b.requestDate) < new Date(a.requestDate))
                        return -1;
                      return 0;
                    },
                    cell: ({ requestDate }) =>
                      formatDateToBrazilian(requestDate),
                  },
                  {
                    name: t("requestType"),
                    center: true,
                    sortable: true,
                    cell: ({ requestType }) => t(`common:${requestType}`),
                  },
                  {
                    name: "Status",
                    selector: (row) => `${t(row.state, { ns: "common" })}`,
                    center: true,
                    sortable: true,
                    cell: ({ state }) => <StatusBadge state={state} />,
                  },
                ]}
                data={approvalsList}
                progressPending={loading}
                onChange={(e) => setSelectedApprovals(e.selectedRows)}
                paginationServer
                paginationTotalRows={totalRows}
                onChangePage={handlePageChange}
                onChangeRowsPerPage={handlePerRowsChange}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Approvals;

import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import usePermissions from "../../../hooks/usePermissions";
import { dataContext } from "../../../utils/permissionEnums";
import { changeSidebarType } from "../../../store/modules/layout/actions";

const SidebarContent: React.FC = () => {
  const { t } = useTranslation("common");
  const dispatch = useDispatch();
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  const menuItems = [
    {
      id: "dashboard",
      title: t("dashboard"),
      icon: "mdi mdi-home",
      route: "/dashboard",
      permission: true,
    },
    {
      id: "approvals",
      title: t("approvals"),
      icon: "mdi mdi-clipboard-check-outline",
      route: "/approvals",
      permission: usePermissions(dataContext.APPROVALS),
    },
    {
      id: "offers",
      title: t("offers"),
      icon: "mdi mdi-cash",
      route: "/offers",
      permission: usePermissions(dataContext.OFFER),
    },
    {
      id: "orders",
      title: t("orders"),
      icon: "mdi mdi-cash", //TODO: atualizar com ícone de orders
      route: "/orders",
      permission: usePermissions(dataContext.ORDER),
    },
    {
      id: "users",
      title: t("users"),
      icon: "ion ion-ios-people",
      route: "/users",
      permission: usePermissions(dataContext.USER),
    },
    {
      id: "advisors",
      title: t("advisors"),
      icon: "mdi mdi-office-building",
      route: "/advisors",
      permission: usePermissions(dataContext.ADVISOR),
    },
    {
      id: "companies",
      title: t("companies"),
      icon: "mdi mdi-format-list-bulleted-square",
      route: "/companies",
      permission: usePermissions(dataContext.INVESTOR_COMPANY),
    },
    {
       id: "persons",
       title: t("persons"),
       icon: "mdi mdi-format-list-bulleted-square",
       route: "/persons",
       permission: usePermissions(dataContext.INVESTOR_COMPANY),
     },
    {
      id: "listings",
      title: t("listings"),
      icon: "mdi mdi-format-list-bulleted-square",
      route: "/listings",
      permission: usePermissions(dataContext.LISTED_COMPANY),
    },

    {
      id: "brokers",
      title: t("brokers"),
      icon: "mdi mdi-account-tie",
      route: "/brokers",
      permission: usePermissions(dataContext.BROKER),
    },
    // {
    //   id: 'commissions',
    //   title: t('commissions'),
    //   icon: 'mdi mdi-cash-multiple',
    //   route: '/commissions',
    //   permission: usePermissions(dataContext.COMMISSION),
    // },
    // {
    //   id: 'accountHolders',
    //   title: t('accountHolders'),
    //   icon: 'mdi mdi-cash-usd-outline',
    //   route: '/accountHolders',
    //   permission: usePermissions(dataContext.ACCOUNT_HOLDER), // NÃO EXISTE MAIS
    // },
    // {
    //   id: 'reservations',
    //   title: t('reservations'),
    //   icon: 'mdi mdi-safe',
    //   route: '/reservations',
    //   permission: usePermissions(dataContext.RESERVATION), // NÃO EXISTE MAIS
    // },
    // {
    //   id: "investors",
    //   title: t("investors"),
    //   icon: "mdi mdi-account-cash",
    //   route: "/investors",
    //   permission: usePermissions(dataContext.INVESTOR_USER),
    // },
    // {
    //   id: 'recommendations',
    //   title: t('recommendations'),
    //   icon: 'mdi mdi-newspaper-variant-multiple-outline',
    //   route: '/recommendations',
    //   permission: true,
    // },
    // {
    //   id: 'currentAccount',
    //   title: t('currentAccount'),
    //   icon: 'fas fa-credit-card',
    //   route: '/currentAccount',
    //   permission: true,
    // },
  ];

  useEffect(() => {
    document.body.setAttribute("data-sidebar", "dark");
  }, []);

  function sidebarToggle() {
    if (!isMobile) return;
    dispatch(changeSidebarType("hide", true));
  }

  return (
    <div id="sidebar-menu">
      <ul className="metismenu list-unstyled" id="side-menu">
        <li id="home" className="menu-title">
          Início
        </li>

        {menuItems.map((item) => {
          if (!item.permission) return null;

          return (
            <li key={`sidebarMenu-${item.id}`}>
              <NavLink
                id={item.id}
                to={item.route}
                className={({ isActive }) => {
                  return `waves-effect ${isActive ? " waves-effect mm-active" : ""
                    }`;
                }}
                onClick={sidebarToggle}
              >
                <i className={item.icon} />
                <span>{item.title}</span>
              </NavLink>
            </li>
          );
        })}
        <li id="help" key={`sidebarMenu-help`}>
          <NavLink
            target="_blank"
            to=""
            onClick={(e) => {
              window.location.href = "mailto:atendimento@bolsaotc.com.br";
              e.preventDefault();
            }}
            className={({ isActive }) => {
              return `waves-effect ${isActive ? " waves-effect mm-active" : ""
                }`;
            }}
          >
            <i className="mdi mdi-help" />
            <span> {t("help")}</span>
          </NavLink>
        </li>
      </ul>
    </div>
  );
};

export default SidebarContent;

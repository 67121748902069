import React, { useState } from "react";
import { Controller, Control, get } from "react-hook-form";
import {
  FormGroup,
  Button,
  Label,
  Input,
  InputGroup,
  InputProps,
} from "reactstrap";

interface Props {
  name: string;
  label: string;
  control: Control<any>;
  required?: boolean;
  aFilename?: string;
}

type InputFileProps = InputProps & Props;

const FileInputHookForm: React.FC<InputFileProps> = ({
  name,
  label,
  control,
  required = false,
  aFilename = "",
  ...rest
}) => {
  const [filename, setFileName] = useState(aFilename);

  return (
    <Controller
      name={name}
      control={control}
      defaultValue=""
      render={({
        field: { value, onBlur, onChange, ...restField },
        formState: { errors },
      }) => {
        const error = get(errors, name);

        const [isFocused, setIsFocused] = useState(false);

        const getStyles = (pStyle) => {
          if (isFocused) {
            return {
              ...pStyle,
              borderColor: "#2684FF",
              boxShadow: "0 0 0 1px #2684FF",
            };
          }

          return { ...pStyle };
        };

        const handleBlur = () => {
          setIsFocused(false);

          if (onBlur) {
            onBlur();
          }
        };

        const handleChange = (e) => {
          const { files } = e.target;

          if (files && files[0]) {
            const reader = new FileReader();

            reader.onload = (eLoad) => {
              onChange(eLoad.target?.result as string);
            };

            reader.readAsDataURL(files[0]);
            setFileName(files[0].name);
          }
        };

        return (
          <FormGroup>
            <Label for={name}>{`${label}${required ? " *" : ""}`}</Label>
            <InputGroup>
              <Input
                id={name}
                className={`${error && "is-invalid"}`}
                type="file"
                aria-invalid={error !== undefined ? "true" : "false"}
                onChange={handleChange}
                onFocus={() => setIsFocused(true)}
                onBlur={handleBlur}
                style={getStyles(rest.style)}
                {...rest}
                {...restField}
              />
              {value && (
                <Button
                  id={`download-${name}`}
                  color="primary"
                  href={value}
                  download={filename}
                >
                  <i className="fas fa-download" />
                </Button>
              )}
            </InputGroup>
            {error && <span className="error">{error.message}</span>}
          </FormGroup>
        );
      }}
    />
  );
};
export default FileInputHookForm;

export const dataContext = {
  USER: 'user',
  ADVISOR: 'advisor',
  INVESTOR_COMPANY: "investorPj",
  LISTED_COMPANY: 'listedCompany',
  OFFER: 'offer',
  ORDER: 'order',
  BROKER: 'broker',
  RESERVATION: 'reservation',
  RATING_LISTED_COMPANY: 'ratingListedCompany',
  APPROVALS: 'approvals',
  INVESTOR_USER: 'investorUser',
  INVESTOR_RESUME: 'investorPfResume',
  INVESTOR_BASIC: 'investorPfBasic', // pendente analise de permissões no backend sem uso no front
  INVESTOR_FINANCIAL: 'investorPfFinancial', // pendente analise de permissões no backend sem uso no front
  ADM: 'ADMINISTRATOR', // pendente analise de permissões no backend sem uso no front
  COMPANIES: 'companies', // pendente analise de permissões no backend sem uso no front
  ISSUE_RESERVATION: 'issue_reservation', // pendente analise de permissões no backend sem uso no front
  RESERVE: 'reserve',  // pendente analise de permissões no backend sem uso no front
  TRACKING: 'tracking',  // pendente analise de permissões no backend sem uso no front
  COMMISSION: 'commission', // pendente análise bolsa otc
  ACCOUNT_HOLDER: 'accountHolder', // pendente análise bolsa otc
};

export const permission = {
  CREATE: 'create',
  SUBMIT: 'submit',
  READ: 'read',
  UPDATE: 'update',
  BLOCK: 'block',
  UNBLOCK: 'unblock',
  PUBLISH: 'publish',
  SIGN: 'sign',
  VALIDATE: 'validate',
  REFUSE: 'refuse',
  PUBLISH_IPO: 'publish_ipo',
  APPROVE: 'approve',
  CANCEL: 'cancel',
  REJECT: 'reject',
  CANCELED: 'canceled',
  RESERVE: 'reserve',
  EXECUTE: 'execute',
  SETTLED: 'settled',
  INVALIDATE: 'invalidate',
  UPLOAD: 'upload',
  CONFIRM: 'confirm',
  EMIT_JOB: 'emitJob',
  REVIEW_JOB: 'reviewJob',
  DISABLE: "disable"
};

import React, { useState, useEffect } from 'react';
import {
  Row,
  Col,
  Card,
  CardBody,
  Label,
  Input as Inp,
  FormGroup,
  Button,
} from 'reactstrap';
import { useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Form } from '@unform/web';
import { useDispatch } from 'react-redux';
import addDays from 'date-fns/addDays';

import SEO from '../../components/SEO';
import ActionBar from '../../components/ActionBar';
import Spinner from '../../components/Spinner';
import Tabs from '../../components/Tabs';
import Input from '../../components/FormFields/Input';
import InputMask from '../../components/FormFields/InputMask';
import DatePicker from '../../components/FormFields/DatePicker';
import Checkbox from '../../components/FormFields/Checkbox';
import CurrentAccount from '../../components/CurrentAccount';
import { useBreadcrumb } from '../../hooks/useBreadcrumb';
import usePermissions from '../../hooks/usePermissions';
import { useInvestor } from '../../hooks/useInvestor';
import { dataContext, permission } from '../../utils/permissionEnums';
import api from '../../services/api';
import { addMessage } from '../../store/modules/toast/actions';

interface InvestorResume {
  id: string;
  birthdate: string;
  cpf: string;
  emailConfirmed: boolean;
  firstName: string;
  investmentAdvisorId: number;
  investmentAdvisorName: string;
  politicallyExposed: boolean;
  qualifiedFormSubmitted: boolean;
  status: string;
  suitabilityFormSubmitted: boolean;
  wallet: string;
}

interface InvestorFinancial {
  id: string;
  annualIncome: number;
  qualification: string;
  suitability: string;
  qualifiedForm: {
    qualifiedQuestion: {
      id: string;
      allowsMultipleAnswers: boolean;
      question: string;
      qualifiedAnswers: {
        id: string;
        answer: string;
        isChoicedAnswer: boolean;
      }[];
    }[];
  };
  suitabilityForm: {
    suitabilityQuestion: {
      id: string;
      allowsMultipleAnswers: boolean;
      question: string;
      suitabilityAnswers: {
        id: string;
        answer: string;
        isChoicedAnswer: boolean;
      }[];
    }[];
  };
}

const InvestorDetails: React.FC = () => {
  const { id } = useParams();
  const { setBreadcrumbItems } = useBreadcrumb();
  const dispatch = useDispatch();
  const { investor } = useInvestor();
  const navigate = useNavigate();
  const { t } = useTranslation('investors');
  const [resumeInfo, setResumeInfo] = useState<InvestorResume>(null);
  const [financialInfo, setFinancialInfo] = useState<InvestorFinancial>(null);
  const hasPermissionToReadResume = usePermissions(
    dataContext.INVESTOR_RESUME,
    permission.READ,
  );
  const hasPermissionToReadFinancial = usePermissions(
    dataContext.INVESTOR_FINANCIAL,
    permission.READ,
  );
  const hasPermissionToBlock = usePermissions(
    dataContext.INVESTOR_USER,
    permission.BLOCK,
  );

  useEffect(() => {
    setBreadcrumbItems({
      title: 'investorDetails',
      items: [
        { title: 'home', link: '/dashboard' },
        { title: 'systemInvestors', link: '/investors' },
        { title: 'investorDataSheet', link: '/' },
      ],
    });
  }, [setBreadcrumbItems]);

  useEffect(() => {
    async function getInvestorResume() {
      try {
        const { data } = await api.get<InvestorResume>(
          `/investors/${id}/resume`,
        );

        setResumeInfo({
          ...data,
          birthdate: addDays(new Date(data.birthdate), 1).toISOString(),
        });
      } catch (error) {
        console.log(error);
      }
    }

    async function getInvestorFinancial() {
      try {
        const { data } = await api.get<InvestorFinancial>(
          `/investors/${id}/financial`,
        );
        setFinancialInfo(data);
      } catch (error) {
        console.log(error);
      }
    }

    if (hasPermissionToReadResume && investor?.id) getInvestorResume();
    if (hasPermissionToReadFinancial && investor?.id) getInvestorFinancial();
  }, [
    id,
    hasPermissionToReadResume,
    hasPermissionToReadFinancial,
    investor?.id,
  ]);

  const blockUnblockInvestor = async param => {
    try {
      const { data } = await api.patch(`/investors/${id}/status`, {
        status: param ? 'BLOCKED' : 'ACTIVE',
      });
      setResumeInfo(data);
      dispatch(
        addMessage({
          title: param ? t('investorBlocked') : t('investorUnblocked'),
          type: 'info',
        }),
      );
    } catch (error) {
      console.log(error);
    }
  };

  async function handleAction(
    type: 'REFUSE' | 'BLOCK' | 'PUBLISH' | 'SUBMIT' | 'UPDATE' | 'UNBLOCK',
  ) {
    switch (type) {
      case 'BLOCK':
        blockUnblockInvestor(true);
        break;
      case 'UNBLOCK':
        blockUnblockInvestor(false);
        break;
      default:
        break;
    }
  }

  if (!investor?.id)
    return (
      <>
        <Button
          color="primary"
          onClick={() => navigate('/investors', { replace: true })}
        >
          <i className="mdi mdi-arrow-left" />
        </Button>
        <Spinner />
      </>
    );

  return (
    <>
      <SEO title={t('visualizeInvestor')} shouldIndexPage={false} />

      <Row>
        <Col xl="12">
          <div className="text-end mb-4">
            <div className="button-items">
              <ActionBar
                actions={[
                  {
                    permission: hasPermissionToBlock,
                    action:
                      resumeInfo?.status === 'BLOCKED'
                        ? permission.UNBLOCK
                        : permission.BLOCK,
                  },
                ]}
                onClick={handleAction}
              />
            </div>
          </div>
        </Col>
      </Row>

      <Card>
        <CardBody>
          <Tabs
            tabs={[
              {
                id: 1,
                icon: 'fas fa-address-book',
                title: t('investorBasicData'),
                content: (
                  <Form initialData={investor} onSubmit={() => ({})}>
                    <h4 className="card-title text-info mb-4 text-uppercase">
                      {t('investorBasicData')}
                    </h4>
                    <Row>
                      <Col xs="12" md="4">
                        <Input label={t('lastName')} name="lastName" disabled />
                      </Col>
                      <Col xs="12" md="4">
                        <Input label={t('sex')} name="sexo" disabled />
                      </Col>
                      <Col xs="12" md="4">
                        <Input
                          label={t('profession')}
                          name="profession"
                          disabled
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col xs="12" md="4">
                        <Input
                          label={t('motherName')}
                          name="motherName"
                          disabled
                        />
                      </Col>
                      <Col xs="12" md="4">
                        <InputMask
                          label={t('cellphone')}
                          name="cellNumber"
                          format="(##) #####-####"
                          disabled
                        />
                      </Col>
                      <Col xs="12" md="4">
                        <Input label={t('email')} name="email" disabled />
                      </Col>
                    </Row>
                    <Row>
                      <Col xs="12" md="4">
                        <Input
                          label={t('nationality')}
                          name="nationality"
                          disabled
                        />
                      </Col>
                      <Col xs="12" md="4">
                        <Input
                          label={t('placeOfBirth')}
                          name="placeOfBirth"
                          disabled
                        />
                      </Col>
                      <Col xs="12" md="4">
                        <Input label={t('address')} name="address" disabled />
                      </Col>
                    </Row>
                  </Form>
                ),
              },
              hasPermissionToReadResume && {
                id: 2,
                icon: 'fas fa-address-card',
                title: t('registrationData'),
                content: resumeInfo?.id && (
                  <Form initialData={resumeInfo} onSubmit={() => ({})}>
                    <h4 className="card-title text-info mb-4 text-uppercase">
                      {t('registrationData')}
                    </h4>
                    <Row>
                      <Col xs="12" md="4">
                        <Input label={t('name')} name="firstName" disabled />
                      </Col>
                      <Col xs="12" md="4">
                        <DatePicker
                          label={t('birthdate')}
                          name="birthdate"
                          dateFormat="dd/MM/yyyy"
                          disabled
                        />
                      </Col>
                      <Col xs="12" md="4">
                        <InputMask
                          label="CPF"
                          name="cpf"
                          format="###.###.###-##"
                          disabled
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col xs="12" md="6">
                        <Input label={t('wallet')} name="wallet" disabled />
                      </Col>
                      <Col xs="12" md="6">
                        <Input
                          label={t('investmentAdviser')}
                          name="investmentAdvisorName"
                          disabled
                        />
                      </Col>
                    </Row>
                    <Col className="px-0">
                      <Checkbox
                        label={t('emailConfirmed')}
                        name="emailConfirmed"
                        disabled
                      />
                      <Checkbox
                        label={t('suitabilityFormSubmitted')}
                        name="suitabilityFormSubmitted"
                        disabled
                      />
                      <Checkbox
                        label={t('politicallyExposed')}
                        name="politicallyExposed"
                        disabled
                      />
                      <Checkbox
                        label={t('qualifiedFormSubmitted')}
                        name="qualifiedFormSubmitted"
                        disabled
                      />
                    </Col>
                  </Form>
                ),
              },
              hasPermissionToReadFinancial && {
                id: 3,
                icon: 'fas fa-money-check-alt',
                title: t('financialData'),
                content: financialInfo?.id && (
                  <Form initialData={financialInfo} onSubmit={() => ({})}>
                    <h4 className="card-title text-info mb-4 text-uppercase">
                      {t('financialData')}
                    </h4>
                    <Row>
                      <Col xs="12" md="12">
                        <InputMask
                          label={t('annualIncome')}
                          name="annualIncome"
                          thousandSeparator="."
                          decimalSeparator=","
                          prefix="R$ "
                          disabled
                        />
                      </Col>
                    </Row>

                    <Row className="mx-0 mb-2">
                      <h4 className="card-title text-info mb-4 text-uppercase">
                        {t('qualifiedForm')}
                      </h4>
                      {resumeInfo?.qualifiedFormSubmitted || !resumeInfo ? (
                        financialInfo?.qualifiedForm.qualifiedQuestion.map(
                          (qQ, qQi) => (
                            <Col xs="12" md="12" key={qQ.id}>
                              <p className="fw-bold">
                                {`${qQi + 1} - ${qQ.question}`}
                              </p>
                              {qQ.qualifiedAnswers.map((qA, qI) => (
                                <FormGroup
                                  key={qA.id}
                                  className="mb-2"
                                  check
                                  inline
                                >
                                  <Inp
                                    type="checkbox"
                                    defaultChecked={qA.isChoicedAnswer}
                                    disabled
                                  />
                                  <Label className="fw-normal" check>
                                    {qA.answer}
                                  </Label>
                                </FormGroup>
                              ))}
                            </Col>
                          ),
                        )
                      ) : (
                        <Col xs="12" md="12">
                          <Label>{t('unansweredQualifiedForm')}</Label>
                        </Col>
                      )}
                    </Row>
                    <Row className="mx-0 mb-2">
                      <h4 className="card-title text-info mb-4 text-uppercase">
                        {t('suitabilityForm')}
                      </h4>
                      {resumeInfo?.suitabilityFormSubmitted || !resumeInfo ? (
                        financialInfo?.suitabilityForm.suitabilityQuestion.map(
                          (qQ, qQi) => (
                            <Col xs="12" md="12" key={qQ.id}>
                              <p className="fw-bold">
                                {`${qQi + 1} - ${qQ.question}`}
                              </p>
                              {qQ.suitabilityAnswers.map((qA, qI) => (
                                <FormGroup
                                  key={qA.id}
                                  className="mb-2"
                                  check
                                  inline
                                >
                                  <Inp
                                    type="checkbox"
                                    defaultChecked={qA.isChoicedAnswer}
                                    disabled
                                  />
                                  <Label className="fw-normal" check>
                                    {qA.answer}
                                  </Label>
                                </FormGroup>
                              ))}
                            </Col>
                          ),
                        )
                      ) : (
                        <Col xs="12" md="12">
                          <Label>{t('unansweredSuitabilityForm')}</Label>
                        </Col>
                      )}
                    </Row>
                  </Form>
                ),
              },
              {
                id: 4,
                icon: 'fas fa-money-check-alt',
                title: t('accountStatement'),
                content: (
                  <CurrentAccount
                    account={{
                      accountNum: '9827465-2',
                      agency: '0001',
                      blockedInFlow: 700,
                      freeToUse: 300,
                      totalBalance: 1000,
                    }}
                  />
                ),
              },
            ]}
          />
        </CardBody>
      </Card>
    </>
  );
};

export default InvestorDetails;

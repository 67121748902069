import React from 'react';
import { useTransition } from 'react-spring';
import { useSelector } from 'react-redux';

import { ApplicationState } from '../store';
import { ToastMessage } from '../store/modules/toast/types';
import Toast from './Toast';

const ToastContainer: React.FC = () => {
  const messages = useSelector<ApplicationState, ToastMessage[]>(
    state => state.toast.messages,
  );
  const messagesWithTransitions = useTransition(
    messages,
    message => message.id,
    {
      from: { right: '-120%', opacity: 0 },
      enter: { right: '0%', opacity: 1 },
      leave: { right: '-120%', opacity: 0 },
    },
  );

  return (
    <div
      className="position-fixed p-2 overflow-hidden align-items-center"
      style={{ zIndex: 1003, right: 0, top: '10%' }}
    >
      {messagesWithTransitions.map(({ item, key, props }) => (
        <Toast key={key} message={item} style={props} />
      ))}
    </div>
  );
};

export default ToastContainer;

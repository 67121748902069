import React from 'react';
import { Card, CardBody, Row, Col } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { FormHandles } from '@unform/core';

import Input from './FormFields/Input';
import InputMask from './FormFields/InputMask';

interface AddressCardProps {
  disabled?: boolean;
  prefix?: string;
  titleColor?: string;
  showCard?: boolean;
  formRef: React.MutableRefObject<FormHandles>;
}

const AddressCard: React.FC<AddressCardProps> = ({
  disabled = false,
  prefix = '',
  titleColor,
  showCard = true,
  formRef,
}) => {
  const { t } = useTranslation();

  const searchCep = async (e: React.FocusEvent<HTMLInputElement>) => {
    const cep = e.target.value.replace(/\D/g, '');
    formRef.current.setErrors({});

    if (cep !== '') {
      const validacep = /^[0-9]{8}$/;

      if (!validacep.test(cep)) {
        formRef.current.setErrors({
          'address.zip': t('invalidZip'),
        });
        return;
      }

      const { data } = await axios.get(`https://viacep.com.br/ws/${cep}/json`);
      if (data.erro) {
        formRef.current.setErrors({
          'address.zip': t('notFoundZip'),
        });
        return;
      }

      formRef.current.setFieldValue('address.street', data.logradouro);
      // formRef.current.setFieldValue('address.additionalInfo', data.complemento);
      formRef.current.setFieldValue('address.neighborhood', data.bairro);
      formRef.current.setFieldValue('address.city', data.localidade);
      formRef.current.setFieldValue('address.country', 'Brasil');
      formRef.current.setFieldValue('address.state', data.uf);
    } else {
      formRef.current.setFieldValue('address.street', '');
      // formRef.current.setFieldValue('address.additionalInfo', '');
      formRef.current.setFieldValue('address.neighborhood', '');
      formRef.current.setFieldValue('address.city', '');
      formRef.current.setFieldValue('address.country', '');
      formRef.current.setFieldValue('address.state', '');
    }
  };

  return (
    <Card style={{ display: showCard ? 'flex' : 'contents' }}>
      <CardBody className={`${showCard ? '' : 'p-0'}`}>
        <h4 className={`card-title mb-4 text-uppercase ${titleColor || ''}`}>
          {t('address')}
        </h4>
        <Row>
          <Col xs="12" md="4">
            <InputMask
              label={t('cep')}
              format="#####-###"
              name={prefix.concat('address.zip')}
              placeholder={t('enterCep')}
              disabled={disabled}
              onBlur={searchCep}
            />
          </Col>
          <Col xs="12" md="4">
            <Input
              label={t('country')}
              name={prefix.concat('address.country')}
              type="text"
              placeholder={t('enterCountry')}
              disabled
            />
          </Col>
          <Col xs="12" md="4">
            <Input
              label={t('state')}
              name={prefix.concat('address.state')}
              type="text"
              placeholder={t('enterState')}
              disabled
            />
          </Col>
        </Row>
        <Row>
          <Col xs="12" md="4">
            <Input
              label={t('city')}
              name={prefix.concat('address.city')}
              type="text"
              placeholder={t('enterCity')}
              disabled
            />
          </Col>
          <Col xs="12" md="5">
            <Input
              label={t('street')}
              name={prefix.concat('address.street')}
              type="text"
              placeholder={t('enterStreet')}
              disabled
            />
          </Col>
          <Col xs="12" md="3">
            <Input
              label={t('number')}
              name={prefix.concat('address.number')}
              type="text"
              placeholder={t('enterNumber')}
              disabled={disabled}
            />
          </Col>
        </Row>
        <Row>
          <Col xs="12" md="6">
            <Input
              label={t('neighborhood')}
              name={prefix.concat('address.neighborhood')}
              type="text"
              placeholder={t('enterNeighborhood')}
              disabled
            />
          </Col>
          <Col xs="12" md="6">
            <Input
              label={t('complement')}
              name={prefix.concat('address.additionalInfo')}
              type="text"
              placeholder={t('enterComplement')}
              disabled={disabled}
            />
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export default AddressCard;

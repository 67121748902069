import React, { useEffect, useRef, useState } from "react";
import NumberFormat, { NumberFormatProps } from "react-number-format";
import { useField } from "@unform/core";

interface Props
  extends NumberFormatProps<React.InputHTMLAttributes<HTMLInputElement>> {
  name: string;
  label: string;
  onChange?(e): void;
}

const InputMask: React.FC<Props> = ({
  name,
  label,
  onChange,
  required = false,
  onFocus,
  onBlur,
  ...rest
}) => {
  const { fieldName, registerField, defaultValue, error } = useField(name);

  const inputRef = useRef(null);

  const [inputValue, setInputValue] = useState<string>(defaultValue || "");
  const [isFocused, setIsFocused] = useState(false);

  const getStyles = (pStyle) => {
    if (isFocused) {
      return {
        ...pStyle,
        borderColor: "#2684FF",
        boxShadow: "0 0 0 1px #2684FF",
      };
    }

    return { ...pStyle };
  };

  const handleFocus = (e) => {
    setIsFocused(true);

    if (onFocus) {
      onFocus(e);
    }
  };

  const handleBlur = (e) => {
    setIsFocused(false);

    if (onBlur) {
      onBlur(e);
    }
  };

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      getValue: (ref) => {
        return inputValue;
      },
      setValue: (ref, value: string) => {
        setInputValue(value);
      },
      clearValue: (ref) => {
        setInputValue("");
      },
    });
  }, [fieldName, registerField, inputValue]);

  return (
    <div className="mb-3">
      {label && (
        <label htmlFor={fieldName}>{`${label}${required ? " *" : ""}`}</label>
      )}
      <NumberFormat
        id={fieldName}
        getInputRef={inputRef}
        defaultValue={defaultValue}
        value={inputValue}
        className={`form-control ${error && "is-invalid"}`}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onValueChange={(values) => {
          if (onChange) onChange(values);
          setInputValue(values.value);
        }}
        thousandSeparator="."
        decimalSeparator=","
        allowedDecimalSeparators={[",", "."]}
        mask="_"
        style={getStyles(rest.style)}
        {...rest}
      />
      {error && <span className="error">{error}</span>}
    </div>
  );
};

export default InputMask;

/* eslint-disable react/function-component-definition */
import DataTable, { TableProps, TableStyles } from 'react-data-table-component';
import { useTranslation } from 'react-i18next';

import Spinner from './Spinner';

interface ObjectFromTable {
  allSelected: boolean;
  selectedCount: number;
  selectedRows: any[];
}

interface Props<T> extends TableProps<T> {
  onChange?(obj: ObjectFromTable): void;
  disabledCondition?(row: T): boolean;
}

function DataTableComponent<T>({
  onChange,
  disabledCondition,
  ...rest
}: Props<T>) {
  const { t } = useTranslation('common');

  const customStyles: TableStyles = {
    headRow: {
      style: {
        backgroundColor: '#005CBE',
        color: '#fff',
      },
    },
    headCells: {
      style: {
        color: '#fff',
      },
      // activeSortStyle: {
      //   fontWeight: 'bold',
      // },
    },
    subHeader: {
      style: {
        width: '100%',
        justifyContent: 'start',
      },
    },
    expanderRow: {
      style: {
        borderLeft: '2px solid lightgray',
      },
    },
  };

  return (
    <DataTable
      noHeader
      persistTableHead
      onSelectedRowsChange={e => onChange && onChange(e)}
      selectableRowDisabled={disabledCondition}
      pagination
      paginationRowsPerPageOptions={[5, 10, 15, 20]}
      paginationComponentOptions={{
        rowsPerPageText: t('rowsPerPageText'),
        rangeSeparatorText: t('rangeSeparatorText'),
        noRowsPerPage: false,
        selectAllRowsItem: false,
        selectAllRowsItemText: t('selectAllRowsItemText'),
      }}
      noDataComponent={<div style={{ padding: '24px', width: '100%', display: 'flex', justifyContent: 'flex-start' }}>{t('noData')}</div>}
      progressComponent={<Spinner hasHeight={false} />}
      customStyles={customStyles}
      {...rest}
    />
  );
}

export default DataTableComponent;

import React, {
  createContext,
  useState,
  useContext,
  Dispatch,
  SetStateAction,
  useMemo,
} from 'react';

import { Profile, Address } from '../store/modules/user/types';

interface LinkedUser {
  name: string;
  cpf: string;
  role: string;
  telephone: string;
  login?: string;
  profilePicture?: string;
  accessType?: string;
}

interface AdvisorType {
  id: string;
  name: string;
}

interface Company {
  id: number;
  cnpj: string;
  name: string;
  companyType: string;
  employees: any[];
}

export interface Advisor {
  id: number;
  type: AdvisorType;
  company: Company;
  state: string;
  address?: Address;
  tradingName: string;
  website: string;
  email: string;
  telephone: string;
  cellphone: string;
  quantityEmployee: number;
  quantityEmployeeId: any;
  annualBillingRange: number;
  revenueSize: any;
  size: any;
  financial: LinkedUser;
  unitResponsible: LinkedUser;
  parentId: number;
  createdBy: Profile;
}

interface EditAdvisorContextData {
  advisor: Advisor;
  setAdvisor: Dispatch<SetStateAction<Advisor>>;
  hasAdvisor: boolean;
}

const EditAdvisorContext = createContext<EditAdvisorContextData>(
  {} as EditAdvisorContextData,
);

export const EditAdvisorProvider: React.FC = ({ children }) => {
  const [advisor, setAdvisor] = useState<Advisor>(null);
  const advisorProviderValue = useMemo(
    () => ({ advisor, setAdvisor, hasAdvisor: !!advisor }),
    [advisor, setAdvisor],
  );

  return (
    <EditAdvisorContext.Provider value={advisorProviderValue}>
      {children}
    </EditAdvisorContext.Provider>
  );
};

export function useEditAdvisor(): EditAdvisorContextData {
  const context = useContext(EditAdvisorContext);

  if (!context) {
    throw new Error(
      'useEditAdvisor must be used within an EditAdvisorProvider',
    );
  }

  return context;
}

import NaturalPersonDistributors from "components/naturalPerson/NaturalPersonDistributor";
import SEO from "components/SEO";
import { useBreadcrumb } from "hooks/useBreadcrumb";
import { useEditNaturalPerson } from "hooks/useEditNaturalPerson";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom"
import { Button, Card, CardBody } from "reactstrap";
import { LabelValue } from "store/modules/company/types";
import { addMessage } from "store/modules/toast/actions";
import { isValidCPF } from "utils/validateDocuments";
import * as Yup from "yup";
import { SubmitHandler, FormHandles } from "@unform/core";
import { createNaturalPersonRequest, updateNaturalPersonRequest } from "store/modules/listing/actions";
import NaturalPersonTab from "components/listingNaturalPerson/create/NaturalPerson";
import { Form } from "@unform/web";
import api from "services/api";
import { formatCnpj } from "utils/documents";
import i18next from "i18next";

const CreateEditNaturalPerson: React.FC = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { t } = useTranslation("persons");

    const { setBreadcrumbItems } = useBreadcrumb();
    const { hasNaturalPerson, naturalPerson, setNaturalPerson } = useEditNaturalPerson();
    const [currentDistributors, setCurrentDistributors] = useState<LabelValue[]>([]);
    const [listDistributors, setListDistributors] = useState<LabelValue[]>([]);
    const [lockListDistributors, setLockListDistributors] = useState<LabelValue[]>([]);

    function extractDataArray(arr) {
        return arr.map((val) => {
          // eslint-disable-next-line no-underscore-dangle
          if (val.__isNew__) {
            return {
              name: val.value,
            };
          }
          return {
            name: val.label || val.name,
            id: val.value || val.id,
          };
        });
      }

      const formRef = useRef<FormHandles>();

      const handleSubmit: SubmitHandler = async (data) => {
        try {
          formRef.current.setErrors({});
          const newData = {
            ...data,
          };

          const schema = Yup.object().shape({
            address: Yup.object().shape({
              zip: Yup.string().test(
                "len",
                t("invalidValue"),
                ({ length }) => length === 0 || length === 8
              ),
            }),
            cpf: Yup.string()
              .nullable()
              .required(t("requiredField"))
              .test({
                message: t("invalidValue"),
                test: (value) => {
                  if (value) {
                    return isValidCPF(value);
                  }

                  return false;
                },
              }),
            name: Yup.string().required(t("requiredField")),
            // maritalStatus: Yup.string().required(t("requiredField")),
            // birthDate: Yup.string().required(t("requiredField")),
            telephone: Yup.string().test({
              message: t("invalidValue"),
              test: (value) => {
                if(value != ""){
                  if(value.length < 12){
                    return false;
                  }
                }
                return true;
            }}),
            email: Yup.string().required(t("requiredField"))
          });
          await schema.validate(newData, {
            abortEarly: false,
          });

          newData.company = {
            cnpj: newData.cpf,
            companyType: "INVESTOR_PEOPLE",
            name: newData.name,
            companyName: newData.name
          }

          const newObj: any = {
            cpf: newData.cpf,
            name: newData.name ?? newData.name,
            maritalStatus: newData.maritalStatus ?? newData.maritalStatus,
            birthDate: newData.birthDate ?? newData.birthDate,
            address: newData.address,
            email: newData.email,
            telephone: newData.telephone,
            cellphone: newData.cellphone,
            distributors: extractDataArray(currentDistributors),
            company: newData.company
          };

          if (newObj.distributors.length === 0) {
            dispatch(
              addMessage({
                title: t("attention"),
                description: t("alertAssociateDistributor"),
                type: "info",
              })
            );
            return false;
          }

          if (hasNaturalPerson) {
            newObj.id = naturalPerson.id;
            dispatch(updateNaturalPersonRequest(newObj, navigate));
          } else {
            dispatch(createNaturalPersonRequest(newObj, navigate));
          }
        } catch (err) {
          const validationErrors = {};

          if (err instanceof Yup.ValidationError) {
            err.inner.forEach((error) => {
              validationErrors[error.path] = error.message;
            });

            formRef.current.setErrors(validationErrors);

            dispatch(
              addMessage({
                title: t("attention"),
                description: t("checkFields"),
                type: "info",
              })
            );

            formRef.current
              .getFieldRef(Object.getOwnPropertyNames(validationErrors)[0])
              .focus();
          }
        }
      };

      const handleCancel = () => {
        setNaturalPerson(null);

        navigate(-1);
      };

      useEffect(() => {
        const errors = formRef.current?.getErrors();

        if (errors && Object.keys(errors).length > 0) {
          formRef.current.submitForm();
        }
      }, [i18next.language]);

      useEffect(() => {
        setBreadcrumbItems({
          title: hasNaturalPerson ? t("editNaturalPerson") : t("registerNewNaturalPerson"),
          items: [
            { title: "home", link: "/dashboard" },
            { title: "persons", link: "/persons" },
            { title: hasNaturalPerson ? t("editNaturalPerson") : t("registerNewNaturalPerson"), link: "" },
          ],
        });

        async function getDistributors() {
          try {
            const { data } = await api.get<any>(`/brokers`);
            let formated = data.map(t => {
              return {
                value: {
                  id: t.id,
                  label: `${formatCnpj(t.company.cnpj)} - ${t.company.name}`,
                  removable: true
                },
                label: `${formatCnpj(t.company.cnpj)} - ${t.company.name}`,
              }
            })

            setLockListDistributors(JSON.parse(JSON.stringify(formated)));

            if (hasNaturalPerson) {
              const formatted: any = naturalPerson.distributors.map(c => {
                return {
                  id: c.value.value,
                  label: c.value.label,
                  removable: true
                }
              })

              setCurrentDistributors(formatted);

              const newListDistributors = formated.filter(f => !formatted.some((c: any) => c.id == f.value.id));
              setListDistributors(newListDistributors);
            } else {
              setListDistributors(formated);
            }
          } catch (error) {
            console.log("[NaturalPersonalDetails][getDistributors]", error);
          }
        }

        getDistributors();

    }, [setBreadcrumbItems, hasNaturalPerson]);

      return (
        <>
          <SEO
            title={
              hasNaturalPerson
                ? t("editNaturalPerson", { ns: "breadcrumb" })
                : t("registerNewNaturalPerson", { ns: "breadcrumb" })
            }
            shouldIndexPage={false}
          />

            <Card>
                <CardBody>
                    <Form
                        ref={formRef}
                        onSubmit={handleSubmit}
                        initialData={hasNaturalPerson ? naturalPerson : {}}
                    >
                      <NaturalPersonTab formRef={formRef} distributors={listDistributors} hasNaturalPerson={hasNaturalPerson} />
                    </Form>
                    <NaturalPersonDistributors
                        currentDistributors={currentDistributors}
                        setCurrentDistributors={setCurrentDistributors}
                        listDistributors={listDistributors}
                        setListDistributors={setListDistributors}
                        lockListDistributors={lockListDistributors}
                    />
                </CardBody>
            </Card>

            <div className="mb-5 text-end">
              <div>
                <Button
                  className="waves-effect waves-light me-3"
                  color="info"
                  size="lg"
                  onClick={() => formRef.current.submitForm()}
                >
                  {t("save")}
                </Button>
                <Button
                  className="waves-effect waves-light"
                  size="lg"
                  color="secondary"
                  onClick={handleCancel}
                  outline
                >
                  {t("cancel")}
                </Button>
              </div>
            </div>
        </>
      );
}

export default CreateEditNaturalPerson;

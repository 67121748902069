import React, {
  createContext,
  useState,
  useContext,
  Dispatch,
  SetStateAction,
  useMemo,
} from 'react';
import { useSelector } from 'react-redux';

import { ApplicationState } from '../store';
import { Profile } from '../store/modules/user/types';

interface EditUserContextData {
  user: Profile;
  setUser: Dispatch<SetStateAction<Profile>>;
  hasUser: boolean;
  isCurrentUser: boolean;
}

const EditUserContext = createContext<EditUserContextData>(
  {} as EditUserContextData,
);

export const EditUserProvider: React.FC = ({ children }) => {
  const [user, setUser] = useState<Profile>(null);
  const currentUserId = useSelector<ApplicationState, number>(
    state => state.user.profile?.id,
  );
  const userProviderValue = useMemo(
    () => ({
      user,
      setUser,
      hasUser: !!user,
      isCurrentUser: currentUserId === user?.id,
    }),
    [user, setUser, currentUserId],
  );

  return (
    <EditUserContext.Provider value={userProviderValue}>
      {children}
    </EditUserContext.Provider>
  );
};

export function useEditUser(): EditUserContextData {
  const context = useContext(EditUserContext);

  if (!context) {
    throw new Error('useEditUser must be used within an EditUserProvider');
  }

  return context;
}

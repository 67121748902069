import { action } from 'typesafe-actions';
import { NavigateFunction } from 'react-router-dom';
import { ListingTypes, Listing } from './types';

export const updateListingRequest = (
  listing: Listing,
  navigate: NavigateFunction,
): TypeSafeAction<ListingTypes> =>
  action(ListingTypes.UPDATE_LISTING_REQUEST, { listing, navigate });

export const updateListingSuccess = (): TypeSafeAction<ListingTypes> =>
  action(ListingTypes.UPDATE_LISTING_SUCCESS);

export const createListingRequest = (
  listing: Listing,
  navigate: NavigateFunction,
): TypeSafeAction<ListingTypes> =>
  action(ListingTypes.CREATE_LISTING_REQUEST, { listing, navigate });

export const createCompaniesRequest = (
  listing: any,
  navigate: NavigateFunction,
): TypeSafeAction<ListingTypes> =>
  action(ListingTypes.CREATE_COMPANIES_REQUEST, { listing, navigate });

export const createListingSuccess = (): TypeSafeAction<ListingTypes> =>
  action(ListingTypes.CREATE_LISTING_SUCCESS);

export const createCompaniesSuccess = (): TypeSafeAction<ListingTypes> =>
  action(ListingTypes.CREATE_COMPANIES_SUCCESS);

export const updateCompaniesRequest = (
  listing: any,
  navigate: NavigateFunction,
): TypeSafeAction<ListingTypes> =>
  action(ListingTypes.UPDATE_COMPANIES_REQUEST, { listing, navigate });


export const updateCompaniesSuccess = (): TypeSafeAction<ListingTypes> =>
  action(ListingTypes.UPDATE_COMPANIES_SUCCESS);

export const createNaturalPersonRequest = (
    listing: any,
    navigate: NavigateFunction,
    ): TypeSafeAction<ListingTypes> =>
    action(ListingTypes.CREATE_NATURAL_PERSON_REQUEST, { listing, navigate });
  
export const createNaturalPersonSuccess = (): TypeSafeAction<ListingTypes> =>
action(ListingTypes.CREATE_NATURAL_PERSON_REQUEST);

export const updateNaturalPersonRequest = (
listing: any,
navigate: NavigateFunction,
): TypeSafeAction<ListingTypes> =>
action(ListingTypes.UPDATE_NATURAL_PERSON_REQUEST, { listing, navigate });

export const updateNaturalPersonSuccess = (): TypeSafeAction<ListingTypes> =>
action(ListingTypes.UPDATE_NATURAL_PERSON_SUCCESS);

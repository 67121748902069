import React, { useState, useEffect, useRef, useMemo } from "react";
import { Card, CardBody, Row, Col, Button } from "reactstrap";
import { Form } from "@unform/web";
import { format } from "date-fns";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Input from "../../components/FormFields/Input";
import InputMask from "../../components/FormFields/InputMask";
import DataTable from "../../components/DataTable";
import ActionBar from "../../components/ActionBar";
import SEO from "../../components/SEO";
import AddressCard from "../../components/AddressCard";
import ContactInfoCard from "../../components/ContactInfoCard";
import ProfessionalDataCard from "../../components/ProfessionalDataCard";
import Spinner from "../../components/Spinner";
import { useBreadcrumb } from "../../hooks/useBreadcrumb";
import { useEditUser } from "../../hooks/useEditUser";
import usePermissions from "../../hooks/usePermissions";
import { AccessType, Profile, UserState } from "../../store/modules/user/types";
import { addMessage } from "../../store/modules/toast/actions";
import api from "../../services/api";
import { dataContext, permission } from "../../utils/permissionEnums";
import { ACCESS_TYPE_ORIGINATOR_C0, ACCESS_TYPE_ORIGINATOR_C4, ACCESS_TYPE_BROKER_B0, ACCESS_TYPE_BROKER_B1, ACCESS_TYPE_BROKER_B2 } from "utils/constants";
import imageUsrPlaceholder from "../../assets/images/usr-placeholder.jpeg";
import { ApplicationState } from "store";

interface LogProps {
  id: number;
  table: string;
  action: string;
  time: string;
  tupleID: number;
  actor: string;
}

const User: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const { t } = useTranslation("users");
  const { setBreadcrumbItems } = useBreadcrumb();
  const { setUser: setUserHook } = useEditUser();
  const logTableRef = useRef(null);
  const formRef = useRef(null);
  const userLogged = useSelector<ApplicationState, UserState>((s) => s.user);
  const [user, setUser] = useState<Profile>({} as Profile);
  const [userLogs, setUserLogs] = useState<LogProps[]>([]);

  const accessType = useSelector<ApplicationState, AccessType>(
    (state) => state.user.accessType
  );

  const isAccessTypeOriginatorOrBroker = useMemo(
    () =>
      [ACCESS_TYPE_ORIGINATOR_C0, ACCESS_TYPE_ORIGINATOR_C4,
        ACCESS_TYPE_BROKER_B0, ACCESS_TYPE_BROKER_B1, ACCESS_TYPE_BROKER_B2
      ].includes(
        accessType.name
      ),
    [accessType.name]
  );

  const hasPermissionToBlock = usePermissions(
    dataContext.USER,
    permission.BLOCK
  );
  const hasPermissionToUpdate = usePermissions(
    dataContext.USER,
    permission.UPDATE
  );

  useEffect(() => {
    async function getUserData() {
      try {
        const { data: userData } = await api.get<Profile>(`/people/${id}`);
        setUser(userData);
        const { data: logs } = await api.get<LogProps[]>(`/people/${id}/logs`);
        const logsResult = logs.map((log) => {
          return {
            ...log,
            time: format(new Date(log.time), "dd/MM/yyyy HH:mm:ss OOOO"),
          };
        });
        setUserLogs(logsResult);
      } catch (error) {
        console.log(error);
      }
    }

    getUserData();
  }, [id]);

  async function fetchReport() {
    try {
      const { data: file } = await api.get(`/people/${id}/report`, {
        responseType: "blob",
      });
      const url = window.URL.createObjectURL(file);
      window.open(url);
    } catch (error) {
      console.log(error);
    }
    return null;
  }

  useEffect(() => {
    setBreadcrumbItems({
      title: "userDetails",
      items: [
        { title: "home", link: "/dashboard" },
        { title: "users", link: "/users" },
        { title: "userDetails", link: "/" },
      ],
    });
  }, [setBreadcrumbItems]);

  function scrollToUserLogs() {
    logTableRef.current.scrollIntoView({ behavior: "smooth" });
  }

  function editUser() {
    setUserHook(user);
    navigate("/users/user");
  }

  function hasValidAccessType(accessType) {
    const invalidAccessTypes = ['B0', 'C0', 'C4'];
    return !invalidAccessTypes.includes(accessType);
  }

  function canBlockUser(hasPermissionToBlock, userLogged) {
    return hasPermissionToBlock && hasValidAccessType(userLogged.profile.accessType);
  }

  async function blockUnblockUser(param) {
    try {
      const { data: userFromResponse } = await api.patch(
        `/people/${user.id}/blocked`,
        {
          set: param,
        }
      );
      dispatch(
        addMessage({
          title: param ? t("userBlocked") : t("userUnblocked"),
          type: "info",
        })
      );
      setUser(userFromResponse);
    } catch (error) {
      console.log(error);
    }
  }

  async function handleAction(
    type: "REFUSE" | "BLOCK" | "PUBLISH" | "SUBMIT" | "UPDATE" | "UNBLOCK"
  ) {
    switch (type) {
      case "BLOCK":
        blockUnblockUser(true);
        break;
      case "UNBLOCK":
        blockUnblockUser(false);
        break;
      case "UPDATE":
        editUser();
        break;
      default:
        break;
    }
  }

  if (!user?.id) return <Spinner />;

  return (
    <>
      <SEO
        title={t("userDetails", { ns: "breadcrumb" })}
        shouldIndexPage={false}
      />
      <Row>
        <Col xl="12">
          <div className="text-end mb-4">
            <div className="button-items">
              <ActionBar
                actions={[
                  {
                    permission: canBlockUser(hasPermissionToBlock, userLogged),
                    action: user?.blocked
                      ? permission.UNBLOCK
                      : permission.BLOCK,
                  },
                  {
                    permission: hasPermissionToUpdate && !isAccessTypeOriginatorOrBroker,
                    action: permission.UPDATE,
                  },
                ]}
                onClick={handleAction}
              />
            </div>
          </div>
        </Col>
      </Row>
      <Form ref={formRef} onSubmit={() => ({})} initialData={user}>
        <Card>
          <div className="widget-user-desc p-4 bg-info d-flex flex-column flex-md-row position-relative text-white">
            <Col xs="12" md="4" className="mb-4 mb-md-0">
              <div className="d-flex flex-column align-items-center text-center">
                <img
                  src={user.profilePicture || imageUsrPlaceholder}
                  alt="user"
                  className="rounded-circle border border-3 border-white"
                  width="150"
                />
                <div className="mt-3">
                  <h4>{user.name}</h4>
                  <p className="mb-1">{t(`common:${user.role}`)}</p>
                </div>
              </div>
            </Col>
            <Col xs="12" md="8">
              <Row className="align-items-center">
                <Col sm="3">
                  <h6 className="mb-0">{t("registration")}</h6>
                </Col>
                <Col sm="9" color="secondary">
                  {user.login}
                </Col>
              </Row>
              <hr />
              <Row className="align-items-center">
                <Col sm="3">
                  <h6 className="mb-0">{t("userAccessType")}</h6>
                </Col>
                <Col sm="9" color="secondary">
                  {user.accessType}
                </Col>
              </Row>
              <hr />
              <Row className="align-items-center">
                <Col sm="3">
                  <h6 className="mb-0">{t("belongingCompany")}</h6>
                </Col>
                <Col sm="9" color="secondary">
                  {user.companyTradingName ?? user.companyName}
                </Col>
              </Row>
              <hr />
              <div className="d-flex justify-content-between">
                <Button
                  id="logRoll"
                  className="waves-effect waves-light me-2"
                  onClick={scrollToUserLogs}
                  color="primary"
                  size="sm"
                >
                  {t("userActivityLog")}
                </Button>
                <Button
                  id="getReport"
                  className="waves-effect waves-light d-flex align-items-center justify-content-center"
                  onClick={fetchReport}
                  color="light"
                  outline
                  size="sm"
                >
                  {t("generateReport")}
                  <i className="far fa-file-pdf ms-2 font-size-24" />
                </Button>
              </div>
            </Col>
          </div>

          <CardBody className="mb-5">
            <h4 className="card-title text-info mb-4 text-uppercase">
              {t("userRegistrationData")}
            </h4>

            <Row>
              <Col xs="12" md="4">
                <Input
                  label={t("name")}
                  name="name"
                  placeholder={t("name")}
                  disabled
                />
              </Col>
              <Col xs="12" md="4">
                <InputMask
                  label="CPF"
                  format="###.###.###-##"
                  placeholder="CPF"
                  name="cpf"
                  disabled
                />
              </Col>
              <Col xs="12" md="4">
                <Input
                  label={t("belongingCompany")}
                  name={
                    user.companyTradingName
                      ? "companyTradingName"
                      : "companyName"
                  }
                  placeholder={t("companyName")}
                  disabled
                />
              </Col>
            </Row>
            <Row>
              <Col xs="12" md="4">
                <Input
                  label={t("role")}
                  name="role"
                  placeholder={t("role")}
                  disabled
                />
              </Col>
            </Row>
          </CardBody>
        </Card>

        <ContactInfoCard titleColor="text-info" isUser disabled />
        <AddressCard formRef={formRef} titleColor="text-info" disabled />
        <ProfessionalDataCard
          titleColor="text-info"
          title="Dados do profissional"
          disabled
        />
      </Form>
      <Card>
        <CardBody>
          <h4 className="card-title text-info mb-4 text-uppercase">
            {t("userActivityLog")}
          </h4>
          <div ref={logTableRef}>
            <DataTable
              columns={[
                {
                  name: t("registration"),
                  selector: (row) => row.actor,
                  center: true,
                },
                {
                  name: t("date"),
                  selector: (row) => row.time,
                  center: true,
                  sortable: true,
                },
                {
                  name: t("activity"),
                  center: true,
                  cell: ({ action, table }) => `${action} ${table}`,
                },
              ]}
              data={userLogs}
            />
          </div>
        </CardBody>
      </Card>
    </>
  );
};

export default User;

import React, { useEffect } from "react";
import { Form } from "@unform/web";
import { useTranslation } from "react-i18next";
import { Card, CardBody, Row, Col, Button } from "reactstrap";

import SEO from "../../components/SEO";
import Input from "../../components/FormFields/Input";
import DatePicker from "../../components/FormFields/DatePicker";
import Select from "../../components/FormFields/Select";
import DataTable from "../../components/DataTable";
import { useBreadcrumb } from "../../hooks/useBreadcrumb";

const RecommendationDetails: React.FC = () => {
  const { t } = useTranslation("recommendations");
  const { setBreadcrumbItems } = useBreadcrumb();
  const initialData = {
    responsible: "Nome do Broker",
    date: new Date(),
    status: 1,
    impactedNum: "5",
    acceptedNum: "5",
    purchaseVolume: "0000",
    salesVolume: "0000",
    commissionGenerated: "R$ 0000",
  };

  useEffect(() => {
    setBreadcrumbItems({
      title: "recommendationDetails",
      items: [
        { title: "home", link: "/dashboard" },
        { title: "systemRecommendations", link: "/recommendations" },
        { title: "recommendationDataSheet", link: "/" },
      ],
    });
  }, [setBreadcrumbItems]);

  return (
    <>
      <SEO title={t("manageRecommendation")} shouldIndexPage={false} />

      <Row>
        <Col xl="12">
          <div className="text-end mb-4">
            <div className="button-items">
              <Button
                type="button"
                color="info"
                size="sm"
                className="waves-effect waves-light rounded-pill px-3"
                // onClick={() => handleClick(action)}
              >
                {t("approveRecommendation")}
                <i className="mdi mdi-check ms-1" />
              </Button>
              <Button
                type="button"
                color="secondary"
                size="sm"
                className="waves-effect waves-light rounded-pill px-3"
                // onClick={() => handleClick(action)}
              >
                {t("cancelRecommendation")}
                <i className="mdi mdi-close ms-1" />
              </Button>
            </div>
          </div>
        </Col>
      </Row>
      <Form initialData={initialData} onSubmit={() => ({})}>
        <Card>
          <CardBody>
            <h4 className="card-title text-info mb-4 text-uppercase">
              {t("recommendationRegistrationData")}
            </h4>

            <Row>
              <Col xs="12" md="4">
                <Input label={t("responsible")} name="responsible" disabled />
              </Col>
              <Col xs="12" md="4">
                <DatePicker
                  label={t("date")}
                  name="date"
                  dateFormat="dd/MM/yyyy"
                  disabled
                />
              </Col>
              <Col xs="12" md="4">
                <Select
                  label={t("status")}
                  name="status"
                  options={[
                    { label: "Aguardando Aprovação", value: 1 },
                    { label: "Aprovado", value: 2 },
                  ]}
                  isDisabled
                />
              </Col>
            </Row>
            <Row>
              <Col xs="12" md="6">
                <Input label={t("impactedNum")} name="impactedNum" disabled />
              </Col>
              <Col xs="12" md="6">
                <Input label={t("acceptedNum")} name="acceptedNum" disabled />
              </Col>
            </Row>
            <Row>
              <Col xs="12" md="4">
                <Input
                  label={t("purchaseVolume")}
                  name="purchaseVolume"
                  disabled
                />
              </Col>
              <Col xs="12" md="4">
                <Input label={t("salesVolume")} name="salesVolume" disabled />
              </Col>
              <Col xs="12" md="4">
                <Input
                  label={t("commissionGenerated")}
                  name="commissionGenerated"
                  disabled
                />
              </Col>
            </Row>
          </CardBody>
        </Card>
        <Card>
          <CardBody>
            <h4 className="card-title text-info mb-4 text-uppercase">
              {t("investorsWhoBooked")}
            </h4>

            <DataTable
              columns={[
                {
                  name: t("accountNumber"),
                  selector: (row) => row.accountNumber,
                  center: true,
                  sortable: true,
                },
                {
                  name: t("holder"),
                  selector: (row) => row.holder,
                  center: true,
                  sortable: true,
                },
                {
                  name: t("size"),
                  selector: (row) => row.size,
                  center: true,
                  sortable: true,
                },
                {
                  name: t("suitability"),
                  selector: (row) => row.suitability,
                  center: true,
                  sortable: true,
                },
                {
                  name: t("investmentAdvisor"),
                  selector: (row) => row.investmentAdvisor,
                  center: true,
                  sortable: true,
                },
                {
                  name: t("appliedBalance"),
                  selector: (row) => row.appliedBalance,
                  center: true,
                  sortable: true,
                },
                {
                  name: t("availableBalance"),
                  selector: (row) => row.availableBalance,
                  center: true,
                  sortable: true,
                },
                {
                  name: t("averageBalance"),
                  selector: (row) => row.averageBalance,
                  center: true,
                  sortable: true,
                },
              ]}
              data={[1, 2, 3, 4].map((i) => ({
                id: i,
                accountNumber: "123",
                holder: "Nome",
                size: "Qualificado",
                suitability: "Moderado",
                investmentAdvisor: "Nome do Assessor",
                appliedBalance: "R$ 50.000,00",
                availableBalance: "R$ 50.000,00",
                averageBalance: "R$ 50.000,00",
              }))}
            />
          </CardBody>
        </Card>
      </Form>
    </>
  );
};

export default RecommendationDetails;

import React, { useEffect, useMemo, useRef, useState } from "react";
import { Card, CardBody, Row, Col, Button } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { FormHandles, SubmitHandler } from "@unform/core";
import { Form } from "@unform/web";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { addMessage } from "../../store/modules/toast/actions";
import Input from "../../components/FormFields/Input";
import Select from "../../components/FormFields/Select";
import InputMask from "../../components/FormFields/InputMask";
import SEO from "../../components/SEO";
import AddressCard from "../../components/AddressCard";
import AccountableCard from "../../components/AccountableCard";
import ContactInfoCard from "../../components/ContactInfoCard";
import { useBreadcrumb } from "../../hooks/useBreadcrumb";
import api from "../../services/api";
import {
  createAdvisorRequest,
  updateAdvisorRequest,
} from "../../store/modules/advisor/actions";
import { Advisor } from "../../store/modules/advisor/types";
import { useEditAdvisor } from "../../hooks/useEditAdvisor";
import i18next from "i18next";
import { handleErrorResponse } from "utils/error-handlers";
import { isValidCNPJ, isValidCPF } from "utils/validateDocuments";

interface LabelValue {
  label: string;
  value: string;
}

const CreateEditUser: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation("advisors");

  const { setBreadcrumbItems } = useBreadcrumb();
  const { hasAdvisor, advisor, setAdvisor } = useEditAdvisor();
  const formRef = useRef<FormHandles>(null);

  const [types, setTypes] = useState<LabelValue[]>([]);

  const [employeeQuantity, setEmployeeQuantity] = useState<{ label: string; value: number }[]>(
    []
  );

  const [sizes, setSizes] = useState<{ label: string; value: number }[]>(
    []
  );

  const handleSubmit: SubmitHandler<
    Omit<Advisor, "type"> & { type: string }
  > = async (data) => {
    try {
      formRef.current.setErrors({});
      const schema = Yup.object().shape({
        tradingName: Yup.string().required(t("tradingNameRequired")),
        company: Yup.object().shape({
          name: Yup.string().required(t("companyNameRequired")),
          cnpj: Yup.string()
            .nullable()
            .required(t("CNPJRequired"))
            .test({
              message: t("invalidCnpj"),
              test: (value) => {
                if (value) {
                  return isValidCNPJ(value);
                }

                return false;
              },
            }),
        }),
        address: Yup.object().shape({
          zip: Yup.string().test(
            "len",
            t("invalidValue"),
            ({ length }) => length === 0 || length === 8
          ),
        }),
        type: Yup.string().required(t("officeRequired")),
        email: Yup.string().required(t("emailRequired")),
        cellphone: Yup.string().test(
          "len",
          t("invalidValue"),
          ({ length }) => length === 0 || length === 13
        ),
        telephone: Yup.string().test({
          message: t("invalidValue"),
          test: (value) => {
            if(value != ""){
              if(value.length < 12){
                return false;
              }
            }
            return true;
        }}),
        financial: Yup.object().shape({
          cpf: Yup.string().when(["name", "role"], {
            is: (name, role) => name !== "" || role !== "",
            then: Yup.string()
              .required(t("requiredField"))
              .test({
                message: t("invalidCpf"),
                test: (value) => {
                  if (value) {
                    return isValidCPF(value);
                  }

                  return false;
                },
              }),
          }),
        }),
      });
      await schema.validate(data, {
        abortEarly: false,
      });

      const selectedOffice = types.find((e) => e.value === data.type);

      const obj: Advisor = {
        id: hasAdvisor ? advisor.id : undefined,
        parentId: advisor?.parentId,
        ...data,
        company: {
          ...data.company,
          id: advisor?.company.id,
          companyType: "ADVISOR",
        },
        quantityEmployeeId: data.quantityEmployeeId === undefined || data.quantityEmployeeId === "" ? null: {
          id: data.quantityEmployeeId
        },
        revenueSize: data.revenueSize === undefined || data.revenueSize === "" ? null : {
          id: data.revenueSize
        },
        address: {
          ...data.address,
          id: advisor?.address.id,
        },
        ...(selectedOffice && {
          type: {
            id: selectedOffice.value,
            name: selectedOffice.label,
          },
        }),
        financial: {
          ...data.financial,
          cpf: data.financial.cpf ? data.financial.cpf : null,
        },
      };

      if (hasAdvisor) {
        dispatch(updateAdvisorRequest(obj, navigate));
      } else {
        dispatch(createAdvisorRequest(obj, navigate));
      }
    } catch (err) {
      const validationErrors = {};

      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
        dispatch(
          addMessage({
            title: t("attention"),
            description: t("checkFields"),
            type: "info",
          })
        );

        formRef.current
          .getFieldRef(Object.getOwnPropertyNames(validationErrors)[0])
          .focus();
      }
    }
  };

  const handleCancel = () => {
    setAdvisor(null);
    navigate(-1);
  };

  const handleKeyOnlyNumber = (event) => {
    if (!/[0-9]/.test(event.key)) {
      event.preventDefault();
    }
  };

  async function getEmployeeQuantity() {
    try {
      const { data } = await api.get<any>("/quantityEmployee/dropdown");
      setEmployeeQuantity(data);
    } catch (error) {
      const { description } = handleErrorResponse(error);
      console.log("[ProfileTab][getEmployeeQuantity]", description);
    }
  }

  async function getSizes() {
    try {
      const { data } = await api.get<any>("/revenueSize/dropdown");
      setSizes(data);
    } catch (error) {
      const { description } = handleErrorResponse(error);
      console.log("[ProfileTab][getSizes]", description);
    }
  }


  useEffect(() => {
    const controller = new AbortController();

    async function getTypes() {
      try {
        const { data } = await api.get<LabelValue[]>("/types", {
          signal: controller.signal,
        });

        setTypes(data);
        getEmployeeQuantity();
        getSizes();
      } catch (error) {
        const { description } = handleErrorResponse(error);
        console.log("[CreateEditUser][getTypes]", description);
      }
    }

    getTypes();

    return () => {
      controller.abort();
    };
  }, []);

  useEffect(() => {
    setBreadcrumbItems({
      title: hasAdvisor ? "editAdvisor" : "registerNewAdvisor",
      items: [
        { title: "home", link: "/dashboard" },
        { title: "advisors", link: "/advisors" },
        { title: hasAdvisor ? "editAdvisor" : "registerNewAdvisor", link: "" },
      ],
    });
  }, [setBreadcrumbItems, hasAdvisor]);

  useEffect(() => {
    const errors = formRef.current?.getErrors();

    if (errors && Object.keys(errors).length > 0) {
      formRef.current.submitForm();
    }
  }, [i18next.language]);

  return (
    <>
      <SEO
        title={
          hasAdvisor
            ? t("editAdvisor", { ns: "breadcrumb" })
            : t("registerNewAdvisor", { ns: "breadcrumb" })
        }
        shouldIndexPage={false}
      />

      <Form
        ref={formRef}
        onSubmit={handleSubmit}
        initialData={hasAdvisor ? advisor : {}}
      >
        <Card>
          <CardBody>
            <h4 className="card-title mb-4 text-uppercase">
              {t("advisorRegistrationData")}
            </h4>

            <Row>
              <Col xs="12" md="4">
                <Input
                  label={t("companyName")}
                  name="company.name"
                  type="text"
                  placeholder={t("enterCompanyName")}
                  required
                />
              </Col>
              <Col xs="12" md="4">
                <Input
                  label={t("tradingName")}
                  name="tradingName"
                  type="text"
                  placeholder={t("enterTradingName")}
                  required
                />
              </Col>
              <Col xs="12" md="4">
                <InputMask
                  label="CNPJ"
                  name="company.cnpj"
                  format="##.###.###/####-##"
                  placeholder={t("enterCNPJ")}
                  required
                  disabled={hasAdvisor}
                />
              </Col>
            </Row>
            <Row>
              <Col xs="12" md="4">
                <Select
                  label={t("employeeQuantity")}
                  name="quantityEmployeeId"
                  isClearable
                  options={employeeQuantity}
                />
              </Col>
              <Col xs="12" md="4">
                <Select
                  label={t("revenueSize")}
                  name="revenueSize"
                  isClearable
                  options={sizes}
                />
              </Col>
              <Col xs="12" md="4">
                <Select
                  label={t("officeType")}
                  name="type"
                  options={types}
                  required
                />
              </Col>
            </Row>
          </CardBody>
        </Card>

        <ContactInfoCard isCellphoneRequired={false} isTelefoneRequired={false} />

        <AddressCard formRef={formRef} />

        <AccountableCard
          title={t("financialResponsible")}
          objName="financial"
        />

        <div className="mb-5 text-end">
          <div>
            <Button
              id="save"
              type="submit"
              color="info"
              className="waves-effect waves-light btn-lg me-3"
            >
              {t("save")}
            </Button>
            <Button
              id="cancel"
              type="button"
              className="waves-effect waves-light btn-lg"
              color="secondary"
              onClick={handleCancel}
              outline
            >
              {t("cancel")}
            </Button>
          </div>
        </div>
      </Form>
    </>
  );
};

export default CreateEditUser;

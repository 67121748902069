import React, { useState, useRef } from 'react';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import { Container, Row, Col, Card, CardBody, Button, Alert } from 'reactstrap';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import InputGroupPassword from '../../components/FormFields/InputGroupPassword';
import SEO from '../../components/SEO';
import api from '../../services/api';

import logoBlue from '../../assets/images/logo-blue.png';
import loginBG from "../../assets/images/login_bg.png";

const ResetPassword: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const [isSent, setSend] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const location = new URLSearchParams(useLocation().search);
  const { t } = useTranslation('auth');
  const nonce = location.get('nonce');

  async function handleSubmit(data) {
    setLoading(true);
    try {
      formRef.current.setErrors({});

      const schema = Yup.object().shape({
        password: Yup.string()
          .required(t('requiredField'))
          .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})(?=.*[!@#\\${'$'}%^&*()_+/\-=])/,
            t('invalidPassword'),
          ),
        passwordConfirmation: Yup.string().oneOf(
          [Yup.ref('password'), null],
          t('passwordsDontMatch'),
        ),
      });
      await schema.validate(data, {
        abortEarly: false,
      });

      await api.post(`/recovery-password-keycloak?nonce=${nonce}`, {
        password: data.password,
      });
      setSend(true);

      setLoading(false);
    } catch (err) {
      setLoading(false);
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach(error => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }
    }
  }

  return (
    <>
      <SEO title={t('resetPassword')} shouldIndexPage={false} />
      <div className="vh-100 d-flex justify-content-center align-items-center"
        style={{
          backgroundImage: `url(${loginBG})`,
          backgroundPosition: 'center bottom',
          backgroundRepeat: 'repeat-x',
          height: '100%',
          backgroundColor: '#fff'
        }}
      >
        <Container>
          <Row className="justify-content-center">
            <Col md="8" lg="6" xl="5">
              <Card className="overflow-hidden">
                <CardBody className="pt-0">
                  <h3 className="text-center mt-4">
                    <a href="/" className="logo logo-admin">
                      <img src={logoBlue} height="30" alt="logo" />
                    </a>
                  </h3>
                  <div className="p-3">
                    {isSent ? (
                      <>
                        <Alert className="text-center" color="success">
                          {t('yourPasswordHasBeenUpdated')}
                        </Alert>
                        <Row className="justify-content-center my-4 py-4">
                          <Link to="/auth/login">
                            <Button
                              color="info"
                              className="w-md waves-effect waves-light"
                            >
                              {t('logIn')}
                            </Button>
                          </Link>
                        </Row>
                      </>
                    ) : (
                      <>
                        <h4 className="text-muted font-size-18 mb-3 text-center">
                          {t('enterYourNewPassword')}
                        </h4>
                        <Form
                          ref={formRef}
                          onSubmit={handleSubmit}
                          className="form-horizontal py-2 my-5"
                        >
                          <InputGroupPassword
                            label={t('newPassword')}
                            name="password"
                          />
                          <InputGroupPassword
                            label={t('repeatPassword')}
                            name="passwordConfirmation"
                          />

                          <Alert color="warning">
                            <h4 className="alert-heading font-18">
                              {t('passwordInstructions')}
                            </h4>
                            <ul>
                              <li>{t('minimunOf8Characters')}</li>
                              <li>{t('anUppercaseLetter')}</li>
                              <li>{t('aSpecialCharacter')}</li>
                              <li>{t('aNumber')}</li>
                              <li>{t('beDifferentFromTheLast8Passwords')}</li>
                            </ul>
                          </Alert>

                          <div className="mb-3 row">
                            <div className="col-12 text-end">
                              <Button
                                id="resetPassword"
                                color="info"
                                type="submit"
                                disabled={loading}
                                className="w-md waves-effect waves-light"
                              >
                                {loading && (
                                  <span
                                    className="spinner-border spinner-border-sm text-white me-1"
                                    role="status"
                                    aria-hidden="true"
                                  />
                                )}
                                {t('changePassword')}
                              </Button>
                            </div>
                          </div>
                        </Form>
                      </>
                    )}
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default ResetPassword;

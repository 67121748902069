import { applyMiddleware, createStore, Store } from 'redux';
import { persistStore } from 'redux-persist';
import createSagaMiddleware from 'redux-saga';

import { AuthState } from './modules/auth/types';
import { UserState } from './modules/user/types';
import { LayoutState } from './modules/layout/types';
import { ToastState } from './modules/toast/types';
import { AdvisorState } from './modules/advisor/types';
import { ListingState } from './modules/listing/types';
import { OfferState } from './modules/offer/types';
import { OrderState } from './modules/order/types';
import { BrokerState } from './modules/broker/types';
import { CommissionState } from './modules/commission/types';

import persistReducers from './persistReducers';
import rootReducer from './modules/rootReducer';
import rootSaga from './modules/rootSaga';

export interface ApplicationState {
  auth: AuthState;
  user: UserState;
  layout: LayoutState;
  toast: ToastState;
  advisor: AdvisorState;
  listing: ListingState;
  offer: OfferState;
  order: OrderState;
  broker: BrokerState;
  commission: CommissionState;
}

const sagaMiddleware = createSagaMiddleware();

const store: Store<ApplicationState> = createStore(
  persistReducers(rootReducer),
  applyMiddleware(sagaMiddleware),
);
const persistor = persistStore(store);

sagaMiddleware.run(rootSaga);

export { store, persistor };

import { all, takeLatest } from "redux-saga/effects";
import api from "../../services/api";

import auth from "./auth/sagas";
import layout from "./layout/sagas";
import user from "./user/sagas";
import advisor from "./advisor/sagas";
import listing from "./listing/sagas";
import offer from "./offer/sagas";
import order from "./order/sagas";
import broker from "./broker/sagas";
import commission from "./commission/sagas";

export function setToken({ payload }: any) {
  if (!payload) return;

  const { token } = payload.auth;

  if (token) {
    api.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  }
}

export default function* rootSaga() {
  return yield all([
    takeLatest("persist/REHYDRATE", setToken),
    auth,
    layout,
    user,
    advisor,
    listing,
    offer,
    order,
    broker,
    commission,
  ]);
}

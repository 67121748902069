import { Helmet } from 'react-helmet';

interface Teste {
  title: string;
  description?: string;
  image?: string;
  shouldExcludeTitleSuffix?: boolean;
  shouldIndexPage?: boolean;
}

const SEO = ({
  title,
  description,
  image,
  shouldExcludeTitleSuffix = false,
  shouldIndexPage = true,
}: Teste) => {
  const pageTitle = `${title} ${!shouldExcludeTitleSuffix ? '| Portal OTC Admin' : ''}`;

  return (
    <Helmet>
      <title>{pageTitle}</title>

      {description && <meta name="description" content={description} />}
      {/* {image && <meta name="image" content={image} />} */}

      {!shouldIndexPage && <meta name="robots" content="noindex,nofollow" />}
    </Helmet>
  );
};

export default SEO;

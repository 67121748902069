import React, { useState, useEffect, useCallback } from "react";
import { Row, Col, Card, CardBody, Button } from "reactstrap";
import { Link } from "react-router-dom";
import SweetAlert from "react-bootstrap-sweetalert";
import { useTranslation } from "react-i18next";

import SEO from "../../components/SEO";
import SearchBar from "../../components/SearchBar";
import DataTable from "../../components/DataTable";
import StatusBadge from "../../components/StatusBadge";
import { useEditBroker } from "../../hooks/useEditBroker";
import { useBreadcrumb } from "../../hooks/useBreadcrumb";
import usePermissions from "../../hooks/usePermissions";
import { dataContext, permission } from "../../utils/permissionEnums";
import api from "../../services/api";
import { handleErrorResponse } from "utils/error-handlers";

interface Broker {
  id?: number;
  name: string;
  officeType: string;
  state: string;
}
interface Request {
  page: number;
  limit: number;
  sort: string;
  count: number;
  content: Broker[];
}

const Brokers: React.FC = () => {
  const { t } = useTranslation("brokers");

  const { setBreadcrumbItems } = useBreadcrumb();
  const { setBroker } = useEditBroker();
  const [publish, setPublish] = useState(false);
  const [brokerList, setBrokerList] = useState<Broker[]>([]);
  const [selectedBrokers, setSelectedBrokers] = useState<Broker[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [perPage, setPerPage] = useState<number>(10);
  const [totalRows, setTotalRows] = useState<number>(0);
  const [persistedFilters, setPersistedFilters] = useState({
    name: "",
  });

  const hasPermissionToPublish = usePermissions(
    dataContext.BROKER,
    permission.PUBLISH
  );

  const hasPermissionToCreate = usePermissions(
    dataContext.BROKER,
    permission.CREATE
  );

  const getBrokers = useCallback(
    async (
      nameParam = persistedFilters.name,
      page = 1,
      size = perPage,
      sort = "id:desc",
      signal?: AbortSignal
    ) => {
      try {
        setLoading(true);

        const { data } = await api.get<Request>(
          `/brokers?name=${nameParam}&page=${page}&limit=${size}&sort=${sort}`,
          { signal }
        );

        setBrokerList(data.content);
        setTotalRows(data.count);
        setPersistedFilters({
          name: nameParam,
        });
      } catch (error) {
        const { description } = handleErrorResponse(error);

        console.log("[Brokers][getBrokers]", description);
      } finally {
        setLoading(false);
      }
    },
    [perPage, persistedFilters.name]
  );

  async function publishBrokers() {
    try {
      const listIds = selectedBrokers.map((broker) => broker.id);
      await api.patch("/brokers/publish", {
        ids: listIds,
      });
      getBrokers();
      setPublish(false);
    } catch (error) {
      console.log(error);
    }
  }

  const handleFetchData = ({ name }: any) => {
    getBrokers(name);
  };

  const handlePageChange = (page) => {
    getBrokers(undefined, page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setPerPage(newPerPage);
    getBrokers(undefined, page, newPerPage, "id:desc");
  };

  useEffect(() => {
    setBreadcrumbItems({
      title: "brokers",
      items: [
        { title: "home", link: "/dashboard" },
        { title: "brokers", link: "/brokers" },
      ],
    });
  }, [setBreadcrumbItems]);

  useEffect(() => {
    const controller = new AbortController();

    getBrokers(undefined, undefined, undefined, "id:desc", controller.signal);

    return () => {
      controller.abort();
    };
  }, [getBrokers]);

  return (
    <>
      <SEO title={t("brokers", { ns: "breadcrumb" })} shouldIndexPage={false} />

      {publish ? (
        <SweetAlert
          warning
          showCancel
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          title={t("doYouWantToPublishTheSelectedBrokers")}
          onConfirm={publishBrokers}
          onCancel={() => {
            setPublish(false);
          }}
        />
      ) : null}

      <Row>
        <Col xs="12" className="mb-3">
          <div className="d-flex flex-md-row justify-content-md-between flex-column align-items-center">
            <div>
              {hasPermissionToCreate && (
                <Link to="/brokers/broker">
                  <Button
                    id="newBroker"
                    color="primary"
                    size="md"
                    className="waves-effect waves-light me-3"
                    onClick={() => setBroker(null)}
                  >
                    <i className="mdi mdi-plus me-2" />
                    {t("newBroker")}
                  </Button>
                </Link>
              )}

              {hasPermissionToPublish && (
                <Button
                  id="publishBroker"
                  size="md"
                  color="primary"
                  disabled={!selectedBrokers.length}
                  className="waves-effect waves-light"
                  onClick={() => {
                    setPublish(true);
                  }}
                >
                  {t("publish")}
                </Button>
              )}
            </div>

            <SearchBar
              fields={[
                {
                  key: "name",
                  label: t("companyName"),
                  variant: "text",
                },
              ]}
              onSearch={handleFetchData}
            />
          </div>
        </Col>
        <Col xs="12">
          <Card>
            <CardBody>
              <DataTable
                columns={[
                  {
                    name: t("id", { ns: "common" }),
                    cell: ({ id }) => (
                      <Link to={`/brokers/${id}`}>
                        <Button
                          id={`broker_${id}`}
                          type="button"
                          color="info"
                          outline
                          size="sm"
                          className="waves-effect waves-light"
                        >
                          {id}
                          <i className="mdi mdi-square-edit-outline ms-1" />
                        </Button>
                      </Link>
                    ),
                    button: true,
                  },
                  {
                    name: t("companyName"),
                    selector: (row) => row.name,
                    center: true,
                    sortable: true,
                  },
                  {
                    name: t("officeType"),
                    selector: (row) => row.officeType,
                    center: true,
                    sortable: true,
                  },
                  {
                    name: t("status"),
                    selector: (row) => row.state,
                    center: true,
                    sortable: true,
                    cell: ({ state }) => <StatusBadge state={state} />,
                  },
                ]}
                data={brokerList}
                selectableRows={hasPermissionToPublish}
                disabledCondition={(row) => row.state !== "SUBMITTED"}
                onChange={(e) => setSelectedBrokers(e.selectedRows)}
                progressPending={loading}
                paginationTotalRows={totalRows}
                onChangePage={handlePageChange}
                onChangeRowsPerPage={handlePerRowsChange}
                paginationServer
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Brokers;

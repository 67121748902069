import React from 'react';
import { Button } from 'reactstrap';
import { useTranslation } from 'react-i18next';

import { useSelector, useDispatch } from 'react-redux';
import SweetAlert from 'react-bootstrap-sweetalert';

import { ApplicationState } from '../store';
import { CreatedUser } from '../store/modules/user/types';
import { clearCreatedUser } from '../store/modules/user/actions';

const CreatedUserSweetAlert: React.FC = () => {
  const { t } = useTranslation('common');
  const dispatch = useDispatch();
  const createdUser = useSelector<ApplicationState, CreatedUser>(
    state => state.user.createdUser,
  );

  const ClearCreatedUser = () => {
    dispatch(clearCreatedUser());
  };
  return createdUser?.login ? (
    <SweetAlert
      title={t('userRegistered')}
      success
      confirmBtnBsStyle="info"
      customButtons={
        <Button color="primary" size="lg" onClick={ClearCreatedUser}>
          {t('confirm')}
        </Button>
      }
      onConfirm={() => ({})}
    >
      <div className="p-4">
        {`${t('username')}: ${createdUser.login}`}
        <br />
        {`${t('password')}: ${createdUser.password}`}
      </div>
    </SweetAlert>
  ) : null;
};

export default CreatedUserSweetAlert;

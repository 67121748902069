import React, { useState, useEffect, useRef, useCallback, useMemo } from "react";
import {
  Card,
  CardBody,
  Row,
  Col,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import { Form } from "@unform/web";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Input from "../../components/FormFields/Input";
import InputMask from "../../components/FormFields/InputMask";
import AddressCard from "../../components/AddressCard";
import AccountableCard from "../../components/AccountableCard";
import ContactInfoCard from "../../components/ContactInfoCard";
import CurrentAccount from "../../components/CurrentAccount";
import ActionBar from "../../components/ActionBar";
import DataTable from "../../components/DataTable";
import SEO from "../../components/SEO";
import Spinner from "../../components/Spinner";
import { useBreadcrumb } from "../../hooks/useBreadcrumb";
import { addMessage } from "../../store/modules/toast/actions";
import api from "../../services/api";
import usePermissions from "../../hooks/usePermissions";
import { dataContext, permission } from "../../utils/permissionEnums";
import { ACCESS_TYPE_ADMIN_A0, ACCESS_TYPE_ORIGINATOR_C0, ACCESS_TYPE_ORIGINATOR_C4 } from "utils/constants";
import { Advisor, useEditAdvisor } from "../../hooks/useEditAdvisor";
import { AccessType } from "store/modules/user/types";
import { ApplicationState } from "store";
import StatusBadge from "components/StatusBadge";

import imageUsrPlaceholder from "../../assets/images/usr-placeholder.jpeg";

interface ListedCompaniesType {
  advisorTechnicalManager: string;
  rating: string;
  sector: string;
  size: string;
  state: string;
  ticker: string;
  tradingName: string;
}

const AdvisorPage: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation("advisors");
  const { setBreadcrumbItems } = useBreadcrumb();
  const listedCompaniesDataTable = useRef(null);
  const formRef = useRef(null);
  const { id } = useParams();
  const { setAdvisor: setAdvisorHook } = useEditAdvisor();
  const [advisor, setAdvisor] = useState<Advisor>({} as Advisor);
  const [listedCompanies, setListedCompanies] = useState<ListedCompaniesType[]>(
    []
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [showExtract, setShowExtract] = useState<boolean>(false);

  const [perPage, setPerPage] = useState<number>(10);
  const [totalRows, setTotalRows] = useState<number>(0);

  const accessType = useSelector<ApplicationState, AccessType>(
    (state) => state.user.accessType
  );

  const isAccessTypeOriginator = useMemo(
    () =>
      [ACCESS_TYPE_ORIGINATOR_C0, ACCESS_TYPE_ORIGINATOR_C4].includes(
        accessType.name
      ),
    [accessType.name]
  );
  const isAccessTypeAdminOrOriginator = useMemo(
    () =>
      [ACCESS_TYPE_ADMIN_A0, ACCESS_TYPE_ORIGINATOR_C0, ACCESS_TYPE_ORIGINATOR_C4].includes(
        accessType.name
      ),
    [accessType.name]
  );


  const hasPermissionToRefuse = usePermissions(
    dataContext.ADVISOR,
    permission.REFUSE,
    advisor.state
  );
  const hasPermissionToBlock = usePermissions(
    dataContext.ADVISOR,
    permission.BLOCK,
    advisor.state
  );
  const hasPermissionToPublish = usePermissions(
    dataContext.ADVISOR,
    permission.PUBLISH,
    advisor.state
  );
  const hasPermissionToSubmit = usePermissions(
    dataContext.ADVISOR,
    permission.SUBMIT,
    advisor.state
  );
  const hasPermissionToUpdate = usePermissions(
    dataContext.ADVISOR,
    permission.UPDATE,
    advisor.state
  );

  const getListedCompanies = useCallback(
    async (page = 1, size = perPage) => {
      setLoading(true);

      const { data: companiesList } = await api.get(
        `/advisors/${id}/listedcompanies?page=${page}&limit=${size}`
      );
      setListedCompanies(companiesList.content);
      setTotalRows(companiesList.count);

      setLoading(false);
    },
    [id, perPage]
  );

  useEffect(() => {
    async function getAdvisorData() {
      try {
        const { data: advisorData } = await api.get<Advisor>(`/advisors/${id}`);

        //Ajuste para dados antigos
        advisorData.annualBillingRange = advisorData.annualBillingRange ? advisorData.annualBillingRange : advisorData.revenueSize?.name;
        advisorData.quantityEmployee = advisorData.quantityEmployee ? advisorData.quantityEmployee : advisorData.quantityEmployeeId?.name;

        setAdvisor(advisorData);

      } catch (error) {
        console.log(error);
      }
    }

    getAdvisorData();
    getListedCompanies();
  }, [id, getListedCompanies]);

  const handlePageChange = (page) => {
    getListedCompanies(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setPerPage(newPerPage);
    getListedCompanies(page, newPerPage);
  };

  async function fetchReport() {
    try {
      const { data: file } = await api.get(`/advisors/${id}/report`, {
        responseType: "blob",
      });
      const url = window.URL.createObjectURL(file);
      window.open(url);
    } catch (error) {
      console.log(error);
    }
    return null;
  }

  useEffect(() => {
    setBreadcrumbItems({
      title: "advisorDetails",
      items: [
        { title: "home", link: "/dashboard" },
        { title: "advisors", link: "/advisors" },
        { title: "advisorDetails", link: "/" },
      ],
    });
  }, [setBreadcrumbItems]);

  function scrollToListedCompanies() {
    listedCompaniesDataTable.current.scrollIntoView({ behavior: "smooth" });
  }

  async function refuseAdvisor(reason) {
    const { data } = await api.patch("/advisors/refuse", {
      id: advisor.id,
      message: reason,
    });
    setAdvisor(data);
    dispatch(
      addMessage({
        title: t("advisorRefused"),
        type: "info",
      })
    );
  }

  async function blockAdvisor() {
    const { data } = await api.patch("/advisors/block", {
      id: advisor.id,
    });
    setAdvisor(data);
    dispatch(
      addMessage({
        title: t("advisorBlocked"),
        type: "info",
      })
    );
  }

  async function publishAdvisor() {
    const { data } = await api.patch("/advisors", {
      ids: [advisor.id],
    });
    setAdvisor(data[0]);
    dispatch(
      addMessage({
        title: t("advisorPublished"),
        type: "success",
      })
    );
  }

  async function submitAdvisor() {
    const { data } = await api.patch("/advisors/submit", {
      id: advisor.id,
    });
    setAdvisor(data);
    dispatch(
      addMessage({
        title: t("advisorSubmitted"),
        type: "success",
      })
    );
  }

  function editAdvisor() {
    setAdvisorHook(advisor);
    navigate("/advisors/advisor");
  }

  async function handleAction(
    type: "REFUSE" | "BLOCK" | "PUBLISH" | "SUBMIT" | "UPDATE",
    reason?: string
  ) {
    switch (type) {
      case "REFUSE":
        refuseAdvisor(reason);
        break;
      case "BLOCK":
        blockAdvisor();
        break;
      case "PUBLISH":
        publishAdvisor();
        break;
      case "SUBMIT":
        submitAdvisor();
        break;
      case "UPDATE":
        editAdvisor();
        break;
      default:
        break;
    }
  }

  if (!advisor.id) return <Spinner />;

  return (
    <>
      <SEO
        title={t("advisorDetails", { ns: "breadcrumb" })}
        shouldIndexPage={false}
      />

      <Modal
        isOpen={showExtract}
        toggle={() => setShowExtract(false)}
        size="xl"
        centered
      >
        <ModalHeader className="justify-content-center">
          {t("accountStatement")}
        </ModalHeader>
        <ModalBody>
          <CurrentAccount
            account={{
              accountNum: "9827465-2",
              agency: "0001",
              blockedInFlow: 700,
              freeToUse: 300,
              totalBalance: 1000,
            }}
          />
        </ModalBody>
        <ModalFooter>
          <Button
            id="closeExtract"
            color="secondary"
            onClick={() => setShowExtract(false)}
          >
            {t("close")}
          </Button>
        </ModalFooter>
      </Modal>

      <Row>
        <Col xl="12">
          <div className="text-end mb-4">
            <div className="button-items">
              <ActionBar
                actions={[
                  {
                    permission: hasPermissionToRefuse,
                    action: permission.REFUSE,
                  },
                  {
                    permission: hasPermissionToBlock && !isAccessTypeOriginator,
                    action: permission.BLOCK,
                  },
                  {
                    permission: hasPermissionToPublish,
                    action: permission.PUBLISH,
                  },
                  {
                    permission: hasPermissionToSubmit,
                    action: permission.SUBMIT,
                  },
                  {
                    permission: hasPermissionToUpdate && isAccessTypeAdminOrOriginator,
                    action: permission.UPDATE ,
                  },
                ]}
                onClick={handleAction}
              />
            </div>
          </div>
        </Col>
      </Row>

      <Form ref={formRef} onSubmit={() => ({})} initialData={advisor}>
        <div className="d-flex bg-white mb-4 shadow-sm rounded flex-column flex-md-row">
          <Col xs="12" md="6" className="p-4 d-flex justify-content-between">
            <div className="d-flex flex-column justify-content-around text-info">
              <small>{t("companyName")}</small>
              <h4 className="mb-1">{advisor.company?.name}</h4>
              <small>{t("registration")}</small>
              <h4 className="mb-1">{advisor.id}</h4>
              <small>{t("tradingName")}</small>
              <h4 className="mb-1">{advisor.tradingName}</h4>
            </div>
            <div className="d-flex flex-column justify-content-between">
              <Button
                id="listedCompaniesRoll"
                className="waves-effect waves-light"
                onClick={scrollToListedCompanies}
                color="primary"
                size="sm"
              >
                {t("relatedCompanies")}
              </Button>
              {/* A pedido da US:85 - Remover as opções <Mostrar Extrato> e <Gerar relatório> de todos os perfis de acesso; */}
              {/* {advisor.state === "PUBLISHED" && (
                <Button
                  id="showExtract"
                  className="waves-effect waves-light"
                  color="info"
                  size="sm"
                  onClick={() => setShowExtract(true)}
                >
                  {t("showExtract")}
                </Button>
              )}
              <Button
                id="getReport"
                className="waves-effect waves-light d-flex align-items-center justify-content-center"
                onClick={fetchReport}
                color="light"
                size="sm"
              >
                {t("generateReport")}
                <i className="far fa-file-pdf ms-2 font-size-24" />
              </Button> */}
            </div>
          </Col>
          <Col
            xs="12"
            md="6"
            className="p-4 bg-info text-white rounded-end text-uppercase"
          >
            <h4>{t("unitResponsible")}</h4>
            <Row>
              <Col
                sm="12"
                md="12"
                className="d-flex flex-row align-items-center justify-content-around py-2"
              >
                {advisor.unitResponsible ? (
                  <>
                    <img
                      src={
                        advisor.unitResponsible?.profilePicture ||
                        imageUsrPlaceholder
                      }
                      alt=""
                      className="rounded-circle avatar-md border border-3 border-white float-start me-2"
                    />
                    <div>
                      <small>{t("name")}</small>
                      <p className="mb-1">{advisor.unitResponsible?.name}</p>
                      <small>{t("role")}</small>
                      <p className="mb-1">{advisor.unitResponsible?.role}</p>
                    </div>
                    <div>
                      <small>{t("registration")}</small>
                      <p className="mb-1">{advisor.unitResponsible?.login}</p>
                      <small>{t("accessType")}</small>
                      <p className="mb-1">
                        {advisor.unitResponsible?.accessType}
                      </p>
                    </div>
                  </>
                ) : (
                  <div>{t("noResponsible")}</div>
                )}
              </Col>
            </Row>
          </Col>
        </div>
        <Card>
          <CardBody>
            <h4 className="card-title text-info mb-4 text-uppercase">
              {t("advisorRegistrationData")}
            </h4>

            <Row>
              <Col xs="12" md="4">
                <Input label={t("companyName")} name="company.name" disabled />
              </Col>
              <Col xs="12" md="4">
                <Input label={t("tradingName")} name="tradingName" disabled />
              </Col>
              <Col xs="12" md="4">
                <InputMask
                  label="CNPJ"
                  name="company.cnpj"
                  format="##.###.###/####-##"
                  disabled
                />
              </Col>
            </Row>
            <Row>
              <Col xs="12" md="4">
                <Input
                  label={t('employeeQuantity')}
                  name="quantityEmployee"
                  disabled
                />
              </Col>
              <Col xs="12" md="4">
                <Input label={t('revenueSize')} name="annualBillingRange" disabled />
              </Col>
              <Col xs="12" md="4">
                <Input label={t("officeType")} name="type.name" disabled />
              </Col>
            </Row>
          </CardBody>
        </Card>

        <ContactInfoCard titleColor="text-info" disabled isTelefoneRequired={false} isCellphoneRequired={false}/>

        <AddressCard formRef={formRef} titleColor="text-info" disabled />

        <AccountableCard
          titleColor="text-info"
          title={t("financialResponsible")}
          objName="financial"
          disabled
        />
      </Form>

      <Card>
        <CardBody>
          <h4 className="card-title text-info mb-4 text-uppercase">
            {t("relatedCompanies")}
          </h4>
          <div ref={listedCompaniesDataTable}>
            <DataTable
              columns={[
                {
                  name: t("registration"),
                  selector: (row) => row.ticker,
                  center: true,
                  sortable: true,
                },
                {
                  name: t("companyName"),
                  selector: (row) => row.tradingName,
                  center: true,
                  sortable: true,
                },
                {
                  name: t("size"),
                  selector: (row) => row.size,
                  center: true,
                  sortable: true,
                },
                {
                  name: t("sector"),
                  selector: (row) => row.sector,
                  center: true,
                  sortable: true,
                },
                {
                  name: t("advisorTechnicalManager"),
                  selector: (row) => row.advisorTechnicalManager,
                  center: true,
                  sortable: true,
                },
                {
                  name: "Status",
                  selector: (row) => `${t(row.state, { ns: "common" })}`,
                  center: true,
                  sortable: true,
                  cell: ({ state }) => <StatusBadge state={state} />,
                },
                {
                  name: t("rating"),
                  selector: (row) => row.rating,
                  center: true,
                  sortable: true,
                },
              ]}
              data={listedCompanies}
              progressPending={loading}
              paginationServer
              paginationTotalRows={totalRows}
              onChangePage={handlePageChange}
              onChangeRowsPerPage={handlePerRowsChange}
            />
          </div>
        </CardBody>
      </Card>
    </>
  );
};

export default AdvisorPage;

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Attachments from '../Attachments';
import { Attachment } from '../../../store/modules/listing/types';


interface Props {
  attachments: Attachment[];
  setAttachments: (attachments: Attachment[]) => void;
}

const FinancialStatementTab: React.FC<Props> = ({ attachments, setAttachments}) => {
  const { t } = useTranslation('listings');

  return (
    <>
      <h4 className="card-title mb-4 text-uppercase">
        {t('consolidatedBalanceSheet')}
      </h4>
      <Attachments attachments={attachments} setAttachments={setAttachments} typeFilter="BPC" attachmentType="BPC" />

      <h4 className="card-title mb-4 text-uppercase">
        {t('incomeStatementForTheConsolidatedFiscalYear')}
      </h4>
      <Attachments attachments={attachments} setAttachments={setAttachments} typeFilter="DRE" attachmentType="DRE" />

      <h4 className="card-title mb-4 text-uppercase">
        {t('consolidadeCashFlowStatement')}
      </h4>
      <Attachments attachments={attachments} setAttachments={setAttachments} typeFilter="DFC" attachmentType="DFC" />

      <h4 className="card-title mb-4 text-uppercase">
        {t('shareCapitalComposition')}
      </h4>
      <Attachments attachments={attachments} setAttachments={setAttachments} typeFilter="CCS" attachmentType="CCS" />
    </>
  );
};

export default FinancialStatementTab;

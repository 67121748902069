import React, { useRef, useEffect, useState } from "react";
import { useField } from "@unform/core";

interface Props {
  name: string;
  label?: string;
}
type InputProps = JSX.IntrinsicElements["input"] & Props;

const Input: React.FC<InputProps> = ({
  name,
  label,
  required = false,
  onFocus,
  onBlur,
  ...rest
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const { fieldName, defaultValue, registerField, error } = useField(name);

  const [isFocused, setIsFocused] = useState(false);

  const getStyles = (pStyle) => {
    if (isFocused) {
      return {
        ...pStyle,
        borderColor: "#2684FF",
        boxShadow: "0 0 0 1px #2684FF",
      };
    }

    return { ...pStyle };
  };

  const handleFocus = (e) => {
    setIsFocused(true);

    if (onFocus) {
      onFocus(e);
    }
  };

  const handleBlur = (e) => {
    setIsFocused(false);

    if (onBlur) {
      onBlur(e);
    }
  };

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      getValue: (ref) => {
        return ref.value;
      },
      setValue: (ref, value) => {
        ref.value = value;
      },
      clearValue: (ref) => {
        ref.value = "";
      },
    });
  }, [fieldName, registerField]);

  return (
    <div className="mb-3">
      {label && (
        <label htmlFor={fieldName}>{`${label}${required ? " *" : ""}`}</label>
      )}
      <input
        id={fieldName}
        className={`form-control ${error ? "is-invalid" : ""}`}
        ref={inputRef}
        defaultValue={defaultValue}
        onFocus={handleFocus}
        onBlur={handleBlur}
        style={getStyles(rest.style)}
        {...rest}
      />
      {error && <span className="error">{error}</span>}
    </div>
  );
};

export default Input;

import React, { useState, useEffect, useRef } from "react";
import { Card, CardBody, Row, Col, Button } from "reactstrap";
import { useDispatch } from "react-redux";
import { FormHandles, SubmitHandler } from "@unform/core";
import { Form } from "@unform/web";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

import SEO from "../../components/SEO";
import Input from "../../components/FormFields/Input";
import InputMask from "../../components/FormFields/InputMask";
import Select from "../../components/FormFields/Select";
import SelectCreatable from "../../components/FormFields/SelectCreatable";
import AddressCard from "../../components/AddressCard";
import ContactInfoCard from "../../components/ContactInfoCard";
import { useBreadcrumb } from "../../hooks/useBreadcrumb";
import { useEditBroker } from "../../hooks/useEditBroker";
import {
  createBrokerRequest,
  updateBrokerRequest,
} from "../../store/modules/broker/actions";
import { LabelValue, Broker } from "../../store/modules/broker/types";
import { addMessage } from "../../store/modules/toast/actions";
import api from "../../services/api";
import { handleErrorResponse } from "utils/error-handlers";
import { isValidCNPJ, isValidCPF } from "utils/validateDocuments";

const CreateEditBroker: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation("brokers");

  const { setBreadcrumbItems } = useBreadcrumb();

  const formRef = useRef<FormHandles>(null);

  const { hasBroker, broker } = useEditBroker();
  const [officeTypes, setOfficeTypes] = useState<LabelValue[]>([]);
  const [certifications, setCertifications] = useState<LabelValue[]>([]);

  function extractDataArray(arr) {
    return arr.map((val) => {
      // eslint-disable-next-line no-underscore-dangle
      if (val.__isNew__) {
        return {
          name: val.value,
        };
      }
      return {
        name: val.label || val.name,
        id: val.value || val.id,
      };
    });
  }

  const handleSubmit: SubmitHandler<
    Omit<Broker, "officeType"> & { officeType: string }
  > = async (data) => {
    try {
      formRef.current.setErrors({});

      const schema = Yup.object().shape({
        company: Yup.object().shape({
          name: Yup.string().required(t("requiredField")),
          cnpj: Yup.string()
            .nullable()
            .required(t("requiredField"))
            .test({
              message: t("invalidField"),
              test: (value) => {
                if (value) {
                  return isValidCNPJ(value);
                }

                return false;
              },
            }),
        }),
        address: Yup.object().shape({
          zip: Yup.string().test(
            "len",
            t("invalidField"),
            ({ length }) => length === 0 || length === 8
          ),
        }),
        tradingName: Yup.string().required(t("requiredField")),
        email: Yup.string().required(t("requiredField")),
        cellphone: Yup.string().test({
          message: t("invalidValue"),
          test: (value) => {
            if(value != ""){
              if(value.length < 13){
                return false;
              }
            }
            return true;
        }}),
        telephone: Yup.string().test({
          message: t("invalidValue"),
          test: (value) => {
            if(value != ""){
              if(value.length < 12){
                return false;
              }
            }
            return true;
        }}),
      });
      await schema.validate(data, {
        abortEarly: false,
      });

      const selectedOffice = officeTypes.find(
        (e) => e.value === data.officeType
      );

      const newObj: Broker = {
        ...data,
        id: broker?.id,
        state: broker?.state ? broker?.state : "DRAFT",
        parentId: broker?.parentId,
        company: {
          ...broker?.company,
          ...data.company,
          companyType: "BROKER",
        },
        address: isEmpty(data.address)
          ? null
          : {
              ...data.address,
              id: broker?.address?.id,
            },
        ...(selectedOffice && {
          officeType: {
            id: selectedOffice.value,
            name: selectedOffice.label,
          },
        }),
        certifications: data.certifications
          ? extractDataArray(data.certifications)
          : undefined,
      };

      if (hasBroker) {
        dispatch(updateBrokerRequest(newObj, navigate));
      } else {
        dispatch(createBrokerRequest(newObj, navigate));
      }
    } catch (err) {
      const validationErrors = {};

      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
        dispatch(
          addMessage({
            title: t("attention"),
            description: t("checkFields"),
            type: "info",
          })
        );
        formRef.current
          .getFieldRef(Object.getOwnPropertyNames(validationErrors)[0])
          .focus();
      }
    }
  };

  function isEmpty(obj) {
    return Object.values(obj).every(
      (x) => x === null || x === "" || x === undefined
    );
  }

  function handleCancel() {
    navigate(-1);
  }

  useEffect(() => {
    setBreadcrumbItems({
      title: hasBroker ? "editBroker" : "registerNewBroker",
      items: [
        { title: "home", link: "/dashboard" },
        { title: "brokers", link: "/brokers" },
        { title: hasBroker ? "editBroker" : "registerNewBroker", link: "" },
      ],
    });
  }, [setBreadcrumbItems, hasBroker]);

  useEffect(() => {
    const controller = new AbortController();

    async function getTypes() {
      try {
        const { data } = await api.get<LabelValue[]>("/officeTypes", {
          signal: controller.signal,
        });

        setOfficeTypes(data);
      } catch (error) {
        const { description } = handleErrorResponse(error);

        console.log("[CreateEditBroker][getTypes]", description);
      }
    }

    async function getCertifications() {
      try {
        const { data } = await api.get<LabelValue[]>(
          "/certifications/dropdown",
          {
            signal: controller.signal,
          }
        );

        setCertifications(data);
      } catch (error) {
        const { description } = handleErrorResponse(error);

        console.log("[CreateEditBroker][getCertifications]", description);
      }
    }

    getTypes();
    getCertifications();

    return () => {
      controller.abort();
    };
  }, []);

  useEffect(() => {
    const errors = formRef.current?.getErrors();

    if (errors && Object.keys(errors).length > 0) {
      formRef.current.submitForm();
    }
  }, [i18next.language]);

  return (
    <>
      <SEO
        title={
          hasBroker
            ? t("editBroker", { ns: "breadcrumb" })
            : t("registerNewBroker", { ns: "breadcrumb" })
        }
        shouldIndexPage={false}
      />

      <Form
        ref={formRef}
        onSubmit={handleSubmit}
        initialData={hasBroker ? broker : {}}
      >
        <Card>
          <CardBody>
            <h4 className="card-title mb-4 text-uppercase">
              {t("brokerDetails")}
            </h4>

            <Row>
              <Col xs="12" md="4">
                <Input
                  label={t("companyName")}
                  name="company.name"
                  type="text"
                  placeholder={t("enterCompanyName")}
                  required
                />
              </Col>
              <Col xs="12" md="4">
                <Input
                  label={t("tradingName")}
                  name="tradingName"
                  type="text"
                  placeholder={t("enterTradingName")}
                  required
                />
              </Col>
              <Col xs="12" md="4">
                <InputMask
                  label="CNPJ"
                  name="company.cnpj"
                  format="##.###.###/####-##"
                  placeholder={t("enterCnpj")}
                  required
                  disabled={broker?.state === "PUBLISHED"}
                />
              </Col>
            </Row>
            <Row>
              <Col xs="12" md="4">
                <Select
                  label={t("officeType")}
                  name="officeType"
                  options={officeTypes}
                  isClearable
                />
              </Col>
              <Col xs="12" md="4">
                <SelectCreatable
                  label={t("certifications")}
                  name="certifications"
                  options={certifications}
                  isMulti
                />
              </Col>
            </Row>
          </CardBody>
        </Card>

        <ContactInfoCard isCellphoneRequired={false} isTelefoneRequired={false}/>

        <AddressCard formRef={formRef} />

        <div className="mb-5 text-end">
          <div>
            <Button
              id="save"
              type="submit"
              color="info"
              className="waves-effect waves-light btn-lg me-3"
            >
              {t("save")}
            </Button>
            <Button
              id="cancel"
              type="button"
              className="waves-effect waves-light btn-lg"
              color="secondary"
              onClick={handleCancel}
              outline
            >
              {t("cancel")}
            </Button>
          </div>
        </div>
      </Form>
    </>
  );
};

export default CreateEditBroker;

import React, { useState, useRef, useEffect } from "react";
import { Container, Row, Col, Card, CardBody, Button } from "reactstrap";
import { FormHandles } from "@unform/core";
import { Form } from "@unform/web";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { IoPersonCircle } from 'react-icons/io5';
import { useParams } from "react-router-dom";

import { ApplicationState } from "../../../store";
import { AuthState, AuthTypes } from "../../../store/modules/auth/types";

import {
  clearLoading,
  setCredentials,
  setResponseData,
} from "../../../store/modules/auth/actions";
import SEO from "../../../components/SEO";

import logoBlue from "../../../assets/images/logo-blue.png";
import loginBG from "../../../assets/images/login_bg.png";
import InputGroupPassword from "components/FormFields/InputGroupPassword";
import api from "services/api";

const MfaLogin: React.FC = () => {
  const navigate = useNavigate();
  const formRef = useRef<FormHandles>(null);
  const { t } = useTranslation("auth");
  const dispatch = useDispatch();
  const auth = useSelector<ApplicationState, AuthState>((s) => s.auth);
  const { name } = useParams();

  async function handleSubmit(data) {
    try {
      dispatch({ type: AuthTypes.SET_LOADING });
      // Remove all previous errors
      formRef.current.setErrors({});

      const schema = Yup.object().shape({
        password: Yup.string().required(t("requiredField")),
      });
      await schema.validate(data, {
        abortEarly: false,
      });

      const response = await api.post('/keycloak/mfa/generate', {
        username: name,
        password: data.password,
      });

      // Validation passed
      dispatch(setResponseData(response.data));
      dispatch(setCredentials(name, data.password));
      navigate(`/auth/mfa-setup/${name}`);
    } catch (err) {
      dispatch({ type: AuthTypes.CLEAR_LOADING });
      const validationErrors = {};

      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }
    }
  }

  return (
    <div
    className="vh-100 d-flex justify-content-center align-items-center bg-white"
    style={{
      backgroundImage: `url(${loginBG})`,
      backgroundPosition: "center bottom",
      backgroundRepeat: "repeat-x",
    }}
    >
      <SEO title={t("mfaLogin")} />
      <Container>
        <Row className="justify-content-center">
          <Col md="8" lg="6" xl="5">
            <Card className="overflow-hidden">
              <CardBody className="pt-0">
                <h3 className="text-center mt-4">
                  <a href="\" className="logo logo-admin">
                    <img src={logoBlue} height="30" alt="logo" />
                  </a>
                </h3>

                <div className="p-3">

                  <p className="font-size-13 mb-1 font-weight-semibold">
                    {t("stepOne")}: <span className="font-weight-medium">{t("enterUserPassword")}</span>
                  </p>

                  <div className="d-flex align-items-center justify-content-center border rounded p-3 my-3">
                    <IoPersonCircle size={30} className="mr-2" />
                    <span className="font-weight-semibold font-size-18">{name}</span>
                  </div>

                  <Form
                    ref={formRef}
                    className="form-horizontal"
                    onSubmit={handleSubmit}
                  >
                    <InputGroupPassword
                      label={t("password")}
                      name="password"
                      placeholder={t("enterPassword")}
                    />
                    <div className="mt-3">
                      <Button
                        id="login"
                        color="info"
                        className="w-100 waves-effect waves-light d-inline-flex justify-content-center align-items-center mt-3"
                        type="submit"
                        disabled={auth.loading}
                      >
                        {auth.loading && (
                          <span
                            className="spinner-border spinner-border-sm text-white me-1"
                            role="status"
                            aria-hidden="true"
                          />
                        )}
                        {t("logIn")}
                      </Button>
                    </div>
                  </Form>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default MfaLogin;

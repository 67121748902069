import React, { useState, useEffect } from "react";
import { Row, Col, Card, CardBody, Button } from "reactstrap";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import SEO from "../../components/SEO";
import SearchBar from "../../components/SearchBar";
import DataTable from "../../components/DataTable";
// import { useEditAccountHolder } from '../../hooks/useEditAccountHolder';
import { useBreadcrumb } from "../../hooks/useBreadcrumb";
// import api from '../../services/api';

// interface Request {
//   page: number;
//   limit: number;
//   sort: string;
//   count: number;
//   content: any[];
// }

const AccountHolders: React.FC = () => {
  const { setBreadcrumbItems } = useBreadcrumb();
  // const { setAccountHolder } = useEditAccountHolder();
  const { t } = useTranslation("accountHolders");
  const [accountHolderList] = useState<any[]>([]);

  useEffect(() => {
    setBreadcrumbItems({
      title: "manageAccountHolders",
      items: [
        { title: "home", link: "/dashboard" },
        { title: "systemAccountHolders", link: "/accountHolders" },
      ],
    });
  }, [setBreadcrumbItems]);

  // useEffect(() => {
  //   getAccountHolders();
  // }, []);

  // const getAccountHolders = async (
  //   cpf = '',
  //   accountNumber = '',
  //   accountHolderName = '',
  // ) => {
  //   try {
  //     const { data } = await api.get<Request>(
  //       `/accountHolders?page=1&limit=20&cpf=${cpf}&accountNumber=${accountNumber}&accountHolderName=${accountHolderName}`,
  //     );

  //     setAccountHolderList(data.content);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  return (
    <>
      <SEO title={t("accountHolders")} shouldIndexPage={false} />

      <Row>
        <Col xs="12">
          <Card>
            <CardBody>
              <div className="d-flex flex-sm-row justify-content-sm-between flex-column">
                <div />

                <SearchBar
                  fields={[
                    {
                      key: "cpf",
                      label: "Cpf",
                      variant: "pattern",
                      mask: "999.999.999-99",
                    },
                    {
                      key: "accountNumber",
                      label: t("accountNumber"),
                      variant: "text",
                    },
                    {
                      key: "accountHolderName",
                      label: t("accountHolderName"),
                      variant: "text",
                    },
                  ]}
                  onSearch={({ cpf, accountNumber, accountHolderName }) => {
                    // getAccountHolders(cpf, accountNumber, accountHolderName);
                    console.log(cpf, accountNumber, accountHolderName);
                  }}
                />
              </div>
            </CardBody>
          </Card>
          <Card style={{ marginBottom: "100px" }}>
            <CardBody>
              <DataTable
                columns={[
                  {
                    name: t("accountNumber"),
                    cell: ({ id, ticker }) => (
                      <Link to={`/accountHolders/${id}`}>
                        <Button
                          id={`accountHolder_${id}`}
                          type="button"
                          color="info"
                          outline
                          size="sm"
                          className="waves-effect waves-light"
                        >
                          {ticker}
                          <i className="mdi mdi-square-edit-outline ms-1" />
                        </Button>
                      </Link>
                    ),
                    button: true,
                  },
                  {
                    name: t("holder"),
                    selector: (row) => row.holder,
                    center: true,
                    sortable: true,
                  },
                  {
                    name: t("framing"),
                    selector: (row) => row.framing,
                    center: true,
                    sortable: true,
                  },
                  {
                    name: t("suitability"),
                    selector: (row) => row.suitability,
                    center: true,
                    sortable: true,
                  },
                  {
                    name: t("wallet"),
                    selector: (row) => row.wallet,
                    center: true,
                    sortable: true,
                  },
                  {
                    name: t("status"),
                    selector: (row) => row.status,
                    center: true,
                    sortable: true,
                  },
                  {
                    name: t("investmentAdvisor"),
                    selector: (row) => row.investmentAdvisor,
                    center: true,
                    sortable: true,
                  },
                  {
                    name: t("balanceApplied"),
                    selector: (row) => row.balanceApplied,
                    center: true,
                    sortable: true,
                  },
                  {
                    name: t("balanceAvailable"),
                    selector: (row) => row.balanceAvailable,
                    center: true,
                    sortable: true,
                  },
                  {
                    name: t("averageBalance"),
                    selector: (row) => row.averageBalance,
                    center: true,
                    sortable: true,
                  },
                ]}
                data={accountHolderList}
                progressPending={!accountHolderList}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default AccountHolders;

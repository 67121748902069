import React, { useRef, useEffect, useState, useMemo } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Row, Col, Card, CardBody, Button } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { format } from "date-fns";
import { Form } from "@unform/web";
import { useTranslation } from "react-i18next";

import api from "../../services/api";
import SEO from "../../components/SEO";
import ActionBar from "../../components/ActionBar";
import Tabs from "../../components/Tabs";
import CompanyProfileTab from "../../components/listingCompany/details/CompanyProfileTab";
import FinancialStatementsTab from "../../components/listingCompany/details/FinancialStatementsTab";
import FinancialIndicatorsTab from "../../components/listingCompany/details/FinancialIndicatorsTab";
import CurrentOfferTab from "../../components/listingCompany/details/CurrentOfferTab";
import CurrentAccount from "../../components/CurrentAccount";
import DataTable from "../../components/DataTable";
import Spinner from "../../components/Spinner";
import Attachments from "../../components/listingCompany/Attachments";
import { useBreadcrumb } from "../../hooks/useBreadcrumb";
import usePermissions from "../../hooks/usePermissions";
import { dataContext, permission } from "../../utils/permissionEnums";
import { useEditListing } from "../../hooks/useEditListing";
import { addMessage } from "../../store/modules/toast/actions";
import { Listing } from "../../store/modules/listing/types";
import { handleErrorResponse } from "utils/error-handlers";
import { formatTelephoneCellphone } from "utils/documents";
import { ACCESS_TYPE_ADMIN_A0, ACCESS_TYPE_ORIGINATOR_C0, ACCESS_TYPE_ORIGINATOR_C4 } from "utils/constants";
import { AccessType, Profile } from "store/modules/user/types";

import { ApplicationState } from "store";
const ListingDetails: React.FC = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation(["listings", "common"]);

  const { setBreadcrumbItems } = useBreadcrumb();
  const { setListing: setListingHook } = useEditListing();

  const logTableRef = useRef(null);

  const [listing, setListing] = useState<Listing>({} as Listing);
  const [percentage, setPercentage] = useState(0);

  const accessType = useSelector<ApplicationState, AccessType>(
    (state) => state.user.accessType
  );

  const isAccessTypeAdmin = useMemo(
    () => [ACCESS_TYPE_ADMIN_A0].includes(accessType.name),
    [accessType.name]
  );

  const hasPermissionToRefuse = usePermissions(
    dataContext.LISTED_COMPANY,
    permission.REFUSE,
    listing.state
  );
  const hasPermissionToBlock = usePermissions(
    dataContext.LISTED_COMPANY,
    permission.BLOCK,
    listing.state
  );
  const hasPermissionToPublish = usePermissions(
    dataContext.LISTED_COMPANY,
    permission.PUBLISH,
    listing.state
  );
  const hasPermissionToSubmit = usePermissions(
    dataContext.LISTED_COMPANY,
    permission.SUBMIT,
    listing.state
  );
  const hasPermissionToUpdate = usePermissions(
    dataContext.LISTED_COMPANY,
    permission.UPDATE,
    listing.state
  );
  const hasPermissionToDisable = usePermissions(
    dataContext.LISTED_COMPANY,
    permission.DISABLE,
    listing.state
  );
  const hasPermissionToReadRating = usePermissions(
    dataContext.RATING_LISTED_COMPANY,
    permission.READ
  );

  function editListing() {
    setListingHook(listing);
    navigate("/listings/listing");
  }

  async function takeAction(type, reason?) {
    const { data } = await api.patch(`/listedcompanies/${listing.id}/state`, {
      changeState: {
        action: type,
        ...(reason && { message: reason }),
      },
    });
    setListing(data);
    dispatch(
      addMessage({
        title: t("listingUpdated"),
        type: "info",
      })
    );
  }

  async function takeActionDisable(type) {
    const { data } = await api.patch(`/listedcompanies/${listing.id}/disable`);
    setListing(data);
    dispatch(
      addMessage({
        title: t("listingUpdated"),
        type: "info",
      })
    );
  }

  async function handleAction(
    type: "REFUSE" | "BLOCK" | "PUBLISH" | "SUBMIT" | "UPDATE" | "DISABLE",
    reason?: string
  ) {
    if (type === "UPDATE") editListing();
    else if (type === "REFUSE") takeAction(type, reason);
    else if (type === "DISABLE") takeActionDisable(type);
    else takeAction(type);
  }

  function scrollToLogs() {
    logTableRef.current.scrollIntoView({ behavior: "smooth" });
  }

  useEffect(() => {
    setBreadcrumbItems({
      title: "listingDetails",
      items: [
        { title: "home", link: "/dashboard" },
        { title: "listings", link: "/listings" },
        { title: "listingDetails", link: "/" },
      ],
    });
  }, [setBreadcrumbItems]);

  useEffect(() => {
    const controller = new AbortController();

    async function getListingData() {
      try {
        const { data: listingData } = await api.get<Listing>(
          `/listedcompanies/${id}`,
          { signal: controller.signal }
        );

        setPercentage(() => {
          const {
            id: idRemoved,
            company: companyRemoved,
            createdBy: createdByRemoved,
            ...objCopy
          } = listingData;
          const objLength = Object.entries(objCopy).length;
          const objLengthFilled = Object.entries(objCopy).filter(
            ([k, v], i) => !!v
          ).length;
          const result = (objLengthFilled / objLength) * 100;

          return parseFloat(result.toFixed(2));
        });

        //Ajuste para dados antigos
        listingData.size = listingData.size ? listingData.size : listingData.revenueSize?.name;
        listingData.quantityEmployee = listingData.quantityEmployee ? listingData.quantityEmployee : listingData.quantityEmployeeId?.name;
        setListing(listingData);
      } catch (error) {
        const { description } = handleErrorResponse(error);

        console.log("[ListingDetails][getListingData]", description);
      }
    }

    getListingData();

    return () => {
      controller.abort();
    };
  }, [id]);

  if (!listing?.id) return <Spinner />;

  return (
    <>
      <SEO
        title={t("listingDetails", { ns: "breadcrumb" })}
        shouldIndexPage={false}
      />

      <Row>
        <Col xs="12">
          <div className="text-end mb-4">
            <div className="button-items">
              <ActionBar
                actions={[
                  {
                    permission: hasPermissionToRefuse,
                    action: permission.REFUSE,
                  },
                  {
                    permission: hasPermissionToBlock && isAccessTypeAdmin,
                    action: permission.BLOCK,
                  },
                  {
                    permission: hasPermissionToPublish,
                    action: permission.PUBLISH,
                  },
                  {
                    permission: hasPermissionToSubmit,
                    action: permission.SUBMIT,
                  },
                  {
                    permission: hasPermissionToUpdate,
                    action: permission.UPDATE,
                  },
                  {
                    permission: hasPermissionToDisable && listing.allowDisable,
                    action: permission.DISABLE,
                  },
                ]}
                onClick={handleAction}
              />
            </div>
          </div>
        </Col>
      </Row>

      <Row className="mb-4">
        <Col xs="12" md="5">
          <Card className="h-100 mb-0">
            <CardBody className="text-info">
              <Row>
                <Col xs="12" md="6">
                  <small>{t("tradingName")}</small>
                  <h4 className="mb-1">{listing?.tradingName}</h4>
                  <small>{t("mainActivity")}</small>
                  <h4 className="mb-1">{listing?.mainActivity}</h4>
                </Col>
                <Col xs="12" md="6">
                  <small>{t("advisorTradingName")}</small>
                  <h4 className="mb-1">{listing?.advisorTradingName}</h4>
                  <small>{t("alphaCode")}</small>
                  <h4 className="mb-1">{listing?.ticker}</h4>
                </Col>
              </Row>
              <Row>
                <Col xs="12">
                  <Button
                    id="logRoll"
                    color="primary"
                    size="sm"
                    onClick={scrollToLogs}
                  >
                    {t("userActivitiesLog")}
                  </Button>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
        <Col xs="12" md="7">
          <Card className="bg-info text-white h-100 mb-0">
            <CardBody>
              <Row>
                <Col xs="12" md="4">
                  <small>{t("rating")}</small>
                  <h4 className="mb-1">{listing?.rating || <br />}</h4>
                  <small>{t("revenueSize")}</small>
                  <h4 className="mb-1">{(listing?.size ? listing?.size : listing?.revenueSize?.name) || <br />}</h4>
                </Col>
                <Col xs="12" md="8">
                  <small>{t("status")}</small>
                  <h4 className="mb-1">
                    {t(`${listing?.state}`, { ns: "common" }) || <br />}
                  </h4>
                  <small>{t("sector")}</small>
                  <h4 className="mb-1">{(listing?.sector ? listing?.sector : listing?.segment?.name) || <br />}</h4>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Card>
        <div className="widget-user-desc p-4 bg-info position-relative text-white">
          <Row>
            <Col xs="12" sm="4">
              <h4>{t("description")}</h4>
              <p>{listing?.description}</p>
            </Col>
            <Col xs="12" sm="4">
              <h4>{t("headquartersAddress")}</h4>
              {listing?.address && (
                <>
                  <p>
                    {`${listing.address.street}${
                      listing.address.number && `, ${listing.address.number}`
                    }`}
                  </p>
                  <p>
                    {`${listing.address.city}${
                      listing.address.state && `, ${listing.address.state}`
                    }${
                      listing.address.country && `, ${listing.address.country}`
                    }${listing.address.zip && `, ${listing.address.zip}`}`}
                  </p>
                </>
              )}
            </Col>
            <Col xs="12" sm="4">
              <h4>{t("contacts")}</h4>
              <p>{listing?.website}</p>
              <p>{formatTelephoneCellphone(listing?.telephone)}</p>
              <p>{listing?.email}</p>
            </Col>
          </Row>
        </div>
        <CardBody>
          <Tabs
            tabs={[
              {
                id: 1,
                icon: "fas fa-building",
                title: t("companyProfile"),
                content: (
                  <CompanyProfileTab listing={listing} tableRef={logTableRef} />
                ),
              },
              {
                id: 2,
                icon: "fas fa-chart-line",
                title: t("financialStatement"),
                content: <FinancialStatementsTab attachments={listing.attachments} />,
              },
              // {
              //   id: 3,
              //   icon: "fas fa-chart-line",
              //   title: t("financialIndicators"),
              //   content: <FinancialIndicatorsTab listing={listing} />,
              // },

              /* 24/04/2023 - Em pedido da do BUG 882 remover a aba emissões porém deixar comentado a lógica */
              // {
              //   id: 4,
              //   icon: "mdi mdi-cash",
              //   title: t("offers"),
              //   content: <CurrentOfferTab listing={listing} />,
              // },
              hasPermissionToReadRating && {
                id: 5,
                icon: "fas fa-star",
                title: t("rating"),
                content: (
                  <>
                    <h4 className="card-title text-info mb-4 text-uppercase">
                      {t("ratingHistory")}
                    </h4>
                    <DataTable
                      columns={[
                        {
                          name: t("rating"),
                          selector: (row) => row.rating.name,
                          center: true,
                          sortable: true,
                        },
                        {
                          name: t("responsible"),
                          selector: (row) => row.responsibleName,
                          center: true,
                          sortable: true,
                        },
                        {
                          name: t("date"),
                          center: true,
                          sortable: true,
                          cell: (row) =>
                            format(
                              new Date(row.time || null),
                              "dd/MM/yyyy HH:mm:ss"
                            ),
                        },
                      ]}
                      data={listing.ratingHistoric}
                      defaultSortFieldId="time"
                      defaultSortAsc={false}
                    />
                  </>
                ),
              },
              // {
              //   id: 6,
              //   icon: "fas fa-comment-dots",
              //   title: t("auditorsOpinion"),
              //   content: (
              //     <Form onSubmit={() => ({})} initialData={listing}>
              //       <h5 className="card-title text-info mb-4 text-uppercase">
              //         {t("auditorsOpinion")}
              //       </h5>
              //       <Row className="mb-4">
              //         <Col xs="12" md="12">
              //           <TextArea
              //             label={t("auditOpinion")}
              //             name="auditOpinion"
              //             disabled
              //           />
              //         </Col>
              //         <Col xs="12" md="12">
              //           <FileInput
              //             label={t("auditReport")}
              //             name="auditReport"
              //             disabled
              //           />
              //         </Col>
              //       </Row>
              //     </Form>
              //   ),
              // },

              /* 24/04/2023 - Em pedido da do BUG 882 remover a aba conta corrente porém deixar comentado a lógica */
              // listing.state === "PUBLISHED" && {
              //   id: 7,
              //   icon: "fas fa-credit-card",
              //   title: t("currentAccount"),
              //   content: (
              //     <CurrentAccount
              //       account={{
              //         agency: "",
              //         accountNum: "",
              //         totalBalance: 0,
              //         freeToUse: 0,
              //         blockedInFlow: 0,
              //       }}
              //     />
              //   ),
              // },
            ]}
          />
        </CardBody>
      </Card>
    </>
  );
};

export default ListingDetails;

// Action Types
export enum BrokerTypes {
  CREATE_BROKER_REQUEST = '@user/CREATE_BROKER_REQUEST',
  CREATE_BROKER_SUCCESS = '@user/CREATE_BROKER_SUCCESS',

  UPDATE_BROKER_REQUEST = '@user/UPDATE_BROKER_REQUEST',
  UPDATE_BROKER_SUCCESS = '@user/UPDATE_BROKER_SUCCESS',
}

// Data type
interface Address {
  id?: number;
  country: string;
  zip: string;
  state: string;
  city: string;
  neighborhood: string;
  street: string;
  number: string;
  additionalInfo: string;
}

interface Company {
  id?: number;
  name: string;
  cnpj: string;
  companyType: string;
  employees: any[];
}

interface UnitResponsible {
  id: number;
  accessType: string;
  cpf: string;
  login: string;
  name: string;
  profilePicture: string;
  role: string;
  telephone: string;
}

export interface LabelValue {
  value: string;
  label: string;
}

export interface Broker {
  id?: number;
  parentId?: number;
  company: Company;
  tradingName: string;
  state: string;
  quantityEmployee: number;
  annualBillingRange: number;
  officeType: {
    id: string;
    name: string;
  };
  website: string;
  email: string;
  telephone: string;
  cellphone: string;
  address: Address;
  unitResponsible: UnitResponsible;
  certifications: LabelValue[];
}

// State Type
export interface BrokerState {
  readonly loading: boolean;
}

import { Reducer } from "redux";
import produce, { Draft } from "immer";
import { OrderState, OrderTypes } from "./types";

const INITIAL_STATE: OrderState = {
  loading: false,
};

const reducer: Reducer<OrderState> = (state = INITIAL_STATE, action) => {
  return produce(state, (draft: Draft<OrderState>) => {
    switch (action.type) {
      case OrderTypes.CREATE_ORDER_REQUEST: {
        draft.loading = true;
        break;
      }
      case OrderTypes.CREATE_ORDER_SUCCESS: {
        draft.loading = false;
        break;
      }
      case OrderTypes.CREATE_ORDER_FAILURE: {
        draft.loading = false;
        break;
      }
      default:
    }
  });
};

export default reducer;

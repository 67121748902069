import { Reducer } from 'redux';
import produce, { Draft } from 'immer';
import { v4 as uuidv4 } from 'uuid';

import { ToastState, ToastTypes, ToastMessage } from './types';

const INITIAL_STATE: ToastState = {
  messages: [],
};

const reducer: Reducer<ToastState> = (state = INITIAL_STATE, action) => {
  return produce(state, (draft: Draft<ToastState>) => {
    switch (action.type) {
      case ToastTypes.ADD_MESSAGE: {
        const newObj: ToastMessage = {
          id: uuidv4(),
          title: action.payload.title,
          description: action.payload.description,
          type: action.payload.type,
        };
        draft.messages = [...draft.messages, newObj];
        break;
      }
      case ToastTypes.REMOVE_MESSAGE: {
        draft.messages = draft.messages.filter(
          message => message.id !== action.payload,
        );
        break;
      }
      default:
    }
  });
};

export default reducer;

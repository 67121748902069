/**
 * Returns the formatted CPF using this model: `000.000.000-00`.
 * @param cpf - String
 */
export const formatCpf = (cpf: string | undefined) => {
  return typeof cpf === "string"
    ? cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4")
    : "";
};

/**
 * Returns the formatted CNPJ using this model: `00.000.000/0000-00`.
 * @param cnpj - String
 */
export const formatCnpj = (cnpj: string | undefined) => {
  return typeof cnpj === "string"
    ? cnpj.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5")
    : "";
};

/**
 * Returns the formatted telephone using this model: `+(00) 00 0000-0000`.
 * @param telephone - String
 */
export const formatTelephone = (telephone: string | undefined) => {
  return typeof telephone === "string"
    ? telephone.replace(/(\d{2})(\d{2})(\d{4})(\d{4})/, "(+$1) $2 $3-$4")
    : "";
};

/**
 * Returns the formatted telephone using this model: `+(00) 00 00000-0000`.
 * @param telephone - String
 */
export const formatTelephoneCellphone = (telephone: string | undefined) => {
  if(!(typeof telephone === "string")){
    return "";
  }
  return telephone.length == 12 ? telephone.replace(/(\d{2})(\d{2})(\d{4})(\d{4})/, "(+$1) $2 $3-$4")
  : telephone.replace(/(\d{2})(\d{2})(\d{5})(\d{4})/, "(+$1) $2 $3-$4");
};

export const formatCpfCnpj = (value: string | undefined) => {
  if (!value)
    return value;
  if (value.length === 11) {
    return value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, "\$1.\$2.\$3\-\$4");
  } else if (value.length === 14) {
    return value.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, "\$1.\$2.\$3\/\$4\-\$5").replace(/\s+/g, '.');
  } else {
    return value;
  }
};

import styled from 'styled-components';

export const Container = styled.form`
  .rdt_TableCol_Sortable {
    text-align: center;
  }

  .eUeqdG {
    white-space: normal;
  }
`;

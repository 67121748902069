import React, {
  createContext,
  useState,
  useContext,
  Dispatch,
  SetStateAction,
  useMemo,
} from 'react';

import { CommissionBody } from '../store/modules/commission/types';

interface EditCommissionContextData {
  commission: CommissionBody;
  setCommission: Dispatch<SetStateAction<CommissionBody>>;
  hasCommission: boolean;
}

const EditCommissionContext = createContext<EditCommissionContextData>(
  {} as EditCommissionContextData,
);

export const EditCommissionProvider: React.FC = ({ children }) => {
  const [commission, setCommission] = useState<CommissionBody>(null);
  const commissionProviderValue = useMemo(
    () => ({ commission, setCommission, hasCommission: !!commission }),
    [commission, setCommission],
  );

  return (
    <EditCommissionContext.Provider value={commissionProviderValue}>
      {children}
    </EditCommissionContext.Provider>
  );
};

export function useEditCommission(): EditCommissionContextData {
  const context = useContext(EditCommissionContext);

  if (!context) {
    throw new Error(
      'useEditCommission must be used within an EditCommissionProvider',
    );
  }

  return context;
}

import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Badge, Tooltip } from "reactstrap";

interface Props {
  state: string;
  manualIssue?: boolean | null;
  manualRevision?: boolean | null;
  id?: string | number;
}

const STATUS = {
  DRAFT: "bf-draft",
  SUBMITTED: "bg-submitted",
  LIQUIDATED: "bg-liquidated",
  LIQUIDATE_IN_PROGRESS: "bg-liquidate-in-progress",
  PUBLISHED: "bg-published",
  CONSUMED: "bg-consumed",
  APPROVED: "bg-approved",
  CONFIRMED: "bg-confirmed",
  CONVERTED: "bg-converted",
  BLOCKED: "bg-blocked",
  REJECTED: "bg-rejected",
  CANCELED: "bg-canceled",
  CREATED: "bg-created",
  EXPIRED: "bg-expired",
  ISSUED: "bg-batch-issued",
  REVISION: "bg-batch-revision",
  CRIADA: "bg-criada", //MOCK
  EXPIRADA: "bg-expirada", //MOCK
  CONFIRMADA: "bg-confirmada", //MOCK
  WAITING_APPROVAL: "bg-waiting-approval",
  REQUESTED: "bg-requested",
  COMPLETED: "bg-completed",
  INVALIDATED: "bg-invalidated",
  DISABLED: "bg-disabled",
};

const StatusBadge: React.FC<Props> = ({ state, manualIssue, manualRevision, id }) => {
  const { t } = useTranslation(); // for status names
  const { t: offersT } = useTranslation("offers"); // for tooltip messages

  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggle = () => setTooltipOpen(!tooltipOpen);

  const normalizedState = STATUS[state];

  let className;
  if (manualIssue && state === "ISSUED") {
    className = "bg-manual-issued px-2 py-1";
  } else if (manualRevision && state === "REVISION") {
    className = "bg-manual-revision px-2 py-1";
  } else {
    className = `${normalizedState} px-2 py-1`;
  }

  let tooltipMessage = '';
  if (manualRevision && state === "REVISION") {
    tooltipMessage = offersT('manualRevision');
  } else if (manualIssue && state === "ISSUED") {
    tooltipMessage = offersT('manualIssue');
  }

  return (
    <>
      <Badge id={`badge_${id}`} className={className} pill>
        {t(state)}
      </Badge>
      {tooltipMessage &&
        <Tooltip placement="bottom" isOpen={tooltipOpen} target={`badge_${id}`} toggle={toggle}>
          {tooltipMessage}
        </Tooltip>
      }
    </>
  );
};

export default StatusBadge;

import React, { useRef, useState, useEffect } from "react";
import ReactDatePicker, {
  ReactDatePickerProps,
  registerLocale,
} from "react-datepicker";
import ptBR from "date-fns/locale/pt-BR";
import enUS from "date-fns/locale/en-US";
import { useField } from "@unform/core";
import { useTranslation } from "react-i18next";
import "react-datepicker/dist/react-datepicker.css";

import {
  convertBrazilianToDate,
  convertISO8601ToDate,
  isValidBrazilianDate,
  isValidDate,
} from "utils/date-time";

interface Props extends Omit<ReactDatePickerProps, "onChange"> {
  name: string;
  label: string;
  placeholder?: string;
  required?: boolean;
}

const DatePicker: React.FC<Props> = ({
  name,
  label,
  placeholder,
  required = false,
  onFocus,
  ...rest
}) => {
  const { i18n } = useTranslation();
  const { fieldName, registerField, defaultValue, error } = useField(name);

  const datepickerRef = useRef(null);

  const [date, setDate] = useState(undefined);
  const [isFocused, setIsFocused] = useState(false);

  const handleChange = (date: Date) => {
    if (!isValidDate(date)) {
      setDate(undefined);

      return;
    }

    setDate(date);
    setIsFocused(false);
  };

  const handleFocus = (e) => {
    setIsFocused(true);

    if (onFocus) {
      onFocus(e);
    }
  };

  const handleBlur = ({ target }) => {
    setIsFocused(false);

    if (!isValidBrazilianDate(target.value)) {
      setDate(undefined);

      return;
    }

    setDate(convertBrazilianToDate(target.value));
  };

  useEffect(() => {
    if (isFocused) {
      datepickerRef.current.input.style.boxShadow = "0 0 0 1px #2684FF";
      datepickerRef.current.input.style.borderColor = "#2684FF";
    } else {
      datepickerRef.current.input.style.boxShadow = "";
      datepickerRef.current.input.style.borderColor = "";
    }
  }, [isFocused]);

  useEffect(() => {
    registerLocale("pt-BR", ptBR);
    registerLocale("en-US", enUS);
  }, []);

  useEffect(() => {
    setDate(defaultValue ? convertISO8601ToDate(defaultValue) : undefined);
  }, [defaultValue]);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: datepickerRef.current,
      path: "props.selected",
      clearValue: (ref: any) => {
        ref.clear();
      },
    });
  }, [fieldName, registerField]);

  return (
    <div className="mb-3">
      <label htmlFor={fieldName}>{`${label}${required ? " *" : ""}`}</label>
      <ReactDatePicker
        id={fieldName}
        className={`form-control ${error ? "is-invalid" : ""}`}
        ref={datepickerRef}
        selected={date}
        onFocus={handleFocus}
        onChange={handleChange}
        onBlur={handleBlur}
        placeholderText={placeholder}
        wrapperClassName="w-100"
        autoComplete="off"
        locale={i18n.language}
        dropdownMode="select"
        showMonthDropdown
        showYearDropdown
        {...rest}
      />
      {error && <span className="error">{error}</span>}
    </div>
  );
};

export default DatePicker;

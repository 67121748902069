import React from "react";
import { Card, CardBody, Row, Col } from "reactstrap";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import Input from "./FormFields/Input";
import DatePicker from "./FormFields/DatePicker";
import ImageInput from "./FormFields/ImageInput";
import { addMessage } from "../store/modules/toast/actions";
import { MB_IN_BYTES } from "utils/numbers";
import InputMask from "./FormFields/InputMask";

interface ProfessionalDataCardProps {
  title: string;
  disabled?: boolean;
  titleColor?: string;
}

const ProfessionalDataCard: React.FC<ProfessionalDataCardProps> = ({
  title,
  disabled,
  titleColor,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  function verifyDocumentImage(e) {
    const extensions = ["image/jpeg", "image/jpg", "image/png"];

    if (!e.currentTarget.files.length) return;

    if (e.currentTarget.files[0].size > 2 * MB_IN_BYTES) {
      e.currentTarget.value = "";

      dispatch(
        addMessage({
          title: t("attention"),
          description: t("maxFileSize", { size: 2 }),
          type: "info",
        })
      );

      return;
    }

    if (!extensions.includes(e.currentTarget.files[0].type)) {
      e.currentTarget.value = "";

      dispatch(
        addMessage({
          title: t("attention"),
          description: t("fileTypeNotSupported"),
          type: "info",
        })
      );
    }
  }

  return (
    <Card>
      <CardBody>
        <h4 className={`card-title mb-4 text-uppercase ${titleColor || ""}`}>
          {title}
        </h4>

        <Row>
          <Col xs="12" md="4">
            <Input
              label={t("type")}
              name="professionalDocument.type"
              placeholder={t("enterType")}
              disabled={disabled}
            />
          </Col>
          <Col xs="12" md="4">
            <Input
              label={t("dispatcher")}
              name="professionalDocument.dispatcher"
              placeholder={t("enterDispatcher")}
              disabled={disabled}
            />
          </Col>
          <Col xs="12" md="4">
            <Input
              label={t("registryNumber")}
              name="professionalDocument.number"
              placeholder={t("enterRegistryNumber")}
              disabled={disabled}
            />
          </Col>
        </Row>
        <Row>
          <Col xs="12" md="4">
            <DatePicker
              label={t("expirationDate")}
              name="professionalDocument.expirationDate"
              placeholder="dd/mm/aaaa"
              dateFormat="dd/MM/yyyy"
              disabled={disabled}
            />
          </Col>
          <Col xs="12" md="8">
            <ImageInput
              label={t("documentImage")}
              name="professionalDocument.documentImage"
              accept=".jpg,.jpeg,.png"
              onChange={verifyDocumentImage}
              disabled={disabled}
            />
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export default ProfessionalDataCard;

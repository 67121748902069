import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  Card,
  CardBody,
  Row,
  Col,
  Button,
  Input as InputUncontrolled,
  Label,
} from "reactstrap";
import { FormHandles, SubmitHandler } from "@unform/core";
import { Form } from "@unform/web";
import * as Yup from "yup";
import NumberFormat from "react-number-format";
import format from "date-fns/format";
import i18next from "i18next";

import SEO from "../../components/SEO";
import Input from "../../components/FormFields/Input";
import InputMask from "../../components/FormFields/InputMask";
import Select from "../../components/FormFields/Select";
import DataTable from "../../components/DataTable";
import { useBreadcrumb } from "../../hooks/useBreadcrumb";
import { useEditCommission } from "../../hooks/useEditCommission";
import {
  createCommissionRequest,
  updateCommissionRequest,
} from "../../store/modules/commission/actions";
import { addMessage } from "../../store/modules/toast/actions";
import api from "../../services/api";

interface BrokerData {
  brokerName: string;
  brokerCNPJ: string;
}

interface BrokerObject {
  value: number;
  label: string;
  data: BrokerData;
}

interface OfferData {
  listedCompanyCNPJ: string;
  listedCompanyName: string;
  totalAmountOffered: number;
  contractTerm: string;
}

interface OfferList {
  value: number;
  label: string;
  data: OfferData;
}

interface ICommisionList {
  id?: number;
  broker: BrokerObject;
  percentage: number;
}

const Commission: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { setBreadcrumbItems } = useBreadcrumb();
  const { t } = useTranslation("commissions");
  const { hasCommission, commission } = useEditCommission();
  const formRef = useRef<FormHandles>(null);
  const brokerFormRef = useRef<FormHandles>(null);
  const [percentageField, setPercentageField] = useState<number>();
  const [offers, setOffers] = useState<OfferList[]>([]);
  const [brokers, setBrokers] = useState<BrokerObject[]>([]);
  const [selectedOffer, setSelectedOffer] = useState<OfferList>();
  const [selectedBroker, setSelectedBroker] = useState<BrokerObject>();
  const [commissionsList, setCommissionsList] = useState<ICommisionList[]>([]);

  useEffect(() => {
    setBreadcrumbItems({
      title: hasCommission ? "editCommission" : "registerNewCommission",
      items: [
        { title: "home", link: "/dashboard" },
        { title: "systemCommissions", link: "/commissions" },
        {
          title: hasCommission ? "editCommission" : "registerNewCommission",
          link: "",
        },
      ],
    });
  }, [setBreadcrumbItems, hasCommission]);

  useEffect(() => {
    async function getOffers() {
      try {
        const { data } = await api.get("/offers/dropdown");
        setOffers(data);
      } catch (error) {
        console.log(error);
      }
    }

    getOffers();
  }, []);

  async function getBrokers(id: number) {
    if (!id) {
      setBrokers([]);
      return;
    }
    try {
      const { data } = await api.get(`/brokers/dropdown?offer=${id}`);
      setBrokers(data);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    if (commission?.values.length && brokers.length) {
      const stuff = commission.values.map((commissionX) => {
        const obj = brokers.find(
          (broker) => broker.value === commissionX.broker
        );

        return {
          id: commissionX.id,
          broker: obj,
          percentage: commissionX.percentage,
        };
      });
      setCommissionsList(stuff);
    }
  }, [commission, brokers]);

  const handleSubmit: SubmitHandler = async (data) => {
    try {
      formRef.current.setErrors({});

      const schema = Yup.object().shape({
        offer: Yup.string().required(t("requiredField")),
      });
      await schema.validate(data, {
        abortEarly: false,
      });

      const newObj = {
        ...data,
        id: commission?.id,
        values: commissionsList.map((obj) => ({
          broker: obj.broker.value,
          percentage: obj.percentage,
        })),
      };

      if (hasCommission) {
        dispatch(updateCommissionRequest(newObj, navigate));
      } else if (!commissionsList.length) {
        dispatch(
          addMessage({
            title: t("attention"),
            description: t("commissionListMustNotBeEmpty"),
            type: "info",
          })
        );
      } else {
        dispatch(createCommissionRequest(newObj, navigate));
      }
    } catch (err) {
      const validationErrors = {};

      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
        dispatch(
          addMessage({
            title: t("attention"),
            description: t("checkFields"),
            type: "info",
          })
        );
        formRef.current
          .getFieldRef(Object.getOwnPropertyNames(validationErrors)[0])
          .focus();
      }
    }
  };

  const add: SubmitHandler = async (data, { reset }) => {
    try {
      brokerFormRef.current.setErrors({});

      const schema = Yup.object().shape({
        brokerTradingName: Yup.string().required(t("requiredField")),
        percentageField: Yup.string().required(t("requiredField")),
      });
      await schema.validate(
        {
          ...data,
          percentageField,
        },
        {
          abortEarly: false,
        }
      );

      const newObj: ICommisionList = {
        id: data.id,
        broker: selectedBroker,
        percentage: percentageField,
      };

      const checkIfContainsBroker = commissionsList.some(
        (commissionItem) => commissionItem.broker.value === selectedBroker.value
      );

      if (checkIfContainsBroker) {
        dispatch(
          addMessage({
            title: t("attention"),
            description: t("brokerAlreadyHasCommissionOnThisOffer"),
            type: "info",
          })
        );
        brokerFormRef.current.setFieldError(
          "brokerTradingName",
          t("alreadyRegisteredOnTable")
        );
      } else {
        setCommissionsList((prevState) => [...prevState, newObj]);
        reset();
        setPercentageField(0);
      }
    } catch (err) {
      const validationErrors = {};

      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        brokerFormRef.current.setErrors(validationErrors);
        dispatch(
          addMessage({
            title: t("attention"),
            description: t("checkFields"),
            type: "info",
          })
        );
      }
    }
  };

  const remove = (arrIndex) => {
    setCommissionsList((prevState) =>
      prevState.filter((value, index) => index !== arrIndex)
    );
  };

  const edit = (rowIndex, row) => {
    brokerFormRef.current.setFieldValue("brokerTradingName", row.broker);
    brokerFormRef.current.setFieldValue("id", row.id);
    setPercentageField(row.percentage);
    setCommissionsList((prevState) =>
      prevState.filter((value, index) => index !== rowIndex)
    );
  };

  function handleCancel() {
    navigate(-1);
  }

  useEffect(() => {
    const errors = formRef.current?.getErrors();
    const brokerErrors = brokerFormRef.current?.getErrors();

    if (errors && Object.keys(errors).length > 0) {
      formRef.current.submitForm();
    }

    if (brokerErrors && Object.keys(brokerErrors).length > 0) {
      brokerFormRef.current.submitForm();
    }
  }, [i18next.language]);

  return (
    <>
      <SEO
        title={hasCommission ? t("editCommission") : t("registerNewCommission")}
        shouldIndexPage={false}
      />

      <Card>
        <CardBody>
          <h4 className="card-title mb-4 text-uppercase">{t("offer")}</h4>
          <Form
            ref={formRef}
            onSubmit={handleSubmit}
            initialData={hasCommission ? commission : {}}
          >
            <Row>
              <Col xs="12" md="4">
                <Select
                  label={t("offerTicker")}
                  name="offer"
                  options={offers}
                  onChangeValue={(e) => {
                    setSelectedOffer(e);
                    getBrokers(e?.value);
                  }}
                  isClearable
                  isDisabled={hasCommission}
                />
              </Col>
              <Col xs="12" md="4" className="mb-3">
                <Label className="text-capitalize">{t("companyName")}</Label>
                <InputUncontrolled
                  className="form-control"
                  value={selectedOffer?.data.listedCompanyName || ""}
                  disabled
                />
              </Col>
              <Col xs="12" md="4" className="mb-3">
                <Label className="text-capitalize">CNPJ</Label>
                <NumberFormat
                  className="form-control"
                  value={selectedOffer?.data.listedCompanyCNPJ || ""}
                  format="##.###.###/####-##"
                  disabled
                />
              </Col>
            </Row>
            <Row className="justify-content-end mb-3">
              <Col xs="12" md="4" className="mb-3">
                <Label className="text-capitalize">{t("offerTerm")}</Label>
                <InputUncontrolled
                  className="form-control"
                  value={
                    selectedOffer?.data.contractTerm
                      ? format(
                          new Date(selectedOffer?.data.contractTerm),
                          "dd/MM/yyyy"
                        ).toString() || ""
                      : ""
                  }
                  disabled
                />
              </Col>
              <Col xs="12" md="4" className="mb-3">
                <Label className="text-capitalize">{t("volumeOffered")}</Label>
                <NumberFormat
                  className="form-control"
                  value={selectedOffer?.data.totalAmountOffered || ""}
                  thousandSeparator="."
                  decimalSeparator=","
                  prefix="R$ "
                  disabled
                />
              </Col>
            </Row>
          </Form>

          <Form ref={brokerFormRef} onSubmit={add}>
            <Row className="p-2 border rounded border-secondary mb-4">
              <Input name="id" className="d-none" />
              <Col xs="12" md="4">
                <Select
                  label={t("brokerTradingName")}
                  name="brokerTradingName"
                  options={brokers}
                  isDisabled={!brokers.length}
                  onChange={setSelectedBroker}
                />
              </Col>
              <Col xs="12" md="4" className="mb-3">
                <Label className="text-capitalize">
                  {t("brokerCompanyName")}
                </Label>
                <InputUncontrolled
                  className="form-control"
                  value={selectedBroker?.data?.brokerName || ""}
                  disabled
                />
              </Col>
              <Col xs="12" md="4" className="mb-3">
                <Label className="text-capitalize">{t("brokerCnpj")}</Label>
                <NumberFormat
                  className="form-control"
                  value={selectedBroker?.data?.brokerCNPJ || ""}
                  format="##.###.###/####-##"
                  disabled
                />
              </Col>
              <Col xs="12" md="4">
                <InputMask
                  label={t("brokerCommission")}
                  name="percentageField"
                  value={percentageField}
                  suffix="%"
                  decimalScale={percentageField >= 100 ? 0 : 2}
                  fixedDecimalScale
                  onValueChange={(e) => {
                    if (e.floatValue > 100) setPercentageField(100);
                    else setPercentageField(e.floatValue);
                  }}
                  maxLength={percentageField >= 100 ? 3 : null}
                />
              </Col>
              <Col xs="12">
                <Button
                  className="waves-effect waves-light"
                  color="primary"
                  type="submit"
                >
                  <i className="mdi mdi-plus me-2" />
                  {t("add")}
                </Button>
              </Col>
            </Row>
          </Form>

          <Row className="mb-4">
            <DataTable
              pagination={false}
              columns={[
                {
                  name: t("brokerCompanyName"),
                  selector: (row) => row.broker.label,
                  sortable: true,
                },
                {
                  name: t("brokerCommission"),
                  selector: (row) => row.percentage,
                  format: (row) => `${row.percentage}%`,
                },
                {
                  name: "",
                  cell: (row, rowIndex) => (
                    <Button
                      className="waves-effect waves-light"
                      color="primary"
                      size="sm"
                      onClick={() => edit(rowIndex, row)}
                    >
                      <i className="mdi mdi-pencil" />
                      <span className="d-none d-sm-inline ms-2">
                        {t("edit")}
                      </span>
                    </Button>
                  ),
                  button: true,
                },
                {
                  name: "",
                  cell: (row, rowIndex) => (
                    <Button
                      className="waves-effect waves-light"
                      color="danger"
                      size="sm"
                      onClick={() => remove(rowIndex)}
                    >
                      <i className="fas fa-trash-alt" />
                      <span className="d-none d-sm-inline ms-2">
                        {t("remove")}
                      </span>
                    </Button>
                  ),
                  button: true,
                },
              ]}
              customStyles={{}}
              data={commissionsList}
            />
          </Row>
        </CardBody>
      </Card>

      <div className="mb-5 text-end">
        <div>
          <Button
            id="save"
            color="info"
            size="lg"
            className="waves-effect waves-light me-3"
            onClick={() => formRef.current.submitForm()}
          >
            {t("save")}
          </Button>
          <Button
            id="cancel"
            type="button"
            className="waves-effect waves-light"
            color="secondary"
            size="lg"
            onClick={handleCancel}
            outline
          >
            {t("cancel")}
          </Button>
        </div>
      </div>
    </>
  );
};

export default Commission;

import { useSelector } from "react-redux";

import { ApplicationState } from "../store";
import { AccessType } from "../store/modules/user/types";

function usePermissions(
  dataContext: string,
  permissionType?: string,
  status?: string
): boolean {
  const accessType = useSelector<ApplicationState, AccessType>(
    (state) => state.user.accessType
  );
  let result = false;

  result = !!accessType?.permissions.find(
    (obj) => obj.dataContext === dataContext
  );

  if (!result) return false;

  if (permissionType) {
    result =
      result &&
      !!accessType.permissions
        .find((obj) => obj.dataContext === dataContext)
        .permissions.includes(permissionType);
  }

  if (status) {
    let permission = [];

    switch (status) {
      case "APPROVED":
        permission = ["update"];
        break;
      case "ISSUED":
        permission = ["update"];
        break;
      case "REVISION":
        permission = ["update"];
        break;
      case "DRAFT":
        permission = ["submit", "update"];
        break;
      case "SUBMITTED":
        permission = ["refuse", "publish", "update"];
        break;
      case "PUBLISHED":
        permission = ["update", "block", "approve", "disable"];
        break;
      default:
    }
    result = result && permission.includes(permissionType);
  }

  return result;
}

export default usePermissions;

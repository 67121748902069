import React, { Dispatch, SetStateAction, useState } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Button,
  ButtonGroup,
  UncontrolledCollapse,
  FormGroup,
  Label,
  Input,
  InputGroup,
} from "reactstrap";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";
import { TrashButton, ConfirmButton, CancelButton } from '../../components/ButtonsComponents';
import DataTable from "../DataTable";
import { Guarantor, Warranty } from "../../store/modules/offer/types";
import { formatCnpj, formatCpf } from "utils/documents";
import { useEditOffer } from "hooks/useEditOffer";
import { MB_IN_BYTES, formatNumber } from "utils/numbers";
import { addMessage } from "store/modules/toast/actions";
import { useDispatch } from "react-redux";
import { FaRegFileAlt } from "react-icons/fa";
import GuarantorModal from "./GuarantorModal";

interface Props {
  setIsAddWarranty: Dispatch<SetStateAction<boolean>>;
  warrantyList: Warranty[];
  setWarrantyList: Dispatch<SetStateAction<Warranty[]>>;
}

const Warranties: React.FC<Props> = ({
  setIsAddWarranty,
  warrantyList,
  setWarrantyList,
}) => {
  const { t } = useTranslation("offers");
  const dispatch = useDispatch();
  const { offer } = useEditOffer();
  const isPublishedOffer = offer?.state === "PUBLISHED";
  const [confirmRemovalIndex, setConfirmRemovalIndex] = useState<number | null>(null);
  const [editDocumentIndex, setEditDocumentIndex] = useState<number | null>(null);
  const [isAddGuarantor, setIsAddGuarantor] = useState<boolean>(false);
  const [guarantorList, setGuarantorList] = useState<Guarantor[]>([]);
  const [selectedWarrantyIndex, setSelectedWarrantyIndex] = useState<number | null>(null);

  const updateWarranty = (index: number, attribute: string, value: any) => {
    setWarrantyList((prevWarranties) => {
      const updatedWarranties = [...prevWarranties];
      updatedWarranties[index][attribute] = value;
      return updatedWarranties;
    });
  };

  const removeWarranty = (index: number) => {
    const warrantyToCheck = warrantyList[index];
    if (warrantyToCheck.observation) {
      setWarrantyList((prevWarranties) => {
        const updatedWarranties = [...prevWarranties];
        const currentWarranty = updatedWarranties[index];

        updatedWarranties[index] = { ...currentWarranty, status: 'INACTIVE' };

        return updatedWarranties;
      });
    } else {
      dispatch(addMessage({ title: t("error"), description: t("warrantyObservationRequired"), type: "error" }));
    }
  };

  const initiateRemoval = (index: number) => {
    setConfirmRemovalIndex(index);
  };

  const cancelRemoval = () => {
    setConfirmRemovalIndex(null);
  };

  const removeGuarantee = (guaranteeIndex: number, warrantyIndex: number) => {
    const warrantyToCheck = warrantyList[warrantyIndex];
    if (warrantyToCheck.observation) {
      setWarrantyList((prevWarranties) => {
        const updatedWarranties = [...prevWarranties];
        if (updatedWarranties[warrantyIndex].guarantors[guaranteeIndex]) {
            updatedWarranties[warrantyIndex].guarantors[guaranteeIndex].status = 'INACTIVE';
        }
        return updatedWarranties;
      });
    } else {
      dispatch(addMessage({ title: t("error"), description: t("warrantyObservationRequired"), type: "error" }));
    }
  };

  return (
    <Card>

      {isAddGuarantor && (
        <GuarantorModal
          isAddGuarantor={isAddGuarantor}
          setIsAddGuarantor={setIsAddGuarantor}
          onChange={(obj) => {
            if (selectedWarrantyIndex !== null) {
              setWarrantyList((oldWarranties) => {
                const updatedWarranties = [...oldWarranties];
                const newGuarantor = { ...obj, status: 'ACTIVE' };
                const updatedGuarantors = [...updatedWarranties[selectedWarrantyIndex].guarantors, newGuarantor];
                updatedWarranties[selectedWarrantyIndex].guarantors = updatedGuarantors;
                return updatedWarranties;
              });
            }
          }}
        />
      )}

      <CardBody>
        <h4 className="card-title mb-4 text-uppercase">
          {t("warranties")}
          <Button
            className="waves-effect waves-light float-end"
            color="primary"
            id="warranties"
            onClick={() => setIsAddWarranty(true)}
          >
            <i className="mdi mdi-plus me-2" />
            {t("addNewWarranty")}
          </Button>
        </h4>

        {!warrantyList.length ? (
          <div
            className="bg-light d-flex justify-content-center align-items-center w-100"
            style={{ height: "100px" }}
          >
            {t("noWarrantyAdded")}
          </div>
        ) : (
          warrantyList
            .filter(warranty => warranty.status === 'ACTIVE')
            .map((warranty, index) => (
            <Card key={index} className="border shadow-none accordion">
              <CardHeader className="d-flex align-items-center">
                <span
                  className="cursor-pointer"
                  id={`toggler_${index}`}
                  data-toggle="collapse"
                >
                  <u>{t(warranty.warrantyType)}</u>
                </span>
                <ButtonGroup className="ms-auto">
                  <Button
                    className="shadow-none font-size-18 ms-2 p-0"
                    color="none"
                    onClick={() => removeWarranty(index)}
                  >
                    <i className="fas fa-trash-alt" />
                  </Button>
                </ButtonGroup>
              </CardHeader>

              <UncontrolledCollapse
                className="border-top"
                toggler={`#toggler_${index}`}
              >
                <CardBody>
                  <form>
                    <Row>
                      <Col xs="12" md="6">
                        <FormGroup>
                          <Label for="warrantyType">{t("warrantyType")}</Label>
                          <Input
                            id="warrantyType"
                            defaultValue={t(warranty.warrantyType)}
                            disabled
                          />
                        </FormGroup>
                      </Col>
                      <Col xs="12" md="6">
                        <FormGroup>
                          <Label>{t("warrantyInstrument")}</Label>
                          <InputGroup>
                            <Input
                              id="warrantyInstrument"
                              defaultValue={warranty.warrantyInstrumentName}
                              disabled={editDocumentIndex !== index}
                            />
                            {editDocumentIndex === index ? (
                              <Input
                                type="file"
                                onChange={(e) => {
                                  const { files } = e.target;

                                  if (files && files[0]) {
                                    if (files[0].size > 2 * MB_IN_BYTES) {
                                      e.currentTarget.value = "";
                                      dispatch(
                                        addMessage({
                                          title: t("attention"),
                                          description: `${t("maxFileSizeMessage")} 2MB`,
                                          type: "info",
                                        })
                                      );
                                      return;
                                    }

                                    const reader = new FileReader();

                                    reader.onload = (eLoad) => {
                                      if (eLoad.target?.result) {
                                        setWarrantyList((oldWarranties) => {
                                          const newWarranties = [...oldWarranties];
                                          newWarranties[index].warrantyInstrument = eLoad.target.result as string;
                                          newWarranties[index].warrantyInstrumentName = files[0].name;
                                          return newWarranties;
                                        });
                                      }
                                    };

                                    reader.readAsDataURL(files[0]);
                                  }
                                }}

                              />
                            ) : (
                              <>
                                <Button
                                  id="edit-warrantyInstrument"
                                  color="secondary"
                                  onClick={() => setEditDocumentIndex(index)}
                                >
                                  <i className="fas fa-edit" />
                                </Button>
                                <Button
                                  id="download-warrantyInstrument"
                                  color="primary"
                                  href={warranty.warrantyInstrument}
                                  download={warranty?.warrantyInstrumentName ?? uuidv4()}
                                >
                                  <i className="fas fa-download" />
                                </Button>
                              </>
                            )}
                          </InputGroup>
                        </FormGroup>
                      </Col>
                    </Row>
                  </form>

                  <Col xs="12" md="6" className="offset-md-6 text-end mb-2">
                    <Button
                      outline
                      id="addGuarantorBtn"
                      className="waves-effect waves-light"
                      color="secondary"
                      type="button"
                      onClick={() => {
                        setIsAddGuarantor(true);
                        setSelectedWarrantyIndex(index);
                      }}
                    >
                      <i className="mdi mdi-plus me-2" />
                      {t("addGuarantor")}
                    </Button>
                  </Col>

                  <Row>
                    <Col className="mb-2">
                      <Label for="observation">{t("observation")}</Label>
                      <Input
                        type="textarea"
                        placeholder={t("typeYourObservationsHere")}
                        value={warranty.observation}
                        className="w-full"
                        onChange={(e) => {
                          const updatedObservation = e.target.value;
                          updateWarranty(index, "observation", updatedObservation);
                        }}
                      />
                    </Col>
                  </Row>

                  <DataTable
                    pagination={false}
                    columns={[
                      {
                        name: t("guarantorName"),
                        selector: (row) => row.guarantorName,
                        center: true,
                        sortable: true,
                      },
                      {
                        name: t("guarantorCompanyName"),
                        selector: (row) => row.guarantorCompanyName,
                        center: true,
                        sortable: true,
                      },
                      {
                        name: "CPF",
                        selector: (row) => row.guarantorCpf,
                        center: true,
                        sortable: true,
                        cell: ({ guarantorCpf }) => formatCpf(guarantorCpf),
                      },
                      {
                        name: "CNPJ",
                        selector: (row) => row.guarantorCnpj,
                        center: true,
                        sortable: true,
                        cell: ({ guarantorCnpj }) => formatCnpj(guarantorCnpj),
                      },
                      {
                        name: t("guarantorPercentage"),
                        selector: (row) => row.guarantorPercentage,
                        center: true,
                        sortable: true,
                        cell: ({ guarantorPercentage }) =>
                          `${formatNumber({
                            numericValue: Number(guarantorPercentage),
                          })} %`,
                      },
                      {
                        name: "",
                        cell: (row, rowIndex) => {
                          const isCurrentRowSelectedForRemoval = confirmRemovalIndex === rowIndex;
                          return (
                            <ButtonGroup>
                              {!isCurrentRowSelectedForRemoval && (
                                <TrashButton onClick={() => initiateRemoval(rowIndex)} />
                              )}
                              {isCurrentRowSelectedForRemoval && (
                                <>
                                  <ConfirmButton onClick={() => removeGuarantee(rowIndex, index)} />
                                  <CancelButton onClick={cancelRemoval} />
                                </>
                              )}
                            </ButtonGroup>
                          );
                        },
                        button: true,
                      }
                    ]}
                    data={warranty.guarantors.filter(guarantor => guarantor.status === 'ACTIVE')}
                    />
                </CardBody>
              </UncontrolledCollapse>
            </Card>
          ))
        )}
      </CardBody>
    </Card>
  );
};

export default Warranties;

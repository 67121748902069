import React, { TextareaHTMLAttributes, useState } from "react";
import { Controller, Control, get } from "react-hook-form";

interface Props {
  name: string;
  label?: string;
  control: Control<any>;
}
type TextAreaProps = TextareaHTMLAttributes<HTMLTextAreaElement> & Props;

const TextArea: React.FC<TextAreaProps> = ({
  name,
  label,
  control,
  ...rest
}) => (
  <Controller
    name={name}
    control={control}
    defaultValue=""
    render={({ field: { onBlur, ...restField }, formState: { errors } }) => {
      const error = get(errors, name);

      const [isFocused, setIsFocused] = useState(false);

      const getStyles = (pStyle) => {
        if (isFocused) {
          return {
            ...pStyle,
            borderColor: "#2684FF",
            boxShadow: "0 0 0 1px #2684FF",
          };
        }

        return { ...pStyle };
      };

      const handleBlur = () => {
        setIsFocused(false);

        if (onBlur) {
          onBlur();
        }
      };

      return (
        <div className="mb-3">
          {label && <label>{label}</label>}
          <textarea
            className={`form-control ${error && "is-invalid"}`}
            aria-invalid={error !== undefined ? "true" : "false"}
            onFocus={() => setIsFocused(true)}
            onBlur={handleBlur}
            style={getStyles(rest.style)}
            {...rest}
            {...restField}
          />
          {error && <span>{error}</span>}
        </div>
      );
    }}
  />
);

export default TextArea;

import React, { useState } from "react";
import { Controller, Control, get } from "react-hook-form";
import NumberFormat, { NumberFormatProps } from "react-number-format";

export interface Props
  extends NumberFormatProps<React.InputHTMLAttributes<HTMLInputElement>> {
  name: string;
  label: string;
  control: Control<any>;
  required?: boolean;
}

const InputMaskHookForm: React.FC<Props> = ({
  name,
  label,
  control,
  required = false,
  ...rest
}) => (
  <Controller
    name={name}
    control={control}
    defaultValue=""
    render={({
      field: { onBlur, onChange, value, ref },
      formState: { errors },
    }) => {
      const error = get(errors, name);

      const [isFocused, setIsFocused] = useState(false);

      const getStyles = (pStyle) => {
        if (isFocused) {
          return {
            ...pStyle,
            borderColor: "#2684FF",
            boxShadow: "0 0 0 1px #2684FF",
          };
        }

        return { ...pStyle };
      };

      const handleBlur = () => {
        setIsFocused(false);

        if (onBlur) {
          onBlur();
        }
      };

      return (
        <div className="mb-3">
          <label htmlFor={name}>{`${label}${required ? " *" : ""}`}</label>
          <NumberFormat
            getInputRef={ref}
            id={name}
            name={name}
            value={value}
            className={`form-control ${error && "is-invalid"}`}
            aria-invalid={error !== undefined ? "true" : "false"}
            onFocus={() => setIsFocused(true)}
            onBlur={handleBlur}
            onValueChange={(v) => {
              onChange(v.value);
            }}
            thousandSeparator="."
            decimalSeparator=","
            allowedDecimalSeparators={[",", "."]}
            mask="_"
            style={getStyles(rest.style)}
            {...rest}
          />
          {error && <span className="error">{error.message}</span>}
        </div>
      );
    }}
  />
);

export default InputMaskHookForm;

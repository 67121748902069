import React, { useRef, Dispatch, SetStateAction, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Form } from "@unform/web";
import * as Yup from "yup";
import { FormHandles, SubmitHandler } from "@unform/core";
import { Row, Col, Button, Card, CardBody } from "reactstrap";
import { useDispatch } from "react-redux";
import axios from "axios";
import i18next from "i18next";

import Input from "../../FormFields/Input";
import InputMask from "../../FormFields/InputMask";
import DataTable from "../../DataTable";
import { useEditListing } from "../../../hooks/useEditListing";
import { Member, ServiceProvider } from "../../../store/modules/listing/types";
import { addMessage } from "../../../store/modules/toast/actions";

interface Props {
  members: Member[];
  serviceProviders: ServiceProvider[];
  setMembers: Dispatch<SetStateAction<Member[]>>;
  setServiceProviders: Dispatch<SetStateAction<ServiceProvider[]>>;
}

const ServiceProviderAndMembers: React.FC<Props> = ({
  members,
  serviceProviders,
  setMembers,
  setServiceProviders,
}) => {
  const { t } = useTranslation("listings");
  const dispatch = useDispatch();
  const { listing } = useEditListing();
  const serviceProviderRef = useRef<FormHandles>(null);
  const memberRef = useRef<FormHandles>(null);

  const handleServiceProvider: SubmitHandler<ServiceProvider> = async (
    data,
    { reset }
  ) => {
    try {
      serviceProviderRef.current.setErrors({});
      const schema = Yup.object().shape({
        serviceTitle: Yup.string().required(t("requiredField")),
        companyName: Yup.string().required(t("requiredField")),
        address: Yup.object().shape({
          zip: Yup.string().test(
            "len",
            t("invalidValue"),
            ({ length }) => length === 0 || length === 8
          ),
        }),
        telephone: Yup.string().test({
          message: t("invalidValue"),
          test: (value) => {
            if(value != ""){
              if(value.length < 12){
                return false;
              }
            }
            return true;
        }}),
      });
      await schema.validate(data, {
        abortEarly: false,
      });

      const newObj = {
        ...data,
        address: {
          ...data.address,
          id: listing?.id,
        },
      };

      setServiceProviders((oldState) => [...oldState, newObj]);
      reset();
    } catch (err) {
      const validationErrors = {};

      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        serviceProviderRef.current.setErrors(validationErrors);
        dispatch(
          addMessage({
            title: t("attention"),
            description: t("checkFields"),
            type: "info",
          })
        );
        serviceProviderRef.current
          .getFieldRef(Object.getOwnPropertyNames(validationErrors)[0])
          .focus();
      }
    }
  };

  const handleMember: SubmitHandler<Member> = async (data, { reset }) => {
    try {
      memberRef.current.setErrors({});
      const schema = Yup.object().shape({
        name: Yup.string().required(t("requiredField")),
        role: Yup.string().required(t("requiredField")),
      });
      await schema.validate(data, {
        abortEarly: false,
      });

      setMembers((oldState) => [...oldState, data]);
      reset();
    } catch (err) {
      const validationErrors = {};

      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        memberRef.current.setErrors(validationErrors);
        dispatch(
          addMessage({
            title: t("attention"),
            description: t("checkFields"),
            type: "info",
          })
        );
        memberRef.current
          .getFieldRef(Object.getOwnPropertyNames(validationErrors)[0])
          .focus();
      }
    }
  };

  const searchCep = async (e: React.FocusEvent<HTMLInputElement>) => {
    const cep = e.target.value.replace(/\D/g, "");
    serviceProviderRef.current.setErrors({});

    if (cep !== "") {
      const validacep = /^[0-9]{8}$/;

      if (!validacep.test(cep)) {
        serviceProviderRef.current.setErrors({
          "address.zip": t("invalidZip"),
        });
        return;
      }

      const { data } = await axios.get(`https://viacep.com.br/ws/${cep}/json`);
      if (data.erro) {
        serviceProviderRef.current.setErrors({
          "address.zip": t("notFoundZip"),
        });
        return;
      }

      serviceProviderRef.current.setFieldValue(
        "address.street",
        data.logradouro
      );
      // serviceProviderRef.current.setFieldValue(
      //   "address.additionalInfo",
      //   data.complemento
      // );
      serviceProviderRef.current.setFieldValue(
        "address.neighborhood",
        data.bairro
      );
      serviceProviderRef.current.setFieldValue("address.city", data.localidade);
      serviceProviderRef.current.setFieldValue("address.country", "Brasil");
      serviceProviderRef.current.setFieldValue("address.state", data.uf);
    } else {
      serviceProviderRef.current.setFieldValue("address.street", "");
      // serviceProviderRef.current.setFieldValue("address.additionalInfo", "");
      serviceProviderRef.current.setFieldValue("address.neighborhood", "");
      serviceProviderRef.current.setFieldValue("address.city", "");
      serviceProviderRef.current.setFieldValue("address.country", "");
      serviceProviderRef.current.setFieldValue("address.state", "");
    }
  };

  useEffect(() => {
    const errors = memberRef.current?.getErrors();
    const serviceProviderErrors = serviceProviderRef.current?.getErrors();

    if (errors && Object.keys(errors).length > 0) {
      memberRef.current.submitForm();
    }

    if (
      serviceProviderErrors &&
      Object.keys(serviceProviderErrors).length > 0
    ) {
      serviceProviderRef.current.submitForm();
    }
  }, [i18next.language]);

  return (
    <>
      <h4 className="card-title my-4 text-uppercase">
        {t("managementMembers")}
      </h4>
      <Form ref={memberRef} onSubmit={handleMember}>
        <div className="p-3 border rounded border-secondary mb-4">
          <div className="d-none">
            <Input name="id" />
          </div>
          <Row>
            <Col xs="12" md="4">
              <Input label={t("name")} name="name" />
            </Col>
            <Col xs="12" md="4">
              <Input label={t("role")} name="role" />
            </Col>
            <Col xs="12" md="4">
              <Input label="LinkedIn" name="link" />
            </Col>
            <Col xs="12" md="12">
              <Button
                className="waves-effect waves-light"
                color="primary"
                type="submit"
              >
                <i className="mdi mdi-plus me-2" />
                {t("add")}
              </Button>
            </Col>
          </Row>
        </div>
      </Form>
      <DataTable
        noTableHead
        columns={[
          {
            name: t("name"),
            selector: (row) => row.name,
            center: true,
            sortable: true,
          },
          {
            name: t("role"),
            selector: (row) => row.role,
            center: true,
            sortable: true,
          },
          {
            name: "LinkedIn",
            selector: (row) => row.link,
            center: true,
            sortable: true,
          },
          {
            name: "",
            button: true,
            cell: (row, rowIndex) => (
              <>
                <Button
                  className="waves-effect waves-light m-1"
                  color="info"
                  size="sm"
                  onClick={() => {
                    setMembers((oldState) =>
                      oldState.filter((e, i) => i !== rowIndex)
                    );
                    memberRef.current.setData(row);
                  }}
                >
                  <i className="mdi mdi-pencil" />
                </Button>
                <Button
                  className="waves-effect waves-light m-1"
                  color="danger"
                  size="sm"
                  onClick={() => {
                    setMembers((oldState) =>
                      oldState.filter((e, i) => i !== rowIndex)
                    );
                  }}
                >
                  <i className="fas fa-trash-alt" />
                </Button>
              </>
            ),
          },
        ]}
        data={members}
        pagination={members.length > 10}
      />

      <h4 className="card-title my-4 text-uppercase">
        {t("serviceProviders")}
      </h4>
      <Form ref={serviceProviderRef} onSubmit={handleServiceProvider}>
        <div className="p-3 border rounded border-secondary mb-4">
          <div className="d-none">
            <Input name="id" />
          </div>
          <Row>
            <Col xs="12" md="4">
              <Input label={t("serviceTitle")} name="serviceTitle" />
            </Col>
            <Col xs="12" md="4">
              <Input label={t("companyName")} name="companyName" />
            </Col>
            <Col xs="12" md="4">
              <InputMask
                label={t("zip")}
                name="address.zip"
                format="#####-###"
                onBlur={searchCep}
              />
            </Col>
          </Row>
          <Row>
            <Col xs="12" md="4">
              <Input label={t("street")} name="address.street" disabled />
            </Col>
            <Col xs="12" md="4">
              <Input
                label={t("neighborhood")}
                name="address.neighborhood"
                disabled
              />
            </Col>
            <Col xs="12" md="4">
              <Input label={t("number")} name="address.number" />
            </Col>
          </Row>
          <Row>
            <Col xs="12" md="4">
              <Input label={t("country")} name="address.country" disabled />
            </Col>
            <Col xs="12" md="4">
              <Input label={t("state")} name="address.state" disabled />
            </Col>
            <Col xs="12" md="4">
              <Input label={t("city")} name="address.city" disabled />
            </Col>
          </Row>
          <Row>
            <Col xs="12" md="4">
              <Input
                label={t("additionalInfo")}
                name="address.additionalInfo"
              />
            </Col>
            <Col xs="12" md="4">
              <InputMask
                label={t("telephone")}
                name="telephone"
                format="+## (##) ####-####"
              />
            </Col>
            <Col xs="12" md="4">
              <Input label="E-mail" name="email" />
            </Col>
          </Row>
          <Row>
            <Col xs="12" md="4">
              <Input label={t("website")} name="site" />
            </Col>
            <Col xs="12" md="12">
              <Button
                className="waves-effect waves-light"
                color="primary"
                type="submit"
              >
                <i className="mdi mdi-plus me-2" />
                {t("add")}
              </Button>
            </Col>
          </Row>
        </div>
      </Form>
      <Row>
        {serviceProviders.map(
          (
            { id, companyName, serviceTitle, address, telephone, email, site },
            idx
          ) => (
            <Col xs="12" sm="4" key={idx}>
              <Card
                className="bg-info text-white"
                key={`serviceProviderList_${companyName}`}
              >
                <CardBody>
                  <div className="card-title mb-2 d-flex">
                    <div className="d-flex flex-row justify-content-between w-100">
                      <h4>{serviceTitle}</h4>
                      <div>
                        <Button
                          className="waves-effect waves-light me-1"
                          color="light"
                          size="sm"
                          onClick={() => {
                            serviceProviderRef.current.setData(
                              serviceProviders[idx]
                            );
                            setServiceProviders((oldState) =>
                              oldState.filter((e, i) => i !== idx)
                            );
                          }}
                        >
                          <i className="mdi mdi-pencil" />
                        </Button>
                        <Button
                          className="waves-effect waves-light"
                          color="light"
                          size="sm"
                          onClick={() => {
                            setServiceProviders((oldState) =>
                              oldState.filter((e, i) => i !== idx)
                            );
                          }}
                        >
                          <i className="fas fa-trash-alt text-danger" />
                        </Button>
                      </div>
                    </div>
                  </div>
                  <div>
                    <p className="text-success">{companyName}</p>
                    <div>
                      <p className="mb-0">
                        <small>
                          {[address.street, address.number]
                            .filter(Boolean)
                            .join(", ")}
                        </small>
                      </p>
                      <p className="mb-0">
                        <small>
                          {[address.city, address.state, address.zip]
                            .filter(Boolean)
                            .join(", ")}
                        </small>
                      </p>
                      <p className="mb-0">
                        <small>{telephone}</small>
                      </p>
                      <p className="mb-0">
                        <small>{email}</small>
                      </p>
                      <p className="mb-0">
                        <small>{site}</small>
                      </p>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          )
        )}
      </Row>
    </>
  );
};

export default ServiceProviderAndMembers;

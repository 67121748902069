// Action Types
export enum AdvisorTypes {
  CREATE_ADVISOR_REQUEST = "@user/CREATE_ADVISOR_REQUEST",
  CREATE_ADVISOR_SUCCESS = "@user/CREATE_ADVISOR_SUCCESS",

  UPDATE_ADVISOR_REQUEST = "@user/UPDATE_ADVISOR_REQUEST",
  UPDATE_ADVISOR_SUCCESS = "@user/UPDATE_ADVISOR_SUCCESS",

  CHANGE_ADVISOR_PICTURE = "@user/CHANGE_ADVISOR_PICTURE",
}

interface PermissionOrData {
  name: string;
}

interface AccessTypePermissions {
  permission: PermissionOrData;
  data: PermissionOrData[];
}

export interface AccessType {
  name: string;
  description: string;
  permissions: AccessTypePermissions[];
}

// Data type
interface Address {
  id?: number;
  country: string;
  zip: string;
  state: string;
  city: string;
  neighborhood: string;
  street: string;
  number: string;
  additionalInfo: string;
}

export interface Advisor {
  id?: number;
  parentId?: number;
  email: string;
  cellphone: string;
  telephone: string;
  website: string;
  company: {
    id?: number;
    name: string;
    cnpj: string;
    companyType: string;
  };
  tradingName: string;
  quantityEmployee: string;
  revenueSize: any;
  quantityEmployeeId: any;
  type: {
    id: string;
    name: string;
  };
  address: Address;
  financial: {
    name: string;
    cpf: string;
    role: string;
    telephone: string;
  };
}

export type AdvisorList = {
  id: number;
  name: string;
  tradingName: string;
  quantityEmployee: number;
  quantityListedCompanies: number;
  state: string;
  type: string;
};

// State Type
export interface AdvisorState {
  readonly loading: boolean;
}

import React, { useState, useEffect } from 'react';
import { Card, CardBody, Button } from 'reactstrap';
import { useTranslation } from 'react-i18next';

import DataTable from '../../DataTable';

const Recipients: React.FC = () => {
  const { t } = useTranslation('recommendations');
  const [recipients, setRecipients] = useState([]);
  const [selectedRecipientsTable, setSelectedRecipientsTable] = useState([]);
  const [selectedRecipients, setSelectedRecipients] = useState([]);

  useEffect(() => {
    setRecipients(
      [1, 2, 3, 4].map(i => ({
        id: i,
        accountNumber: '123',
        holder: 'Nome',
        suitability: 'Moderado',
        wallet: 'Muito Bom',
        size: 'Qualificado',
        investmentAdvisor: 'Nome do Assessor',
        appliedBalance: 'R$ 50.000,00',
        availableBalance: 'R$ 50.000,00',
        averageBalance: 'R$ 50.000,00',
      })),
    );
  }, []);

  const addSelected = () => {
    setRecipients(oldState =>
      oldState.filter(a => {
        return !selectedRecipientsTable.find(b => {
          return a.id === b.id;
        });
      }),
    );
    setSelectedRecipients(oldState => [
      ...oldState,
      ...selectedRecipientsTable,
    ]);
    setSelectedRecipientsTable([]);
  };

  const addAll = () => {
    setSelectedRecipients(oldState => [...oldState, ...recipients]);
    setRecipients([]);
  };

  const removeFromList = rowIndex => {
    const el = selectedRecipients.find((e, i) => i === rowIndex);
    setSelectedRecipients(oldState =>
      oldState.filter((e, i) => i !== rowIndex),
    );
    setRecipients(oldState => [...oldState, el]);
  };

  return (
    <>
      <Card>
        <CardBody>
          <h4 className="card-title mb-4 text-uppercase">
            {t('selectRecipients')}
          </h4>

          <DataTable
            columns={[
              {
                name: t('accountNumber'),
                selector: row => row.accountNumber,
                center: true,
                sortable: true,
              },
              {
                name: t('holder'),
                selector: row => row.holder,
                center: true,
                sortable: true,
              },
              {
                name: t('size'),
                selector: row => row.size,
                center: true,
                sortable: true,
              },
              {
                name: t('suitability'),
                selector: row => row.suitability,
                center: true,
                sortable: true,
              },
              {
                name: t('wallet'),
                selector: row => row.wallet,
                center: true,
                sortable: true,
              },
            ]}
            data={recipients}
            selectableRows
            onChange={e => setSelectedRecipientsTable(e.selectedRows)}
          />
          <div>
            <Button
              id="addSelected"
              size="md"
              color="primary"
              className="waves-effect waves-light me-3"
              onClick={addSelected}
            >
              {t('addSelected')}
            </Button>
            <Button
              id="addAllWalletClients"
              size="md"
              color="primary"
              className="waves-effect waves-light me-3"
              onClick={addAll}
            >
              {t('addAllWalletClients')}
            </Button>
          </div>
        </CardBody>
      </Card>
      <Card>
        <CardBody>
          <h4 className="card-title mb-4 text-uppercase">
            {t('selectedRecipients')}
          </h4>

          <DataTable
            columns={[
              {
                name: t('accountNumber'),
                selector: row => row.accountNumber,
                center: true,
                sortable: true,
              },
              {
                name: t('holder'),
                selector: row => row.holder,
                center: true,
                sortable: true,
              },
              {
                name: t('size'),
                selector: row => row.size,
                center: true,
                sortable: true,
              },
              {
                name: t('suitability'),
                selector: row => row.suitability,
                center: true,
                sortable: true,
              },
              {
                name: t('investmentAdvisor'),
                selector: row => row.investmentAdvisor,
                center: true,
                sortable: true,
              },
              {
                name: t('appliedBalance'),
                selector: row => row.appliedBalance,
                center: true,
                sortable: true,
              },
              {
                name: t('availableBalance'),
                selector: row => row.availableBalance,
                center: true,
                sortable: true,
              },
              {
                name: t('averageBalance'),
                selector: row => row.averageBalance,
                center: true,
                sortable: true,
              },
              {
                name: '',
                button: true,
                cell: (row, rowIndex) => (
                  <Button
                    className="waves-effect waves-light m-1"
                    color="danger"
                    size="sm"
                    onClick={() => removeFromList(rowIndex)}
                  >
                    <i className="fas fa-trash-alt" />
                  </Button>
                ),
              },
            ]}
            data={selectedRecipients}
          />
        </CardBody>
      </Card>
    </>
  );
};

export default Recipients;

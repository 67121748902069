import React from "react";
import { Container } from "reactstrap";
import { Outlet } from "react-router-dom";

import Topbar from "./Topbar";
import Navbar from "./Navbar";
import Breadcrumb from "../../BreadCrumb";
import CreatedUserSweetAlert from "../../CreatedUserSweetAlert";

const LayoutV: React.FC = () => {
  return (
    <div id="layout-wrapper" style={{ height: "100vh" }}>
      <Topbar />

      <Navbar />

      <div className="main-content">
        <div className="page-content">
          <Container fluid>
            <CreatedUserSweetAlert />
            <Breadcrumb />
            <Outlet />
            {/* <Footer /> */}
          </Container>
        </div>
      </div>
    </div>
  );
};

export default LayoutV;

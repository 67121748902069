import React, { useEffect, useState } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import { Form } from "@unform/web";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { Card, CardBody, Row, Col, Button, Alert, Modal } from "reactstrap";

import ActionBar from "../../components/ActionBar";
import SEO from "../../components/SEO";
import Input from "../../components/FormFields/Input";
import DatePicker from "../../components/FormFields/DatePicker";
import usePermissions from "../../hooks/usePermissions";
import { dataContext, permission } from "../../utils/permissionEnums";
import { addMessage } from "../../store/modules/toast/actions";
import InputPercentage from "../../components/FormFields/InputPercentage";
import api from "../../services/api";
import { handleErrorResponse } from "utils/error-handlers";
import { useBreadcrumb } from "hooks/useBreadcrumb";
import ModalObservation from "./components/ModalObservation";

interface Approval {
  id: number;
  requestDate: string;
  requestType: string;
  requester: string;
  requesterName: string;
  state: string;
}

interface Offer {
  id: number;
  contractNumber: number;
  investorMaximumLotsPercentage: number;
  investorMaximumLotsQuantity: number;
  investorPaybackTime: number;
  emissionDate: number;
  floatingRatePercentage: string;
  ticker: number;
  lotsQuantity: number;
  listedCompany: number;
  reservationDeadline: number;
  reservationsMinimumPercentage: number;
  resourcesDestination: number;
  startReservationDate: number;
  totalEffectiveCost: number;
  totalIssuanceAmount: number;
  latePaymentInterest: number;
  indexer: { name: string };
  frequencyPayment: { id: string; name: string };
  leadingDistributor: { id: string; name: string };
  isExclusive: string;
}

const ApprovalsDetails: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation("approvals");
  const { id } = useParams();
  const { setBreadcrumbItems } = useBreadcrumb();

  const [approvalsList, setApprovalsList] = useState<Approval>();
  const [offerList, setOfferList] = useState<Offer>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [observation, setObservation] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [observationError, setObservationError] = useState("");

  const hasPermissionToApprove = usePermissions(
    dataContext.APPROVALS,
    permission.APPROVE
  );

  const hasPermissionToRefuse = usePermissions(
    dataContext.APPROVALS,
    permission.REFUSE
  );

  const isOkTApprove = () =>
    !offerList?.contractNumber ||
    !offerList?.ticker ||
    !offerList?.reservationDeadline ||
    !offerList?.emissionDate ||
    !offerList?.totalEffectiveCost ||
    !offerList?.leadingDistributor.name;

  const approval = async () => {
    try {
      const { status } = await api.put(
        `/approval/${id}/${
          approvalsList?.requestType === "OFFER_CONSOLIDATION"
            ? "consolidate"
            : "approve"
        }`
      );

      if (status === 204) {
        dispatch(
          addMessage({
            title: t("confirmApprove"),
            type: "success",
          })
        );

        navigate("/approvals");
      }
    } catch (error) {
      dispatch(
        addMessage({
          title: t("incorrectApproval"),
          type: "error",
        })
      );
    }
  };

  const handleValidateMinimum = async () => {
    try {
      if(approvalsList?.requestType === "OFFER_CONSOLIDATION") {
        const { status } = await api.post(`/approval/${id}/validateMinimumReached`);
        if (status === 200) {
          approval();
        }
      } else {
        return approval();
      }
    } catch (error) {
      if (error.response.status === 406) {
        setModalOpen(true);
      } else {
        return console.log(error);
      }
    }
  };

  const handleObservationApproval = async () => {
    if (!observation) {
      setObservationError(t("observationRequired"));
      return;
    }
    setObservationError('');
    try {
      const { status } = await api.put(`/approval/${id}/consolidate`, { observation });

      if (status === 204) {
        dispatch(
          addMessage({
            title: t("confirmApprove"),
            type: "success",
          })
        );
        setModalOpen(false);
        navigate("/approvals");
      }
    } catch (error) {
      dispatch(
        addMessage({
          title: t("incorrectApproval"),
          type: "error",
        })
      );
    }
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const rejectApproval = async () => {
    try {
      const { status } = await api.put(`/approval/${id}/refuse`);

      if (status === 204) {
        dispatch(
          addMessage({
            title: t("refusedOfferSuccessfully"),
            type: "success",
          })
        );

        navigate("/approvals");
      }
    } catch (error) {
      dispatch(
        addMessage({
          title: t("incorrectReject"),
          type: "error",
        })
      );
    }
  };

  const handleAction = async (type: "APPROVE" | "REFUSE", reason?: string) => {
    if (type === "APPROVE" ) {
      handleValidateMinimum();
      return;
    }

    if (type === "REFUSE") {
      rejectApproval();

      return;
    }
  };

  useEffect(() => {
    const controller = new AbortController();

    const getApprovals = async () => {
      setIsLoading(true);

      try {
        const { data } = await api.get(`/approval/${id}`, {
          signal: controller.signal,
        });
        setApprovalsList(data.approval);
        setOfferList(data.offer);
      } catch (error) {
        const { description } = handleErrorResponse(error);

        console.log("[ApprovalsDetails][getApprovals]", description);
      } finally {
        setIsLoading(false);
      }
    };

    getApprovals();

    return () => {
      controller.abort();
    };
  }, []);

  useEffect(() => {
    setBreadcrumbItems({
      title: "approvalDetails",
      items: [
        { title: "home", link: "/dashboard" },
        { title: "approvals", link: "/approvals" },
        { title: "approvalDetails", link: "" },
      ],
    });
  }, [setBreadcrumbItems]);

  return (
    <>
      <SEO
        title={t("approvalDetails", { ns: "breadcrumb" })}
        shouldIndexPage={false}
      />

      <Row>
        <Col xl="12">
          <div className="text-end mb-4">
            <div className="button-items">
              {approvalsList?.state === "WAITING_APPROVAL" && (
                <>
                  <ActionBar
                    actions={[
                      {
                        permission: hasPermissionToApprove,
                        action: permission.APPROVE,
                      },
                      {
                        permission: hasPermissionToRefuse,
                        action: permission.REFUSE,
                      },
                    ]}
                    requestType={approvalsList?.requestType}
                    onClick={handleAction}
                    disabled={
                      isOkTApprove() &&
                      approvalsList?.requestType === "OFFER_PUBLICATION"
                    }
                  />
                </>
              )}

              <Button
                id="cancel-button"
                type="button"
                color="secondary"
                size="sm"
                className="waves-effect waves-light rounded-pill px-3"
                onClick={() => navigate("/approvals")}
              >
                {t("cancel")}
                <i className="mdi mdi-close ms-1" />
              </Button>
            </div>
          </div>
        </Col>
      </Row>

      {!isLoading && isOkTApprove() && (
        <Alert color="danger">
          <Link to={`/offers/offer/${offerList.id}`}>
            <span
              className="alert-link"
              style={{ textDecoration: "underline" }}
            >
              {t("erromessageApprove")}
              <i className="mdi mdi-square-edit-outline ms-1" />
            </span>
          </Link>
        </Alert>
      )}

      {approvalsList && approvalsList?.requestType !== "OFFER_CONSOLIDATION" && (
        <Form initialData={approvalsList} onSubmit={() => ({})}>
          <Card>
            <CardBody>
              <h4 className="card-title text-info mb-4 text-uppercase">
                {t("requestInformation")}
              </h4>

              <Row>
                <Col xs="12" md="4">
                  <DatePicker
                    label={t("requestDate")}
                    name="requestDate"
                    dateFormat="dd/MM/yyyy"
                    disabled
                  />
                </Col>
                <Col xs="12" md="4">
                  <Input
                    label={t("requestType")}
                    name="requestType"
                    defaultValue={t(`${approvalsList.requestType}`, {
                      ns: "common",
                    })}
                    disabled
                  />
                </Col>
                <Col xs="12" md="4">
                  <Input label={t("requester")} name="requester" disabled />
                </Col>
              </Row>

              <Row>
                <Col xs="12" md="4">
                  <Input
                    label={t("state")}
                    name="state"
                    defaultValue={t(`${approvalsList.state}`, {
                      ns: "common",
                    })}
                    disabled
                  />
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Form>
      )}

      <Form onSubmit={() => ({})} initialData={offerList}>
        <Card>
          <CardBody>
            <h4 className="card-title text-info mb-4 text-uppercase">
              {t("issueInformation")}
            </h4>

            <Row>
              <Col xs="12" md="4">
                <Input label="Ticker" name="ticker" maxLength={3} disabled />
              </Col>
              <Col xs="12" md="8">
                <Input
                  label={t("instrumentType")}
                  name="instrumentType.name"
                  disabled
                />
              </Col>
            </Row>
            <Row>
              <Col xs="12" md="4">
                <Input label={t("currency")} name="currency.name" disabled />
              </Col>
              <Col xs="12" md="4">
                <Input
                  label={t("contractNumber")}
                  name="contractNumber"
                  disabled
                />
              </Col>
              <Col xs="12" md="4">
                <Input
                  label={t("cashierRelease")}
                  name="cashierRelease.name"
                  disabled
                />
              </Col>
              <Col xs="12" md="4">
                <DatePicker
                  label={t("emissionDate")}
                  name="emissionDate"
                  dateFormat="dd/MM/yyyy"
                  disabled
                />
              </Col>
              <Col xs="12" md="4">
                <Input label={t("unitValue")} name="unitValue" disabled />
              </Col>
              <Col xs="12" md="4">
                <Input
                  label={t("paymentForm")}
                  name="paymentForm.name"
                  disabled
                />
              </Col>
            </Row>
            <Row>
              <Col xs="12" md="4">
                <InputPercentage
                  label={t("floatingRatePercentage")}
                  name="floatingRatePercentage"
                  disabled
                />
              </Col>
              <Col xs="12" md="4">
                <InputPercentage
                  label={t("investorMaximumLotsPercentage")}
                  name="investorMaximumLotsPercentage"
                  disabled
                />
              </Col>
              <Col xs="12" md="4">
                <Input
                  label={t("interestRateApplication")}
                  name="interestRateApplication.name"
                  disabled
                />
              </Col>
            </Row>

            <Row>
              <Col xs="12" md="4">
                <Input label={t("indexer")} name="indexer.name" disabled />
              </Col>
              <Col xs="12" md="4">
                <Input
                  label={t("frequencyPayment")}
                  name="frequencyPayment.name"
                  disabled
                />
              </Col>
              <Col xs="12" md="4">
                <Input
                  label={t("interestCalculationCriteria")}
                  name="interestCalculationCriteria.name"
                  disabled
                />
              </Col>
            </Row>

            <Row>
              <Col xs="12" md="4">
                <Input
                  label={t("investorMaximumLotsQuantity")}
                  name="investorMaximumLotsQuantity"
                  disabled
                />
              </Col>

              <Col xs="12" md="4">
                <Input
                  label={t("leadingDistributor")}
                  name="leadingDistributor.name"
                  disabled
                />
              </Col>

              <Col xs="12" md="4">
                <Input
                  label={t("investorPaybackTime")}
                  name="investorPaybackTime"
                  disabled
                />
              </Col>
            </Row>

            <Row>
              <Col xs="12" md="4">
                <Input
                  label={t("lateFeeOption")}
                  name="lateFeeOption.name"
                  disabled
                />
              </Col>

              <Col xs="12" md="4">
                <Input
                  label={t("lateFeeValue")}
                  name="lateFeeValue"
                  disabled
                  prefix="R$ "
                />
              </Col>
              <Col xs="12" md="4">
                <Input
                  label={t("latePaymentInterest")}
                  name="latePaymentInterest"
                  prefix="R$ "
                  disabled
                />
              </Col>
            </Row>

            <Row>
              <Col xs="12" md="4">
                <Input
                  label={t("minimumReservationValue")}
                  name="minimumReservationValue"
                  prefix="R$ "
                  disabled
                />
              </Col>

              <Col xs="12" md="4">
                <Input
                  label={t("remunerationRate")}
                  name="remunerationRate.name"
                  disabled
                />
              </Col>
              <Col xs="12" md="4">
                <DatePicker
                  label={t("reservationDeadline")}
                  name="reservationDeadline"
                  dateFormat="dd/MM/yyyy"
                  disabled
                />
              </Col>
            </Row>

            <Row>
              <Col xs="12" md="4">
                <InputPercentage
                  label={t("reservationsMinimumPercentage")}
                  name="reservationsMinimumPercentage"
                  disabled
                />
              </Col>

              <Col xs="12" md="4">
                <Input
                  label={t("resourcesDestination")}
                  name="resourcesDestination"
                  disabled
                />
              </Col>
              <Col xs="12" md="4">
                <DatePicker
                  label={t("startReservationDate")}
                  name="startReservationDate"
                  dateFormat="dd/MM/yyyy"
                  disabled
                />
              </Col>
            </Row>

            <Row>
              <Col xs="12" md="4">
                <Input
                  label={t("totalEffectiveCost")}
                  name="totalEffectiveCost"
                  disabled
                />
              </Col>

              <Col xs="12" md="4">
                <Input
                  label={t("totalIssuanceAmount")}
                  name="totalIssuanceAmount"
                  disabled
                />
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Form>
      <ModalObservation
        isOpen={modalOpen}
        onClose={handleModalClose}
        onChange={(e) => setObservation(e.target.value)}
        onSubmit={handleObservationApproval}
        observationError={observationError}
      />
    </>
  );
};

export default ApprovalsDetails;

import React from 'react'
import { Modal } from 'reactstrap'

import { useTranslation } from "react-i18next";

interface ModalObservationProps {
  isOpen: boolean;
  onClose: () => void;
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onSubmit: () => void;
  observationError: string;
}

const ModalObservation: React.FC<ModalObservationProps> = ({isOpen, onClose, onChange, onSubmit, observationError}) => {
  const { t } = useTranslation("approvals");

  return (
    <Modal isOpen={isOpen} onClose={onClose} className="modal-dialog-centered" >
        <div className="modal-header ">
          <h2 className="modal-title ">{t("observation")}</h2>
          <button type="button" className="btn-close" onClick={onClose}></button>
        </div>
        <div className="modal-body">
          <span className="text-danger fs-5">{t("observationNotReachedMinimumValue")}</span>
          <div className="form-group">
            <label htmlFor="observation" className="fs-5 mt-2">{t("informations")}</label>
            <textarea
              id="observation"
              className="form-control"
              style={{maxHeight: "100vh", overflow: "auto"}}
              onChange={onChange}
            />
            {observationError && <div className="text-danger">{observationError}</div>}
          </div>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-primary"
            onClick={onSubmit}
          >
            {t("submit")}
          </button>
        </div>
      </Modal>
  )
}

export default ModalObservation

import { all, takeLatest, call, put } from 'redux-saga/effects';

import i18n from '../../../i18n';
import api from '../../../services/api';

import { addMessage } from '../toast/actions';
import { BrokerTypes } from './types';
import { createBrokerSuccess, updateBrokerSuccess } from './actions';

export function* createBroker({ payload }: any) {
  try {
    const { broker, navigate } = payload;

    yield call(api.post, '/brokers', broker);

    yield put(
      addMessage({
        title: i18n.t('toasts:brokerHasBeenCreated'),
        type: 'success',
      }),
    );
    yield put(createBrokerSuccess());
    navigate('/brokers');
  } catch (error) {
    console.log('Erro ao adicionar broker!');
  }
}

export function* updateBroker({ payload }: any) {
  try {
    const { broker, navigate } = payload;

    yield call(api.put, `/brokers/${broker.id}`, broker);

    yield put(
      addMessage({
        title: i18n.t('toasts:brokerHasBeenUpdated'),
        type: 'success',
      }),
    );
    yield put(updateBrokerSuccess());
    navigate(-1);
  } catch (error) {
    console.log('Erro ao atualizar broker!');
  }
}

export default all([
  takeLatest(BrokerTypes.CREATE_BROKER_REQUEST, createBroker),
  takeLatest(BrokerTypes.UPDATE_BROKER_REQUEST, updateBroker),
]);

import React, { useState, useEffect, useMemo } from 'react';
import {
  Card,
  CardBody,
  Row,
  Col,
  Badge,
  FormGroup,
  Label,
  Input,
} from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import SEO from '../../components/SEO';
import DataTable from '../../components/DataTable';
import { useBreadcrumb } from '../../hooks/useBreadcrumb';
import reports from './mock/reports.json';
import reservations from './mock/reservations.json';
import { ApplicationState } from '../../store';
import { Profile } from '../../store/modules/user/types';

const ReservationDetails: React.FC = () => {
  const { setBreadcrumbItems } = useBreadcrumb();
  const { t } = useTranslation('reservations');
  const profile = useSelector<ApplicationState, Profile>(
    state => state.user.profile,
  );
  const isBroker = profile?.accessType.startsWith('B');
  const [loading, setLoading] = useState<boolean>(false);
  const [brokerFilter, setBrokerFilter] = useState('');
  const [advisorFilter, setAdvisorFilter] = useState('');
  const filteredItems = reservations.filter(
    item =>
      item.broker &&
      item.broker.toLowerCase().includes(brokerFilter.toLowerCase()) &&
      item.investmentAdvisor &&
      item.investmentAdvisor
        .toLowerCase()
        .includes(advisorFilter.toLowerCase()),
  );

  useEffect(() => {
    setBreadcrumbItems({
      title: 'reservationDetails',
      items: [
        { title: 'home', link: '/dashboard' },
        { title: 'reservations', link: '/reservations' },
        { title: 'reservationDataSheet', link: '/' },
      ],
    });
  }, [setBreadcrumbItems]);

  useEffect(() => {
    function getData() {
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }

    getData();
  }, []);

  const formatCurrency = (value: number) =>
    new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(value);

  const SubHeaderComponent = useMemo(() => {
    return (
      <>
        <FormGroup className={`pe-2 ${isBroker ? 'd-none' : ''}`}>
          <Label>{t('filterByBroker')}</Label>
          <Input
            value={brokerFilter}
            onChange={e => setBrokerFilter(e.target.value)}
          />
        </FormGroup>
        <FormGroup className="px-2">
          <Label>{t('filterByAdvisor')}</Label>
          <Input
            value={advisorFilter}
            onChange={e => setAdvisorFilter(e.target.value)}
          />
        </FormGroup>
      </>
    );
  }, [brokerFilter, advisorFilter, t, isBroker]);

  return (
    <>
      <SEO title={t('manageReservation')} shouldIndexPage={false} />

      <Row>
        {reports.map((report, key) => (
          <Col md="4" key={key}>
            <Card className="mini-stat bg-primary">
              <CardBody className="mini-stat-img">
                <div className="mini-stat-icon">
                  <i className={`mdi ${report.icon} float-end`} />
                </div>
                <div className="text-white">
                  <h6 className="text-uppercase mb-1 font-size-16">
                    {report.title}
                  </h6>
                  <h6 className="mb-3 fw-normal">{report.titleDesc}</h6>
                  <h2 className="mb-4">{report.value}</h2>
                  <Badge color={report.color}>{report.result}</Badge>
                  <span className="ms-2">{report.desc}</span>
                </div>
              </CardBody>
            </Card>
          </Col>
        ))}
      </Row>

      <Card>
        <CardBody>
          <DataTable
            subHeader
            subHeaderComponent={SubHeaderComponent}
            columns={[
              {
                name: t('broker'),
                selector: row => row.broker,
                center: true,
                sortable: true,
                omit: isBroker,
              },
              {
                name: t('investmentAdvisor'),
                selector: row => row.investmentAdvisor,
                center: true,
                sortable: true,
              },
              {
                name: t('reservedValue'),
                selector: row => row.reservedValue,
                center: true,
                sortable: true,
                cell: ({ reservedValue }) => formatCurrency(reservedValue),
              },
              {
                name: t('recommendationsNum'),
                selector: row => row.recommendationsNum,
                center: true,
                sortable: true,
              },
              {
                name: t('reservationsNum'),
                selector: row => row.reservationsNum,
                center: true,
                sortable: true,
              },
              {
                name: t('expectedComission'),
                selector: row => row.expectedComission,
                center: true,
                sortable: true,
                cell: ({ expectedComission }) =>
                  formatCurrency(expectedComission),
              },
            ]}
            data={filteredItems}
            progressPending={loading}
          />
        </CardBody>
      </Card>
    </>
  );
};

export default ReservationDetails;

import React, { useState, useEffect, useCallback, useMemo } from "react";
import { Row, Col, Card, CardBody, Button, Tooltip } from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import SEO from "../../components/SEO";
import SearchBar from "../../components/SearchBar";
import DataTable from "../../components/DataTable";
import StatusBadge from "../../components/StatusBadge";
import { useEditOffer } from "../../hooks/useEditOffer";
import { useBreadcrumb } from "../../hooks/useBreadcrumb";
import usePermissions from "../../hooks/usePermissions";
import { dataContext, permission } from "../../utils/permissionEnums";
import api from "../../services/api";
import { ApplicationState } from "store";
import { AccessType, Profile } from "store/modules/user/types";
import { formatNumber } from "utils/numbers";
import { tickerRegex } from "utils/constants";
import SweetAlert from "react-bootstrap-sweetalert";
import moment from "moment";
import { getOptionsTrueOrFalse } from "utils/constants";

interface ListedCompany {
  name: string;
  size: string;
  sector: string;
  state: string;
  tradingName: string;
}

interface Offer {
  id?: number;
  ticker: string;
  listedCompany: ListedCompany;
  leadingDistributor: string;
  state: string;
  manualIssue: boolean;
  manualRevision: boolean;
  totalIssuanceAmount: number;
  saleValue: string;
  emissionDate: string;
  reservationDeadline: string;
}

interface Request {
  page: number;
  limit: number;
  sort: string;
  count: number;
  content: Offer[];
}

const Offers: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation("offers");

  const { setBreadcrumbItems } = useBreadcrumb();
  const { setOffer } = useEditOffer();
  const profile = useSelector<ApplicationState, Profile>(
    (state) => state.user.profile
  );

  const [offerList, setOfferList] = useState<Offer[]>([]);
  const [selectedOffers, setSelectedOffers] = useState<Offer[]>([]);
  const [loading, setLoading] = useState(false);
  const [perPage, setPerPage] = useState(10);
  const [totalRows, setTotalRows] = useState(0);
  const [emitJob, setEmitJob] = useState(false);
  const [reviewJob, setReviewJob] = useState(false);
  const [tooltipOpenReview, setTooltipOpenReview] = useState(false);
  const [tooltipOpenIssued, setTooltipOpenIssued] = useState(false);
  const [persistedFilters, setPersistedFilters] = useState({
    name: "",
    ticker: "",
    isExclusive: ""
  });

  const hasPermissionToCreate = usePermissions(
    dataContext.OFFER,
    permission.CREATE
  );

  const hasPermissionToReviewJob = usePermissions(
    dataContext.OFFER,
    permission.REVIEW_JOB
  );

  const hasPermissionToEmitJob = usePermissions(
    dataContext.OFFER,
    permission.EMIT_JOB
  );

  const accessType = useSelector<ApplicationState, AccessType>(
    (state) => state.user.accessType
  );

  const getOffers = useCallback(
    async (
      nameParam = persistedFilters.name,
      tickerParam = persistedFilters.ticker,
      isExclusive = persistedFilters.isExclusive,
      page = 1,
      size = perPage
    ) => {
      setLoading(true);

      let params = {
        companyType: profile.companyType,
        name: nameParam,
        ticker: tickerParam,
        isExclusive: isExclusive,
        page: page,
        limit: size,
        sort: "id:desc",
      };

      let url = "/offers";
      if (profile.companyType === "BROKER") {
        url += "/allofferdistributor";
        delete params.companyType;
      }

      const { data } = await api.get<Request>(url, {
        params: params,
      });

      setOfferList(data.content);
      setTotalRows(data.count);
      setPersistedFilters({
        name: nameParam,
        ticker: tickerParam,
        isExclusive: isExclusive,
      });

      setLoading(false);
    },
    [perPage, persistedFilters.name, persistedFilters.ticker, persistedFilters.isExclusive]
  );

  const handleFetchData = ({ name, ticker, isExclusive }: any) => {
    getOffers(name, ticker, isExclusive);
  };

  const handlePageChange = (page) => {
    getOffers(undefined, undefined, undefined, page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setPerPage(newPerPage);
    getOffers(undefined, undefined, undefined, page, newPerPage);
  };

  const disabledStates = ["DRAFT", "SUBMITTED", "CANCELED"];

  useEffect(() => {
    getOffers();
  }, [getOffers]);

  useEffect(() => {
    setBreadcrumbItems({
      title: "offers",
      items: [
        { title: "home", link: "/dashboard" },
        { title: "offers", link: "/offers" },
      ],
    });
  }, [setBreadcrumbItems]);

  async function emitJobOffers() {
    try {
      const listIds = selectedOffers.map((offer) => offer.id);
      await api.post("/runbatch/issue", {
        ids: listIds,
      });
      getOffers();
      setEmitJob(false);
    } catch (error) {
      console.log(error);
    }
  }

  async function reviewJobOffers() {
    try {
      const listIds = selectedOffers.map((offer) => offer.id);
      await api.post("/runbatch/revision", {
        ids: listIds,
      });
      getOffers();
      setReviewJob(false);
    } catch (error) {
      console.log(error);
    }
  }

  const toggleTooltipReview = () => setTooltipOpenReview(!tooltipOpenReview);
  const toggleTooltipIssued = () => setTooltipOpenIssued(!tooltipOpenIssued);

  const tooltipReview = selectedOffers[0]?.state === "PUBLISHED" && moment(selectedOffers[0]?.reservationDeadline).isAfter(moment());
  const tooltipIssued = selectedOffers[0]?.state === "APPROVED" && moment(selectedOffers[0]?.emissionDate).isAfter(moment());

  return (
    <>
      {emitJob ? (
        <SweetAlert
          warning
          showCancel
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          title={t("doYouWantToEmitThisJob")}
          onConfirm={emitJobOffers}
          onCancel={() => {
            setEmitJob(false);
          }}
        />
      ) : null}

      {reviewJob ? (
        <SweetAlert
          warning
          showCancel
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          title={t("doYouWantToReviewThisJob")}
          onConfirm={reviewJobOffers}
          onCancel={() => {
            setReviewJob(false);
          }}
        />
      ) : null}

      <SEO title={t("offers", { ns: "breadcrumb" })} shouldIndexPage={false} />

      <Row>
        <Col xs="12" className="mb-3">
          <div className="d-flex flex-md-row justify-content-md-between flex-column align-items-center">
            <div>
              {hasPermissionToCreate && (
                <Link to="/offers/offer">
                  <Button
                    id="newOffer"
                    color="primary"
                    size="md"
                    className="waves-effect waves-light me-3"
                    onClick={() => setOffer(null)}
                  >
                    <i className="mdi mdi-plus me-2" />
                    {t("newOffer")}
                  </Button>
                </Link>
              )}
              <Button
                id="btnReservations"
                color="primary"
                size="md"
                disabled={!selectedOffers.length || selectedOffers.length > 1}
                className="waves-effect waves-light"
                onClick={() => {
                  const [anOffer] = selectedOffers;

                  if (anOffer?.id) {
                    navigate(`/offers/${anOffer.id}/reservations`);
                  }
                }}
              >
                {t("reservation")}
              </Button>

              {hasPermissionToReviewJob && (
                <>
                  <Tooltip
                    placement="bottom"
                    isOpen={tooltipReview}
                    target="btnReviewJob"
                    toggle={toggleTooltipReview}
                  >
                    {t("issueStillReserveWindow")}
                  </Tooltip>

                  <Button
                    id="btnReviewJob"
                    color="primary"
                    size="md"
                    disabled={!selectedOffers.length || selectedOffers[0]?.state !== 'PUBLISHED' || moment(selectedOffers[0]?.reservationDeadline).isAfter(moment())}
                    className="waves-effect waves-light ms-3"
                    onClick={() => {
                      setReviewJob(true);
                    }}
                  >
                    {t("reviewJob")}
                  </Button>
                </>
              )}

              {hasPermissionToEmitJob && (
                <>
                  <Tooltip
                    placement="bottom"
                    isOpen={tooltipIssued}
                    target="btnIssuedJob"
                    toggle={toggleTooltipIssued}
                  >
                    {t("issueReachDate")}
                  </Tooltip>

                  <Button
                    id="btnIssuedJob"
                    color="primary"
                    size="md"
                    disabled={!selectedOffers.length || selectedOffers[0]?.state !== 'APPROVED' || moment(selectedOffers[0]?.emissionDate).isAfter(moment())}
                    className="waves-effect waves-light ms-3"
                    onClick={() => {
                      setEmitJob(true);
                    }}
                  >
                    {t("emitJob")}
                  </Button>
                </>
              )}
            </div>

            <SearchBar
              fields={[
                {
                  key: "name",
                  label: t("originator"),
                  variant: "text",
                },
                {
                  key: "ticker",
                  label: "Ticker",
                  variant: "pattern",
                  mask: tickerRegex,
                },
                {
                  key: "isExclusive",
                  label: t("isExclusive"),
                  variant: "select",
                  options: getOptionsTrueOrFalse(t),
                },
              ]}
              onSearch={handleFetchData}
            />
          </div>
        </Col>
        <Col xs="12">
          <Card>
            <CardBody>
              <DataTable
                columns={[
                  {
                    name: "Ticker",
                    cell: ({ id, ticker }) => (
                      <Link to={`/offers/${id}`}>
                        <Button
                          id={`offer_${id}`}
                          type="button"
                          color="info"
                          outline
                          size="sm"
                          className="waves-effect waves-light"
                        >
                          {ticker}
                          <i className="mdi mdi-square-edit-outline ms-1" />
                        </Button>
                      </Link>
                    ),
                    button: true,
                  },
                  {
                    name: t("originator"),
                    selector: (row) => row.listedCompany.tradingName,
                    center: true,
                    sortable: true,
                  },
                  {
                    name: t("leadingDistributor"),
                    selector: (row) => row.leadingDistributor,
                    center: true,
                    sortable: true,
                  },
                  {
                    name: "Status",
                    selector: (row) => `${t(row.state, { ns: "common" })}`,
                    center: true,
                    sortable: true,
                    cell: (row) => (
                      <StatusBadge
                        id={row.id}
                        state={row.state}
                        manualIssue={row.manualIssue}
                        manualRevision={row.manualRevision}
                      />
                    ),
                  },
                  {
                    name: t("totalIssuanceAmount"),
                    selector: (row) => row.totalIssuanceAmount,
                    cell: ({ totalIssuanceAmount }) =>
                      `R$ ${formatNumber({
                        numericValue: totalIssuanceAmount,
                      })}`,
                    center: true,
                    sortable: true,
                  },
                ]}
                data={offerList}
                selectableRows
                disabledCondition={(row) => disabledStates.includes(row.state)}
                onChange={(e) => setSelectedOffers(e.selectedRows)}
                progressPending={loading}
                paginationTotalRows={totalRows}
                onChangePage={handlePageChange}
                onChangeRowsPerPage={handlePerRowsChange}
                paginationServer
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Offers;

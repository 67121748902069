import React from 'react';
import { useTranslation } from 'react-i18next';
import Attachments from '../Attachments';
import { Attachment } from '../../../store/modules/listing/types';

interface Props {
  attachments: Attachment[];
}

const FinancialStatementsTab: React.FC<Props> = ({ attachments }) => {
  const { t } = useTranslation('listings');

  return (
    <>
      <h4 className="card-title text-info mb-4 text-uppercase">
        {t('consolidatedBalanceSheet')}
      </h4>
      <Attachments attachments={attachments} detailPage={true} typeFilter="BPC" attachmentType="BPC" />

      <h4 className="card-title text-info mb-4 text-uppercase">
        {t('incomeStatementForTheConsolidatedFiscalYear')}
      </h4>
      <Attachments attachments={attachments} detailPage={true} typeFilter="DRE" attachmentType="DRE" />

      <h4 className="card-title text-info mb-4 text-uppercase">
        {t('consolidadeCashFlowStatement')}
      </h4>
      <Attachments attachments={attachments} detailPage={true} typeFilter="DFC" attachmentType="DFC" />

      <h4 className="card-title text-info mb-4 text-uppercase">
        {t('shareCapitalComposition')}
      </h4>
      <Attachments attachments={attachments} detailPage={true} typeFilter="CCS" attachmentType="CCS" />
    </>
  );
};

export default FinancialStatementsTab;

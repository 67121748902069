import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Card, CardBody, Button } from "reactstrap";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import format from "date-fns/format";
import { Form } from "@unform/web";
import { SubmitHandler, FormHandles } from "@unform/core";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import i18next from "i18next";

import SEO from "../../components/SEO";
import DataTable from "../../components/DataTable";
import Input from "../../components/FormFields/Input";
import InputMask from "../../components/FormFields/InputMask";
import DatePicker from "../../components/FormFields/DatePicker";
import { useBreadcrumb } from "../../hooks/useBreadcrumb";
import {
  InvestorRegistrationContext,
  useInvestor,
} from "../../hooks/useInvestor";
import { addMessage } from "../../store/modules/toast/actions";
import api from "../../services/api";

interface FormData {
  cpf: string;
  motherName?: string;
  birthDate?: Date;
}

const Investors: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation("investors");
  const { setBreadcrumbItems } = useBreadcrumb();
  const { setInvestor } = useInvestor();
  const formRef = useRef<FormHandles>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [investors, setInvestors] = useState<InvestorRegistrationContext[]>([]);

  useEffect(() => {
    setBreadcrumbItems({
      title: "manageInvestors",
      items: [
        { title: "home", link: "/dashboard" },
        { title: "systemInvestors", link: "/investors" },
      ],
    });
  }, [setBreadcrumbItems]);

  const getInvestor = async (obj: FormData) => {
    try {
      setLoading(true);
      const queryParam = obj.birthDate
        ? `birthdate=${format(new Date(obj.birthDate), "yyyy-MM-dd")}`
        : `motherName=${obj.motherName}`;

      const { data } = await api.get<InvestorRegistrationContext>(
        `/investors/${obj.cpf}?${queryParam}`
      );

      setInvestors([data]);
      setLoading(false);
    } catch (error) {
      dispatch(
        addMessage({
          title: t("attention"),
          description: t("investorNotFound"),
          type: "info",
        })
      );
      setLoading(false);
      setInvestors([]);
    }
  };

  const handleSubmit: SubmitHandler<FormData> = async (data) => {
    try {
      formRef.current.setErrors({});

      const schema = Yup.object().shape(
        {
          cpf: Yup.string().required(t("requiredField")),
          motherName: Yup.string().when("birthDate", {
            is: (birthDate) => !birthDate || birthDate.length === 0,
            then: Yup.string().required(t("motherNameOrBirthDate")),
            otherwise: Yup.string(),
          }),
          birthDate: Yup.date().when("motherName", {
            is: (motherName) => !motherName || motherName.length === 0,
            then: Yup.date().nullable().required(t("motherNameOrBirthDate")),
            otherwise: Yup.date().nullable(),
          }),
        },
        [["motherName", "birthDate"]]
      );
      await schema.validate(data, {
        abortEarly: false,
      });

      getInvestor(data);
    } catch (err) {
      const validationErrors = {};

      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
        dispatch(
          addMessage({
            title: t("attention"),
            description: t("checkFields"),
            type: "info",
          })
        );
        const el = formRef.current.getFieldRef(
          Object.getOwnPropertyNames(validationErrors)[0]
        );

        if (el.focus) {
          el.focus();
        } else if (el.setFocus) {
          el.setFocus();
        }
      }
    }
  };

  const navigateToDetails = (id) => {
    setInvestor(investors[0]);
    navigate(`/investor/${id}`);
  };

  useEffect(() => {
    const errors = formRef.current?.getErrors();

    if (errors && Object.keys(errors).length > 0) {
      formRef.current.submitForm();
    }
  }, [i18next.language]);

  return (
    <>
      <SEO title={t("investors")} shouldIndexPage={false} />

      <Form ref={formRef} onSubmit={handleSubmit}>
        <Card>
          <CardBody>
            <Row>
              <Col xs="12" md="4">
                <InputMask
                  label={t("enterCPF")}
                  name="cpf"
                  autoComplete="off"
                  format="###.###.###-##"
                />
              </Col>
              <Col xs="12" md="4">
                <Input
                  label={t("mothersName")}
                  name="motherName"
                  autoComplete="off"
                  onKeyDown={(e) => {
                    if ((e.target as HTMLInputElement).value.length > 2)
                      formRef?.current.clearField("birthDate");
                  }}
                />
              </Col>
              <Col xs="12" md="4">
                <DatePicker
                  label={t("birthDate")}
                  name="birthDate"
                  dateFormat="dd/MM/yyyy"
                  onSelect={() => {
                    formRef?.current.clearField("motherName");
                  }}
                  onChangeRaw={(e) => {
                    if (e.target?.value?.length > 2)
                      formRef?.current.clearField("motherName");
                  }}
                  isClearable
                />
              </Col>
              <Col xs="12">
                <Button
                  id="search"
                  color="primary"
                  size="md"
                  className="waves-effect waves-light"
                  type="submit"
                >
                  <i className="fa fa-search me-2" />
                  {t("searchInvestor")}
                </Button>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Form>
      <Card>
        <CardBody>
          <DataTable
            columns={[
              {
                name: t("identifier"),
                cell: ({ id, lastName }) => (
                  <Button
                    id={`investor_${id}`}
                    type="button"
                    color="info"
                    outline
                    size="sm"
                    className="waves-effect waves-light"
                    onClick={() => navigateToDetails(id)}
                  >
                    {lastName}
                    <i className="mdi mdi-square-edit-outline ms-1" />
                  </Button>
                ),
                button: true,
              },
              {
                name: t("lastName"),
                selector: (row) => row.lastName,
                center: true,
              },
              {
                name: t("email"),
                selector: (row) => row.email,
                center: true,
              },
              {
                name: t("cellNumber"),
                center: true,
                cell: ({ cellNumber }) =>
                  cellNumber.replace(/(\d{2})(\d{5})(\d{4})/, "($1) $2-$3"),
              },
              {
                name: t("sex"),
                selector: (row) => row.sexo,
                center: true,
              },
            ]}
            data={investors}
            progressPending={loading}
            pagination={false}
          />
        </CardBody>
      </Card>
    </>
  );
};

export default Investors;

import React from 'react';
import { Card, CardBody, Row, Col } from 'reactstrap';
import { useTranslation } from 'react-i18next';

import Input from './FormFields/Input';
import InputMask from './FormFields/InputMask';

interface AccountableProps {
  objName: string;
  title: string;
  disabled?: boolean;
  titleColor?: string;
}

const AccountableCard: React.FC<AccountableProps> = ({
  objName,
  title,
  disabled,
  titleColor,
}) => {
  const { t } = useTranslation();

  return (
    <Card>
      <CardBody>
        <h4 className={`card-title mb-4 text-uppercase ${titleColor || ''}`}>
          {title}
        </h4>
        <Row>
          <Col xs="12" md="8">
            <Input
              label={t('fullName')}
              name={`${objName}.name`}
              type="text"
              placeholder={t('enterFullName')}
              disabled={disabled}
            />
          </Col>
          <Col xs="12" md="4">
            <InputMask
              label="CPF"
              format="###.###.###-##"
              name={`${objName}.cpf`}
              placeholder={t('enterCpf')}
              disabled={disabled}
            />
          </Col>
        </Row>

        <Row>
          <Col xs="12" md="8">
            <Input
              label={t('role')}
              name={`${objName}.role`}
              type="text"
              placeholder={t('enterRole')}
              disabled={disabled}
            />
          </Col>
          <Col xs="12" md="4">
            <InputMask
              label={t('telephone')}
              name={`${objName}.telephone`}
              format="+## (##) ####-####"
              placeholder={t('enterTelephone')}
              disabled={disabled}
            />
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export default AccountableCard;

import React, { useState, useEffect, useCallback, useMemo } from "react";
import { Button, Row, Col, Card, CardBody } from "reactstrap";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";

import SEO from "../../components/SEO";
import SearchBar from "../../components/SearchBar";
import DataTable from "../../components/DataTable";
import { useBreadcrumb } from "../../hooks/useBreadcrumb";
import api from "../../services/api";
import { handleErrorResponse } from "utils/error-handlers";
import usePermissions from "hooks/usePermissions";
import { dataContext, permission } from "utils/permissionEnums";
import { useEditUser } from "hooks/useEditUser";
import { ApplicationState } from "store";
import { AccessType } from "store/modules/user/types";
import { ACCESS_TYPE_ORIGINATOR_C0, ACCESS_TYPE_ORIGINATOR_C4, ACCESS_TYPE_BROKER_B0, ACCESS_TYPE_BROKER_B1, ACCESS_TYPE_BROKER_B2 } from "utils/constants";

interface User {
  id: number;
  registration: string;
  name: string;
  userType: string;
  accreditedUnit: string;
  role: string;
}
interface Request {
  page: number;
  limit: number;
  sort: string;
  count: number;
  content: User[];
}

const Users: React.FC = () => {
  const { t } = useTranslation("users");
  const navigate = useNavigate();
  const { setBreadcrumbItems } = useBreadcrumb();

  const [usersData, setUsersData] = useState<User[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [perPage, setPerPage] = useState<number>(10);
  const [totalRows, setTotalRows] = useState<number>(0);
  const [persistedFilters, setPersistedFilters] = useState({
    name: "",
    accreditedUnit: "",
  });
  const { setUser } = useEditUser();

  const accessType = useSelector<ApplicationState, AccessType>(
    (state) => state.user.accessType
  );

  const isAccessTypeOriginatorOrBroker = useMemo(
    () =>
      [ACCESS_TYPE_ORIGINATOR_C0, ACCESS_TYPE_ORIGINATOR_C4,
        ACCESS_TYPE_BROKER_B0, ACCESS_TYPE_BROKER_B1, ACCESS_TYPE_BROKER_B2
      ].includes(
        accessType.name
      ),
    [accessType.name]
  );

  const getUsers = useCallback(
    async (
      nameParam = persistedFilters.name,
      accreditedUnitParam = persistedFilters.accreditedUnit,
      page = 1,
      size = perPage
    ) => {
      try {
        setLoading(true);

        const { data: userList } = await api.get<Request>(
          `/people?name=${nameParam}&accreditedUnit=${accreditedUnitParam}&page=${page}&limit=${size}`
        );

        setUsersData(userList.content);
        setTotalRows(userList.count);
        setPersistedFilters({
          accreditedUnit: accreditedUnitParam,
          name: nameParam,
        });
      } catch (error) {
        const { description } = handleErrorResponse(error);

        console.log("[Users][getUsers]", description);
      } finally {
        setLoading(false);
      }
    },
    [perPage, persistedFilters.accreditedUnit, persistedFilters.name]
  );

  const handleFetchData = ({ name, accreditedUnit }: any) => {
    getUsers(name, accreditedUnit);
  };

  const handlePageChange = (page) => {
    getUsers(undefined, undefined, page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setPerPage(newPerPage);
    getUsers(undefined, undefined, page, newPerPage);
  };

  const handleAddUser = () => {
    setUser(null);

    navigate("/users/user");
  };

  const hasPermissionToCreateUser = usePermissions(
    dataContext.USER,
    permission.CREATE
  );

  useEffect(() => {
    setBreadcrumbItems({
      title: "users",
      items: [
        { title: "home", link: "/dashboard" },
        { title: "users", link: "/users" },
      ],
    });
  }, [setBreadcrumbItems]);

  useEffect(() => {
    getUsers();
  }, [getUsers]);

  return (
    <>
      <SEO title={t("users")} shouldIndexPage={false} />

      <Row>
        <Col xs="12" className="mb-3">
          {/* <div className="d-flex flex-sm-row justify-content-sm-end"> */}
          <div className="d-flex flex-sm-row justify-content-sm-between align-items-center">
            {hasPermissionToCreateUser && !isAccessTypeOriginatorOrBroker ? (
              <Button
                id="newUser"
                size="md"
                color="primary"
                className="waves-effect waves-light"
                onClick={handleAddUser}
              >
                <i className="mdi mdi-plus me-2" />
                {t("newUser")}
              </Button>
            ) : (
              <div></div>
            )}

            <SearchBar
              fields={[
                {
                  key: "name",
                  label: t("fullName"),
                  variant: "text",
                },
                {
                  key: "accreditedUnit",
                  label: t("accreditedUnit"),
                  variant: "text",
                },
              ]}
              onSearch={handleFetchData}
            />
          </div>
        </Col>
        <Col xs="12">
          <Card>
            <CardBody>
              <DataTable
                columns={[
                  {
                    name: t("registration"),
                    cell: ({ id, registration }) => (
                      <Link to={`/users/${id}`}>
                        <Button
                          id={`user_${id}`}
                          color="info"
                          size="sm"
                          outline
                          className="waves-effect waves-light"
                        >
                          {registration}
                          <i className="mdi mdi-square-edit-outline ms-1" />
                        </Button>
                      </Link>
                    ),
                    button: true,
                  },
                  {
                    name: t("fullName"),
                    selector: (row) => row.name,
                    center: true,
                    sortable: true,
                  },
                  {
                    name: t("userType"),
                    selector: (row) => row.userType,
                    center: true,
                    sortable: true,
                  },
                  {
                    name: t("accreditedUnit"),
                    selector: (row) => row.accreditedUnit,
                    center: true,
                    sortable: true,
                  },
                  {
                    name: t("role"),
                    selector: (row) => row.role,
                    center: true,
                    sortable: true,
                  },
                ]}
                data={usersData}
                progressPending={loading}
                paginationServer
                paginationTotalRows={totalRows}
                onChangePage={handlePageChange}
                onChangeRowsPerPage={handlePerRowsChange}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Users;
